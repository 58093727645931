import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { propOr, pipe, replace } from 'ramda'
import { convertPriceWithLabel } from 'utils/price'
import { SORT_ORDER } from 'utils/table'
import { formatDate, FORMATS } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import { numberWithSpaces, roundNumber, twoDecimal } from 'utils/number'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { USER_ROLES } from 'utils/user'
import useSelectionSaga from 'hooks/useSelectionSaga'

import { getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'
import { getMonitoringArray, getMonitoringMeta, getIsMonitoringLoading } from 'features/monitoring/ducks/selectors'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'
import { getUserLogin, getUserRole } from 'features/auth/ducks/selectors'
import { getHasSomeItemsSelectedGlobally } from 'ducks/global/selectors'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import ProductThumbnail from 'components/ProductThumbnail'
import BooleanIcons from 'components/BooleanIcons'
import AllegroPromo from 'features/allegro/components/AllegroPromo'
import Tooltip from 'components/atoms/Tooltip'
import AllegroPackagePromo from 'components/icons/AllegroPackagePromo'
import AllegroAds from 'components/icons/AllegroAds'
import AllegroBargain from 'components/icons/AllegroBargain'
import Star from 'components/icons/Star'
import AllegroHalfPercent from 'components/icons/AllegroHalfPercent'
import DynamicsIndicator from 'components/DynamicsIndicator'
import MonitoringFilters from 'features/monitoring/components/MonitoringFilters'
import CopyToClipboard from 'components/CopyToClipboard'
import CreateSearchQueryPopover from 'features/searchQueries/components/CreateSearchQueryPopover'
import SelectSearchQueriesMenu from 'features/searchQueries/SelectSearchQueriesMenu'
import Checkbox from 'components/atoms/Checkbox'
import MonitoringSelectedItemsState from 'features/monitoring/components/MonitoringSelectedItemsState'
import ToBeOrderedFilter from 'features/monitoring/components/ToBeOrderedFilter'
import NotSellingFilter from 'features/monitoring/components/NotSellingFilter'
import AddProductsToCart from 'features/products/AddProductsToCart'
import AssignTagPopover from 'features/tags/components/AssignTagPopover'
import AssignWeightSchemaPopover from 'features/weightSchemas/components/AssignWeightSchemaPopover'
import CartStatus from 'features/monitoring/components/CartStatus'
import TransitStatus from 'features/monitoring/components/TransitStatus'
import UpdateMultipleEstimatedShippingTimePopover
  from 'features/products/components/UpdateMultipleEstimatedShippingTimePopover'
import FrozenMoneyHeaderCell from 'features/monitoring/components/FrozenMoneyHeaderCell'
import SnoozeProductsPopover from 'features/products/components/SnoozeProductsPopover'
import SnoozeAutoDecisionPopover from 'features/products/components/SnoozeAutoDecisionPopover'
import FlagsCell from 'features/monitoring/components/FlagsCell'
import MaxPriceFilter from 'features/monitoring/components/MaxPriceFilter'
import MinMarginFilter from 'features/monitoring/components/MinMarginFilter'
import MonitoringColumnsVisibilitySettings from 'features/users/MonitoringColumnsVisibilitySettings'
import DownloadMonitoringCSV from 'features/monitoring/components/DownloadMonitoringCSV'

export const MonitoringTable = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const urlSearchQuery = useUrlSearchQuery()
  const userLogin = useSelector(getUserLogin)
  const userRole = useSelector(getUserRole)
  const hasSomeItemsSelectedGlobally = useSelector(getHasSomeItemsSelectedGlobally)

  useEffect(() => {
    if (userRole !== USER_ROLES.admin && userLogin && urlSearchQuery.hasSearchQuery(API_FIELD_NAME.userLogin) === false) {
      urlSearchQuery.setSearchQuery({
        [API_FIELD_NAME.userLogin]: userLogin
      })
    }
  }, [userLogin])

  useEffect(() => {
    urlSearchQuery.setSearchQuery({
      [API_FIELD_NAME.isActive]: true
    })
  }, [])

  const data = useSelector(getMonitoringArray)
  const isLoading = useSelector(getIsMonitoringLoading)
  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)
  const pagination = useSelector(getMonitoringMeta)
  const fetchMonitoring = useCallback(
    query => dispatch(fetchMonitoringRoutine(query)),
    [dispatch]
  )

  const visibleItemsIds = data.map(data => data.id)

  const {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem,
    handleSelectCollection
  } = useSelectionSaga(visibleItemsIds)

  const headers = [
    {
      id: 'thumbnail',
      sortable: false,
      cell: ''
    },
    {
      id: API_FIELD_NAME.ean,
      sortable: true,
      cell: t('monitoringPage.table.headers.id')
    },
    {
      id: API_FIELD_NAME.productName,
      sortable: true,
      cell: t('monitoringPage.table.headers.name')
    },
    {
      id: API_FIELD_NAME.stock,
      sortable: true,
      cell: t('monitoringPage.table.headers.stock'),
      tooltip: t('monitoringPage.table.tooltip.stock'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.shippingStatus,
      sortable: false,
      cell: ''
    },
    {
      id: API_FIELD_NAME.retailPrice,
      sortable: true,
      cell: t('monitoringPage.table.headers.retailPrice'),
      tooltip: t('monitoringPage.table.tooltip.retailPrice'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.margin,
      sortable: true,
      cell: t('monitoringPage.table.headers.margin'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.frozenMoney,
      sortable: true,
      cell: <FrozenMoneyHeaderCell />,
      tooltip: t('monitoringPage.table.tooltip.frozenMoney'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.salePlanDiff,
      sortable: true,
      cell: t('monitoringPage.table.headers.salePlanDiff'),
      tooltip: t('monitoringPage.table.tooltip.salePlanDiff'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldNew,
      sortable: true,
      cell: t('monitoringPage.table.headers.soldNew'),
      tooltip: t('monitoringPage.table.tooltip.soldNew'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldToday,
      sortable: true,
      cell: t('monitoringPage.table.headers.soldToday'),
      tooltip: t('monitoringPage.table.tooltip.soldToday'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.sold1,
      sortable: true,
      cell: t('monitoringPage.table.headers.sold1'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldShort,
      sortable: true,
      cell: t('monitoringPage.table.headers.soldX', { count: soldShortValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldLong,
      sortable: true,
      cell: t('monitoringPage.table.headers.soldX', { count: soldLongValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.willLastShort,
      sortable: true,
      cell: t('monitoringPage.table.headers.willLastX', { count: soldShortValue }),
      tooltip: t('monitoringPage.table.tooltip.willLastX', { count: soldShortValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.willLastLong,
      sortable: true,
      cell: t('monitoringPage.table.headers.willLastX', { count: soldLongValue }),
      tooltip: t('monitoringPage.table.tooltip.willLastX', { count: soldLongValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.salesDynamics,
      sortable: true,
      cell: t('monitoringPage.table.headers.salesDynamics'),
      tooltip: t('monitoringPage.table.tooltip.salesDynamics'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.lastChange,
      sortable: true,
      cell: t('monitoringPage.table.headers.lastChange'),
      tooltip: t('monitoringPage.table.tooltip.lastChange')
    },
    {
      id: API_FIELD_NAME.isBundled,
      sortable: true,
      cell: t('monitoringPage.table.headers.isBundled')
    },
    {
      id: 'flags',
      sortable: false,
      cell: t('monitoringPage.table.headers.flags')
    },
    {
      id: 'promo',
      sortable: false,
      cell: t('monitoringPage.table.headers.promo')
    },
    {
      id: API_FIELD_NAME.allegroFee,
      sortable: true,
      cell: t('monitoringPage.table.headers.allegroFee'),
      tooltip: t('monitoringPage.table.tooltip.allegroFee'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.linesName,
      sortable: true,
      cell: t('monitoringPage.table.headers.line')
    },
    {
      id: API_FIELD_NAME.estimatedShippingTime,
      sortable: true,
      cell: t('monitoringPage.table.headers.estimatedShippingTime'),
      tooltip: t('monitoringPage.table.tooltip.estimatedShippingTime'),
      align: 'right'
    },
    {
      id: 'selectAll',
      sortable: false,
      cell: (
        <Checkbox
          indeterminate={hasSomeItemSelected}
          checked={hasAllVisibleItemsSelected}
          onChange={handleSelectCollection}
        />
      )
    }
  ]

  const getNumberPropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      numberWithSpaces
    )

  const getPricePropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      convertPriceWithLabel,
      numberWithSpaces
    )

  const getRoundedPricePropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      convertPriceWithLabel,
      roundNumber,
      numberWithSpaces
    )

  const getRoundedNumberPropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      roundNumber,
      numberWithSpaces
    )

  const cellName = columnId => columnId ? `${replace('.', '-', columnId)}` : ''

  const renderItems = data.map((data, index) => (
    <TableRow checked={isItemSelected(data.id)} hover key={propOr(`monitoring-row-${index}`, 'id', data)}>
      <TableCell name={cellName('thumbnail')}>
        <ThumbnailCellContainer>
          <ProductThumbnail product={data} />
        </ThumbnailCellContainer>
      </TableCell>
      <TableCell name={cellName(API_FIELD_NAME.ean)}>
        <Tooltip title={propOr('', 'ean', data)}>
          <div>
            <CopyToClipboard textToCopy={propOr('', 'ean', data)}>
              {propOr('-', 'ean', data)}
            </CopyToClipboard>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell name={cellName(API_FIELD_NAME.productName)}>
        <ProductNameContainer>
          <Tooltip title={propOr('-', 'name', data)}>
            <Link
              id={`product-link-${index}`}
              to={{
                pathname: `/product/${data.id}`,
                state: {
                  from: `${history.location.pathname}${history.location.search}`
                }
              }}
            >
              {propOr('-', 'name', data)}
            </Link>
          </Tooltip>
        </ProductNameContainer>
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.stock)}>
        {getNumberPropAndFormat('stock')(data)}
        {data.virtualStock > 0 && <div className='virtual-stock'>{` (${getNumberPropAndFormat('virtualStock')(data)})`}</div>}
      </TableCell>
      <TableCell name={cellName(API_FIELD_NAME.shippingStatus)}>
        <CellCenteredContainer>
          <CartStatus data={data} />
          &nbsp;
          <TransitStatus data={data} />
        </CellCenteredContainer>
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.retailPrice)}>
        {getPricePropAndFormat('retailPrice')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.margin)}>
        {propOr('0.00', 'margin', data)}%
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.frozenMoney)}>
        {getRoundedPricePropAndFormat('frozenMoney')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.salePlanDiff)}>
        {getNumberPropAndFormat('salePlanDiff')(data)}%
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.soldNew)}>
        {getNumberPropAndFormat('soldNew')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.soldToday)}>
        {getNumberPropAndFormat('soldToday')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.sold1)}>
        {getNumberPropAndFormat('sold1')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.soldShort)}>
        {getNumberPropAndFormat('soldShort')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.soldLong)}>
        {getNumberPropAndFormat('soldLong')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.willLastShort)}>
        {getRoundedNumberPropAndFormat('willLastShort')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.willLastLong)}>
        {getRoundedNumberPropAndFormat('willLastLong')(data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.salesDynamics)}>
        <DynamicCellContainer>
          <DynamicsIndicator value={propOr(0, 'salesDynamics')(data)} />
          <span>
            {getNumberPropAndFormat('salesDynamics')(data)}%
          </span>
        </DynamicCellContainer>
      </TableCell>
      <TableCell name={cellName(API_FIELD_NAME.lastChange)}>
        {
          isNotNilOrEmpty(data.latestCommentCreatedAt) ? (
            <Tooltip
              title={
                <TooltipContent>
                  <strong>{data.latestCommentAuthor}:</strong>
                  <br />{data.latestCommentContent}
                </TooltipContent>
              }
              interactive
            >
              <LastCommentCreatedAtContainer>
                {formatDate(data.latestCommentCreatedAt, FORMATS.slash)}
              </LastCommentCreatedAtContainer>
            </Tooltip>
          ) : '-'
        }
      </TableCell>
      <TableCell name={cellName(API_FIELD_NAME.isBundled)}>
        <BooleanIcons checked={propOr(false, 'isBundled', data)} />
      </TableCell>
      <TableCell name={cellName('flags')}>
        <FlagsCell data={data} />
      </TableCell>
      <TableCell name={cellName('promo')}>
        <PromoCellContainer>
          {propOr(false, 'hasAds', data) && <AllegroPromo logo={<AllegroAds />} promoType={t('monitoringPage.table.allegroPromoTypes.hasAds')} />}
          {propOr(false, 'isEmphasized', data) && <AllegroPromo logo={<Star />} promoType={t('monitoringPage.table.allegroPromoTypes.isEmphasized')} />}
          {propOr(false, 'hasPromotionPackage', data) && <AllegroPromo logo={<AllegroPackagePromo />} promoType={t('monitoringPage.table.allegroPromoTypes.hasPromotionPackage')} />}
          {propOr(false, 'isBargain', data) && <AllegroPromo logo={<AllegroBargain />} promoType={t('monitoringPage.table.allegroPromoTypes.isBargain')} />}
          {propOr(false, 'hasHalfPercent', data) && <AllegroPromo logo={<AllegroHalfPercent />} promoType={t('monitoringPage.table.allegroPromoTypes.hasHalfPercent')} />}
        </PromoCellContainer>
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.allegroFee)}>
        {getPricePropAndFormat('allegroFee')(data)}
      </TableCell>
      <TableCell name={cellName(API_FIELD_NAME.linesName)}>
        {propOr('-', 'lineName', data)}
      </TableCell>
      <TableCell align='right' name={cellName(API_FIELD_NAME.estimatedShippingTime)}>
        {getRoundedNumberPropAndFormat('estimatedShippingTime')(data)}
      </TableCell>
      <TableCell width='2%'>
        <Checkbox
          checked={isItemSelected(data.id)}
          onChange={handleSelectItem(data.id)}
        />
      </TableCell>
    </TableRow>
  ))

  return (
    <EntitiesList
      emptyStateText={t('monitoringPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchMonitoring}
      isLoading={isLoading}
      defaultSortedColumnId={API_FIELD_NAME.stock}
      defaultSortDirection={SORT_ORDER.desc}
      withSearch
      searchLabel={t('monitoringPage.table.search.label')}
      filters={<MonitoringFilters />}
      additionalActionButtons={(
        <>
          <MinMarginFilter />
          <MaxPriceFilter />
          <NotSellingFilter />
          <ToBeOrderedFilter />
          <CreateSearchQueryPopover />
          <SelectSearchQueriesMenu />
          <MonitoringColumnsVisibilitySettings />
          <DownloadMonitoringCSV />
        </>
      )}
      hasSomeItemSelected={hasSomeItemsSelectedGlobally}
      selectionActions={(
        <>
          <MonitoringSelectedItemsState />
          <SelectedItemsActionsContainer>
            <SnoozeAutoDecisionPopover />
            <SnoozeProductsPopover />
            <UpdateMultipleEstimatedShippingTimePopover />
            <AddProductsToCart />
            <AssignTagPopover />
            <AssignWeightSchemaPopover />
          </SelectedItemsActionsContainer>
        </>
      )}
    />
  )
}

MonitoringTable.defaultProps = {
  data: []
}

export default MonitoringTable

const ThumbnailCellContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 10px;
`

const CellCenteredContainer = styled.div`
  display: inline-flex;
  align-items: center;
  
  .virtual-stock {
    font-size: 10px;
  }
`

const LastCommentCreatedAtContainer = styled.div`
  cursor: pointer;
`

const TooltipContent = styled.div`
  font-size: 11px;
  font-weight: 300;
`

const PromoCellContainer = styled.div`
  display: flex;
`

const ProductNameContainer = styled.div`
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DynamicCellContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SelectedItemsActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
