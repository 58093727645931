import i18n from 'providers/i18n'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import {
  head,
  drop,
  includes,
  pipe,
  propOr,
  keys,
  map,
  applySpec,
  identity,
  equals,
  or,
  cond,
  T,
  omit, ifElse, split, last, pathOr, prop, filter, not, propEq
} from 'ramda'
import { convertFromPenniesValue } from 'utils/price'
import { formatDate, FORMATS } from 'utils/date'
import qs from 'qs'
import { fetchTags } from 'services/TagsService'
import { SORT_ORDER } from 'utils/table'
import { PRODUCT_TASKS_API_FIELDS } from 'utils/flags'
import { SHIPPING_CART_STATUS, SHIPPING_STATUS } from 'utils/shippingOrders'
import PATHS from 'utils/paths'

export const booleanFilterOptions = () => [
  {
    label: i18n.t('filters.boolean.true'),
    value: 'true'
  },
  {
    label: i18n.t('filters.boolean.false'),
    value: 'false'
  }
]

export const nullableFilterOptions = () => [
  {
    label: i18n.t('filters.nullable.isNotNull'),
    value: 'isNotNull'
  },
  {
    label: i18n.t('filters.nullable.isNull'),
    value: 'isNull'
  }
]

export const cartStatusFilterOptions = () => [
  {
    label: i18n.t('filters.cartStatus.in_cart'),
    value: SHIPPING_CART_STATUS.inCart
  },
  {
    label: i18n.t('filters.boolean.false'),
    value: 'isNull'
  }
]

export const numericFilterOptions = () => [
  {
    label: '-',
    value: ''
  },
  {
    label: i18n.t('filters.numeric.biggerThan'),
    value: '>'
  },
  {
    label: i18n.t('filters.numeric.lowerThan'),
    value: '<'
  },
  {
    label: i18n.t('filters.numeric.equals'),
    value: '='
  },
  {
    label: i18n.t('filters.numeric.range'),
    value: '|'
  }
]

export const dateFilterOptions = () => [
  {
    label: '-',
    value: ''
  },
  {
    label: i18n.t('filters.date.before'),
    value: '<'
  },
  {
    label: i18n.t('filters.date.after'),
    value: '>'
  },
  {
    label: i18n.t('filters.date.null'),
    value: 'isNull'
  }
]

export const transitFilterOptions = () => [
  {
    label: i18n.t('filters.boolean.true'),
    value: '>0'
  },
  {
    label: i18n.t('filters.boolean.false'),
    value: '=0'
  }
]

export const allegroMultiSelectFilterOptions = () => [
  {
    label: i18n.t('monitoringPage.table.allegroPromoTypes.isEmphasized'),
    value: API_FIELD_NAME.isEmphasized
  },
  {
    label: i18n.t('monitoringPage.table.allegroPromoTypes.hasAds'),
    value: API_FIELD_NAME.hasAds
  },
  {
    label: i18n.t('monitoringPage.table.allegroPromoTypes.hasPromotionPackage'),
    value: API_FIELD_NAME.hasPromotionPackage
  },
  {
    label: i18n.t('monitoringPage.table.allegroPromoTypes.hasHalfPercent'),
    value: API_FIELD_NAME.hasHalfPercent
  },
  {
    label: i18n.t('monitoringPage.table.allegroPromoTypes.isBargain'),
    value: API_FIELD_NAME.isBargain
  }
]

export const flagsMultiSelectFilterOptions = () =>
  pipe(
    map(
      applySpec({
        label: value => i18n.t(`monitoringPage.table.flags.${value.name}`),
        value: propOr('', 'name')
      })
    )
  )(PRODUCT_TASKS_API_FIELDS)

export const fieldsToConvertToPennies = [
  API_FIELD_NAME.retailPrice,
  API_FIELD_NAME.purchasePrice,
  API_FIELD_NAME.frozenMoney,
  API_FIELD_NAME.allegroFee
]

export const dateFields = [
  API_FIELD_NAME.lastChange,
  API_FIELD_NAME.activatedAt,
  API_FIELD_NAME.iaiCreatedAt
]

export const NULL_OPTIONS = {
  isNull: 'isNull',
  isNotNull: 'isNotNull'
}

export const isValueNullOption = value => or(equals(value, NULL_OPTIONS.isNull), equals(value, NULL_OPTIONS.isNotNull))

const keyExistsInArray = array => key => includes(key, array)

const getKeyValueFromGivenObject = obj => key => propOr('', key, obj)

const getConvertedPriceValue = value => {
  const urlIndicator = head(value)
  const priceValue = drop(1, value)
  return urlIndicator + convertFromPenniesValue(priceValue)
}

const getConvertedRangePriceValue = value => {
  const priceBaseValue = pipe(
    drop(1),
    split(','),
    head
  )(value)

  const priceSecondValue = pipe(
    drop(1),
    split(','),
    last
  )(value)

  return `${convertFromPenniesValue(priceBaseValue)} - ${convertFromPenniesValue(priceSecondValue)}`
}

const getConvertedRangeValue = value => {
  const priceBaseValue = pipe(
    drop(1),
    split(','),
    head
  )(value)

  const priceSecondValue = pipe(
    drop(1),
    split(','),
    last
  )(value)

  return `${priceBaseValue} - ${priceSecondValue}`
}

const getConvertedDateValue = value => {
  const urlIndicator = head(value)
  const dateValue = drop(1, value)
  return urlIndicator + formatDate(dateValue, FORMATS.slash)
}

const convertPriceOfKeyValue = obj => key => ifElse(
  keyValueIsRange(obj),
  convertPriceOfRangeKeyValue(obj),
  convertPriceOfStandardKeyValue(obj)
)(key)

const convertPriceOfRangeKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  getConvertedRangePriceValue
)(key)

const convertPriceOfStandardKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  getConvertedPriceValue
)(key)

const isBooleanFilter = value => or(equals('true', value), equals('false', value))
const isNullableFilter = value => or(equals('isNull', value), equals('isNotNull', value))
export const isRangeFilter = indicator => equals(indicator, '|')

const keyValueIsBoolean = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  isBooleanFilter
)(key)

const keyValueIsRange = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  head,
  isRangeFilter
)(key)

const keyValueIsNullable = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  isNullableFilter
)(key)

const keyIsStatus = key => equals(API_FIELD_NAME.shippingStatus, key)
const keyIsVirtualStock = key => equals(API_FIELD_NAME.virtualStock, key)
const keyIsOrderStatus = key => equals(API_FIELD_NAME.orderStatus, key)

const convertBooleanToTranslationOfGivenKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  value => i18n.t(`filters.boolean.${value}`)
)(key)

const convertRangeValueToTranslatedKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  getConvertedRangeValue
)(key)

const convertNullableValueToTranslatedKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  value => i18n.t(`filters.nullable.${value}`)
)(key)

const convertStatusValueToTranslatedKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  value => i18n.t(`filters.cartStatus.${value}`)
)(key)

const convertVirtualStockValueToTranslatedKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  value => i18n.t(`filters.virtualStock.${value}`)
)(key)

const convertDateOfKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  getConvertedDateValue
)(key)

const convertOrderStatusValueToTranslatedKeyValue = obj => key => pipe(
  getKeyValueFromGivenObject(obj),
  value => i18n.t(`filters.orderStatus.${value}`)
)(key)

const convertValueIfNeeded = obj => key => {
  return cond([
    [keyExistsInArray(fieldsToConvertToPennies), convertPriceOfKeyValue(obj)],
    [keyExistsInArray(dateFields), convertDateOfKeyValue(obj)],
    [keyValueIsBoolean(obj), convertBooleanToTranslationOfGivenKeyValue(obj)],
    [keyValueIsRange(obj), convertRangeValueToTranslatedKeyValue(obj)],
    [keyValueIsNullable(obj), convertNullableValueToTranslatedKeyValue(obj)],
    [keyIsStatus, convertStatusValueToTranslatedKeyValue(obj)],
    [keyIsVirtualStock, convertVirtualStockValueToTranslatedKeyValue(obj)],
    [keyIsOrderStatus, convertOrderStatusValueToTranslatedKeyValue(obj)],
    [T, getKeyValueFromGivenObject(obj)]
  ])(key)
}

export const transformFiltersToNamesAndValues = (obj, excludedFilters = []) => {
  return pipe(
    omit(excludedFilters),
    keys,
    map(
      applySpec({
        name: identity,
        value: convertValueIfNeeded(obj)
      })
    )
  )(obj)
}

export const removePaginationQuery = omit([
  'rowsPerPage',
  'page'
])

export const prepareSearchQueryPayload = pipe(
  query => qs.parse(query, { ignoreQueryPrefix: true }),
  removePaginationQuery,
  qs.stringify
)

const generateSearchTagOptions = (valueKey, excludedTags = []) => async searchQuery => {
  try {
    const response = await fetchTags(qs.stringify({
      limit: {
        take: 50,
        page: 1
      },
      order: {
        by: 'name',
        dir: SORT_ORDER.asc
      },
      search: searchQuery
    }))

    return pipe(
      pathOr([], ['data', 'data']),
      map(
        applySpec({
          value: prop(valueKey),
          label: prop('name')
        })
      ),
      filter(
        option => not(includes(option.value, excludedTags))
      )
    )(response)
  } catch (e) {
    console.error(e)
  }
}

export const handleSearchTagOptions = generateSearchTagOptions('id')
export const handleSearchTagFilterOptions = generateSearchTagOptions('name')
export const handleSearchTagOptionsWithExcluded = excludedTagIds => generateSearchTagOptions('id', excludedTagIds)

export const defaultAdminFiltersByPath = userLogin => ({
  [PATHS.monitoring]: { [API_FIELD_NAME.isActive]: true },
  [PATHS.ordersHistory]: { [API_FIELD_NAME.orderStatus]: SHIPPING_STATUS.confirmed },
  [PATHS.alertsOrders]: { [API_FIELD_NAME.alertsUserLogin]: userLogin, [API_FIELD_NAME.isAlertArchived]: false },
  [PATHS.alertsMentions]: { [API_FIELD_NAME.alertsUserLogin]: userLogin, [API_FIELD_NAME.isAlertArchived]: false },
  [PATHS.alertsPriceDown]: { [API_FIELD_NAME.alertsUserLogin]: userLogin, [API_FIELD_NAME.isAlertArchived]: false },
  [PATHS.alertsSale]: { [API_FIELD_NAME.alertsUserLogin]: userLogin, [API_FIELD_NAME.isAlertArchived]: false }
})

export const defaultUserFiltersByPath = userLogin => ({
  [PATHS.monitoring]: { [API_FIELD_NAME.userLogin]: userLogin, [API_FIELD_NAME.isActive]: true },
  [PATHS.alertsOrders]: { [API_FIELD_NAME.alertsUserLogin]: userLogin },
  [PATHS.alertsMentions]: { [API_FIELD_NAME.alertsUserLogin]: userLogin },
  [PATHS.alertsPriceDown]: { [API_FIELD_NAME.alertsUserLogin]: userLogin },
  [PATHS.alertsSale]: { [API_FIELD_NAME.alertsUserLogin]: userLogin },
  [PATHS.cart]: { [API_FIELD_NAME.userLogin]: userLogin },
  [PATHS.ordersHistory]: { [API_FIELD_NAME.shippingOrderUsers]: userLogin, [API_FIELD_NAME.orderStatus]: SHIPPING_STATUS.confirmed }
})

export const isFilteredByCurrentUser = (userLogin, transformedFilters, fieldName = API_FIELD_NAME.userLogin) => {
  return pipe(
    filter(propEq('name', fieldName)),
    head,
    propOr('', 'value'),
    equals(userLogin)
  )(transformedFilters)
}

export const isFilteredByActiveAlerts = (transformedFilters, fieldName = API_FIELD_NAME.isAlertArchived) => {
  return pipe(
    filter(propEq('name', fieldName)),
    head,
    propOr('', 'value'),
    toString,
    equals('false')
  )(transformedFilters)
}

export const isOrdersFilteredByCurrentUser = (userLogin, transformedFilters, fieldName = API_FIELD_NAME.shippingOrderUsers) => {
  return pipe(
    filter(propEq('name', fieldName)),
    head,
    propOr('', 'value'),
    equals(userLogin)
  )(transformedFilters)
}

export const isFilteredByActive = transformedFilters => {
  return pipe(
    filter(propEq('name', API_FIELD_NAME.isActive)),
    head,
    propOr(false, 'value'),
    equals(i18n.t('filters.boolean.true'))
  )(transformedFilters)
}

export const isFilteredByOrderConfirmed = transformedFilters => {
  return pipe(
    filter(propEq('name', API_FIELD_NAME.orderStatus)),
    head,
    propOr('', 'value'),
    equals(i18n.t(`filters.orderStatus.${SHIPPING_STATUS.confirmed}`))
  )(transformedFilters)
}

export const checkDefaultFiltersForAdmin = (userLogin, transformedFilters) => ({
  [PATHS.monitoring]: isFilteredByActive(transformedFilters) && transformedFilters.length === 1,
  [PATHS.ordersHistory]: transformedFilters.length === 1 && isFilteredByOrderConfirmed(transformedFilters),
  [PATHS.cart]: transformedFilters.length === 0,
  [PATHS.alertsOrders]: isFilteredByActiveAlerts(transformedFilters, API_FIELD_NAME.isAlertArchived) && isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1,
  [PATHS.alertsPriceDown]: isFilteredByActiveAlerts(transformedFilters, API_FIELD_NAME.isAlertArchived) && isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1,
  [PATHS.alertsSale]: isFilteredByActiveAlerts(transformedFilters, API_FIELD_NAME.isAlertArchived) && isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1,
  [PATHS.alertsMentions]: isFilteredByActiveAlerts(transformedFilters, API_FIELD_NAME.isAlertArchived) && isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1
})

export const checkDefaultFiltersForUser = (userLogin, transformedFilters) => ({
  [PATHS.monitoring]: isFilteredByActive(transformedFilters) && isFilteredByCurrentUser(userLogin, transformedFilters) && transformedFilters.length === 2,
  [PATHS.cart]: isFilteredByCurrentUser(userLogin, transformedFilters) && transformedFilters.length === 1,
  [PATHS.ordersHistory]: isOrdersFilteredByCurrentUser(userLogin, transformedFilters) && isFilteredByOrderConfirmed(transformedFilters) && transformedFilters.length === 2,
  [PATHS.alertsOrders]: isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1,
  [PATHS.alertsPriceDown]: isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1,
  [PATHS.alertsSale]: isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1,
  [PATHS.alertsMentions]: isFilteredByCurrentUser(userLogin, transformedFilters, API_FIELD_NAME.alertsUserLogin) && transformedFilters.length === 1
})
