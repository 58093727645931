import errors, { getErrorName } from 'utils/errors'
import { SEVERITY } from 'utils/toast'

export const getCreateLineError = error => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.lineNameTaken:
    case errors.entityExists:
      errorTranslationKey = 'toast.nameTaken'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}
