import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as authService from 'services/AuthService'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { SEVERITY } from 'utils/toast'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { fetchSettingsRoutine } from 'features/settings/ducks/actions'
import * as usersService from 'services/UsersService'

// ROUTINES

export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')
export const fetchAuthUserRoutine = createRoutine('FETCH_AUTH_USER')
export const updateUserSettingsRoutine = createRoutine('UPDATE_AUTH_USER_SETTINGS')

// ACTIONS

function * loginUser ({ payload: { values = {}, callback = () => {} } }) {
  yield put(loginUserRoutine.request())
  try {
    const result = yield call(authService.loginUser, values)
    LocalStorageService.set(LOCAL_STORAGE_KEYS.token, result.data.token)
    yield put(loginUserRoutine.success())
    callback()
    yield put(fetchAuthUserRoutine())
  } catch (e) {
    yield put(loginUserRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.loginFailed',
        severity: SEVERITY.error
      })
    )
  }
}

function * logoutUser () {
  yield put(logoutUserRoutine.request())
  LocalStorageService.remove(LOCAL_STORAGE_KEYS.token)
  yield put(logoutUserRoutine.success())
}

function * fetchAuthUser () {
  yield put(fetchAuthUserRoutine.request())
  try {
    const result = yield call(authService.fetchAuthUser)
    yield put(fetchAuthUserRoutine.success(result.data))
    yield put(fetchSettingsRoutine())
  } catch (e) {
    yield put(fetchAuthUserRoutine.failure(e))
  }
}

function * updateUserSettings ({ payload }) {
  yield put(updateUserSettingsRoutine.request())
  try {
    const result = yield call(usersService.updateUserSettings, payload)
    yield put(updateUserSettingsRoutine.success(result.data))
    yield put(fetchAuthUserRoutine())
  } catch (e) {
    yield put(updateUserSettingsRoutine.failure(e))
  }
}

// WATCHERS

export function * loginUserWatcher () {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

export function * logoutUserWatcher () {
  yield takeLatest(logoutUserRoutine.TRIGGER, logoutUser)
}

export function * fetchAuthUserWatcher () {
  yield takeLatest(fetchAuthUserRoutine.TRIGGER, fetchAuthUser)
}

export function * updateUserSettingsWatcher () {
  yield takeLatest(updateUserSettingsRoutine.TRIGGER, updateUserSettings)
}

// SAGAS

export const authSagas = [
  fork(loginUserWatcher),
  fork(logoutUserWatcher),
  fork(fetchAuthUserWatcher),
  fork(updateUserSettingsWatcher)
]
