import APIService from 'services/APIService'
import { isNotNilOrEmpty } from 'utils/ramda'

const api = APIService()

export const fetchWeightSchemas = query => api.get(`/weight-schemas?${query}`)
export const createWeightSchema = payload => {
  const formData = new window.FormData()
  formData.append('name', payload.name)
  formData.append('schema', payload.file)

  return api.post('/weight-schemas', formData)
}

export const editWeightSchema = ({ id, values }) => {
  const formData = new window.FormData()
  formData.append('name', values.name)
  if (isNotNilOrEmpty(values.name)) {
    formData.append('schema', values.file)
  }

  return api.patch(`/weight-schemas/${id}`, formData)
}
export const deleteWeightSchema = id => api.delete(`/weight-schemas/${id}`)
export const assignProductsToWeightSchema = ({ id, productIds }) => api.patch(`/weight-schemas/${id}/assign`, { productIds })
export const removeWeightSchemaFromProduct = ({ productId, id }) => api.patch(`/weight-schemas/${id}/remove-from-product`, { productId })

export const setWeightSchemaAsDefault = ({ id }) => api.patch(`/weight-schemas/${id}/default`)
export const unsetDefaultWeightSchema = () => api.patch('/weight-schemas/unset-default')
