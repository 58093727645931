import React from 'react'
import styled from 'styled-components'

import AddAllegroAccount from 'features/allegro/components/AddAllegroAccount'
import IntegrationsAggregateStatus from 'features/integrations/IntegrationsAggregateStatus'
import IaiAccount from 'features/integrations/IaiAccount'
import Container from 'components/Container'
import Grid from 'components/atoms/Grid'
import VerticalAlignContainer from 'components/VerticalAlignContainer'
import Paper from 'components/atoms/Paper'
import AllegroAccountList from 'features/allegro/AllegroAccountList'

export const IntegrationsPage = (props) => {
  return (
    <Container>
      <Paper>
        <IntegrationsStatusContainer>
          <IntegrationsAggregateStatus />
        </IntegrationsStatusContainer>
        <ActionsContainer>
          <SectionTitle>
            IAI
          </SectionTitle>
        </ActionsContainer>
        <IaiAccount />
        <ActionsContainer>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <SectionTitle>
                Allegro
              </SectionTitle>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledButtonContainer>
                <AddAllegroAccount />
              </StyledButtonContainer>
            </Grid>
          </Grid>
        </ActionsContainer>
        <AllegroAccountList />
      </Paper>
    </Container>
  )
}

export default IntegrationsPage

const ActionsContainer = styled.div`
  text-align: right;
  padding: 20px 0;
  margin-bottom: 40px;
  margin-top: 40px;
  border-bottom: 1px solid ${props => props.theme.palette.common.gray400};
`

const SectionTitle = styled.div`
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 19px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
`

const StyledButtonContainer = styled(VerticalAlignContainer)`
  justify-content: flex-end;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin: 10px 0;
    justify-content: center;
    
    button, a {
      width: 100%;
    }
  }
`

const IntegrationsStatusContainer = styled.div`
  text-align: right;
  padding: 20px 0;
  margin-bottom: 40px;
`
