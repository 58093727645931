import TableRow from '@material-ui/core/TableRow'
import styled from 'styled-components'
import { transparentize } from 'polished'

export default styled(TableRow)`
  background-color: ${props => props.checked ? transparentize('0.9', props.theme.palette.secondary.main) : 'none'};
  transition: background-color 0.2s ${props => props.theme.transitions.easing.easeInOut};
  
  &:hover {
    background-color: ${props => props.checked ? transparentize('0.8', props.theme.palette.secondary.main) : props.theme.palette.common.gray100} !important;
  }
`
