import React, { useState } from 'react'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { isNotNilOrEmpty } from 'utils/ramda'

const Input = ({ raw, name, label, required, disabled, type, autoComplete, autoFocus, value, error, inputProps, inputRef, onChange, onFocus, onBlur, errorText, multiline, rows, rowsMax, endAdornment }) => {
  const [inputType, setInputType] = useState(type)
  const classes = useInputStyles()
  const rawClasses = useInputRawStyles()

  const previewPassword = () => setInputType('text')
  const hidePassword = () => setInputType('password')
  const passwordVisible = type === 'password' && inputType === 'text'
  const handlePasswordVisibility = passwordVisible ? hidePassword : previewPassword

  const passwordVisibilitySwitch = (
    <InputAdornment position='end'>
      <IconButton onClick={handlePasswordVisibility}>
        {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment>
  )

  const customEndAdornment = (
    <InputAdornment position='end'>
      {endAdornment}
    </InputAdornment>
  )

  const getEndAdornment = () => {
    switch (true) {
      case isNotNilOrEmpty(endAdornment):
        return customEndAdornment
      case type === 'password':
        return passwordVisibilitySwitch
      default:
        return ''
    }
  }

  return (
    <InputContainer id={`input-${name}-container`}>
      <TextField
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        className={raw ? rawClasses.textField : classes.textField}
        disabled={disabled}
        variant='outlined'
        required={required}
        name={name}
        label={label}
        value={value}
        helperText={errorText}
        type={inputType || 'text'}
        error={error}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        InputProps={{
          endAdornment: getEndAdornment(),
          id: `input-${name}`,
          max: 99999.99,
          ...inputProps
        }}
      />
    </InputContainer>
  )
}

const InputContainer = styled.div`
  margin: 10px 0;
`

const useInputStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  }
}))

const useInputRawStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '3px !important',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '4px'
    },
    '& .MuiFormLabel-root': {
      display: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    }
  }
}))

Input.defaultProps = {
  label: '',
  value: '',
  required: false,
  disabled: false,
  type: 'text',
  autoComplete: 'false',
  autoFocus: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  inputProps: {}
}

export default Input
