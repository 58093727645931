import React from 'react'

import ToggleProductHasAds from 'features/allegro/ToggleProductHasAds'

export const PromoSection = (props) => {
  return (
    <ToggleProductHasAds />
  )
}

export default PromoSection
