import { ShippingOrders, ShippingOrder, ShippingOrdersMeta } from 'features/shippingOrders/ducks/records'
import { fetchShippingOrdersRoutine, getShippingOrderItemsRoutine } from 'features/shippingOrders/ducks/actions'
import {
  API_STATES,
  transferCollectionToImmutable
} from 'utils/redux'

export default (state = new ShippingOrders(), action) => {
  switch (action.type) {
    case fetchShippingOrdersRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchShippingOrdersRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchShippingOrdersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('shippingOrders', transferCollectionToImmutable(action.payload.data, ShippingOrder))
        .set('shippingOrdersMeta', new ShippingOrdersMeta(action.payload.meta))
    case getShippingOrderItemsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('shippingOrderItems', {
          ...state.shippingOrderItems,
          [action.payload.id]: action.payload.data
        })
    default:
      return state
  }
}
