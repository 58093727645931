import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-radius: ${props => props.theme.shape.borderRadius};
  border: 1px solid ${props => props.theme.palette.common.gray300};
`
