import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, select } from '@redux-saga/core/effects'
import * as commentsService from 'services/ProductCommentsService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { propOr } from 'ramda'
import { getProductCommentsMeta } from 'features/comments/ducks/selectors'
import qs from 'qs'
import { SORT_ORDER } from 'utils/table'

// ROUTINES

export const createProductCommentRoutine = createRoutine('CREATE_PRODUCT_COMMENT')
export const fetchProductCommentsRoutine = createRoutine('FETCH_PRODUCT_COMMENTS')

// ACTIONS

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * createProductComment ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createProductCommentRoutine.request())
  try {
    const result = yield call(commentsService.createProductComment, values)
    yield put(createProductCommentRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.createCommentSuccess',
        severity: SEVERITY.success
      })
    )
    const currentPaginationState = yield select(state => getProductCommentsMeta(state, values.id))
    yield put(fetchProductCommentsRoutine({
      id: values.id,
      query: qs.stringify({
        limit: {
          page: currentPaginationState.page,
          take: currentPaginationState.take
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    }))
    callback()
  } catch (e) {
    yield put(createProductCommentRoutine.failure(e))
    console.error(e)
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * fetchProductComments ({ payload }) {
  yield put(fetchProductCommentsRoutine.request())
  try {
    const result = yield call(commentsService.fetchProductComments, payload)
    yield put(fetchProductCommentsRoutine.success({ productId: payload.id, result: propOr({}, 'data', result) }))
  } catch (e) {
    yield put(fetchProductCommentsRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function * createProductCommentWatcher () {
  yield takeLatest(createProductCommentRoutine.TRIGGER, createProductComment)
}

export function * fetchProductCommentsWatcher () {
  yield takeLatest(fetchProductCommentsRoutine.TRIGGER, fetchProductComments)
}

// SAGAS

export const commentsSagas = [
  fork(createProductCommentWatcher),
  fork(fetchProductCommentsWatcher)
]
