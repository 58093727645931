import { useEffect, useCallback } from 'react'
import { arrayContainsFullArray, arrayContainsPartArray } from 'utils/array'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedItems } from 'ducks/global/selectors'
import { handleSelectCollectionRoutine, handleSelectItemRoutine, handleResetSelectionRoutine } from 'ducks/global/actions'
import { includes } from 'ramda'

export default (visibleItems, allItemsData) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(getSelectedItems)
  const hasSomeItemSelected = arrayContainsPartArray(selectedItems)(visibleItems)
  const hasAllVisibleItemsSelected = arrayContainsFullArray(selectedItems)(visibleItems)

  const handleSelectItem = useCallback(
    (item, data) => dispatch(handleSelectItemRoutine({ item, data })),
    [dispatch]
  )

  const handleSelectCollection = useCallback(
    (collection, collectionData) => dispatch(handleSelectCollectionRoutine({ collection, collectionData })),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  const isItemSelected = item => includes(item, selectedItems)

  useEffect(() => {
    return () => {
      handleResetSelection()
    }
  }, [])

  return {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem: (item, data) => () => handleSelectItem(item, data),
    handleSelectCollection: () => handleSelectCollection(visibleItems, allItemsData),
    handleResetSelection
  }
}
