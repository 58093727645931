import { ifElse, join, pipe, take, takeLast, append, prepend, identity } from 'ramda'
import { lengthIsGreaterThan } from 'utils/ramda'

export const addEllipsisForXCharacters = (number, placement = 'end') => {
  const addEllipsis = placement === 'end' ? append : prepend
  const takeCharacters = placement === 'end' ? take : takeLast

  return ifElse(
    lengthIsGreaterThan(number),
    pipe(
      takeCharacters(number),
      addEllipsis('...'),
      join('')
    ),
    identity
  )
}
