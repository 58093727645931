import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as ordersService from 'services/OrdersService'
import { pathOr } from 'ramda'

// ROUTINES

export const fetchTodayOrdersRoutine = createRoutine('FETCH_TODAY_ORDERS')

// ACTIONS

function * fetchTodayOrders ({ payload }) {
  yield put(fetchTodayOrdersRoutine.request())
  try {
    const result = yield call(ordersService.fetchTodayOrders, payload)
    yield put(fetchTodayOrdersRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchTodayOrdersRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function * fetchTodayOrdersWatcher () {
  yield takeLatest(fetchTodayOrdersRoutine.TRIGGER, fetchTodayOrders)
}

// SAGAS

export const ordersSagas = [
  fork(fetchTodayOrdersWatcher)
]
