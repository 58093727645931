import React from 'react'
import Icon from 'components/Icon'

export const Plug = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 16 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='plug-icon' stroke='none' fill='none'>
        <g id='plug-icon-2' transform='translate(-20.000000, -329.000000)'>
          <g
            id='02-Icons-/-24x24-/-ic24-plugin'
            transform='translate(16.000000, 327.000000)'
          >
            <path
              d='M15,2 C15.5522847,2 16,2.44771525 16,3 L16,6 L17,6 C18.6568542,6 20,7.34314575 20,9 L20,12 C20,15.3137085 17.3137085,18 14,18 L13,18 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,18 L10,18 C6.6862915,18 4,15.3137085 4,12 L4,9 C4,7.34314575 5.34314575,6 7,6 L8,6 L8,3 C8,2.44771525 8.44771525,2 9,2 C9.55228475,2 10,2.44771525 10,3 L10,6 L14,6 L14,3 C14,2.44771525 14.4477153,2 15,2 Z M17,8 L7,8 C6.44771525,8 6,8.44771525 6,9 L6,12 C6,14.209139 7.790861,16 10,16 L14,16 C16.209139,16 18,14.209139 18,12 L18,9 C18,8.44771525 17.5522847,8 17,8 Z'
              id='Icon-Color'
            />
          </g>
        </g>
      </g>
    </Icon>
  )
}

export default Plug
