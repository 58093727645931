import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as productsService from 'services/ProductsService'
import { pathOr } from 'ramda'

// ROUTINES

export const fetchWillLastHistoryRoutine = createRoutine('FETCH_WILL_LAST_HISTORY')

// ACTIONS

function * fetchWillLastHistory ({ payload }) {
  yield put(fetchWillLastHistoryRoutine.request())
  try {
    const result = yield call(productsService.fetchWillLastHistory, payload)
    yield put(fetchWillLastHistoryRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchWillLastHistoryRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function * fetchWillLastHistoryWatcher () {
  yield takeLatest(fetchWillLastHistoryRoutine.TRIGGER, fetchWillLastHistory)
}

// SAGAS

export const willLastHistorySagas = [
  fork(fetchWillLastHistoryWatcher)
]
