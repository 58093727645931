import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Producer = Record({
  id: '',
  iaiId: '',
  name: '',
  createdAt: '',
  lineName: '',
  lineId: '',
  updatedAt: null
})

export const ProducersMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Producers = Record({
  state: API_STATES.PRISTINE,
  producersMeta: new ProducersMeta(),
  producers: []
})
