import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import queryString from 'query-string'

import { getAllegroAccountsArray } from 'features/allegro/ducks/selectors'
import { fetchAllegroAccountsRoutine } from 'features/allegro/ducks/actions'

import AllegroAccount from 'features/allegro/components/AllegroAccount'
import AllegroIntegrationStatusNotification
  from 'features/allegro/components/AllegroIntegrationStatusNotification'
import SadFace from 'components/icons/SadFace'
import { propEq } from 'ramda'

export const AllegroAccountList = (props) => {
  const { history: { location: { search } } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const accountsArray = useSelector(getAllegroAccountsArray)
  const fetchAllegroAccounts = useCallback(
    query => dispatch(fetchAllegroAccountsRoutine(query)),
    [dispatch]
  )
  const query = queryString.parse(search)
  const isAllegroAccountAdded = propEq('integration_added', 'true')(query)

  useEffect(() => {
    fetchAllegroAccounts(
      qs.stringify({
        limit: {
          page: 1,
          take: 100
        }
      })
    )
  }, [])

  const renderItems = accountsArray.map(record => (
    <AllegroAccount key={record.id} data={record} />
  ))

  const emptyState = (
    <EmptyState>
      <SadFace />&nbsp;
      {t('integrationsPage.emptyState')}
    </EmptyState>
  )

  // see README.md for explanation /@Sandra 25.05.2020
  const allegroLogout = (
    <StyledAllegroLogoutFrame>
      <iframe src='https://allegro.pl/logout.php' />
    </StyledAllegroLogoutFrame>
  )

  return (
    <>
      {
        accountsArray.length > 0 ? (
          <AllegroAccountListContainer>
            {renderItems}
          </AllegroAccountListContainer>
        ) : (
          <>
            {emptyState}
          </>
        )
      }
      {
        isAllegroAccountAdded && allegroLogout
      }
      <AllegroIntegrationStatusNotification />
    </>
  )
}

export default withRouter(AllegroAccountList)

const AllegroAccountListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-radius: ${props => props.theme.shape.borderRadius};
  border: 1px solid ${props => props.theme.palette.common.gray300};
`

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
`
const StyledAllegroLogoutFrame = styled.div`
  width: 0px;
  height: 0px;
  
  iframe {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }
`
