import React from 'react'
import styled from 'styled-components'
import { APP_NAME } from 'utils/general'
import Value from 'components/icons/Value'

export const Logo = (props) => {
  return (
    <StyledLogo>
      <StyledValueIcon />
      <div className='logo__name'>{APP_NAME}</div>
    </StyledLogo>
  )
}

export default Logo

const StyledLogo = styled.div`
  color: ${props => props.theme.palette.primary.main};
  font-weight: 600;
  font-size: 30px;
  letter-spacing: -0.5px;
  display: inline-flex;
  align-items: center;
`

const StyledValueIcon = styled(Value)`
  margin-right: 5px;
  font-size: 40px;
`
