import { createRoutine } from 'redux-saga-routines'
import { pathOr } from 'ramda'
import { put, call, takeLatest, fork, delay, select } from '@redux-saga/core/effects'
import * as alertsService from 'services/AlertsService'
import { SEVERITY } from 'utils/toast'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { generateCurrentQuery } from 'utils/query'
import { ALERT_TYPES, prepareFetchAlertsCurrentQuery } from 'utils/alerts'
import qs from 'qs'
import { getUserLogin } from 'features/auth/ducks/selectors'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

// ROUTINES

export const fetchAlertsRoutine = createRoutine('FETCH_ALERTS')
export const archiveAlertsRoutine = createRoutine('ARCHIVE_ALERTS')
export const markAlertsAsSeenRoutine = createRoutine('MARK_ALERTS_AS_SEEN')
export const fetchUnseenAlertsSummaryRoutine = createRoutine('FETCH_UNSEEN_ALERTS_SUMMARY')
export const fetchUnarchivedAlertsRoutine = createRoutine('FETCH_UNARCHIVED_ALERTS')
export const assignAlertsToUserRoutine = createRoutine('ASSIGN_ALERTS_TO_USER')

// ACTIONS

function * fetchAlerts ({ payload }) {
  yield put(fetchAlertsRoutine.request())
  try {
    const result = yield call(alertsService.fetchAlerts, payload)
    yield put(fetchAlertsRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchAlertsRoutine.failure(e))
  }
}

function * assignAlertsToUser ({ payload: { values, callback = () => {} } }) {
  yield put(assignAlertsToUserRoutine.request())
  try {
    yield call(alertsService.assignAlertsToUser, values)
    yield put(fetchAlertsRoutine(prepareFetchAlertsCurrentQuery(generateCurrentQuery(), [ALERT_TYPES.mentions])))
    yield put(handleResetSelectionRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.assignAlertsToUserSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(assignAlertsToUserRoutine.success())
    callback()
  } catch (e) {
    yield put(assignAlertsToUserRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function * fetchUnarchivedAlerts () {
  yield put(fetchUnarchivedAlertsRoutine.request())
  try {
    const userLogin = yield select(getUserLogin)
    const prepareQuery = {
      filter: {
        [API_FIELD_NAME.alertsUserLogin]: userLogin
      },
      limit: {
        page: 1,
        take: 1
      }
    }

    const result = yield call(alertsService.fetchAlerts, qs.stringify(prepareQuery))
    yield put(fetchUnarchivedAlertsRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchUnarchivedAlertsRoutine.failure(e))
  }
}

function * fetchUnseenAlertsSummary () {
  yield put(fetchUnseenAlertsSummaryRoutine.request())
  try {
    const result = yield call(alertsService.fetchUnseenAlertsSummary)
    yield put(fetchUnseenAlertsSummaryRoutine.success(pathOr({}, ['data'], result)))
  } catch (e) {
    yield put(fetchUnseenAlertsSummaryRoutine.failure(e))
  }
}

function * archiveAlerts ({ payload: { values, types = [], callback = () => {} } }) {
  yield put(archiveAlertsRoutine.request())
  try {
    yield call(alertsService.archiveAlerts, values)
    delay(500)
    yield put(fetchAlertsRoutine(prepareFetchAlertsCurrentQuery(generateCurrentQuery(), types)))
    yield put(archiveAlertsRoutine.success())
    yield put(fetchUnseenAlertsSummaryRoutine())
    yield put(fetchUnarchivedAlertsRoutine())
    yield put(showToastRoutine({
      key: 'toast.archiveAlertSuccess',
      severity: SEVERITY.success
    }))
    callback()
  } catch (e) {
    yield put(archiveAlertsRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.archiveAlertFailed',
      severity: SEVERITY.error
    }))
  }
}

function * markAlertsAsSeen ({ payload }) {
  yield put(markAlertsAsSeenRoutine.request())
  try {
    yield call(alertsService.markAlertsAsSeen, payload)
    yield put(markAlertsAsSeenRoutine.success())
  } catch (e) {
    yield put(markAlertsAsSeenRoutine.failure(e))
  }
}

// WATCHERS

export function * fetchAlertsWatcher () {
  yield takeLatest(fetchAlertsRoutine.TRIGGER, fetchAlerts)
}

export function * archiveAlertsWatcher () {
  yield takeLatest(archiveAlertsRoutine.TRIGGER, archiveAlerts)
}

export function * markAlertsAsSeenWatcher () {
  yield takeLatest(markAlertsAsSeenRoutine.TRIGGER, markAlertsAsSeen)
}

export function * fetchUnseenAlertsSummaryWatcher () {
  yield takeLatest(fetchUnseenAlertsSummaryRoutine.TRIGGER, fetchUnseenAlertsSummary)
}

export function * fetchUnarchivedAlertsWatcher () {
  yield takeLatest(fetchUnarchivedAlertsRoutine.TRIGGER, fetchUnarchivedAlerts)
}

export function * assignAlertsToUserWatcher () {
  yield takeLatest(assignAlertsToUserRoutine.TRIGGER, assignAlertsToUser)
}

// SAGAS

export const alertsSagas = [
  fork(fetchAlertsWatcher),
  fork(archiveAlertsWatcher),
  fork(markAlertsAsSeenWatcher),
  fork(fetchUnseenAlertsSummaryWatcher),
  fork(assignAlertsToUserWatcher),
  fork(fetchUnarchivedAlertsWatcher)
]
