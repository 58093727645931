import { propOr, pipe, equals, pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'

export const selectSettings = state => state.settings

export const getIsSettingsLoading = createSelector(
  selectSettings,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getSettingsData = createSelector(
  selectSettings,
  propOr({}, 'settings')
)

export const getSoldShortValue = createSelector(
  selectSettings,
  pipe(
    pathOr(7, ['settings', 'soldShort']),
    Number
  )
)

export const getSoldLongValue = createSelector(
  selectSettings,
  pipe(
    pathOr(30, ['settings', 'soldLong']),
    Number
  )
)

export const getPriceUpQty = createSelector(
  selectSettings,
  pipe(
    pathOr(3, ['settings', 'priceIncreaseThreshold']),
    Number
  )
)

export const getPriceUpPercentage = createSelector(
  selectSettings,
  pipe(
    pathOr(3, ['settings', 'priceIncreasePercentage']),
    Number
  )
)

export const getPriceUpValue = createSelector(
  selectSettings,
  pipe(
    pathOr(0, ['settings', 'priceIncreaseValue']),
    Number
  )
)

export const getPriceUpNotSellingThreshold = createSelector(
  selectSettings,
  pipe(
    pathOr(3, ['settings', 'notSellingThreshold']),
    Number
  )
)

export const getStockDiffPercentage = createSelector(
  selectSettings,
  pipe(
    pathOr(10, ['settings', 'stockDiffPercentage']),
    Number
  )
)

export const gePriceDownStockDiffPercentage = createSelector(
  selectSettings,
  pipe(
    pathOr(10, ['settings', 'priceDownStockDiffPercentage']),
    Number
  )
)

export const getStockDiffAmount = createSelector(
  selectSettings,
  pipe(
    pathOr(5, ['settings', 'stockDiffAmount']),
    Number
  )
)

export const getDeliveredProductsSyncInterval = createSelector(
  selectSettings,
  pipe(
    pathOr(60, ['settings', 'deliveredProductsSyncInterval']),
    Number
  )
)

export const getPriceDownQty = createSelector(
  selectSettings,
  pipe(
    pathOr(3, ['settings', 'priceDecreaseThreshold']),
    Number
  )
)

export const getPriceDownPercentage = createSelector(
  selectSettings,
  pipe(
    pathOr(3, ['settings', 'priceDecreasePercentage']),
    Number
  )
)

export const getPriceDownValue = createSelector(
  selectSettings,
  pipe(
    pathOr(0, ['settings', 'priceDecreaseValue']),
    Number
  )
)

export const getPriceDownMargin = createSelector(
  selectSettings,
  pipe(
    pathOr(0, ['settings', 'minMarginValue']),
    Number
  )
)
