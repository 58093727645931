import { propOr, pipe, equals } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'

export const selectWillLastHistory = state => state.willLastHistory

export const getIsWillLastHistoryLoading = createSelector(
  selectWillLastHistory,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getWillLastHistoryMeta = createSelector(
  selectWillLastHistory,
  propOr({}, 'willLastHistoryMeta')
)

export const getWillLastHistoryArray = createSelector(
  selectWillLastHistory,
  propOr([], 'willLastHistory')
)
