import { string, object, array } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Assign Products to Tag form validator

export const assignProductsToTagSchema = object().shape({
  tagId: string()
    .required(() => ({ key: 'form.tag.errors.required' })),
  productIds: array().required()
})

export const validateAssignProductsToTagField = values => validateField(assignProductsToTagSchema, values)
export const validateAssignProductsToTagValues = validateValues(assignProductsToTagSchema)

// Add Tag form validator

export const addTagSchema = object().shape({
  name: string()
    .required(() => ({ key: 'form.tagName.errors.required' }))
    .min(3, () => ({
      key: 'form.tagName.errors.lengthMin',
      options: { number: 3 }
    }))
    .max(20, () => ({
      key: 'form.tagName.errors.lengthMax',
      options: { number: 50 }
    }))
})

export const validateAddTagField = values => validateField(addTagSchema, values)
export const validateAddTagValues = validateValues(addTagSchema)
