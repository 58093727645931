import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  deleteSalePlanRoutine
} from 'features/salePlans/ducks/actions'

import DeleteWithConfirm from 'components/DeleteWithConfirm'

export const DeleteSalePlan = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleDelete = useCallback(
    payload => dispatch(deleteSalePlanRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = id => handleDelete(id)

  return (
    <DeleteWithConfirm
      text={t('productPage.salePlan.deletePlan.confirmation.content')}
      submitText={t('productPage.salePlan.deletePlan.confirmation.submitCTA')}
      cancelText={t('productPage.salePlan.deletePlan.confirmation.cancelCTA')}
      id={id}
      onSubmit={handleSubmit}
    />
  )
}

export default withRouter(DeleteSalePlan)
