import React, { useState, useEffect } from 'react'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { propOr, isNil, filter, includes, split, values } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'

import PlainMultiSelect from 'components/atoms/FilterInputs/PlainMultiSelect'

export const MultiSelectFilter = (props) => {
  const { name, label, options, disableArray } = props
  const urlSearchQuery = useUrlSearchQuery()
  const { location: { search } } = useHistory()

  const urlQuery = propOr(disableArray ? '' : [], name)(urlSearchQuery.getSearchQuery())

  const selectedOptions = () => {
    const transformedUrlQuery = disableArray ? split(',', urlQuery) : values(urlQuery)

    return filter(
      option => includes(option.value, transformedUrlQuery)
    )(options)
  }

  const [value, setValue] = useState(selectedOptions())

  useEffect(() => {
    setValue(selectedOptions())
  }, [search, options])

  const handleChange = e => {
    const parsedNilValue = isNil(e) ? [] : e
    setValue(parsedNilValue)
    const mappedValues = parsedNilValue.map(value => value.value)
    const payload = disableArray ? mappedValues.join(',') : mappedValues

    if (isNotNilOrEmpty(parsedNilValue)) {
      urlSearchQuery.setSearchQuery({
        [name]: payload
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
    }
  }

  return (
    <>
      <PlainMultiSelect
        value={value}
        options={options}
        label={label}
        onChange={handleChange}
      />
    </>
  )
}

MultiSelectFilter.defaultProps = {
  options: []
}

export default MultiSelectFilter
