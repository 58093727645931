import { createGlobalStyle } from 'styled-components'

export default theme => createGlobalStyle`
  html {
    height: 100%;
    position: relative;
    margin: 0;
    color: ${theme.palette.text.main};
    font-size: 14px;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  body {
    line-height: 1;
    background-color: ${theme.palette.common.white};
    margin: 0;
    font-family: ${theme.typography.fontFamily};
  }
  
  * {
    box-sizing: border-box;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  
  ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  a {
    color: ${theme.palette.text.main};
    text-decoration: none;
    background-color: transparent;
    transition: color 0.2s ${theme.transitions.easing.easeInOut};

    &:hover {
      color: ${theme.palette.primary.main};
    }
  }
  
  input:-webkit-autofill {
    height: 100%;
    color: ${theme.palette.text.main} !important;
    box-shadow: 0 0 0px 1000px ${theme.palette.common.white} inset !important;
    background-color: ${theme.palette.common.white} !important;
  }
  
  input:-internal-autofill-selected {
    height: 100%;
    color: ${theme.palette.text.main} !important;
    box-shadow: 0 0 0px 1000px ${theme.palette.common.white} inset !important;
    background-color: ${theme.palette.common.white} !important;
  }

  input::-ms-clear, input::-ms-reveal {
    display: none;
  }
  
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td, th {
    padding: 0;
  }
`
