import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import AssignTagToProductForm from 'features/tags/AssignTagToProductForm'
import Modal from 'components/atoms/Modal'
import Add from 'components/icons/Add'
import Chip from 'components/atoms/Chip'

export const AssignTagToProductModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <ChipContainer>
        <Chip size='small' color='secondary' onClick={handleOpen} label={<Add fontSize='small' />} />
      </ChipContainer>
      <Modal
        title={t('productPage.assignTag.title')}
        open={open}
        handleClose={handleClose}
      >
        <AssignTagToProductForm onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default AssignTagToProductModal

const ChipContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  
  .MuiChip-root {
    border-radius: 50% !important;
    width: 20px !important;
    height: 20px !important;
  }
  
  .MuiChip-label {
    display: flex;
    padding: 0 !important;
  }
  
  svg {
    line-height: 20px !important;
    font-size 16px !important;
  }
`
