import React from 'react'

import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import Container from 'components/Container'
import SaleChannelsTable from 'features/saleChannels/SaleChannelsTable'

export const TagsPage = (props) => {
  return (
    <Container>
      <TablePaperContainer>
        <SaleChannelsTable />
      </TablePaperContainer>
    </Container>
  )
}

export default TagsPage
