import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { SEVERITY } from 'utils/toast'

import { showToastRoutine } from 'features/toast/ducks/actions'

export const CopyToClipboard = (props) => {
  const { children, textToCopy } = props
  const textareaRef = useRef(null)
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleCopy = () => {
    textareaRef.current.select()
    document.execCommand('copy')
    showToast({
      key: 'toast.copySuccess',
      severity: SEVERITY.success
    })
  }

  return (
    <CopyContainer>
      <CopyTrigger onClick={handleCopy}>{children}</CopyTrigger>
      <HiddenTextarea readOnly ref={textareaRef} value={textToCopy} />
    </CopyContainer>
  )
}

export default CopyToClipboard

const CopyContainer = styled.div`
  display: inline-block;
`

const CopyTrigger = styled.div`
  cursor: pointer;
`

const HiddenTextarea = styled.textarea`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px,
  overflow: hidden;
  padding: 0;
  position: absolute;
`
