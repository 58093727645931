import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useTranslation } from 'react-i18next'

import Button from 'components/atoms/Button'
import Back from 'components/icons/Back'

export const BackButton = (props) => {
  const [prevPath, setPrevPath] = useState(null)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    setPrevPath(pathOr(null, ['location', 'state', 'from'], history))
  }, [])

  const handleGoBack = () => {
    switch (true) {
      case isNotNilOrEmpty(prevPath):
        return history.push(prevPath)
      case isNotNilOrEmpty(props.defaultPath):
        return history.push(props.defaultPath)
      default:
        return history.goBack()
    }
  }

  return (
    <Button size='small' startIcon={<Back />} onClick={handleGoBack}>
      {props.text || t('navigation.goBack')}
    </Button>
  )
}

export default BackButton
