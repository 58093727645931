import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateDeliveredProductsSyncIntervalFormSchemaField,
  validateDeliveredProductsSyncIntervalFormSchemaValues
} from 'features/settings/ducks/schema'

import { saveSettingsRoutine } from 'features/settings/ducks/actions'
import { getIsSettingsLoading, getDeliveredProductsSyncInterval } from 'features/settings/ducks/selectors'

import Form from 'components/atoms/Form'
import Button from 'components/atoms/Button'
import Select from 'components/formElements/SingleSelectField'

const emptyValues = {
  deliveredProductsSyncInterval: 60
}

export const DeliveredProductsSyncIntervalForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSettingsLoading)
  const deliveredProductsSyncInterval = useSelector(getDeliveredProductsSyncInterval)

  const saveSettings = useCallback(
    payload => dispatch(saveSettingsRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateDeliveredProductsSyncIntervalFormSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ deliveredProductsSyncInterval })
  }, [deliveredProductsSyncInterval])

  useEffect(() => {}, [])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    saveSettings(values)
  }

  const options = [
    {
      label: 60,
      value: 60
    },
    {
      label: 30,
      value: 30
    },
    {
      label: 15,
      value: 15
    }
  ]

  return (
    <Form onSubmit={handleSubmit}>
      <Select
        required
        options={options}
        value={values.deliveredProductsSyncInterval}
        name='deliveredProductsSyncInterval'
        label={t('form.minutes.label')}
        onChange={handleOnChange}
        validate={validateDeliveredProductsSyncIntervalFormSchemaField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('settingsPage.saveSettings.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default DeliveredProductsSyncIntervalForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
