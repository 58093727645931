import { pipe, pathOr, find, propEq, propOr } from 'ramda'
import { createSelector } from 'reselect'
import { PRODUCT_TASKS_NAMES } from 'utils/flags'

export const selectProductFlags = state => state.flags

export const getProductFlagsArray = createSelector(
  selectProductFlags,
  (state, id) => id,
  (flags, id) => pipe(
    pathOr([], ['flags', id])
  )(flags)
)

export const getProductFlagDefinitionByTaskName = createSelector(
  selectProductFlags,
  getProductFlagsArray,
  (state, id, taskName) => taskName,
  (flags, productFlags, taskName) => pipe(
    find(
      propEq('name', taskName)
    ),
    propOr({}, 'definition')
  )(productFlags)
)

export const getProductPromoDiffDefinition = createSelector(
  selectProductFlags,
  getProductFlagsArray,
  (flags, productFlags) => pipe(
    find(
      propEq('name', PRODUCT_TASKS_NAMES.promoDiff)
    ),
    propOr({}, 'definition')
  )(productFlags)
)

export const getProductPriceDownDefinition = createSelector(
  selectProductFlags,
  getProductFlagsArray,
  (flags, productFlags) => pipe(
    find(
      propEq('name', PRODUCT_TASKS_NAMES.priceDown)
    ),
    propOr({}, 'definition')
  )(productFlags)
)
