import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import qs from 'qs'
import {
  validateAssignUserToAlertsField,
  validateAssignUserToAlertsValues
} from 'features/alerts/ducks/schema'

import { getSelectedItems } from 'ducks/global/selectors'
import { fetchUsersRoutine } from 'features/users/ducks/actions'
import { getUsersAsOptions } from 'features/users/ducks/selectors'
import { assignAlertsToUserRoutine } from 'features/alerts/ducks/actions'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

import SingleSelect from 'components/formElements/SingleSelectField'
import Button from 'components/atoms/Button'

const emptyValues = {
  userId: '',
  alertsIds: []
}

export const AssignAlertsToUserForm = ({ onSubmit }) => {
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const userOptions = useSelector(getUsersAsOptions)
  const dispatch = useDispatch()
  const fetchUsers = useCallback(
    query => dispatch(fetchUsersRoutine(query)),
    [dispatch]
  )
  const assignAlertsToUser = useCallback(
    payload => dispatch(assignAlertsToUserRoutine(payload)),
    [dispatch]
  )
  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchUsers(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      }
    }))
  }, [])

  useEffect(() => {
    validateAssignUserToAlertsValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('alertsIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const callback = () => {
    onSubmit()
    handleResetSelection()
  }

  const handleSubmit = e => {
    e.preventDefault()
    assignAlertsToUser({ values, callback })
  }

  return (
    <AssignUserContainer>
      <form className='assign-user__form' onSubmit={handleSubmit}>
        <SingleSelect
          options={userOptions}
          required
          name='userId'
          label={t('form.user.label')}
          onChange={handleOnChange}
          validate={validateAssignUserToAlertsField(values)}
        />
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          {t('alertsPage.assignUser.CTA')}
        </Button>
      </form>
    </AssignUserContainer>
  )
}

export default AssignAlertsToUserForm

const AssignUserContainer = styled.div`
  min-width: 400px;
  
  .assign-user__form {
    display: flex;
    align-items: center;
  }
  
  button {
    margin-left: 10px;
  }
`
