import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { lighten, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useTranslation } from 'react-i18next'
import { generateSelectedTheme } from 'utils/theme'

import { getAllAllegroAccountsCount, getActiveAllegroAccountsCount } from 'features/allegro/ducks/selectors'
import { getIaiErrorExists } from 'features/integrations/ducks/selectors'

import Warning from 'components/icons/Warning'
import Success from 'components/icons/Success'

export const IntegrationsAggregateStatus = (props) => {
  const { t } = useTranslation()

  const allAllegroAccountsCount = useSelector(getAllAllegroAccountsCount)
  const activeAllegroAccountsCount = useSelector(getActiveAllegroAccountsCount)

  const iaiErrorExists = useSelector(getIaiErrorExists)
  const iaiAllAccounts = 1
  const iaiActiveAccountsCount = iaiErrorExists ? 0 : 1

  const allAccountsCount = allAllegroAccountsCount + iaiAllAccounts
  const allActiveAccountsCount = activeAllegroAccountsCount + iaiActiveAccountsCount

  const inactiveAccountsCount = allAccountsCount - allActiveAccountsCount

  const calculateProgress = Math.floor((allActiveAccountsCount / allAccountsCount) * 100)

  const getByStatus = (onError, onWarning, onSuccess) => {
    switch (true) {
      case calculateProgress === 0 && allAccountsCount > 0:
        return onError
      case calculateProgress < 100:
        return onWarning
      default:
        return onSuccess
    }
  }

  const currentStatus = getByStatus(
    t('integrationsPage.aggregateStatus.error'),
    t('integrationsPage.aggregateStatus.warning', { count: inactiveAccountsCount }),
    t('integrationsPage.aggregateStatus.success')
  )

  const currentColor = getByStatus(
    generateSelectedTheme().palette.error.main,
    generateSelectedTheme().palette.warning.main,
    generateSelectedTheme().palette.success.main
  )

  const currentIcon = getByStatus(
    <Warning />,
    <Warning />,
    <Success />
  )

  const BorderLinearProgress = withStyles({
    root: {
      height: 12,
      borderRadius: 4,
      backgroundColor: lighten(currentColor, 0.5)
    },
    bar: {
      borderRadius: 4,
      backgroundColor: currentColor
    }
  })(LinearProgress)

  return allAccountsCount > 0 ? (
    <IntegrationsAggregateStatusContainer currentColor={currentColor}>
      <div className='aggregate-status__text'>
        <span className='aggregate-status__icon'>
          {currentIcon}
        </span>
        {currentStatus}
      </div>
      <BorderLinearProgress
        variant='determinate'
        value={calculateProgress}
      />
    </IntegrationsAggregateStatusContainer>
  ) : ''
}

IntegrationsAggregateStatus.defaultProps = {
  value: 0,
  maxValue: 0
}

export default IntegrationsAggregateStatus

const IntegrationsAggregateStatusContainer = styled.div`
  width: 100%;
  
  .aggregate-status__text {
    text-align: left;
    font-size: 19px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .aggregate-status__icon {
    font-size: 25px;
    margin-right: 10px;
    color: ${props => props.currentColor};
  }
`
