import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  syncProductRoutine,
  syncAllegroRoutine,
  syncBundlesRoutine
} from 'features/products/ducks/actions'
import { getIsProductSyncLoading } from 'features/products/ducks/selectors'

import IconButton from 'components/atoms/IconButton'
import Tooltip from 'components/atoms/Tooltip'
import Sync from 'components/icons/Sync'
import Modal from 'components/atoms/Modal'

export const SyncProduct = (props) => {
  const { match: { params: { id } } } = props
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsProductSyncLoading)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const syncProduct = useCallback(
    payload => dispatch(syncProductRoutine(payload)),
    [dispatch]
  )

  const syncAllegro = useCallback(
    payload => dispatch(syncAllegroRoutine(payload)),
    [dispatch]
  )

  const syncBundles = useCallback(
    payload => dispatch(syncBundlesRoutine(payload)),
    [dispatch]
  )

  const handleSyncAllegro = () => syncAllegro({ id })
  const handleSyncBundles = () => syncBundles({ id })
  const handleSyncProduct = () => syncProduct({ id })

  return (
    <SyncContainer>
      <Tooltip title={t('productPage.sync.tooltip')}>
        <IconButton
          type='button'
          id='button-sync'
          color='primary'
          onClick={handleOpen}
        >
          <div className='icon-container'>
            <Sync />
          </div>
        </IconButton>
      </Tooltip>
      <Modal
        title={t('productPage.sync.title')}
        open={open}
        handleClose={handleClose}
      >

        <SyncElement>
          <IconButton
            type='button'
            id='button-sync'
            color='primary'
            onClick={handleSyncAllegro}
            disabled={isLoading}
          >
            <div className='icon-container'>
              <Sync />
            </div>
          </IconButton>
          <div>
            <div className='sync-element__title'>{t('productPage.sync.allegroSync.title')}*</div>
            <div className='sync-element__details'>{t('productPage.sync.allegroSync.details')}</div>
          </div>
        </SyncElement>

        <SyncElement>
          <IconButton
            type='button'
            id='button-sync'
            color='primary'
            onClick={handleSyncBundles}
            disabled={isLoading}
          >
            <div className='icon-container'>
              <Sync />
            </div>
          </IconButton>
          <div>
            <div className='sync-element__title'>{t('productPage.sync.bundlesSync.title')}*</div>
            <div className='sync-element__details'>{t('productPage.sync.bundlesSync.details')}</div>
          </div>
        </SyncElement>

        <SyncElement>
          <IconButton
            type='button'
            id='button-sync'
            color='primary'
            onClick={handleSyncProduct}
            disabled={isLoading}
          >
            <div className='icon-container'>
              <Sync />
            </div>
          </IconButton>
          <div>
            <div className='sync-element__title'>{t('productPage.sync.productSync.title')}*</div>
            <div className='sync-element__details'>{t('productPage.sync.productSync.details')}</div>
          </div>
        </SyncElement>
        <SyncHint>* {t('productPage.sync.hint')}</SyncHint>
      </Modal>
    </SyncContainer>
  )
}

export default withRouter(SyncProduct)

const SyncContainer = styled.div`
  display: inline-block;
  
  .icon-container {
    display: inline-block;
    font-size: 21px;
    width: 21px;
    height: 21px;
    text-align: center;
  }
`

const SyncHint = styled.div`
  font-size: 11px;
  margin-top: 20px;
  color: ${props => props.theme.palette.common.gray600};
`

const SyncElement = styled.div`
  display: flex;
  align-items: center;
  
  .sync-element__title {
    font-size: 16px;
    font-weight: 500;
  }
  
  .sync-element__details {
    font-size: 11px;
    color: ${props => props.theme.palette.common.gray600};
  }
`
