import React from 'react'
import MonitoringTable from 'features/monitoring/MonitoringTable'
import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import PageColumnContainer from 'components/PageColumnContainer'

export const MonitoringPage = (props) => {
  return (
    <PageColumnContainer>
      <TablePaperContainer>
        <MonitoringTable />
      </TablePaperContainer>
    </PageColumnContainer>
  )
}

export default MonitoringPage
