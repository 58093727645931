import { string, object, number } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Update auction price form validator

export const updateAuctionPriceSchema = object().shape({
  offerId: string()
    .required(),
  productId: string()
    .required(),
  price: number()
    .required(() => ({ key: 'form.retailPrice.errors.required' }))
    .min(0, () => ({
      key: 'form.retailPrice.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateUpdateAuctionPriceField = values => validateField(updateAuctionPriceSchema, values)
export const validateUpdateAuctionPriceValues = validateValues(updateAuctionPriceSchema)
