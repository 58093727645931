import React from 'react'
import Select from 'react-select'
import { REACT_SELECT_STYLES } from 'utils/reactSelectStandard'
import styled from 'styled-components'
import { generateSelectedTheme } from 'utils/theme'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getSelectedTheme } from 'ducks/global/selectors'

export const PlainMultiSelect = (props) => {
  const { options, label } = props
  const { t } = useTranslation()
  const selectedThemeMode = useSelector(getSelectedTheme)

  return (
    <StyledContainer>
      <StyledLabel>
        {label}
      </StyledLabel>
      <Select
        styles={REACT_SELECT_STYLES(generateSelectedTheme(selectedThemeMode))}
        options={options}
        {...props}
        isMulti
        noOptionsMessage={() => t('filters.select.noResults')}
      />
    </StyledContainer>
  )
}

PlainMultiSelect.defaultProps = {
  required: false,
  isSearchable: true,
  options: [],
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  label: ''
}

export default PlainMultiSelect

const StyledContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledLabel = styled.div`
  display: inline-block;
  flex: none;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
`
