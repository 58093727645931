import React from 'react'
import Icon from 'components/Icon'

export const OrderList = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 480 480.03305'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='m184.015625 24.03125c-13.253906 0-24 10.746094-24 24 0 13.257812 10.746094 24 24 24s24-10.742188 24-24c0-13.253906-10.746094-24-24-24zm0 32c-4.417969 0-8-3.578125-8-8 0-4.417969 3.582031-8 8-8s8 3.582031 8 8c0 4.421875-3.582031 8-8 8zm0 0' />
        <path d='m368.015625 224.03125v-152c0-13.253906-10.746094-24-24-24h-72c0-8.835938-7.164063-16-16-16h-27.4375c-3.226563-.179688-5.988281-2.375-6.890625-5.476562-5.683594-15.925782-20.765625-26.554688-37.671875-26.554688s-31.988281 10.628906-37.671875 26.554688c-.898438 3.101562-3.660156 5.296874-6.886719 5.476562h-27.441406c-8.835937 0-16 7.164062-16 16h-72c-13.253906 0-24 10.746094-24 24v384c0 13.257812 10.746094 24 24 24h456v-256zm-48 16h48v59.058594l-24-12-24 12zm24-176c4.417969 0 8 3.582031 8 8v152h-16v-144h-64v-16zm-232-16h27.441406c10.003907-.113281 18.828125-6.582031 21.953125-16.085938 3.40625-9.5625 12.457032-15.945312 22.605469-15.945312s19.203125 6.382812 22.609375 15.945312c3.125 9.503907 11.945312 15.972657 21.953125 16.085938h27.4375v48h-144zm144 64c8.835937 0 16-7.160156 16-16h48v128h-112v32h-32v16h32v16h-32v16h32v48h-32v16h32v16h-32v16h32v32h-160v-336h48c0 8.839844 7.164063 16 16 16zm-232 352c-4.417969 0-8-3.578125-8-8v-384c0-4.417969 3.582031-8 8-8h72v16h-64v368h176v16zm440 0h-240v-224h80v84.945312l40-20 40 20v-84.945312h80zm0 0' />
        <path d='m64.015625 224.03125h80v-60.6875l25.65625-25.65625-11.3125-11.308594-17.65625 17.652344h-76.6875zm16-64h44.6875l-20.6875 20.691406-10.34375-10.34375-11.3125 11.308594 21.65625 21.65625 24-24v28.6875h-48zm0 0' />
        <path d='m140.703125 240.03125h-76.6875v80h80v-60.6875l25.65625-25.65625-11.3125-11.308594zm-12.6875 64h-48v-48h44.6875l-20.6875 20.691406-10.34375-10.34375-11.3125 11.308594 21.65625 21.65625 24-24zm0 0' />
        <path d='m158.359375 318.378906-17.65625 17.652344h-76.6875v80h80v-60.6875l25.65625-25.65625zm-30.34375 81.652344h-48v-48h44.6875l-20.6875 20.691406-10.34375-10.34375-11.3125 11.308594 21.65625 21.65625 24-24zm0 0' />
        <path d='m176.015625 160.03125h80v16h-80zm0 0' />
        <path d='m176.015625 192.03125h80v16h-80zm0 0' />
        <path d='m344.015625 456.03125h112v-64h-112zm16-48h80v32h-80zm0 0' />
        <path d='m368.015625 416.03125h16v16h-16zm0 0' />
        <path d='m392.015625 416.03125h40v16h-40zm0 0' />
      </g>
    </Icon>
  )
}

export default OrderList
