import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getHeadErrorOrEmptyObject } from 'utils/form'

import Select from 'components/atoms/SingleSelect'

export const SingleSelectField = props => {
  const {
    options,
    name,
    label,
    onChange,
    validate,
    reset,
    value: initialValue,
    required,
    disabled
  } = props
  const [value, _setValue] = useState(initialValue || '')
  const [touched, _setTouched] = useState(false)
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: {}
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (touched && !reset) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObject(v) })
      })
    }
  }, [value, touched, reset])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (reset) {
      _setValue(initialValue || '')
    }
  }, [reset])

  const handleBlur = () => _setTouched(true)
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  return (
    <Select
      onBlur={handleBlur}
      onChange={handleChange}
      name={name}
      value={value}
      options={options}
      error={!valid && !disabled}
      errorText={valid || disabled ? '' : t(error.key, error.options)}
      label={label}
      required={required}
    />
  )
}

SingleSelectField.defaultProps = {
  validate: () => {}
}

export default SingleSelectField
