import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import qs from 'qs'
import {
  validateAssignLineToProducersField,
  validateAssignLineToProducersValues
} from 'features/producers/ducks/schema'
import { SORT_ORDER } from 'utils/table'

import { getSelectedItems } from 'ducks/global/selectors'
import { fetchLinesRoutine } from 'features/lines/ducks/actions'
import { getLinesAsOptions } from 'features/lines/ducks/selectors'
import { assignProducersToLineRoutine } from 'features/producers/ducks/actions'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

import Grid from 'components/atoms/Grid'
import SingleSelect from 'components/formElements/SingleSelectField'
import Button from 'components/atoms/Button'
import Paper from 'components/atoms/Paper'
import IconButton from 'components/atoms/IconButton'
import Close from 'components/icons/Close'

const emptyValues = {
  lineId: '',
  producerIds: []
}

export const AssignLineToProducersForm = (props) => {
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const lineOptions = useSelector(getLinesAsOptions)
  const dispatch = useDispatch()
  const fetchLines = useCallback(
    query => dispatch(fetchLinesRoutine(query)),
    [dispatch]
  )
  const assignProducersToLine = useCallback(
    payload => dispatch(assignProducersToLineRoutine(payload)),
    [dispatch]
  )
  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchLines(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'name',
        dir: SORT_ORDER.asc
      }
    }))
  }, [])

  useEffect(() => {
    validateAssignLineToProducersValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('producerIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    assignProducersToLine(values)
  }

  return (
    <StyledPaper>
      <AssignUserContainer>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className='assign-line__summary'>
              <span>
                {t('producersPage.assignLine.selectedItems', { count: selectedItems.length })}
              </span>
              {
                selectedItems.length > 0 && (
                  <div className='assign-line__reset'>
                    <IconButton size='small' onClick={handleResetSelection}>
                      <Close />
                    </IconButton>
                  </div>
                )
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <form className='assign-line__form' onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <SingleSelect
                    options={lineOptions}
                    required
                    name='lineId'
                    label={t('form.line.label')}
                    onChange={handleOnChange}
                    validate={validateAssignLineToProducersField(values)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className='assign-line__submit'>
                    <Button
                      type='submit'
                      id='button-submit'
                      disabled={!valid}
                      onClick={handleSubmit}
                    >
                      {t('producersPage.assignLine.assignCTA')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </AssignUserContainer>
    </StyledPaper>
  )
}

export default AssignLineToProducersForm

const StyledPaper = styled(Paper)`
  padding: 10px 33px;
  margin-bottom: 20px;
`

const AssignUserContainer = styled.div`
  .assign-line__summary {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-bottom: 10px;
    }
  }
  
  .assign-line__reset {
    margin-right: 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-right: 0;
    }
  }
  
  .assign-line__form {
    
  }
  
  .assign-line__submit {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin: 0;
    }
  }
`
