import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatNumber, twoDecimal } from 'utils/number'
import { propOr, uniqBy, prop } from 'ramda'
import { formatPrice } from 'utils/price'
import { hasNonEmptyProp } from 'utils/ramda'
import { formatDate, FORMATS } from 'utils/date'
import { getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'

import { fetchProductAuctionsRoutine } from 'features/productAuctions/ducks/actions'
import { getProductAuctions } from 'features/productAuctions/ducks/selectors'

import GiftBasket from 'components/icons/GiftBasket'
import Table from 'components/atoms/Table/Table'
import TableBody from 'components/atoms/Table/TableBody'
import TableHeader from 'components/atoms/Table/TableHeader'
import TableHeaderCell from 'components/atoms/Table/TableHeaderCell'
import TableRow from 'components/atoms/Table/TableRow'
import TableCell from 'components/atoms/Table/TableCell'
import UpdateBundleRetailPriceModal from 'features/products/components/UpdateBundleRetailPriceModal'
import BundleItem from 'features/products/components/BundleItem'
import { generateIaiProductLink } from 'utils/general'
import ProductBundleAuctionsList from 'features/productAuctions/ProductBundleAuctionList'
import ToggleProductBundleHasAds from 'features/allegro/ToggleProductBundleHasAds'
import AllegroPromo from 'features/allegro/components/AllegroPromo'
import AllegroAds from 'components/icons/AllegroAds'
import Star from 'components/icons/Star'
import AllegroPackagePromo from 'components/icons/AllegroPackagePromo'
import AllegroBargain from 'components/icons/AllegroBargain'
import AllegroHalfPercent from 'components/icons/AllegroHalfPercent'

export const ProductBundle = (props) => {
  const { t } = useTranslation()
  const { data } = props
  const dispatch = useDispatch()
  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)
  const auctions = useSelector(state => getProductAuctions(state, data.productId))

  const fetchProductAuctions = useCallback(
    () => dispatch(fetchProductAuctionsRoutine(data.productId)),
    [dispatch]
  )

  useEffect(() => {
    fetchProductAuctions()
  }, [])

  const headers = [
    {
      id: 'stock',
      sortable: false,
      cell: t('productPage.bundles.table.headers.stock'),
      tooltip: t('monitoringPage.table.tooltip.stock'),
      align: 'right'
    },
    {
      id: 'price',
      sortable: false,
      cell: t('productPage.bundles.table.headers.retailPrice'),
      tooltip: t('monitoringPage.table.tooltip.retailPrice'),
      align: 'right'
    },
    {
      id: 'edit-price',
      sortable: false,
      cell: ''
    },
    {
      id: 'margin',
      sortable: false,
      cell: t('productPage.bundles.table.headers.margin'),
      align: 'right'
    },
    {
      id: 'soldToday',
      sortable: false,
      cell: t('productPage.bundles.table.headers.soldToday'),
      tooltip: t('monitoringPage.table.tooltip.soldToday'),
      align: 'right'
    },
    {
      id: 'sold1',
      sortable: false,
      cell: t('productPage.bundles.table.headers.sold1'),
      align: 'right'
    },
    {
      id: 'soldShort',
      sortable: false,
      cell: t('productPage.bundles.table.headers.soldX', { count: soldShortValue }),
      align: 'right'
    },
    {
      id: 'soldLong',
      sortable: false,
      cell: t('productPage.bundles.table.headers.soldX', { count: soldLongValue }),
      align: 'right'
    },
    {
      id: 'iaiCreatedAt',
      sortable: false,
      cell: t('productPage.bundles.table.headers.iaiCreatedAt'),
      tooltip: t('monitoringPage.table.tooltip.iaiCreatedAt')
    },
    {
      id: 'activatedAt',
      sortable: false,
      cell: t('productPage.bundles.table.headers.activatedAt'),
      tooltip: t('monitoringPage.table.tooltip.activatedAt')
    },
    {
      id: 'promo',
      sortable: false,
      cell: t('monitoringPage.table.headers.promo')
    },
    {
      id: 'allegroFee',
      sortable: false,
      cell: t('productPage.bundles.table.headers.allegroFee'),
      tooltip: t('monitoringPage.table.tooltip.allegroFee'),
      align: 'right'
    }
  ]

  const renderHeaders = headers.map(header => (
    <TableHeaderCell
      disableSticky
      key={header.id}
      sortable={header.sortable}
      columnId={header.id}
      align={header.align || 'left'}
      tooltip={header.tooltip || null}
    >
      {header.cell}
    </TableHeaderCell>
  ))

  const renderRow = (
    <TableRow>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'stock', data))}
      </TableCell>
      <TableCell align='right'>
        {formatPrice(propOr(twoDecimal(0), 'retailPrice', data))}
      </TableCell>
      <TableCell align='left'>
        <UpdateBundleRetailPriceModal data={data} />
      </TableCell>
      <TableCell align='right'>
        {propOr('0.00', 'margin', data)}%
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'soldToday', data))}
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'sold1', data))}
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'soldShort', data))}
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'soldLong', data))}
      </TableCell>
      <TableCell>
        {hasNonEmptyProp('iaiCreatedAt')(data) ? formatDate(data.iaiCreatedAt, FORMATS.slash) : '-'}
      </TableCell>
      <TableCell>
        {hasNonEmptyProp('activatedAt')(data) ? formatDate(data.activatedAt, FORMATS.slash) : '-'}
      </TableCell>
      <TableCell>
        <PromoCellContainer>
          {propOr(false, 'hasAds', data) && <AllegroPromo logo={<AllegroAds />} promoType={t('monitoringPage.table.allegroPromoTypes.hasAds')} />}
          {propOr(false, 'isEmphasized', data) && <AllegroPromo logo={<Star />} promoType={t('monitoringPage.table.allegroPromoTypes.isEmphasized')} />}
          {propOr(false, 'hasPromotionPackage', data) && <AllegroPromo logo={<AllegroPackagePromo />} promoType={t('monitoringPage.table.allegroPromoTypes.hasPromotionPackage')} />}
          {propOr(false, 'isBargain', data) && <AllegroPromo logo={<AllegroBargain />} promoType={t('monitoringPage.table.allegroPromoTypes.isBargain')} />}
          {propOr(false, 'hasHalfPercent', data) && <AllegroPromo logo={<AllegroHalfPercent />} promoType={t('monitoringPage.table.allegroPromoTypes.hasHalfPercent')} />}
        </PromoCellContainer>
      </TableCell>
      <TableCell align='right'>
        {formatPrice(propOr(twoDecimal(0), 'allegroFee', data))}
      </TableCell>
    </TableRow>
  )

  const renderBundleItems =
    uniqBy(prop('ean'), data.bundleItems).map(item => <BundleItem data={item} key={`bundle-item-${item.id}`} />)

  const renderAuctions = <ProductBundleAuctionsList bundleProductId={data.productId} data={auctions} />

  return (
    <BundleContainer>
      <div className='bundle__info'>
        <div className='bundle__name'>
          <div className='bundle__icon'>
            <GiftBasket />
          </div>
          <span>
            <a
              href={generateIaiProductLink(data.iaiId)}
              rel='noopener noreferrer'
              target='_blank'
            >
              {propOr('', 'name', data)} ({propOr('', 'iaiId', data)})
            </a>
          </span>
          <ToggleProductBundleHasAds id={data.productId} />
        </div>
        <div className='bundle__table'>
          <Table padding='none'>
            <TableHeader>
              <TableRow>
                {renderHeaders}
              </TableRow>
            </TableHeader>
            <TableBody>
              {renderRow}
            </TableBody>
          </Table>
        </div>
        <div className='bundle__components'>
          <div className='bundle__subtitle'>
            {t('productPage.bundles.components')}
          </div>
          <div>
            {renderBundleItems}
          </div>
        </div>
        {
          auctions.length > 0 && (
            <div className='bundle__auctions'>
              {renderAuctions}
            </div>
          )
        }
      </div>
    </BundleContainer>
  )
}

export default ProductBundle

const BundleContainer = styled.div`
  display: inline-flex;
  width: 100%;
  background-color: ${props => props.theme.palette.common.gray100};
  padding: 20px;
  border-radius: ${props => props.theme.shape.borderRadius};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 10px;
  }
  
  .bundle__icon {
    font-size: 20px;
    margin-right: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 17px;
    margin-right: 10px;
    }
  }
  
  .bundle__info {
    width: 100%;
    flex-grow: 1;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      padding: 0;
    }
  }
  
  .bundle__name {
    display: flex;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 25px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  
  .bundle__table {
    overflow: hidden;
    overflow-x: auto;
  
    .MuiTableCell-root,
    .MuiTableRow-head,
    .MuiTableRow-root {
      background-color: transparent !important;
      font-size: 11px !important;
      line-height: 12px !important;
      border: none !important;
    }
  }
  
  .bundle__components {}
  
  .bundle__subtitle {
    margin: 30px 0 10px 0;
    font-weight: 500;
  }
  
  .bundle__auctions {
    margin-top: 30px;
    
    th {
      background-color: transparent !important;
    }
  }
`

const PromoCellContainer = styled.div`
  display: flex;
`
