import { isNotNilOrEmpty } from 'utils/ramda'
import { pipe, propOr } from 'ramda'
import qs from 'qs'
import { API_FIELD_NAME } from 'utils/apiFilterFields'

export const ALERT_TYPES = {
  priceChange: 'priceChange',
  stockChange: 'stockChange',
  suddenSale: 'suddenSale',
  notSelling: 'notSelling',
  mentions: 'mention',
  priceStockChange: 'priceStockChange',
  newProduct: 'newProduct'
}

export const ALERT_UNITS = {
  [ALERT_TYPES.priceChange]: 'zł',
  [ALERT_TYPES.stockChange]: 'szt'
}

export const ALERT_DIFFERENCE_UNITS = {
  [ALERT_TYPES.priceChange]: '%',
  [ALERT_TYPES.stockChange]: 'szt'
}

export const isAlertSeen = alert => pipe(
  propOr(null, 'seen_at'),
  isNotNilOrEmpty
)(alert)

export const prepareFetchAlertsCurrentQuery = (currentQuery, alertTypes) => {
  const parsedCurrentQuery = qs.parse(currentQuery, { ignoreQueryPrefix: true })
  const prepareQuery = {
    ...parsedCurrentQuery,
    filter: {
      ...propOr({}, 'filter', parsedCurrentQuery),
      [API_FIELD_NAME.alertType]: alertTypes
    }
  }
  return qs.stringify(prepareQuery)
}

export const getAlertUnit = alert => {
  const type = propOr('', 'type', alert)
  return propOr('', type, ALERT_UNITS)
}

export const geDifferenceAlertUnit = alert => {
  const type = propOr('', 'type', alert)
  return propOr('', type, ALERT_DIFFERENCE_UNITS)
}
