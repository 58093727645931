import React from 'react'
import Icon from 'components/Icon'

export const AllegroHalfPercent = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 340 321'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          d='M318.8,82c-9.5-12.2-27.2-14.5-39.5-5c-1.8,1.5-3.5,3-5,5c-3.5,5-5.5,11-5.2,17.2c0,6.2,1.8,12.2,5.2,17.2
      c9.5,12.3,27.2,14.5,39.5,5c1.8-1.5,3.5-3,5-5c3.5-5,5.5-11,5.2-17.2C324.3,93,322.3,87,318.8,82z M310,105.5c-2,6-6.8,10.7-13.5,11
      c-6.8,0.3-11.5-4.7-13.5-11c-1.5-4.3-1.5-8.8,0-13c2-6,6.7-10.8,13.5-11c6.5-0.2,11.5,4.8,13.5,11C311.5,96.7,311.5,101.2,310,105.5
      L310,105.5z M107.3,125.7c0,1-0.8,1.5-1.5,1.5l0,0h-9.3c-1,0-1.5-0.8-1.5-1.5l0,0v-76c0-1,0.8-1.5,1.5-1.5l0,0h9.3
      c1,0,1.5,0.8,1.5,1.5l0,0V125.7z M85.8,125.7c0,1-0.7,1.5-1.5,1.5l0,0H75c-1,0-1.5-0.8-1.5-1.5l0,0v-76c0-0.8,0.8-1.5,1.5-1.5h9.2
      c1,0,1.5,0.8,1.5,1.5l0,0V125.7L85.8,125.7z M267,74.2v6.5c0,1-0.5,1.8-1.5,2c-0.2,0-0.5,0-0.8,0c-10.5-2.2-17,2.5-17,12.2v31.2
      c0,1-0.8,1.5-1.5,1.5H237c-1,0-1.5-0.8-1.5-1.5V94c0-6,2.2-12,6.7-16c4.5-4.5,10.5-7,17-6.8c2.2,0,4.2,0.2,6.5,0.5
      C266.8,72,267,72.5,267,74.2L267,74.2z M227.3,99c0-6.2-1.7-12.2-5.2-17.2c-9.5-12.2-27.2-14.5-39.5-5c-1.8,1.5-3.5,3-5,5
      c-7.2,10.2-7.2,24,0,34.5c5.2,7,13.5,11,22.2,11c5.8,0,11.2-1.8,15.8-4.8v6.8c0,9.2-8,11-13.3,11.2c-3.5,0-7,0-10.5-0.5
      c-1.5-0.2-2.5,0-2.5,1.5v8.2c0,0.8,0.8,1.2,1.5,1.2l0,0c12,1,20,0.8,26-2.7c4.2-2.5,7.5-6.5,8.8-11.2c1-3.8,1.5-7.5,1.5-11.3
      L227.3,99L227.3,99z M199.8,116.5c-6.7,0-11.5-4.8-13.5-11c-1.5-4.2-1.5-8.8,0-13c2-6,6.7-11,13.5-11c14.8,0,15.2,15.2,15.2,17.2
      C215,100.7,214.8,116.5,199.8,116.5z M64.8,93.5c0-7.2-1.8-13-5.3-16.8S49,71,42.5,71s-13,1.8-18.7,5c-0.2,0.2-0.5,0.5-0.5,1l0.5,8
      c0.2,0.8,1,1.2,1.8,1.2c0.2,0,0.5-0.2,0.8-0.2c8-5,17.8-6,22.8-3.5c2.8,1.5,4.5,4.5,4.2,7.8v1.2H39.8c-5.8,0-23.5,2.2-23.5,18.5v0.2
      c-0.2,5,1.8,10,5.8,13c4.2,3,9.2,4.5,14.2,4h27.2c1,0,1.5-0.8,1.5-1.5l0,0L64.8,93.5L64.8,93.5z M52.8,117.2h-16c-2,0-4-0.8-5.8-2
      s-2.8-3.2-2.8-5.2c0-3,2-8.8,11.7-8.8h12.8V117.2z M141.5,71c-16.5,0-23.8,11-26,20.2c-0.8,2.5-1,5-1,7.8c0,6.2,1.8,12.2,5.2,17.2
      c5.2,6.8,13.5,11,22.2,11c9,0.2,14.8-1.2,19.2-3.8c1-0.5,1.2-1.8,1-2.8v-7.2c0-1.5-1-2.2-2.2-1.2c-5.2,3.5-11.5,5-18,4.2
      c-7.5,0-13.5-5.8-14.2-13h36.5c0.8,0,1.5-0.8,1.5-1.5C166.8,92,163.8,71,141.5,71z M128.3,93.5c0.2-6.8,6-12.2,13-11.8
      c0,0,0,0,0.2,0c6-0.2,11.2,4.2,11.8,10.2c0,0.5,0,1,0,1.5H128.3z'
        />
      </g>
      <g>
        <path
          d='M89.4,228c0,17.8-2.7,31-8.1,39.5c-5.4,8.6-13.6,12.8-24.6,12.8c-10.6,0-18.6-4.4-24.1-13.2c-5.5-8.8-8.3-21.9-8.3-39.1
        c0-17.7,2.7-30.7,8-39.2c5.3-8.5,13.5-12.8,24.4-12.8c10.7,0,18.8,4.4,24.4,13.2C86.6,198,89.4,210.9,89.4,228z M31.7,228
        c0,15.7,2.1,27.3,6.2,34.8c4.1,7.5,10.4,11.3,18.7,11.3c8.7,0,15.1-3.9,19.1-11.6c4-7.8,6-19.3,6-34.5c0-15-2-26.3-6-34.1
        c-4-7.8-10.4-11.6-19.1-11.6s-15.1,3.9-19,11.6C33.7,201.6,31.7,213,31.7,228z'
        />
        <path
          d='M108.5,273.5c0-4.5,1.8-6.8,5.5-6.8c3.8,0,5.7,2.3,5.7,6.8c0,4.5-1.9,6.8-5.7,6.8C110.4,280.3,108.5,278.1,108.5,273.5z'
        />
        <path
          d='M168.3,218c10.8,0,19.3,2.6,25.5,7.8c6.2,5.2,9.3,12.4,9.3,21.6c0,10.4-3.2,18.5-9.7,24.3c-6.5,5.8-15.4,8.7-26.8,8.7
        c-5,0-9.8-0.5-14.4-1.5c-4.5-1-8.3-2.4-11.4-4.3v-7.1c5,2.5,9.4,4.3,13.3,5.3s8,1.5,12.4,1.5c8.9,0,16-2.3,21.4-7
        c5.4-4.7,8-11,8-19c0-7.5-2.6-13.5-7.8-17.8c-5.2-4.3-12.3-6.4-21.3-6.4c-6,0-12.3,0.9-18.9,2.7L144,224l4-46.4h48.8v6.7h-42.3
        l-3.1,35.8C158.6,218.7,164.2,218,168.3,218z'
        />
        <path
          d='M259.8,207.8c0,10.4-1.7,18.4-5.2,23.8c-3.5,5.4-8.5,8.1-15,8.1c-6.1,0-11-2.8-14.5-8.4c-3.5-5.6-5.2-13.4-5.2-23.6
        c0-10.3,1.7-18.2,5-23.6s8.2-8.1,14.7-8.1c6.4,0,11.4,2.8,14.9,8.4C258.1,190,259.8,197.8,259.8,207.8z M226.8,207.8
        c0,8.6,1,15.1,3.1,19.4c2.1,4.3,5.3,6.5,9.8,6.5c8.9,0,13.4-8.6,13.4-25.9c0-8.5-1.1-14.9-3.4-19.2c-2.3-4.3-5.6-6.4-10-6.4
        c-4.4,0-7.7,2.1-9.8,6.4C227.8,192.9,226.8,199.3,226.8,207.8z M300.9,177.6l-56.2,101.4H238l56.2-101.4H300.9z M318.9,248.6
        c0,10.4-1.7,18.4-5.1,23.8c-3.4,5.4-8.4,8.1-14.9,8.1c-6.3,0-11.2-2.8-14.6-8.4c-3.5-5.6-5.2-13.5-5.2-23.5
        c0-10.4,1.7-18.3,5.1-23.6c3.4-5.4,8.3-8,14.7-8c6.3,0,11.3,2.8,14.8,8.3C317.1,230.8,318.9,238.5,318.9,248.6z M285.8,248.6
        c0,8.6,1,15,3.1,19.2c2.1,4.3,5.3,6.4,9.8,6.4c8.9,0,13.4-8.6,13.4-25.7c0-17.1-4.5-25.6-13.4-25.6c-4.4,0-7.7,2.1-9.8,6.3
        C286.9,233.6,285.8,240,285.8,248.6z'
        />
      </g>
    </Icon>
  )
}

export default AllegroHalfPercent
