import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr, pipe } from 'ramda'
import useSelectionSaga from 'hooks/useSelectionSaga'
import { sortStringsAsc } from 'utils/ramda'

import { getLinesArray, getLinesMeta, getIsLinesLoading } from 'features/lines/ducks/selectors'
import { fetchLinesRoutine } from 'features/lines/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import Checkbox from 'components/atoms/Checkbox'
import Chip from 'components/atoms/Chip'
import DeleteLineButton from 'features/lines/DeleteLineButton'
import EditLineModal from 'features/lines/components/EditLineModal'

export const LinesTable = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(getLinesArray)
  const isLoading = useSelector(getIsLinesLoading)
  const pagination = useSelector(getLinesMeta)
  const fetchLines = useCallback(
    query => dispatch(fetchLinesRoutine(query)),
    [dispatch]
  )

  const visibleItemsIds = data.map(data => data.id)

  const {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem,
    handleSelectCollection
  } = useSelectionSaga(visibleItemsIds)

  const headers = [
    {
      id: 'selectAll',
      sortable: false,
      cell: (
        <Checkbox
          indeterminate={hasSomeItemSelected}
          checked={hasAllVisibleItemsSelected}
          onChange={handleSelectCollection}
        />
      )
    },
    {
      id: 'name',
      sortable: true,
      cell: t('linesPage.table.headers.name')
    },
    {
      id: 'producers',
      sortable: false,
      cell: t('linesPage.table.headers.producers')
    },
    {
      id: 'user_login',
      sortable: true,
      cell: t('linesPage.table.headers.user')
    },
    {
      id: 'actions',
      sortable: false,
      cell: ''
    }
  ]

  const renderProducers = producers => producers.map((producer, index) => (
    <StyledChip
      size='small'
      label={producer}
      key={`${producer}-${index}`}
    />
  ))

  const renderItems = data.map((data, index) => (
    <TableRow checked={isItemSelected(data.id)} hover key={propOr(`producers-row-${index}`, 'id', data)}>
      <TableCell width='2%'>
        <Checkbox
          checked={isItemSelected(data.id)}
          onChange={handleSelectItem(data.id)}
        />
      </TableCell>
      <TableCell>
        {propOr('-', 'name', data)}
      </TableCell>
      <TableCell>
        <ProducersCell>
          {
            pipe(
              propOr([], 'producers'),
              sortStringsAsc,
              renderProducers
            )(data)
          }
        </ProducersCell>
      </TableCell>
      <TableCell>
        {propOr('-', 'userLogin', data)}
      </TableCell>
      <TableCell>
        <ActionsCell>
          <DeleteLineButton data={data} />
          <EditLineModal data={data} />
        </ActionsCell>
      </TableCell>
    </TableRow>
  ))

  return (
    <EntitiesList
      emptyStateText={t('linesPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchLines}
      isLoading={isLoading}
      defaultSortedColumnId='name'
    />
  )
}

export default LinesTable

const StyledChip = styled(Chip)`
  margin: 0 2px 5px;
`

const ProducersCell = styled.div`
  margin: 8px 0 3px;
`

const ActionsCell = styled.div`
  display: flex;
  align-items: center;
`
