import { string, object, array } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Assign User to Alerts form validator

export const assignUserToAlertsSchema = object().shape({
  userId: string()
    .required(() => ({ key: 'form.user.errors.required' })),
  alertsIds: array().required()
})

export const validateAssignUserToAlertsField = values => validateField(assignUserToAlertsSchema, values)
export const validateAssignUserToAlertsValues = validateValues(assignUserToAlertsSchema)
