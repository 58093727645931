import { propOr, pipe, equals, applySpec, prop, map } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES, transformToArray } from 'utils/redux'

export const selectUsers = state => state.users

export const getIsUsersLoading = createSelector(
  selectUsers,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getUsersMeta = createSelector(
  selectUsers,
  propOr({}, 'usersMeta')
)

export const getUsersArray = createSelector(
  selectUsers,
  pipe(propOr({}, 'users'), transformToArray)
)

export const getUsersAsOptions = createSelector(
  selectUsers,
  pipe(
    propOr({}, 'users'),
    transformToArray,
    map(
      applySpec({
        label: prop('login'),
        value: prop('id')
      })
    )
  )
)

export const getUsersAsMentions = createSelector(
  selectUsers,
  pipe(
    propOr({}, 'users'),
    transformToArray,
    map(
      applySpec({
        display: prop('login'),
        id: prop('id')
      })
    )
  )
)

export const getUserLoginAsOptions = createSelector(
  selectUsers,
  pipe(
    propOr({}, 'users'),
    transformToArray,
    map(
      applySpec({
        label: prop('login'),
        value: prop('login')
      })
    )
  )
)

export const getUsers = createSelector(
  selectUsers,
  propOr({}, 'users')
)

export const getUsersLoginArray = createSelector(
  selectUsers,
  pipe(propOr({}, 'users'), transformToArray, map(prop('login')))
)
