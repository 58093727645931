import React from 'react'
import AsyncSelect from 'react-select/async'
import { generateSelectedTheme } from 'utils/theme'
import { REACT_SELECT_STYLES } from 'utils/reactSelectStandard'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getSelectedTheme } from 'ducks/global/selectors'

export const PlainAsyncSelect = (props) => {
  const { loadOptions, label, isMulti } = props
  const { t } = useTranslation()
  const selectedThemeMode = useSelector(getSelectedTheme)

  return (
    <StyledContainer>
      <StyledLabel>
        {label}
      </StyledLabel>
      <AsyncSelect
        isClearable
        isMulti={isMulti}
        styles={REACT_SELECT_STYLES(generateSelectedTheme(selectedThemeMode))}
        loadOptions={loadOptions}
        defaultOptions
        noOptionsMessage={() => t('filters.select.noResults')}
        {...props}
      />
    </StyledContainer>
  )
}

export default PlainAsyncSelect

const StyledContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledLabel = styled.div`
  display: inline-block;
  flex: none;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
`
