import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddLineForm from 'features/lines/AddLineForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Pallet from 'components/icons/Pallet'

export const AddLineModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button startIcon={<Pallet />} onClick={handleOpen}>{t('linesPage.addLine.addLineTitle')}</Button>
      <Modal
        title={t('linesPage.addLine.addLineTitle')}
        open={open}
        handleClose={handleClose}
      >
        <AddLineForm onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default AddLineModal
