import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useResetFilters from 'hooks/useResetFilters'

import Popover from 'components/atoms/Popover'
import IconButton from 'components/atoms/IconButton'
import Filters from 'components/icons/Filters'
import Button from 'components/atoms/Button'
import { transformFiltersToNamesAndValues } from 'utils/filters'

export const FiltersPopover = props => {
  const transformedFilters = transformFiltersToNamesAndValues(props.selectedFilters)
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const { t } = useTranslation()
  const handleResetFilters = useResetFilters()

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const FiltersContentPopover = (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <PopoverContainer>
        {props.children}
        <ButtonContainer>
          <Button variant='outlined' onClick={handleResetFilters}>
            {t('filters.resetCTA')}
          </Button>
          <Button onClick={handleClose}>
            {t('filters.closeFilters')}
          </Button>
        </ButtonContainer>
      </PopoverContainer>
    </Popover>
  )

  return (
    <>
      <TriggerButtonContainer>
        <IconButton onClick={handleOpen}>
          <Filters />
        </IconButton>
        {
          transformedFilters.length > 0 && (
            <div className='filters__count'>{transformedFilters.length}</div>
          )
        }
      </TriggerButtonContainer>
      {FiltersContentPopover}
    </>
  )
}

export default FiltersPopover

export const TriggerButtonContainer = styled.div`
  position: relative;
  
  & .filters__count {
    display: none;
    position: absolute;
    width: 13px;
    height: 13px;
    line-height: 13px;
    text-align: center;
    top: 5px;
    right: 5px;
    font-size: 9px;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: block;
    }
  }
`

export const PopoverContainer = styled.div`
  padding: 20px;
  min-width: 300px;
  max-height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: auto;
`

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 20px;
  
  & button + button {
    margin-left: 10px;
  }
`
