import React from 'react'
import styled from 'styled-components'
import {
  checkDefaultFiltersForAdmin,
  checkDefaultFiltersForUser,
  transformFiltersToNamesAndValues
} from 'utils/filters'
import { useSelector } from 'react-redux'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { API_FIELD_NAME_TRANSLATION } from 'utils/apiFilterFields'
import { equals, propOr, values } from 'ramda'
import { useTranslation } from 'react-i18next'
import useResetFilters from 'hooks/useResetFilters'
import { USER_ROLES } from 'utils/user'

import { getUserLogin, getUserRole } from 'features/auth/ducks/selectors'
import { getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'

import Close from 'components/icons/Close'
import Chip from 'components/atoms/Chip'
import { useHistory } from 'react-router-dom'

export const SelectedFilters = (props) => {
  const history = useHistory()
  const urlSearchQuery = useUrlSearchQuery()
  const userLogin = useSelector(getUserLogin)
  const userRole = useSelector(getUserRole)
  const transformedFilters = transformFiltersToNamesAndValues(props.selectedFilters, props.excludedSelectedFilters)
  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)
  const handleResetFilters = useResetFilters()
  const { t } = useTranslation()

  const hasDefaultFiltersForAdmin = propOr(false, history.location.pathname, checkDefaultFiltersForAdmin(userLogin, transformedFilters))
  const hasDefaultFiltersForUser = propOr(false, history.location.pathname, checkDefaultFiltersForUser(userLogin, transformedFilters))

  const shouldDisplayResetFilters = equals(userRole, USER_ROLES.admin) ? !hasDefaultFiltersForAdmin : !hasDefaultFiltersForUser

  const joinWithCommaIfArray = value => {
    // eslint-disable-next-line valid-typeof
    return typeof value === 'object' ? values(value).join(', ') : value
  }

  const getFilterTranslatedName = name => propOr(name, name)(API_FIELD_NAME_TRANSLATION({ soldLongValue, soldShortValue }))

  const renderFilters = transformedFilters.map(filter => (
    <StyledChip
      key={`filter-${filter.name}`}
      label={(
        <ChipContent>
          <div id={`selected-filter-${filter.name}`}><strong>{getFilterTranslatedName(filter.name)}</strong>: {joinWithCommaIfArray(filter.value)}</div>
          <StyledClose id={`remove-selected-filter-${filter.name}`} onClick={() => urlSearchQuery.removeSearchQuery(filter.name)} />
        </ChipContent>
      )}
    />
  ))

  return (
    <SelectedFiltersContainer>
      <div>
        <div className='selected-filters__list'>
          {renderFilters}
        </div>
      </div>
      {
        shouldDisplayResetFilters && (
          <div className='selected-filters__reset' onClick={handleResetFilters}>
            {t('filters.resetCTA')}
          </div>
        )
      }
    </SelectedFiltersContainer>
  )
}

export default SelectedFilters

const StyledChip = styled(Chip)`
  margin: 0 2px 5px;
`

const StyledClose = styled(Close)`
  margin-left: 5px;
  cursor: pointer;
`

const ChipContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SelectedFiltersContainer = styled.div`
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    width: 100%;
  }
  
  .selected-filters__list {
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .selected-filters__reset {
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 11px;
    text-transform: uppercase;
  }
`
