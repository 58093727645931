import { createRoutine } from 'redux-saga-routines'
import { pathOr } from 'ramda'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as daysOffService from 'services/DaysOffService'

// ROUTINES

export const fetchDaysOffRoutine = createRoutine('FETCH_DAYS_OFF')

// ACTIONS

function * fetchDaysOff ({ payload }) {
  yield put(fetchDaysOffRoutine.request())
  try {
    const result = yield call(daysOffService.fetchDaysOff, payload)
    yield put(fetchDaysOffRoutine.success(pathOr({}, ['data'], result)))
  } catch (e) {
    yield put(fetchDaysOffRoutine.failure(e))
  }
}

// WATCHERS

export function * fetchDaysOffWatcher () {
  yield takeLatest(fetchDaysOffRoutine.TRIGGER, fetchDaysOff)
}

// SAGAS

export const daysOffSagas = [
  fork(fetchDaysOffWatcher)
]
