import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SEVERITY } from 'utils/toast'
import { fileDownload } from 'utils/download'
import { useTranslation } from 'react-i18next'

import { getMonitoringCSV } from 'services/MonitoringService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { generateCurrentQuery } from 'utils/query'

import IconButton from 'components/atoms/IconButton'
import Download from 'components/icons/Download'
import Tooltip from 'components/atoms/Tooltip'

export const DownloadMonitoringCSV = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleDownload = async () => {
    setIsLoading(true)
    try {
      const response = await getMonitoringCSV(generateCurrentQuery())
      fileDownload(response.data, 'monitoring.csv')
      setIsLoading(false)
      return showToast({
        key: 'toast.downloadCSVSuccess',
        severity: SEVERITY.success
      })
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      return showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
  }

  return (
    <Tooltip title={t('monitoringPage.download.csv')}>
      <IconButton disabled={isLoading} id='download-csv-monitoring-table' onClick={handleDownload}>
        <Download />
      </IconButton>
    </Tooltip>
  )
}

export default DownloadMonitoringCSV
