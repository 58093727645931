import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { validateCreateProductCommentValues, validateCreateProductCommentField } from 'features/comments/ducks/schema'

import { createProductCommentRoutine } from 'features/comments/ducks/actions'
import { getIsProductCommentsLoading } from 'features/comments/ducks/selectors'

import UserMentionsField from 'components/formElements/UserMentionsField'
import Button from 'components/atoms/Button'

const emptyValues = {
  id: '',
  content: '',
  mentions: []
}

export const CreateCommentForm = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsProductCommentsLoading)

  const createComment = useCallback(
    payload => dispatch(createProductCommentRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateCreateProductCommentValues(values, _validateSchema)
  }, [values, id])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    handleOnChange('id', id)
  }, [])

  const resetForm = () => handleOnChange('content', '')

  const handleSubmit = e => {
    e.preventDefault()
    createComment({
      values,
      callback: resetForm
    })
  }

  const handleMentionsInputChange = (value, mentions) =>
    setValues(prevValues => ({
      ...prevValues,
      content: value,
      mentions
    }))

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div className='create-comment__input'>
        <UserMentionsField
          singleLine
          required
          reset={values.content === ''}
          mentions={values.mentions}
          name='content'
          label={t('form.comment.label')}
          onChange={handleMentionsInputChange}
          validate={validateCreateProductCommentField(values)}
        />
      </div>
      <div className='create-comment__cta'>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('productPage.comments.createCTA')}
        </Button>
      </div>
    </StyledForm>
  )
}

export default withRouter(CreateCommentForm)

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  
  .create-comment__input {
    flex-grow: 1;
    max-width: calc(100% - 160px);
  }
  
  .create-comment__cta {
    margin-left: 10px;
  }
`
