import { pipe, pathOr, propOr, equals } from 'ramda'
import { DeliveriesMeta } from 'features/deliveries/ducks/records'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'

export const selectProductDeliveries = state => state.deliveries

export const getIsProductDeliveriesLoading = createSelector(
  selectProductDeliveries,
  pipe(
    propOr('', 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductDeliveriesArray = createSelector(
  selectProductDeliveries,
  (state, id) => id,
  (deliveries, id) => pipe(
    pathOr([], ['deliveries', id])
  )(deliveries)
)

export const getProductDeliveriesMeta = createSelector(
  selectProductDeliveries,
  propOr(new DeliveriesMeta(), 'deliveriesMeta')
)
