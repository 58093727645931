import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import IconButton from 'components/atoms/IconButton'
import Popover from 'components/atoms/Popover'
import SnoozeAutoDecision from 'components/icons/SnoozeAutoDecision'
import Tooltip from 'components/atoms/Tooltip'
import SnoozeAutoDecisionForm
  from 'features/products/SnoozeAutoDecisionForm'

export const SnoozeAutoDecisionPopover = (props) => {
  const { t } = useTranslation()
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? 'assign-tag' : undefined

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const PopupComponent = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <PopoverContainer>
        <SnoozeAutoDecisionForm selectedItemsDataProp={props.selectedItemsDataProp} onSubmit={handleClose} />
      </PopoverContainer>
    </Popover>
  )

  return (
    <SnoozeContainer>
      <Tooltip title={t('monitoringPage.snoozeAutoDecision.label')}>
        <div>
          <IconButton
            type='button'
            id='button-snooze'
            onClick={handleOpen}
            color='primary'
          >
            <div className='icon-container'>
              <SnoozeAutoDecision />
            </div>
          </IconButton>
        </div>
      </Tooltip>
      {PopupComponent}
    </SnoozeContainer>
  )
}

export default SnoozeAutoDecisionPopover

export const PopoverContainer = styled.div`
  padding: 20px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
     width: calc(100vw - 32px);
  }
`

const SnoozeContainer = styled.div`
  display: inline-block;
  
  .icon-container {
    display: inline-block;
    font-size: 21px;
    width: 21px;
    height: 21px;
    text-align: center;
  }
`
