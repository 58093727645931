import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { includes } from 'ramda'
import { getUserRole } from 'features/auth/ducks/selectors'
import { USER_ROLES } from 'utils/user'
import { THEME_MODES } from 'utils/theme'

import { getSelectedTheme } from 'ducks/global/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'

export const SideNavigationListItem = props => {
  const {
    isActive,
    onClick,
    icon: Icon,
    text,
    allowedRoles,
    id,
    hasNotification,
    notificationContent,
    path
  } = props

  const userRole = useSelector(getUserRole)

  const visible = includes(userRole, allowedRoles)
  const selectedThemeMode = useSelector(getSelectedTheme)

  return visible && (
    <SideNavListItemContainer
      isActive={isActive}
      onClick={onClick}
      id={id}
      themeMode={selectedThemeMode}
      hasNotification={hasNotification}
      notificationContent={notificationContent || ''}
    >
      <Link className='side-nav-list-item__link' to={path}>
        <div className='side-nav-list-item__icon'>
          <Icon />
        </div>
        <div className='side-nav-list-item__text'>{text}</div>
      </Link>
    </SideNavListItemContainer>
  )
}

SideNavigationListItem.defaultProps = {
  visible: true,
  isActive: false,
  text: '',
  onClick: () => {},
  allowedRoles: [USER_ROLES.admin, USER_ROLES.user]
}

export default SideNavigationListItem

const SideNavListItemContainer = styled.div`
  position: relative;
  white-space: nowrap;
  padding: 16px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  .side-nav-list-item__link {
    display: flex;
    align-items: center;
  }

  .side-nav-list-item__icon {
    position: relative;
    flex: none;
    display: inline-block;
    width: ${props => props.theme.dimensions.sideNavShrinkWidth};
    text-align: center;
    font-size: 20px;
    transition: ${props =>
  `color 300ms ${props.theme.transitions.easing.easeInOut}`};
    color: ${props => {
      switch (true) {
        case props.isActive && props.themeMode === THEME_MODES.light:
          return props.theme.palette.secondary.main
        case props.isActive && props.themeMode === THEME_MODES.dark:
          return props.theme.palette.primary.main
        default:
          return props.theme.palette.primary.contrastText
      }
    }};
    
    &::after {
      display: ${props => props.hasNotification ? 'block' : 'none'};
      content: '${props => props.notificationContent}';
      position: absolute;
      width: ${props => isNotNilOrEmpty(props.notificationContent) ? '11px' : '8px'};
      height: ${props => isNotNilOrEmpty(props.notificationContent) ? '11px' : '8px'};
      line-height: ${props => isNotNilOrEmpty(props.notificationContent) ? '11px' : '8px'};
      top: 0;
      right 16px;
      border-radius: 50%;
      background-color: ${props => props.theme.palette.error.main};
      font-size: 8px;
      text-align: center;
    }
  }

  .side-nav-list-item__text {
    white-space: nowrap;
    display: inline-block;
    color: ${props => {
      switch (true) {
        case props.isActive && props.themeMode === THEME_MODES.light:
          return props.theme.palette.secondary.main
        case props.isActive && props.themeMode === THEME_MODES.dark:
          return props.theme.palette.primary.main
        default:
          return props.theme.palette.primary.contrastText
      }
    }};
    transition: ${props =>
  `color 300ms ${props.theme.transitions.easing.easeInOut}`};
  }

  &:hover {
    .side-nav-list-item__icon {
      color: ${props => props.themeMode === THEME_MODES.light ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
    }

    .side-nav-list-item__text {
      color: ${props => props.themeMode === THEME_MODES.light ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
    }
  }
`
