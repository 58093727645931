import React from 'react'
import styled from 'styled-components'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { equals, propOr } from 'ramda'
import { addToArrayIfNotIncluded } from 'utils/array'
import { Link } from 'react-scroll'

export const SectionMenuItem = (props) => {
  const { text, id } = props
  const urlSearchQuery = useUrlSearchQuery()

  const handleClick = () => {
    const currentState = propOr([], 'sections', urlSearchQuery.getSearchQuery())
    urlSearchQuery.setSearchQuery({
      sections: addToArrayIfNotIncluded(id)(currentState),
      current: id
    })
  }

  const isActive = () => {
    const currentSection = propOr('', 'current', urlSearchQuery.getSearchQuery())
    return equals(id, currentSection)
  }

  return (
    <Link
      to={id}
      key={id}
      spy
      smooth
      offset={-330}
    >
      <SectionMenuItemContainer
        isActive={isActive()}
        onClick={handleClick}
        id={id}
      >
        <span className='section-menu-item__text'>{text}</span>
      </SectionMenuItemContainer>
    </Link>
  )
}

export default SectionMenuItem

const SectionMenuItemContainer = styled.div`
  padding: 16px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  .section-menu-item__text {
    color: ${props => props.isActive ? props.theme.palette.primary.main : props.theme.palette.text.main};
    transition: ${props => `color 300ms ${props.theme.transitions.easing.easeInOut}`};
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    
    @media (max-width: 1550px) {
      font-size: 10px;
    }
  }
  
  &:hover {
    .section-menu-item__text {
      color: ${props => props.theme.palette.primary.dark};
    }
  }
`
