import React, { useEffect, useState } from 'react'

import Switch from 'components/atoms/Switch'

export const SwitchField = props => {
  const {
    name,
    onChange,
    label,
    value: initialValue,
    disabled
  } = props

  const [value, _setValue] = useState(initialValue || false)

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  const handleChange = e => {
    _setValue(e.target.checked)
    onChange(name, e.target.checked)
  }

  return (
    <Switch
      label={label}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      name={name}
    />
  )
}

export default SwitchField
