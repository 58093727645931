import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  validateSnoozeProductsSchemaField,
  validateSnoozeProductsSchemaValues
} from 'features/products/ducks/schema'
import { generateCurrentQuery } from 'utils/query'

import { getSelectedItems } from 'ducks/global/selectors'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'
import { snoozeProductsRoutine } from 'features/products/ducks/actions'
import { getIsProductLoading } from 'features/products/ducks/selectors'

import DatePickerField from 'components/formElements/DatePickerField'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

const emptyValues = {
  comment: '',
  snoozedUntil: '',
  productIds: []
}

export const SnoozeProductsForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const selectedItems = useSelector(getSelectedItems)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsProductLoading)

  const snoozeProducts = useCallback(
    payload => dispatch(snoozeProductsRoutine(payload)),
    [dispatch]
  )

  const fetchMonitoring = useCallback(
    () => dispatch(fetchMonitoringRoutine(generateCurrentQuery())),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    validateSnoozeProductsSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('productIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    props.onSubmit()
    fetchMonitoring()
    handleResetSelection()
  }

  const handleSubmit = e => {
    e.preventDefault()
    snoozeProducts({
      values,
      callback: handleCallback
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div className='date-picker-container'>
        <DatePickerField
          disablePast
          required
          name='snoozedUntil'
          label={t('form.snoozeDate.label')}
          onChange={handleOnChange}
          validate={validateSnoozeProductsSchemaField(values)}
        />
      </div>
      <div className='input-container'>
        <TextField
          required
          name='comment'
          label={t('form.comment.label')}
          onChange={handleOnChange}
          validate={validateSnoozeProductsSchemaField(values)}
        />
      </div>
      <div className='button-container'>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('monitoringPage.snoozeProducts.saveCTA')}
        </Button>
      </div>
    </StyledForm>
  )
}

export default SnoozeProductsForm

const StyledForm = styled.form`
  display: flex;
  align-items: center;s
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
    align-items: flex-end;
  }
  
  .input-container {
    min-width: 300px;
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      min-width: 100%;
      margin-left: 0;
    }
  }
  
  .button-container {
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-left: 0;
      min-width: 100%;
    }
  }
`
