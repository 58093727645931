import React from 'react'

import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import TagsTable from 'features/tags/TagsTable'
import Container from 'components/Container'

export const TagsPage = (props) => {
  return (
    <Container>
      <TablePaperContainer>
        <TagsTable />
      </TablePaperContainer>
    </Container>
  )
}

export default TagsPage
