import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  validateAttachSaleChannelToProductField,
  validateAttachSaleChannelToProductValues
} from 'features/saleChannels/ducks/schema'
import { PRODUCT_SALE_CHANNEL_STATUSES, productSaleChannelStatusOptions } from 'utils/saleChannels'

import { attachProductToSaleChannelRoutine } from 'features/saleChannels/ducks/actions'

import TextField from 'components/formElements/TextField'
import SingleSelectField from 'components/formElements/SingleSelectField'
import Button from 'components/atoms/Button'

const emptyValues = {
  saleChannelId: '',
  productId: '',
  status: '',
  link: ''
}

export const AddSaleChannelToProductForm = (props) => {
  const { onSubmit, saleChannelId, match: { params: { id } } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const hasLinkRequired = values.status === PRODUCT_SALE_CHANNEL_STATUSES.published

  const addSaleChannelToProduct = useCallback(
    payload => dispatch(attachProductToSaleChannelRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateAttachSaleChannelToProductValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      ...values,
      productId: id,
      saleChannelId
    })
  }, [])

  const handleOnChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    addSaleChannelToProduct({
      values,
      callback: onSubmit
    })
  }

  const handleChangeStatus = (name, value) => {
    setValues({
      ...values,
      [name]: value,
      link: hasLinkRequired ? values.link : ''
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit} autocomplete='off'>
      <FieldContainer>
        <SingleSelectField
          options={productSaleChannelStatusOptions()}
          required
          name='status'
          label={t('form.saleChannelStatus.label')}
          onChange={handleChangeStatus}
          validate={validateAttachSaleChannelToProductField(values)}
        />
      </FieldContainer>
      {
        hasLinkRequired && (
          <FieldContainer>
            <TextField
              disabled={!hasLinkRequired}
              required={hasLinkRequired}
              name='link'
              label={t('form.saleChannelLink.label')}
              onChange={handleOnChange}
              validate={validateAttachSaleChannelToProductField(values)}
              autocomplete='off'
            />
          </FieldContainer>
        )
      }
      <ButtonContainer>
        <Button type='submit' disabled={!valid} onClick={handleSubmit}>
          {t('productPage.saleChannels.attachSaleChannelCTA')}
        </Button>
      </ButtonContainer>
    </StyledForm>
  )
}

export default withRouter(AddSaleChannelToProductForm)

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FieldContainer = styled.div`
  display: inline-block;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: inline-block;
  line-height: 75px;
`
