import styled from 'styled-components'
import React, { useCallback, useEffect } from 'react'
import TrackVisibility from 'react-on-screen'
import { useDispatch } from 'react-redux'

import { markAlertsAsSeenRoutine } from 'features/alerts/ducks/actions'

const TrackedNewAlertRowNotification = ({ id, isVisible }) => {
  const dispatch = useDispatch()

  const handleMarkAlertAsSeen = useCallback(
    () => dispatch(markAlertsAsSeenRoutine({ alertIds: [id] })),
    [dispatch]
  )

  useEffect(() => {
    isVisible && handleMarkAlertAsSeen()
  }, [isVisible])

  return (
    <NotificationContainer />
  )
}

export const NewAlertRowNotification = ({ id }) => {
  return (
    <TrackVisibility>
      <TrackedNewAlertRowNotification id={id} />
    </TrackVisibility>
  )
}

export default NewAlertRowNotification

const NotificationContainer = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.success.main};
`
