import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, delay } from '@redux-saga/core/effects'
import * as salePlansService from 'services/SalePlansService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { propOr } from 'ramda'

// ROUTINES

export const fetchSalePlanRoutine = createRoutine('FETCH_SALE_PLAN')
export const createSalePlanRoutine = createRoutine('CREATE_SALE_PLAN')
export const deleteSalePlanRoutine = createRoutine('DELETE_SALE_PLAN')

// ACTIONS

function * fetchSalePlan ({ payload }) {
  yield put(fetchSalePlanRoutine.request())
  try {
    const result = yield call(salePlansService.fetchSalePlan, payload)
    yield put(fetchSalePlanRoutine.success(propOr({}, 'data')(result)))
  } catch (e) {
    yield put(fetchSalePlanRoutine.failure(e))
    console.error(e)
  }
}

function * createSalePlan ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createSalePlanRoutine.request())
  try {
    yield call(salePlansService.createSalePlan, values)
    delay(500)
    yield put(fetchSalePlanRoutine({ productId: values.productId }))
    yield put(
      showToastRoutine({
        key: 'toast.createSalePlanSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(createSalePlanRoutine.success())
    callback()
  } catch (e) {
    yield put(createSalePlanRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * deleteSalePlan ({ payload }) {
  yield put(deleteSalePlanRoutine.request())
  try {
    yield call(salePlansService.deleteSalePlan, payload)
    yield put(
      showToastRoutine({
        key: 'toast.deleteSalePlanSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchSalePlanRoutine({ productId: payload }))
    yield put(deleteSalePlanRoutine.success())
  } catch (e) {
    yield put(deleteSalePlanRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * fetchSalePlanWatcher () {
  yield takeLatest(fetchSalePlanRoutine.TRIGGER, fetchSalePlan)
}

export function * createSalePlanWatcher () {
  yield takeLatest(createSalePlanRoutine.TRIGGER, createSalePlan)
}

export function * deleteSalePlanWatcher () {
  yield takeLatest(deleteSalePlanRoutine.TRIGGER, deleteSalePlan)
}

// SAGAS

export const salePlansSagas = [
  fork(fetchSalePlanWatcher),
  fork(createSalePlanWatcher),
  fork(deleteSalePlanWatcher)
]
