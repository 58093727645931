import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddUserForm from 'features/users/AddUserForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import AddUser from 'components/icons/AddUser'

export const AddUserModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button startIcon={<AddUser />} onClick={handleOpen}>{t('usersPage.addUserCTA')}</Button>
      <Modal
        title={t('usersPage.addUserCTA')}
        open={open}
        handleClose={handleClose}
      >
        <AddUserForm onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default AddUserModal
