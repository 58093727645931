import APIService from 'services/APIService'

const api = APIService()

/**
 * Creates new user
 * @param {Object} payload - auth data
 * @param {string} payload.email - users email address
 * @param {string} payload.login - users login
 */
export const createUser = payload => api.post('/users', payload)

export const fetchUsers = query => api.get(`/users?${query}`)
export const updateUser = payload => api.patch(`/users/${payload.id}`, payload)
export const updateUserSettings = payload => api.patch('/users/settings', payload)
