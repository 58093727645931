import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'
import { PRODUCT_SALE_CHANNEL_STATUSES } from 'utils/saleChannels'

// Create/Edit sale channel form validator

export const createSaleChannel = object().shape({
  name: string()
    .required(() => ({ key: 'form.saleChannelName.errors.required' }))
    .min(3, () => ({
      key: 'form.saleChannelName.errors.lengthMin',
      options: { number: 3 }
    }))
    .max(50, () => ({
      key: 'form.saleChannelName.errors.lengthMax',
      options: { number: 50 }
    }))
})

export const validateCreateSaleChannelField = values => validateField(createSaleChannel, values)
export const validateCreateSaleChannelValues = validateValues(createSaleChannel)

// Attach sale channel to product form validator

export const attachSaleChannelToProduct = object().shape({
  saleChannelId: string().required(() => ({ key: 'form.saleChannelName.errors.required' })),
  productId: string().required(),
  status: string().required(() => ({ key: 'form.saleChannelStatus.errors.required' })),
  link: string().when('status', {
    is: value => value === PRODUCT_SALE_CHANNEL_STATUSES.published,
    then: string()
      .required(() => ({ key: 'form.saleChannelLink.errors.required' }))
      .url(() => ({ key: 'form.saleChannelLink.errors.url' }))
  })
})

export const validateAttachSaleChannelToProductField = values => validateField(attachSaleChannelToProduct, values)
export const validateAttachSaleChannelToProductValues = validateValues(attachSaleChannelToProduct)
