import { createRoutine } from 'redux-saga-routines'
import { pathOr } from 'ramda'
import { put, call, takeLatest, takeEvery, fork, delay } from '@redux-saga/core/effects'
import * as productsService from 'services/ProductsService'
import * as allegroService from 'services/AllegroService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

// ROUTINES

export const fetchProductAuctionsRoutine = createRoutine('FETCH_PRODUCT_AUCTIONS')
export const updateAuctionPriceRoutine = createRoutine('UPDATE_AUCTION_PRICE')
export const syncSingleAuctionRoutine = createRoutine('SYNC_SINGLE_AUCTION')

// ACTIONS

function * fetchProductAuctions ({ payload }) {
  yield put(fetchProductAuctionsRoutine.request())
  try {
    const result = yield call(productsService.fetchAuctions, payload)
    yield put(fetchProductAuctionsRoutine.success({
      id: payload,
      data: pathOr([], ['data'], result)
    }))
  } catch (e) {
    yield put(fetchProductAuctionsRoutine.failure(e))
  }
}

function * updateAuctionPrice ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateAuctionPriceRoutine.request())
  try {
    const result = yield call(allegroService.updateAuctionPrice, { offerId: values.offerId, price: values.price })
    yield put(updateAuctionPriceRoutine.success(result.data))
    yield delay(500)
    yield put(fetchProductAuctionsRoutine(values.productId))
    yield put(
      showToastRoutine({
        key: 'toast.updateAuctionPriceSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(updateAuctionPriceRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * syncSingleAuction ({ payload }) {
  yield put(syncSingleAuctionRoutine.request())
  try {
    yield call(allegroService.syncSingleAuction, { offerId: payload.offerId })
    yield delay(500)
    yield put(fetchProductAuctionsRoutine(payload.productId))
    yield put(syncSingleAuctionRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.syncSingleAuctionSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(syncSingleAuctionRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * fetchProductAuctionsWatcher () {
  yield takeEvery(fetchProductAuctionsRoutine.TRIGGER, fetchProductAuctions)
}

export function * updateAuctionPriceWatcher () {
  yield takeLatest(updateAuctionPriceRoutine.TRIGGER, updateAuctionPrice)
}

export function * syncSingleAuctionWatcher () {
  yield takeLatest(syncSingleAuctionRoutine.TRIGGER, syncSingleAuction)
}

// SAGAS

export const productAuctionsSagas = [
  fork(fetchProductAuctionsWatcher),
  fork(syncSingleAuctionWatcher),
  fork(updateAuctionPriceWatcher)
]
