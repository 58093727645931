import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  validateAutoDecisionPriceDownFormField,
  validateAutoDecisionPriceDownFormValues,
  validateAutoDecisionPriceDownFormCurrentValues
} from 'features/flags/ducks/schema'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getPriceUpAmountByUnit,
  unitOptions,
  UNIT_FORMATS,
  getPriceUpUnitByValues,
  getPriceUpAmountByValues,
  convertFromPenniesIfPrice
} from 'utils/flags'
import { convertToPenniesValue } from 'utils/price'

import {
  getPriceDownQty,
  getPriceDownPercentage,
  getPriceDownValue,
  getIsSettingsLoading,
  getPriceDownMargin
} from 'features/settings/ducks/selectors'

import TextField from 'components/formElements/TextField'
import SingleSelectField from 'components/formElements/SingleSelectField'
import Button from 'components/atoms/Button'
import { saveSettingsRoutine } from 'features/settings/ducks/actions'

const emptyValues = {
  unit: UNIT_FORMATS.currency,
  qty: 0,
  amount: 0,
  minMargin: 0
}

export const AutoDecisionForm = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const priceDecreasePercentage = useSelector(getPriceDownPercentage)
  const priceDecreaseValue = useSelector(getPriceDownValue)
  const priceDecreaseThreshold = useSelector(getPriceDownQty)
  const minMargin = useSelector(getPriceDownMargin)
  const isLoading = useSelector(getIsSettingsLoading)

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [currentValuesValid, _validateCurrentValues] = useState(false)

  const saveSettings = useCallback(
    payload => dispatch(saveSettingsRoutine(payload)),
    [dispatch]
  )

  const getCurrentValues = () => {
    const amount = getPriceUpAmountByValues(priceDecreasePercentage, priceDecreaseValue)
    const unit = getPriceUpUnitByValues(priceDecreasePercentage)

    return ({
      unit,
      qty: priceDecreaseThreshold,
      amount: convertFromPenniesIfPrice(unit, amount),
      minMargin
    })
  }

  useEffect(() => {
    validateAutoDecisionPriceDownFormValues(values, _validateSchema)
    validateAutoDecisionPriceDownFormCurrentValues(getCurrentValues(), _validateCurrentValues)
  }, [values, currentValuesValid])

  useEffect(() => {
    const currentValues = getCurrentValues()
    setValues(currentValues)
  }, [priceDecreasePercentage, priceDecreaseValue, priceDecreaseThreshold, minMargin])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    const priceDecreaseValue = Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.currency)(values.unit))

    saveSettings({
      priceDecreaseThreshold: Number(values.qty),
      priceDecreasePercentage: Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.percentage)(values.unit)),
      priceDecreaseValue: convertToPenniesValue(priceDecreaseValue),
      minMarginValue: Number(values.minMargin)
    })
  }

  const handleUnitChange = (name, value) => {
    setValues({
      ...values,
      amount: '',
      [name]: value
    })
  }

  return (
    <form>
      <div>
        <SingleSelectField
          options={unitOptions()}
          required
          name='unit'
          label={t('form.unit.label')}
          validate={validateAutoDecisionPriceDownFormField(values)}
          onChange={handleUnitChange}
          value={values.unit}
        />
        <TextField
          required
          type='number'
          name='amount'
          label={`${t('form.priceDecreaseAmount.label')} ${values.unit}`}
          onChange={handleOnChange}
          validate={validateAutoDecisionPriceDownFormField(values)}
          value={values.amount}
        />
        <TextField
          required
          type='number'
          name='minMargin'
          label={t('form.minMargin.label')}
          onChange={handleOnChange}
          validate={validateAutoDecisionPriceDownFormField(values)}
          value={values.minMargin}
        />
        <TextField
          required
          type='number'
          name='qty'
          label={t('form.daysThreshold.label')}
          onChange={handleOnChange}
          validate={validateAutoDecisionPriceDownFormField(values)}
          value={values.qty}
        />
      </div>
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid || isLoading}
          onClick={handleSubmit}
        >
          {t('settingsPage.autoDecisionPriceDownSettings.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

export default withRouter(AutoDecisionForm)

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
