import { drop, equals, find, head, not, pipe, propOr, tap, test, toLower } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const mentionPattern = /@\[(.*?)\]\((.*?)\)/g
export const isNotSelectedMention = pipe(test(mentionPattern), not)
export const isBeginingWihMention = pipe(head, equals('@'))
export const getMentionWithoutCharacter = drop(1)
export const findUserByDisplay = users => login => find(pipe(propOr('', 'display'), toLower, equals(toLower(login))))(users)
export const isUserLoginMention = (word, users) => pipe(
  tap(console.log),
  getMentionWithoutCharacter,
  tap(console.log),
  findUserByDisplay(users),
  isNotNilOrEmpty
)(word)

export const shouldAddNewMention = (word, users) =>
  isBeginingWihMention(word) && isNotSelectedMention(word) && isUserLoginMention(word, users)
