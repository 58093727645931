import React from 'react'
import styled from 'styled-components'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { Element } from 'react-scroll'
import { isSectionExpanded } from 'utils/productPage'

import Collapse from 'components/atoms/Collapse'
import Expand from 'components/icons/Expand'
import { propOr } from 'ramda'
import { reactToItemSelection } from 'utils/array'
import { transparentize } from 'polished'

export const ProductSection = (props) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { title, component: Component, id } = props
  const isExpanded = () => isSectionExpanded(id, urlSearchQuery.getSearchQuery())

  const toggleExpand = () => {
    const currentState = propOr([], 'sections', urlSearchQuery.getSearchQuery())
    return urlSearchQuery.setSearchQuery({
      sections: reactToItemSelection(id)(currentState)
    })
  }

  return (
    <Element name={id}>
      <ProductSectionContainer id={id} expanded={isExpanded()}>
        <div onClick={toggleExpand} className='product-section__header'>
          <div>
            {title}
          </div>
          <div className='product-section__expand-icon'>
            <Expand />
          </div>
        </div>
        <Collapse in={isExpanded()} timeout='auto' unmountOnExit>
          {isExpanded() && <Component />}
        </Collapse>
      </ProductSectionContainer>
    </Element>
  )
}

export default ProductSection

export const ProductSectionContainer = styled.div`
  margin: 5px 0;

  .product-section__header {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer
    padding: 10px;
    border-radius: ${props => props.theme.shape.borderRadius};
    color: ${props => props.theme.palette.common.gray600};
    background-color: ${props => transparentize('0.95', props.theme.palette.primary.main)};
    transition: color 0.2s ${props => props.theme.transitions.easing.easeInOut};
    
    &:hover {
      color: ${props => props.theme.palette.primary.dark};
    }
  }
  
  .product-section__expand-icon {
    transform: ${props => props.expanded ? 'rotate(180deg)' : 'none'};
    transition: transform 0.2s ${props => props.theme.transitions.easing.easeInOut};
    color: ${props => props.theme.palette.common.gray600};
  }
`
