import {
  propOr,
  pipe,
  equals,
  map,
  applySpec,
  prop,
  filter,
  propEq,
  groupBy,
  path, any, pathOr, not
} from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES, transformById } from 'utils/redux'
import { PRODUCT_SALE_CHANNEL_STATUSES } from 'utils/saleChannels'

export const selectSaleChannels = state => state.saleChannels

export const getIsSaleChannelsLoading = createSelector(
  selectSaleChannels,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getSaleChannelsMeta = createSelector(
  selectSaleChannels,
  propOr({}, 'saleChannelsMeta')
)

export const getSaleChannelsArray = createSelector(
  selectSaleChannels,
  propOr([], 'saleChannels')
)

export const getSaleChannels = createSelector(
  selectSaleChannels,
  pipe(
    propOr([], 'saleChannels'),
    transformById
  )
)

export const getSaleChannelsAsOptions = createSelector(
  selectSaleChannels,
  pipe(
    propOr([], 'saleChannels'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('id')
      })
    )
  )
)

export const getSaleChannelsNamesAsOptions = createSelector(
  selectSaleChannels,
  pipe(
    propOr([], 'saleChannels'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('name')
      })
    )
  )
)

export const getProductSaleChannelsByChannelName = createSelector(
  selectSaleChannels,
  (state, productId) => productId,
  (state, productId, channelName) => channelName,
  (saleChannels, productId, channelName) =>
    pipe(
      propOr([], 'productSaleChannels'),
      filter(propEq('product_id', productId)),
      groupBy(path(['saleChannel', 'name'])),
      propOr([], channelName)
    )(saleChannels)
)

export const getHasSaleChannelPublished = createSelector(
  selectSaleChannels,
  (state, productId) => productId,
  (state, productId, channelName) => channelName,
  (saleChannels, productId, channelName) =>
    pipe(
      propOr([], 'productSaleChannels'),
      filter(propEq('product_id', productId)),
      groupBy(path(['saleChannel', 'name'])),
      propOr([], channelName),
      any(propEq('status', PRODUCT_SALE_CHANNEL_STATUSES.published))
    )(saleChannels)
)

export const getSaleChannelNameById = createSelector(
  selectSaleChannels,
  (state, saleChannelId) => saleChannelId,
  (saleChannels, saleChannelId) => pipe(
    propOr([], 'saleChannels'),
    transformById,
    pathOr('', [saleChannelId, 'name'])
  )(saleChannels)
)

export const getUnpublishedProductChannels = createSelector(
  selectSaleChannels,
  (state, productId) => productId,
  (state, productId, saleChannelId) => saleChannelId,
  (saleChannels, productId, saleChannelId) =>
    pipe(
      propOr([], 'productSaleChannels'),
      filter(propEq('product_id', productId)),
      groupBy(path(['saleChannel', 'id'])),
      propOr([], saleChannelId),
      filter(
        pipe(
          propEq('status', PRODUCT_SALE_CHANNEL_STATUSES.published),
          not
        )
      )
    )(saleChannels)
)

export const getAllOtherProductChannelsById = createSelector(
  selectSaleChannels,
  (state, productId) => productId,
  (state, productId, saleChannelId) => saleChannelId,
  (state, productId, saleChannelId, idToKeep) => idToKeep,
  (saleChannels, productId, saleChannelId, idToKeep) =>
    pipe(
      propOr([], 'productSaleChannels'),
      filter(propEq('product_id', productId)),
      groupBy(path(['saleChannel', 'id'])),
      propOr([], saleChannelId),
      filter(
        pipe(
          propEq('id', idToKeep),
          not
        )
      )
    )(saleChannels)
)
