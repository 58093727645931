import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const User = Record({
  login: '',
  email: '',
  role: '',
  is_active: false,
  id: '',
  avatar: '',
  created_at: '',
  updated_at: null,
  settings: {}
})

export const UsersMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Users = Record({
  state: API_STATES.PRISTINE,
  usersMeta: new UsersMeta(),
  users: {}
})
