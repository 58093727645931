import { SaleChannel, SaleChannels, SaleChannelsMeta, ProductSaleChannel } from 'features/saleChannels/ducks/records'
import { fetchSaleChannelsRoutine, fetchProductSaleChannelsRoutine, createSaleChannelRoutine } from 'features/saleChannels/ducks/actions'
import {
  API_STATES,
  transferCollectionToImmutable
} from 'utils/redux'

export default (state = new SaleChannels(), action) => {
  switch (action.type) {
    case createSaleChannelRoutine.REQUEST:
    case fetchSaleChannelsRoutine.REQUEST:
    case fetchProductSaleChannelsRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchSaleChannelsRoutine.FAILURE:
    case fetchProductSaleChannelsRoutine.FAILURE:
    case createSaleChannelRoutine.FAILURE:
    case createSaleChannelRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
    case fetchSaleChannelsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('saleChannels', transferCollectionToImmutable(action.payload.data, SaleChannel))
        .set('saleChannelsMeta', new SaleChannelsMeta(action.payload.meta))
    case fetchProductSaleChannelsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('productSaleChannels', transferCollectionToImmutable(action.payload.data, ProductSaleChannel))
    default:
      return state
  }
}
