import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, delay } from '@redux-saga/core/effects'
import * as tagsService from 'services/TagsService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { pathOr } from 'ramda'
import { generateCurrentQuery } from 'utils/query'
import { getProductRoutine } from 'features/products/ducks/actions'
import { getEntityExistsError } from 'utils/errors'

// ROUTINES

export const fetchTagsRoutine = createRoutine('FETCH_TAGS')
export const assignProductsToTagRoutine = createRoutine('ASSIGN_PRODUCTS_TO_TAG')
export const assignProductToTagRoutine = createRoutine('ASSIGN_PRODUCT_TO_TAG')
export const createTagRoutine = createRoutine('CREATE_TAG')
export const editTagRoutine = createRoutine('EDIT_TAG')
export const deleteTagRoutine = createRoutine('DELETE_TAG')
export const removeTagFromProductRoutine = createRoutine('REMOVE_TAG_FROM_PRODUCT')

// ACTIONS

function * fetchTags ({ payload }) {
  yield put(fetchTagsRoutine.request())
  try {
    const result = yield call(tagsService.fetchTags, payload)
    yield put(fetchTagsRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchTagsRoutine.failure(e))
    console.error(e)
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * assignProductsToTag ({ payload: { values = {}, callback = () => {} } }) {
  yield put(assignProductsToTagRoutine.request())
  try {
    yield call(tagsService.assignProductsToTag, values)
    yield put(assignProductsToTagRoutine.success())
    yield put(showToastRoutine({
      key: 'toast.assignProductsToTagSuccess',
      severity: SEVERITY.success
    }))
    callback()
  } catch (e) {
    yield put(assignProductsToTagRoutine.failure(e))
    console.error(e)
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * createTag ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createTagRoutine.request())
  try {
    yield call(tagsService.createTag, values)
    yield put(
      showToastRoutine({
        key: 'toast.createTagSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchTagsRoutine(generateCurrentQuery()))
    yield put(createTagRoutine.success())
    callback()
  } catch (e) {
    yield put(createTagRoutine.failure(e))
    yield put(
      showToastRoutine(getEntityExistsError(e))
    )
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * editTag ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editTagRoutine.request())
  try {
    yield call(tagsService.editTag, values)
    yield put(
      showToastRoutine({
        key: 'toast.editTagSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchTagsRoutine(generateCurrentQuery()))
    yield put(editTagRoutine.success())
    callback()
  } catch (e) {
    yield put(editTagRoutine.failure(e))
    yield put(
      showToastRoutine(getEntityExistsError(e))
    )
  }
}

function * deleteTag ({ payload }) {
  yield put(deleteTagRoutine.request())
  try {
    yield call(tagsService.deleteTag, payload)
    yield put(
      showToastRoutine({
        key: 'toast.deleteTagSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchTagsRoutine(generateCurrentQuery()))
    yield put(deleteTagRoutine.success())
  } catch (e) {
    yield put(deleteTagRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

function * removeTagFromProduct ({ payload: { values = {}, callback = () => {} } }) {
  yield put(removeTagFromProductRoutine.request())
  try {
    yield call(tagsService.removeTagFromProduct, values)
    yield put(
      showToastRoutine({
        key: 'toast.removeTagFromProductSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(getProductRoutine({ id: values.productId }))
    yield put(removeTagFromProductRoutine.success())
    callback()
  } catch (e) {
    yield put(removeTagFromProductRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

function * assignProductToTag ({ payload: { values = {}, callback = () => {} } }) {
  put(assignProductToTagRoutine.request())
  try {
    yield put(assignProductsToTagRoutine({ values }))
    yield put(assignProductToTagRoutine.success())
    yield delay(500)
    yield put(getProductRoutine({ id: values.productIds[0] }))
    callback()
  } catch (e) {
    yield put(assignProductToTagRoutine.failure(e))
  }
}

// WATCHERS

export function * fetchTagsWatcher () {
  yield takeLatest(fetchTagsRoutine.TRIGGER, fetchTags)
}

export function * assignProductsToTagWatcher () {
  yield takeLatest(assignProductsToTagRoutine.TRIGGER, assignProductsToTag)
}

export function * createTagWatcher () {
  yield takeLatest(createTagRoutine.TRIGGER, createTag)
}

export function * editTagWatcher () {
  yield takeLatest(editTagRoutine.TRIGGER, editTag)
}

export function * deleteTagWatcher () {
  yield takeLatest(deleteTagRoutine.TRIGGER, deleteTag)
}

export function * removeTagFromProductWatcher () {
  yield takeLatest(removeTagFromProductRoutine.TRIGGER, removeTagFromProduct)
}

export function * assignProductToTagWatcher () {
  yield takeLatest(assignProductToTagRoutine.TRIGGER, assignProductToTag)
}

// SAGAS

export const tagsSagas = [
  fork(fetchTagsWatcher),
  fork(assignProductsToTagWatcher),
  fork(createTagWatcher),
  fork(editTagWatcher),
  fork(deleteTagWatcher),
  fork(removeTagFromProductWatcher),
  fork(assignProductToTagWatcher)
]
