import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getHasSaleChannelPublished, getSaleChannelNameById } from 'features/saleChannels/ducks/selectors'

import AddSaleChannelToProductForm from 'features/saleChannels/AddSaleChannelToProductForm'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'

export const AddSaleChannelToProductModal = (props) => {
  const { saleChannelId, match: { params: { id } } } = props
  const saleChannelName = useSelector(state => getSaleChannelNameById(state, saleChannelId))
  const isSaleChannelPublished = useSelector(state => getHasSaleChannelPublished(state, id, saleChannelName))
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button size='small' onClick={handleOpen}>
        {isSaleChannelPublished ? t('productPage.saleChannels.addSaleChannel.addCTA') : t('productPage.saleChannels.addSaleChannel.changeCTA')}
      </Button>
      <Modal
        title={t('productPage.saleChannels.addSaleChannel.title')}
        open={open}
        handleClose={handleClose}
      >
        <AddSaleChannelToProductForm saleChannelId={saleChannelId} onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default withRouter(AddSaleChannelToProductModal)
