import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as usersService from 'services/UsersService'
import { SEVERITY } from 'utils/toast'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { pathOr } from 'ramda'
import { getCreateUserError } from 'features/users/ducks/errors'

// ROUTINES

export const createUserRoutine = createRoutine('CREATE_USER')
export const fetchUsersRoutine = createRoutine('FETCH_USERS')
export const updateUserRoutine = createRoutine('UPDATE_USER')

// ACTIONS

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * createUser ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createUserRoutine.request())
  try {
    const result = yield call(usersService.createUser, values)
    yield put(createUserRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.createUserSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(createUserRoutine.failure(e))
    yield put(
      showToastRoutine(getCreateUserError(e))
    )
  }
}

function * fetchUsers ({ payload }) {
  yield put(fetchUsersRoutine.request())
  try {
    const result = yield call(usersService.fetchUsers, payload)
    yield put(fetchUsersRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchUsersRoutine.failure(e))
    console.error(e)
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * updateUser ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateUserRoutine.request())
  try {
    const result = yield call(usersService.updateUser, values)
    yield put(updateUserRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.createUserSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(updateUserRoutine.failure(e))
    yield put(
      showToastRoutine(getCreateUserError(e))
    )
  }
}

// WATCHERS

export function * createUserWatcher () {
  yield takeLatest(createUserRoutine.TRIGGER, createUser)
}

export function * fetchUsersWatcher () {
  yield takeLatest(fetchUsersRoutine.TRIGGER, fetchUsers)
}

export function * updateUserWatcher () {
  yield takeLatest(updateUserRoutine.TRIGGER, updateUser)
}

// SAGAS

export const usersSagas = [
  fork(createUserWatcher),
  fork(fetchUsersWatcher),
  fork(updateUserWatcher)
]
