import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { applySpec, prop, pipe, propOr } from 'ramda'
import {
  validateAssignProductsToTagValues
} from 'features/tags/ducks/schema'
import { handleSearchTagOptions } from 'utils/filters'

import { showToastRoutine } from 'features/toast/ducks/actions'
import { assignProductsToTagRoutine } from 'features/tags/ducks/actions'
import { createTag } from 'services/TagsService'
import { getSelectedItems } from 'ducks/global/selectors'

import AsyncCreatableSelectField from 'components/formElements/AsyncCreatableSelectField'
import Button from 'components/atoms/Button'
import { SEVERITY } from 'utils/toast'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

const emptyValues = {
  tagId: '',
  productIds: []
}

export const AssignTagToProductsForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const selectedItems = useSelector(getSelectedItems)
  const dispatch = useDispatch()

  const assignProductsToTag = useCallback(
    payload => dispatch(assignProductsToTagRoutine(payload)),
    [dispatch]
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    validateAssignProductsToTagValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('productIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    props.onSubmit()
    handleResetSelection()
  }

  const handleSubmit = e => {
    e.preventDefault()
    assignProductsToTag({
      values,
      callback: handleCallback
    })
  }

  const handleCreateOption = async inputValue => {
    try {
      const response = await createTag({ name: inputValue })
      showToast({
        key: 'toast.createTagSuccess',
        severity: SEVERITY.success
      })
      return pipe(
        propOr({}, 'data'),
        applySpec({
          value: prop('id'),
          label: prop('name')
        })
      )(response)
    } catch (e) {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
  }

  return (
    <AssignTagToProductsFormContainer>
      <div className='select-container'>
        <AsyncCreatableSelectField
          required
          name='tagId'
          handleSearchOptions={handleSearchTagOptions}
          handleCreateNewOption={handleCreateOption}
          label={t('monitoringPage.tag.assignTag.label')}
          values={values}
          onChange={handleOnChange}
        />
      </div>
      <div className='button-container'>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          {t('monitoringPage.tag.assignTag.assignCTA')}
        </Button>
      </div>
    </AssignTagToProductsFormContainer>
  )
}

export default AssignTagToProductsForm

const AssignTagToProductsFormContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
    align-items: flex-end;
  }
  
  .select-container {
    min-width: 300px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      min-width: 100%;
    }
  }
  
  .button-container {
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-left: 0;
      min-width: 100%;
    }
  }
`
