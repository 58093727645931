import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getMonitoringFrozenMoneySum } from 'features/monitoring/ducks/selectors'

export const FrozenMoneyHeaderCell = (props) => {
  const frozenMoneySum = useSelector(getMonitoringFrozenMoneySum)
  const { t } = useTranslation()

  return (
    <FrozenMoneyHeaderCellContainer>
      <span className='total-count'>({frozenMoneySum})</span>
      <span>{t('monitoringPage.table.headers.frozenMoney')}</span>
    </FrozenMoneyHeaderCellContainer>
  )
}

export default FrozenMoneyHeaderCell

const FrozenMoneyHeaderCellContainer = styled.div`
  position: relative;
  
  .total-count {
    font-size: 10px;
    white-space: nowrap;
    display: block;
    margin-bottom: -7px;
  }
`
