import React from 'react'
import styled from 'styled-components'

import LoginForm from 'features/auth/LoginForm'
import VerticalAlignContainer from 'components/VerticalAlignContainer'
import Paper from 'components/atoms/Paper'
import Logo from 'components/Logo'

export const LoginPage = (props) => {
  return (
    <VerticalAlignContainer>
      <StyledPaper>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <LoginForm />
      </StyledPaper>
    </VerticalAlignContainer>
  )
}

export default LoginPage

const StyledPaper = styled(Paper)`
  min-width: 600px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    min-width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
  }
`

const LogoContainer = styled.div`
  margin: 10px 0;
  text-align: center;
`
