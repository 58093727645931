import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, BrowserRouter, Redirect } from 'react-router-dom'
import PATHS from 'utils/paths'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dateMomentUtils from '@date-io/moment'
import muiTheme from 'theme/muiTheme'
import globalStyles from 'theme/globalStyles'
import { USER_ROLES } from 'utils/user'
import { generateSelectedTheme } from 'utils/theme'

import { getSelectedTheme } from 'ducks/global/selectors'

import Toast from 'features/toast/Toast'
import PrivateRoute from 'components/PrivateRoute'
import PublicRoute from 'components/PublicRoute'
import LoginPage from 'pages/LoginPage'
import DashboardPage from 'pages/DashboardPage'
import MonitoringPage from 'pages/MonitoringPage'
import IntegrationsPage from 'pages/IntegrationsPage'
import UsersPage from 'pages/UsersPage'
import InitResetPasswordPage from 'pages/InitResetPasswordPage'
import FinishResetPasswordPage from 'pages/FinishResetPasswordPage'
import ProducersPage from 'pages/ProducersPage'
import LinesPage from 'pages/LinesPage'
import ProductPage from 'pages/ProductPage'
import SettingsPage from 'pages/SettingsPage'
import TagsPage from 'pages/TagsPage'
import OrdersPage from 'pages/OrdersPage'
import SaleChannels from 'pages/SaleChannels'
import AlertsPage from 'pages/AlertsPage'
import NotFoundPage from 'pages/NotFoundPage'

function App () {
  const selectedThemeMode = useSelector(getSelectedTheme)

  const selectedTheme = generateSelectedTheme(selectedThemeMode)
  const GlobalStyles = globalStyles(selectedTheme)

  return (
    <ThemeProvider theme={selectedTheme}>
      <MuiPickersUtilsProvider utils={dateMomentUtils}>
        <MuiThemeProvider theme={muiTheme(selectedTheme)}>
          <Toast />
          <GlobalStyles />
          <BrowserRouter>
            <Switch>
              <PublicRoute exact path={PATHS.login} component={LoginPage} />
              <PublicRoute exact path={PATHS.resetPasswordInit} component={InitResetPasswordPage} />
              <PublicRoute exact path={PATHS.resetPasswordFinish} component={FinishResetPasswordPage} />
              <PrivateRoute exact path={PATHS.notFound} component={NotFoundPage} />
              <PrivateRoute exact path={PATHS.dashboard} component={DashboardPage} />
              <PrivateRoute exact path={PATHS.monitoring} component={MonitoringPage} />
              <PrivateRoute exact path={PATHS.product} component={ProductPage} />
              <PrivateRoute exact path={PATHS.users} component={UsersPage} allowedRoles={[USER_ROLES.admin]} />
              <PrivateRoute exact path={PATHS.producers} component={ProducersPage} allowedRoles={[USER_ROLES.admin]} />
              <PrivateRoute exact path={PATHS.lines} component={LinesPage} allowedRoles={[USER_ROLES.admin]} />
              <PrivateRoute exact path={PATHS.integrations} component={IntegrationsPage} />
              <PrivateRoute exact path={PATHS.tags} component={TagsPage} />
              <PrivateRoute path={PATHS.alerts} component={AlertsPage} />
              <PrivateRoute path={PATHS.orders} component={OrdersPage} />
              <PrivateRoute exact path={PATHS.saleChannels} component={SaleChannels} allowedRoles={[USER_ROLES.admin]} />
              <PrivateRoute exact path={PATHS.settings} component={SettingsPage} allowedRoles={[USER_ROLES.admin]} />
              <Redirect to={PATHS.login} />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
