import React from 'react'
import Icon from 'components/Icon'

export const AllegroAds = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 340 321'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          d='M318.8,82c-9.5-12.2-27.2-14.5-39.5-5c-1.8,1.5-3.5,3-5,5c-3.5,5-5.5,11-5.2,17.2c0,6.2,1.8,12.2,5.2,17.2
          c9.5,12.3,27.2,14.5,39.5,5c1.8-1.5,3.5-3,5-5c3.5-5,5.5-11,5.2-17.2C324.3,93,322.3,87,318.8,82z M310,105.5c-2,6-6.8,10.7-13.5,11
          c-6.8,0.3-11.5-4.7-13.5-11c-1.5-4.3-1.5-8.8,0-13c2-6,6.7-10.8,13.5-11c6.5-0.2,11.5,4.8,13.5,11C311.5,96.7,311.5,101.2,310,105.5
          L310,105.5z M107.3,125.7c0,1-0.8,1.5-1.5,1.5l0,0h-9.3c-1,0-1.5-0.8-1.5-1.5l0,0v-76c0-1,0.8-1.5,1.5-1.5l0,0h9.3
          c1,0,1.5,0.8,1.5,1.5l0,0V125.7z M85.8,125.7c0,1-0.7,1.5-1.5,1.5l0,0H75c-1,0-1.5-0.8-1.5-1.5l0,0v-76c0-0.8,0.8-1.5,1.5-1.5h9.2
          c1,0,1.5,0.8,1.5,1.5l0,0V125.7L85.8,125.7z M267,74.2v6.5c0,1-0.5,1.8-1.5,2c-0.2,0-0.5,0-0.8,0c-10.5-2.2-17,2.5-17,12.2v31.2
          c0,1-0.8,1.5-1.5,1.5H237c-1,0-1.5-0.8-1.5-1.5V94c0-6,2.2-12,6.7-16c4.5-4.5,10.5-7,17-6.8c2.2,0,4.2,0.2,6.5,0.5
          C266.8,72,267,72.5,267,74.2L267,74.2z M227.3,99c0-6.2-1.7-12.2-5.2-17.2c-9.5-12.2-27.2-14.5-39.5-5c-1.8,1.5-3.5,3-5,5
          c-7.2,10.2-7.2,24,0,34.5c5.2,7,13.5,11,22.2,11c5.8,0,11.2-1.8,15.8-4.8v6.8c0,9.2-8,11-13.3,11.2c-3.5,0-7,0-10.5-0.5
          c-1.5-0.2-2.5,0-2.5,1.5v8.2c0,0.8,0.8,1.2,1.5,1.2l0,0c12,1,20,0.8,26-2.7c4.2-2.5,7.5-6.5,8.8-11.2c1-3.8,1.5-7.5,1.5-11.3
          L227.3,99L227.3,99z M199.8,116.5c-6.7,0-11.5-4.8-13.5-11c-1.5-4.2-1.5-8.8,0-13c2-6,6.7-11,13.5-11c14.8,0,15.2,15.2,15.2,17.2
          C215,100.7,214.8,116.5,199.8,116.5z M64.8,93.5c0-7.2-1.8-13-5.3-16.8S49,71,42.5,71s-13,1.8-18.7,5c-0.2,0.2-0.5,0.5-0.5,1l0.5,8
          c0.2,0.8,1,1.2,1.8,1.2c0.2,0,0.5-0.2,0.8-0.2c8-5,17.8-6,22.8-3.5c2.8,1.5,4.5,4.5,4.2,7.8v1.2H39.8c-5.8,0-23.5,2.2-23.5,18.5v0.2
          c-0.2,5,1.8,10,5.8,13c4.2,3,9.2,4.5,14.2,4h27.2c1,0,1.5-0.8,1.5-1.5l0,0L64.8,93.5L64.8,93.5z M52.8,117.2h-16c-2,0-4-0.8-5.8-2
          s-2.8-3.2-2.8-5.2c0-3,2-8.8,11.7-8.8h12.8V117.2z M141.5,71c-16.5,0-23.8,11-26,20.2c-0.8,2.5-1,5-1,7.8c0,6.2,1.8,12.2,5.2,17.2
          c5.2,6.8,13.5,11,22.2,11c9,0.2,14.8-1.2,19.2-3.8c1-0.5,1.2-1.8,1-2.8v-7.2c0-1.5-1-2.2-2.2-1.2c-5.2,3.5-11.5,5-18,4.2
          c-7.5,0-13.5-5.8-14.2-13h36.5c0.8,0,1.5-0.8,1.5-1.5C166.8,92,163.8,71,141.5,71z M128.3,93.5c0.2-6.8,6-12.2,13-11.8
          c0,0,0,0,0.2,0c6-0.2,11.2,4.2,11.8,10.2c0,0.5,0,1,0,1.5H128.3z'
        />
      </g>
      <g>
        <path
          d='M94.4,236.9H53.2L39,272.7h-7.8L72,170.9h4.5l39.9,101.8h-8L94.4,236.9z M55.7,230.5H92L78.2,194 c-1.2-2.9-2.5-6.8-4.2-11.9c-1.2,4.4-2.6,8.5-4.1,12.1L55.7,230.5z'
        />
        <path
          d='M207.7,221c0,17-4.5,29.9-13.4,38.6c-8.9,8.7-22,13.1-39.3,13.1h-24.3V171.3h27.4c16.2,0,28.5,4.2,36.9,12.7
      C203.5,192.4,207.7,204.8,207.7,221z M200,221.3c0-14.5-3.7-25.4-11.1-32.8c-7.4-7.3-18.2-11-32.5-11h-18.7v88.9h16.8
      C184.9,266.4,200,251.4,200,221.3z'
        />
        <path
          d='M287,246.5c0,8.5-3.1,15.2-9.3,20.1c-6.2,4.9-14.5,7.4-24.8,7.4c-12.4,0-21.9-1.4-28.5-4.1v-7.1c7.3,3.1,16.6,4.6,27.9,4.6
      c8.3,0,14.9-1.9,19.8-5.7c4.9-3.8,7.3-8.8,7.3-15c0-3.8-0.8-7-2.4-9.5c-1.6-2.5-4.3-4.8-7.9-6.9c-3.7-2.1-9-4.3-16.1-6.7
      c-10.4-3.6-17.5-7.4-21.5-11.5c-4-4.1-5.9-9.6-5.9-16.5c0-7.6,3-13.8,8.9-18.5c5.9-4.8,13.6-7.2,22.9-7.2c9.5,0,18.5,1.8,26.8,5.4
      l-2.6,6.1c-8.4-3.5-16.5-5.3-24.1-5.3c-7.5,0-13.5,1.7-17.9,5.2s-6.7,8.2-6.7,14.1c0,3.7,0.7,6.8,2,9.2c1.4,2.4,3.6,4.6,6.7,6.5
      c3.1,1.9,8.4,4.2,15.9,6.9c7.9,2.7,13.8,5.4,17.8,7.9c4,2.6,6.9,5.5,8.7,8.7C286.1,238,287,241.9,287,246.5z'
        />
      </g>
    </Icon>
  )
}

export default AllegroAds
