import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { THEME_MODES } from 'utils/theme'

import { changeThemeRoutine } from 'ducks/global/actions'

import Moon from 'components/icons/Moon'
import Sun from 'components/icons/Sun'
import { getSelectedTheme } from 'ducks/global/selectors'

export const ThemeModeToggle = (props) => {
  const dispatch = useDispatch()
  const selectedThemeMode = useSelector(getSelectedTheme)
  const changeTheme = useCallback(
    themeMode => dispatch(changeThemeRoutine(themeMode)),
    [dispatch]
  )

  const handleChangeTheme = () => {
    const newTheme = selectedThemeMode === THEME_MODES.light ? THEME_MODES.dark : THEME_MODES.light
    changeTheme(newTheme)
  }

  return (
    <ThemeModeToggleContainer onClick={handleChangeTheme} themeMode={selectedThemeMode}>
      <Moon className='theme-toggle__icon theme-toggle__icon--dark' />
      <Sun className='theme-toggle__icon theme-toggle__icon--light' />
    </ThemeModeToggleContainer>
  )
}

export default ThemeModeToggle

const ThemeModeToggleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  margin-left: 15px;
  
  &:hover {
    color: ${props => props.theme.palette.secondary.main};
  }
  
  .theme-toggle__icon {
    position: absolute;
    left: 0;
    transition: all 0.5s ${props => props.theme.transitions.easing.easeInOut};
  }
  
  .theme-toggle__icon--light {
    opacity: ${props => props.themeMode === THEME_MODES.light ? '0' : '1'};
  }
  
  .theme-toggle__icon--dark {
    opacity: ${props => props.themeMode === THEME_MODES.light ? '1' : '0'};
  }
`
