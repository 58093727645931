import { equals, identity, ifElse, pipe, always, isNil } from 'ramda'
import { numberWithSpaces, roundNumber, twoDecimal } from 'utils/number'

export const convertFromPenniesValue = priceInPennies => Number(priceInPennies) / 100
export const convertToPenniesValue = price => Math.floor(Number(price) * 100)

export const convertPriceWithLabel = pipe(
  convertFromPenniesValue,
  num => num.toFixed(2)
)

export const convertToPenniesIfNumber = ifElse(
  equals(''),
  identity,
  convertToPenniesValue
)

export const convertFromPenniesIfNumber = ifElse(
  equals(''),
  identity,
  convertFromPenniesValue
)

export const calculateMargin = (retailPrice, purchasePrice) => {
  const calculate = ((retailPrice - purchasePrice) / retailPrice) * 100
  return calculate.toFixed(2)
}

export const formatPrice =
  pipe(
    ifElse(
      isNil,
      always(twoDecimal(0)),
      identity
    ),
    convertPriceWithLabel,
    numberWithSpaces
  )

export const roundAndFormatPrice =
  pipe(
    ifElse(
      isNil,
      always(twoDecimal(0)),
      identity
    ),
    convertPriceWithLabel,
    roundNumber,
    numberWithSpaces
  )
