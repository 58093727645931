import React from 'react'
import PATHS from 'utils/paths'
import { Redirect } from 'react-router-dom'

export const DashboardPage = (props) => {
  return (
    <Redirect
      to={{
        pathname: PATHS.monitoring,
        state: { from: props.location }
      }}
    />
  )
}

export default DashboardPage
