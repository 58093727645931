import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'

export const CombinedSelectWithInput = (props) => {
  const { label, options, name, onSelectChange, selectValue, inputType, onInputChange, inputBaseValue, isRange, inputSecondValue, onSecondInputChange } = props
  const classes = useStyles()

  const renderOptions = options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))

  const getBaseInputProps = isRange ? ({ min: 0, max: inputSecondValue }) : ({ min: 0 })

  return (
    <StyledContainer>
      <StyledLabel>
        {label}
      </StyledLabel>
      <Select
        id={`select-${name}`}
        className={classes.textField}
        onChange={onSelectChange}
        value={selectValue}
      >
        {renderOptions}
      </Select>
      <Input
        variant='outlined'
        className={classes.textField}
        type={inputType || 'text'}
        onChange={onInputChange}
        value={inputBaseValue}
        inputProps={getBaseInputProps}
      />
      {
        isRange && (
          <>
            <span>&nbsp;-&nbsp;</span>
            <Input
              variant='outlined'
              className={classes.textField}
              type={inputType || 'text'}
              onChange={onSecondInputChange}
              value={inputSecondValue}
              error={inputSecondValue < inputBaseValue}
              inputProps={{
                min: inputBaseValue
              }}
            />
          </>
        )
      }
    </StyledContainer>
  )
}

export default CombinedSelectWithInput

const StyledContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledLabel = styled.div`
  display: inline-block;
  flex: none;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
`

const useStyles = makeStyles(theme => ({
  control: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  }
}))
