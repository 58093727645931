import React from 'react'

import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import ProducersTable from 'features/producers/ProducersTable'
import PageColumnContainer from 'components/PageColumnContainer'
import AssignLineToProducersForm from 'features/producers/AssignLineToProducersForm'
import Container from 'components/Container'

export const ProducersPage = (props) => {
  return (
    <Container>
      <PageColumnContainer>
        <AssignLineToProducersForm />
        <TablePaperContainer>
          <ProducersTable />
        </TablePaperContainer>
      </PageColumnContainer>
    </Container>
  )
}

export default ProducersPage
