import React from 'react'
import MuiTableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getUsersHiddenColumns } from 'features/auth/ducks/selectors'
import { includes } from 'ramda'

export const TableCell = (props) => {
  const { name } = props
  const hiddenColumns = useSelector(getUsersHiddenColumns)

  return (
    <StyledCell
      {...props}
      hidden={includes(name, hiddenColumns)}
    >
      <div className='cell-content'>
        {props.children}
      </div>
    </StyledCell>
  )
}

export default TableCell

const StyledCell = styled(MuiTableCell)`
  display: ${props => props.hidden ? 'none' : 'table-cell'} !important;

  .cell-content {
    padding: 0 6px;
  }
`
