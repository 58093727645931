import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'

import IconButton from 'components/atoms/IconButton'
import Upload from 'components/icons/Upload'

export const FileUpload = (props) => {
  const { reset } = props
  const [file, setFile] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (reset) {
      setFile(null)
    }
  }, [reset])

  const handleChange = e => {
    e.preventDefault()
    setFile(e.target.files[0])
    props.onChange(e.target.files[0])
  }

  const placeholder = props.placeholder || t('form.file.placeholder')

  return (
    <FileUploadWrapper>
      <IconButton color='primary'>
        <label htmlFor={props.name} className='file-upload__label'>
          <Upload />
        </label>
      </IconButton>
      <span className='file-upload__name'>{propOr(placeholder, 'name', file)}</span>
      <input className='file-upload__input' id={props.name} onChange={handleChange} type='file' />
    </FileUploadWrapper>
  )
}

export default FileUpload

const FileUploadWrapper = styled.div`
  input[type="file"] {
    display: none;
  }
  
  .file-upload__name {
    font-size: 10px;
    color: ${props => props.theme.palette.common.gray500};
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`
