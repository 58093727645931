import React from 'react'
import styled from 'styled-components'

import KeyboardArrowUp from 'components/icons/KeyboardArrowUp'
import KeyboardArrowDown from 'components/icons/KeyboardArrowDown'

export const DynamicsIndicator = (props) => {
  const { value } = props

  const displayIndicator = () => {
    switch (true) {
      case Number(value) > 0:
        return <div className='arrow-up'><KeyboardArrowUp /></div>
      case Number(value) < 0:
        return <div className='arrow-down'><KeyboardArrowDown /></div>
      default:
        return ''
    }
  }

  return (
    <IndicatorContainer>
      {displayIndicator()}
    </IndicatorContainer>
  )
}

export default DynamicsIndicator

const IndicatorContainer = styled.div`
  display: inline-block;
  padding: 0 3px;

  .arrow-up {
    color: ${props => props.theme.palette.error.main};
  }
  
  .arrow-down {
    color: ${props => props.theme.palette.success.main};
  }
`
