import React, { useEffect, useState } from 'react'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr, head, drop } from 'ramda'
import { dateFilterOptions, isValueNullOption } from 'utils/filters'
import { FORMATS, formatDate } from 'utils/date'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import CombinedSelectWithDate from 'components/atoms/FilterInputs/CombinedSelectWithDate'

export const DateFilter = (props) => {
  const { name, label } = props
  const urlSearchQuery = useUrlSearchQuery()
  const { location: { search } } = useHistory()

  const urlQuery = propOr('', name)(urlSearchQuery.getSearchQuery())
  const urlIndicator = isValueNullOption(urlQuery) ? urlQuery : head(urlQuery)
  const urlValue = isValueNullOption(urlIndicator) ? null : drop(1, urlQuery)

  const [indicator, setIndicator] = useState(urlIndicator)
  const [value, setValue] = useState(urlValue)

  useEffect(() => {
    setValue(urlValue)
    setIndicator(urlIndicator)
  }, [search])

  const handleIndicatorChange = e => {
    setIndicator(e.target.value)
    if (isValueNullOption(e.target.value)) {
      urlSearchQuery.setSearchQuery({
        [name]: e.target.value
      })
    } else if (isNotNilOrEmpty(value) && isNotNilOrEmpty(e.target.value)) {
      urlSearchQuery.setSearchQuery({
        [name]: e.target.value + value
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
      setValue('')
    }
  }

  const handleValueChange = e => {
    const newValue = formatDate(e, FORMATS.slash)
    const isDateValid = moment(newValue, FORMATS.slash).isValid()

    setValue(newValue)
    if (isNotNilOrEmpty(indicator) && isNotNilOrEmpty(newValue) && isDateValid) {
      urlSearchQuery.setSearchQuery({
        [name]: indicator + e
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
    }
  }

  return (
    <>
      <CombinedSelectWithDate
        label={label}
        selectValue={indicator}
        options={dateFilterOptions()}
        onSelectChange={handleIndicatorChange}
        onDateChange={handleValueChange}
        dateValue={value}
      />
    </>
  )
}

export default DateFilter
