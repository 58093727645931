import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  validatePromoDiffFormField,
  validatePromoDiffFormValues,
  validatePromoDiffCurrentValues
} from 'features/flags/ducks/schema'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getPriceUpAmountByUnit,
  unitOptions,
  UNIT_FORMATS,
  PRODUCT_TASKS_NAMES,
  getPriceUpUnitByValues,
  getPriceUpAmountByValues,
  convertFromPenniesIfPrice
} from 'utils/flags'
import { convertToPenniesValue } from 'utils/price'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

import { createProductTaskRoutine, toggleProductTasksRoutine } from 'features/flags/ducks/actions'
import { getProductPromoDiffDefinition } from 'features/flags/ducks/selectors'
import { getProductPromoDiffFlag } from 'features/products/ducks/selectors'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

import TextField from 'components/formElements/TextField'
import SingleSelectField from 'components/formElements/SingleSelectField'
import SwitchField from 'components/formElements/SwitchField'
import IconButton from 'components/atoms/IconButton'
import Success from 'components/icons/Success'
import PromoDiff from 'components/icons/PromoDiff'
import Tooltip from 'components/atoms/Tooltip'
import { isSectionExpanded, SECTION_IDS } from 'utils/productPage'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import { propOr } from 'ramda'

const emptyValues = {
  unit: UNIT_FORMATS.currency,
  amount: 0
}

export const PromoDiffForm = (props) => {
  const { match: { params: { id } } } = props
  const urlSearchQuery = useUrlSearchQuery()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const flagDefinition = useSelector(state => getProductPromoDiffDefinition(state, id))
  const isActive = useSelector(state => getProductPromoDiffFlag(state, id))

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [switchValue, setSwitchValue] = useState(false)
  const [currentValuesValid, _validateCurrentValues] = useState(false)

  const togglePromoDiffFlag = useCallback(
    payload => dispatch(toggleProductTasksRoutine(payload)),
    [dispatch]
  )

  const setPromoDiffDefinition = useCallback(
    payload => dispatch(createProductTaskRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const getCurrentValues = () => {
    const priceIncreasePercentage = propOr(0, 'priceIncreasePercentage', flagDefinition)
    const priceIncreaseValue = propOr(0, 'priceIncreaseValue', flagDefinition)

    const amount = getPriceUpAmountByValues(priceIncreasePercentage, priceIncreaseValue)
    const unit = getPriceUpUnitByValues(priceIncreasePercentage)

    return ({
      unit,
      amount: convertFromPenniesIfPrice(unit, amount)
    })
  }

  useEffect(() => {
    validatePromoDiffFormValues(values, _validateSchema)
    validatePromoDiffCurrentValues(getCurrentValues(), _validateCurrentValues)
  }, [values, currentValuesValid, id])

  useEffect(() => {
    const currentValues = getCurrentValues()
    setValues(currentValues)
  }, [flagDefinition, id])

  useEffect(() => {
    setSwitchValue(isActive)
  }, [isActive, id])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const priceIncreaseValue = Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.currency)(values.unit))

    setPromoDiffDefinition({
      values: {
        id,
        taskName: PRODUCT_TASKS_NAMES.promoDiff,
        definition: {
          priceIncreasePercentage: Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.percentage)(values.unit)),
          priceIncreaseValue: convertToPenniesValue(priceIncreaseValue)
        }
      },
      callback: handleCallback
    })
  }

  const handleSwitchChange = (name, value) => {
    setSwitchValue(value)
    togglePromoDiffFlag({
      values: { id, isEnabled: value, taskName: PRODUCT_TASKS_NAMES.promoDiff },
      callback: handleCallback
    })
  }

  const SwitchComponent = (
    <SwitchField
      value={switchValue}
      label={(
        <LabelContainer>
          <div className='label-icon'><PromoDiff /></div>&nbsp;
          {t('productPage.flags.promoDiff.label')}
        </LabelContainer>
      )}
      onChange={handleSwitchChange}
      name='promoDiff'
      disabled={!currentValuesValid || !valid}
    />
  )

  const handleUnitChange = (name, value) => {
    setValues({
      ...values,
      amount: '',
      [name]: value
    })
  }

  return (
    <StyledForm>
      <SwitchContainer>
        {
          currentValuesValid && valid ? SwitchComponent : (
            <Tooltip title={t('productPage.flags.promoDiff.tooltip')}>
              <div>
                {SwitchComponent}
              </div>
            </Tooltip>
          )
        }
      </SwitchContainer>
      <InputsContainer>
        <FieldContainer>
          <SingleSelectField
            options={unitOptions()}
            required
            name='unit'
            label={t('form.unit.label')}
            validate={validatePromoDiffFormField(values)}
            onChange={handleUnitChange}
            value={values.unit}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            required
            type='number'
            name='amount'
            label={`${t('form.priceIncreaseAmount.label')} ${values.unit}`}
            onChange={handleOnChange}
            validate={validatePromoDiffFormField(values)}
            value={values.amount}
          />
        </FieldContainer>
      </InputsContainer>
      <SubmitContainer>
        <IconButton
          color='primary'
          type='submit'
          id='promo-diff-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          <Success />
        </IconButton>
      </SubmitContainer>
    </StyledForm>
  )
}

export default withRouter(PromoDiffForm)

const StyledForm = styled.form`
  display: flex;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
  }
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  .label-icon {
    font-size: 20px;
  }
`

const FieldContainer = styled.div`
  display: inline-block;
  margin-left: 10px;
  min-width: 200px;
`

const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 77px;
`

const InputsContainer = styled.div`
  display: inline-flex;
  min-height: 77px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  height: 77px;
  margin-left: 10px;
`
