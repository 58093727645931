import i18n from 'providers/i18n'
import { PRODUCT_TASKS_API_FIELDS } from 'utils/flags'
import { append, mergeAll, pipe, reduce } from 'ramda'

// backend saves "products" as an alias, so use `p.x` instead of `products.x`
// use underscore for multi-word attributes
// use the `example.x` convention to refer to a specific table in the database

export const API_FIELD_NAME = {
  ean: 'ean',
  productName: 'p.name',
  stock: 'pmd.stock',
  retailPrice: 'retail_price',
  purchasePrice: 'purchase_price',
  frozenMoney: 'pmd.frozen_money',
  margin: 'pmd.margin',
  isBundled: 'pmd.is_bundled',
  soldNew: 'pmd.sold_new',
  soldToday: 'pmd.sold_today',
  sold1: 'pmd.sold_1',
  soldShort: 'pmd.sold_short',
  soldLong: 'pmd.sold_long',
  willLastShort: 'pmd.will_last_short',
  willLastLong: 'pmd.will_last_long',
  salesDynamics: 'pmd.sales_dynamics',
  producersName: 'producers.name',
  userLogin: 'users.login',
  allegroFee: 'pmd.allegro_fee',
  lastChange: 'pmd.last_comment_created_at',
  linesName: 'lines.name',
  isEmphasized: 'pmd.emphasized',
  hasAds: 'pmd.ads',
  hasPromotionPackage: 'pmd.promotion_package',
  hasHalfPercent: 'pmd.half_percent',
  isBargain: 'pmd.bargain',
  tagsName: 'tags.name',
  toBeOrdered: '__to_be_ordered',
  estimatedShippingTime: 'estimated_shipping_time',
  suggestedShippingQty: 'suggested_shipping_qty',
  shippingQty: 'actual_shipping_qty',
  shippingStatus: 'pmd.shipping_status',
  activatedAt: 'p.activated_at',
  iaiCreatedAt: 'p.iai_created_at',
  isActive: 'pmd.is_active',
  virtualStock: 'pmd.virtual_stock',
  snoozedUntil: 'snoozed_until',
  autoDecisionSnoozedUntil: 'auto_decision_snoozed_until',
  orderStatus: 'status',
  saleChannelExists: '__new_sale_channel',
  alertType: 'a.type',
  alertsUserLogin: 'u.login',
  alertsLineName: 'l.name',
  notSelling: '__not_selling',
  shippingOrderUsers: 'search',
  salePlanDiff: 'sale_plan_diff',
  tagsExcluded: '__exclude_tags',
  weightSchema: 'ws.name',
  activeSalePlan: '__has_active_plan',
  maxPrice: '__has_max_price',
  isAlertArchived: 'a.is_archived',
  minMargin: '__has_min_margin'
}

const flagTranslations = () =>
  pipe(
    reduce(
      (acc, value) => append({ [value.name]: value.translation }, acc),
      []
    ),
    mergeAll
  )(PRODUCT_TASKS_API_FIELDS)

export const API_FIELD_NAME_TRANSLATION = ({ soldShortValue, soldLongValue }) => ({
  [API_FIELD_NAME.ean]: i18n.t('monitoringPage.table.headers.id'),
  [API_FIELD_NAME.productName]: i18n.t('monitoringPage.table.headers.name'),
  [API_FIELD_NAME.stock]: i18n.t('monitoringPage.table.headers.stock'),
  [API_FIELD_NAME.retailPrice]: i18n.t('monitoringPage.table.headers.retailPrice'),
  [API_FIELD_NAME.purchasePrice]: i18n.t('monitoringPage.table.headers.purchasePrice'),
  [API_FIELD_NAME.frozenMoney]: i18n.t('monitoringPage.table.headers.frozenMoney'),
  [API_FIELD_NAME.margin]: i18n.t('monitoringPage.table.headers.margin'),
  [API_FIELD_NAME.isBundled]: i18n.t('monitoringPage.table.headers.isBundled'),
  [API_FIELD_NAME.soldNew]: i18n.t('monitoringPage.table.headers.soldNew'),
  [API_FIELD_NAME.soldToday]: i18n.t('monitoringPage.table.headers.soldToday'),
  [API_FIELD_NAME.sold1]: i18n.t('monitoringPage.table.headers.sold1'),
  [API_FIELD_NAME.soldShort]: i18n.t('monitoringPage.table.headers.soldX', { count: soldShortValue }),
  [API_FIELD_NAME.soldLong]: i18n.t('monitoringPage.table.headers.soldX', { count: soldLongValue }),
  [API_FIELD_NAME.willLastShort]: i18n.t('monitoringPage.table.headers.willLastX', { count: soldShortValue }),
  [API_FIELD_NAME.willLastLong]: i18n.t('monitoringPage.table.headers.willLastX', { count: soldLongValue }),
  [API_FIELD_NAME.salesDynamics]: i18n.t('monitoringPage.table.headers.salesDynamics'),
  [API_FIELD_NAME.userLogin]: i18n.t('monitoringPage.table.headers.userLogin'),
  [API_FIELD_NAME.alertsUserLogin]: i18n.t('monitoringPage.table.headers.userLogin'),
  [API_FIELD_NAME.allegroFee]: i18n.t('monitoringPage.table.headers.allegroFee'),
  [API_FIELD_NAME.lastChange]: i18n.t('monitoringPage.table.headers.lastChange'),
  [API_FIELD_NAME.linesName]: i18n.t('monitoringPage.table.headers.line'),
  [API_FIELD_NAME.alertsLineName]: i18n.t('monitoringPage.table.headers.line'),
  [API_FIELD_NAME.isEmphasized]: i18n.t('monitoringPage.table.allegroPromoTypes.isEmphasized'),
  [API_FIELD_NAME.hasAds]: i18n.t('monitoringPage.table.allegroPromoTypes.hasAds'),
  [API_FIELD_NAME.hasPromotionPackage]: i18n.t('monitoringPage.table.allegroPromoTypes.hasPromotionPackage'),
  [API_FIELD_NAME.hasHalfPercent]: i18n.t('monitoringPage.table.allegroPromoTypes.hasHalfPercent'),
  [API_FIELD_NAME.isBargain]: i18n.t('monitoringPage.table.allegroPromoTypes.isBargain'),
  [API_FIELD_NAME.tagsName]: i18n.t('monitoringPage.table.headers.tags'),
  [API_FIELD_NAME.toBeOrdered]: i18n.t('monitoringPage.table.headers.toBeOrdered'),
  [API_FIELD_NAME.shippingStatus]: i18n.t('monitoringPage.table.headers.shippingStatus'),
  [API_FIELD_NAME.activatedAt]: i18n.t('monitoringPage.table.headers.activatedAt'),
  [API_FIELD_NAME.iaiCreatedAt]: i18n.t('monitoringPage.table.headers.iaiCreatedAt'),
  [API_FIELD_NAME.isActive]: i18n.t('monitoringPage.table.headers.isActive'),
  [API_FIELD_NAME.virtualStock]: i18n.t('monitoringPage.table.headers.virtualStock'),
  [API_FIELD_NAME.snoozedUntil]: i18n.t('monitoringPage.table.headers.snoozedUntil'),
  [API_FIELD_NAME.autoDecisionSnoozedUntil]: i18n.t('monitoringPage.table.headers.autoDecisionSnoozedUntil'),
  [API_FIELD_NAME.estimatedShippingTime]: i18n.t('monitoringPage.table.headers.estimatedShippingTime'),
  [API_FIELD_NAME.saleChannelExists]: i18n.t('monitoringPage.table.headers.saleChannelExists'),
  [API_FIELD_NAME.notSelling]: i18n.t('monitoringPage.table.headers.notSelling'),
  [API_FIELD_NAME.shippingOrderUsers]: i18n.t('monitoringPage.table.headers.userLogin'),
  [API_FIELD_NAME.tagsExcluded]: i18n.t('monitoringPage.table.headers.tagsExcluded'),
  [API_FIELD_NAME.weightSchema]: i18n.t('monitoringPage.table.headers.weightSchema'),
  [API_FIELD_NAME.activeSalePlan]: i18n.t('monitoringPage.table.headers.activeSalePlan'),
  [API_FIELD_NAME.maxPrice]: i18n.t('monitoringPage.table.headers.maxPrice'),
  [API_FIELD_NAME.isAlertArchived]: i18n.t('alertsPage.statuses.archived'),
  [API_FIELD_NAME.minMargin]: i18n.t('monitoringPage.table.headers.minMargin'),
  ...flagTranslations()
})
