import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import { useTranslation } from 'react-i18next'
import { TABLE_ROWS_LENGTH } from 'utils/table'

export const Pagination = props => {
  const { pagination, setRowsPerPage, rowsPerPage, page, setPaginationPage } = props
  const { t } = useTranslation()

  const handleChangePage = (event, newPage) => setPaginationPage(newPage + 1)

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPaginationPage(1)
  }

  const getDisplayedRowsLabel = params => {
    const { from, to, count, page } = params
    return t('pagination.displayedRows', { from, to, count, page })
  }

  return (
    <TablePagination
      labelRowsPerPage={t('pagination.rowsPerPage')}
      labelDisplayedRows={getDisplayedRowsLabel}
      rowsPerPageOptions={[TABLE_ROWS_LENGTH, TABLE_ROWS_LENGTH * 1.5, TABLE_ROWS_LENGTH * 2]}
      // -1 because MUI pagination component is counting from 0
      page={Number(page) - 1}
      rowsPerPage={Number(rowsPerPage)}
      count={Number(pagination.recordsTotal)}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

export default Pagination
