import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer, { rootSaga } from 'ducks/rootReducer'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger({ collapsed: true })

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(loggerMiddleware)
}

const store = createStore(rootReducer, applyMiddleware(...middlewares))
sagaMiddleware.run(rootSaga)

function ReduxProvider (props) {
  return (
    <Provider store={store}>
      {props.children}
    </Provider>
  )
}

export default ReduxProvider
