import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PATHS from 'utils/paths'
import { USER_ROLES } from 'utils/user'
import Layout from 'theme/PublicLayout'

import { getIsAuthorised } from 'features/auth/ducks/selectors'

export const PublicRoute = (props) => {
  const { component: Component, ...rest } = props

  const isAuthorised = useSelector(getIsAuthorised)

  const WrappedComponent = props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )

  return isAuthorised && props.path === PATHS.login ? (
    <Redirect
      to={{
        pathname: PATHS.dashboard,
        state: { from: props.location }
      }}
    />
  ) : <Route {...rest} component={WrappedComponent} />
}

PublicRoute.defaultProps = {
  allowedRoles: [USER_ROLES.admin, USER_ROLES.user]
}

export default PublicRoute
