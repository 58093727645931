import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Line = Record({
  id: '',
  name: '',
  createdAt: '',
  userLogin: '',
  producers: [],
  updatedAt: null,
  userId: null
})

export const LinesMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Lines = Record({
  state: API_STATES.PRISTINE,
  linesMeta: new LinesMeta(),
  lines: []
})
