import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, select } from '@redux-saga/core/effects'
import * as settingsService from 'services/SettingsService'
import { pathOr } from 'ramda'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { getSettingsData } from 'features/settings/ducks/selectors'
import { SEVERITY } from 'utils/toast'

// ROUTINES

export const fetchSettingsRoutine = createRoutine('FETCH_SETTINGS')
export const saveSettingsRoutine = createRoutine('SAVE_SETTINGS')

// ACTIONS

function * fetchSettings ({ payload }) {
  yield put(fetchSettingsRoutine.request())
  try {
    const result = yield call(settingsService.fetchSettings, payload)
    yield put(fetchSettingsRoutine.success(pathOr({}, ['data'], result)))
  } catch (e) {
    yield put(fetchSettingsRoutine.failure(e))
    console.error(e)
  }
}

function * saveSettings ({ payload }) {
  yield put(saveSettingsRoutine.request())
  try {
    const currentData = yield select(getSettingsData)
    yield call(settingsService.saveSettings, { ...currentData.toJS(), ...payload })
    yield put(fetchSettingsRoutine())
    yield put(saveSettingsRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.saveSettingSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(saveSettingsRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

// WATCHERS

export function * saveSettingsWatcher () {
  yield takeLatest(saveSettingsRoutine.TRIGGER, saveSettings)
}

export function * fetchSettingsWatcher () {
  yield takeLatest(fetchSettingsRoutine.TRIGGER, fetchSettings)
}

// SAGAS

export const settingsSagas = [
  fork(saveSettingsWatcher),
  fork(fetchSettingsWatcher)
]
