import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'

// initialStates
import { Auth } from 'features/auth/ducks/records'
import { Monitoring } from 'features/monitoring/ducks/records'
import { Users } from 'features/users/ducks/records'
import { Toast } from 'features/toast/ducks/records'
import { Allegro } from 'features/allegro/ducks/records'
import { Producers } from 'features/producers/ducks/records'
import { ProductComments } from 'features/comments/ducks/records'
import { Lines } from 'features/lines/ducks/records'
import { Integrations } from 'features/integrations/ducks/records'
import { Settings } from 'features/settings/ducks/records'
import { SearchQueries } from 'features/searchQueries/ducks/records'
import { Tags } from 'features/tags/ducks/records'
import { Products } from 'features/products/ducks/records'
import { ShippingOrders } from 'features/shippingOrders/ducks/records'
import { ProductFlags } from 'features/flags/ducks/records'
import { SaleChannels } from 'features/saleChannels/ducks/records'
import { Alerts } from 'features/alerts/ducks/records'
import { SalePlans } from 'features/salePlans/ducks/records'
import { DaysOff } from 'features/daysOff/ducks/records'
import { WeightSchemas } from 'features/weightSchemas/ducks/records'
import { ProductAuctions } from 'features/productAuctions/ducks/records'
import { Deliveries } from 'features/deliveries/ducks/records'
import { Orders } from 'features/orders/ducks/records'
import { WillLastHistory } from 'features/willLastHistory/ducks/records'

// reducers
import authReducer from 'features/auth/ducks/reducers'
import monitoringReducer from 'features/monitoring/ducks/reducers'
import usersReducer from 'features/users/ducks/reducers'
import toastReducer from 'features/toast/ducks/reducers'
import globalReducer from 'ducks/global/reducers'
import allegroReducer from 'features/allegro/ducks/reducers'
import producersReducer from 'features/producers/ducks/reducers'
import commentsReducer from 'features/comments/ducks/reducers'
import linesReducer from 'features/lines/ducks/reducers'
import integrationsReducer from 'features/integrations/ducks/reducers'
import settingReducer from 'features/settings/ducks/reducers'
import searchQueriesReducer from 'features/searchQueries/ducks/reducers'
import tagsReducer from 'features/tags/ducks/reducers'
import productsReducer from 'features/products/ducks/reducers'
import shippingOrdersReducer from 'features/shippingOrders/ducks/reducers'
import flagsReducer from 'features/flags/ducks/reducers'
import saleChannelsReducer from 'features/saleChannels/ducks/reducers'
import alertsReducer from 'features/alerts/ducks/reducers'
import salePlansReducer from 'features/salePlans/ducks/reducers'
import daysOffReducer from 'features/daysOff/ducks/reducers'
import weightSchemaReducer from 'features/weightSchemas/ducks/reducers'
import productAuctionsReducer from 'features/productAuctions/ducks/reducers'
import deliveriesReducer from 'features/deliveries/ducks/reducers'
import ordersReducer from 'features/orders/ducks/reducers'
import willLastHistoryReducer from 'features/willLastHistory/ducks/reducers'

// sagas
import { authSagas, logoutUserRoutine } from 'features/auth/ducks/actions'
import { monitoringSagas } from 'features/monitoring/ducks/actions'
import { toastSagas } from 'features/toast/ducks/actions'
import { usersSagas } from 'features/users/ducks/actions'
import { globalSagas } from 'ducks/global/actions'
import { allegroSagas } from 'features/allegro/ducks/actions'
import { producersSagas } from 'features/producers/ducks/actions'
import { commentsSagas } from 'features/comments/ducks/actions'
import { linesSagas } from 'features/lines/ducks/actions'
import { integrationsSagas } from 'features/integrations/ducks/actions'
import { settingsSagas } from 'features/settings/ducks/actions'
import { searchQueriesSagas } from 'features/searchQueries/ducks/actions'
import { tagsSagas } from 'features/tags/ducks/actions'
import { productsSagas } from 'features/products/ducks/actions'
import { shippingOrdersSagas } from 'features/shippingOrders/ducks/actions'
import { flagsSagas } from 'features/flags/ducks/actions'
import { saleChannelsSagas } from 'features/saleChannels/ducks/actions'
import { alertsSagas } from 'features/alerts/ducks/actions'
import { salePlansSagas } from 'features/salePlans/ducks/actions'
import { daysOffSagas } from 'features/daysOff/ducks/actions'
import { weighSchemasSagas } from 'features/weightSchemas/ducks/actions'
import { productAuctionsSagas } from 'features/productAuctions/ducks/actions'
import { deliveriesSagas } from 'features/deliveries/ducks/actions'
import { ordersSagas } from 'features/orders/ducks/actions'
import { willLastHistorySagas } from 'features/willLastHistory/ducks/actions'

// reducer
const appReducer = combineReducers({
  auth: authReducer,
  monitoring: monitoringReducer,
  users: usersReducer,
  toast: toastReducer,
  global: globalReducer,
  allegro: allegroReducer,
  producers: producersReducer,
  comments: commentsReducer,
  lines: linesReducer,
  integrations: integrationsReducer,
  settings: settingReducer,
  searchQueries: searchQueriesReducer,
  tags: tagsReducer,
  products: productsReducer,
  shippingOrders: shippingOrdersReducer,
  flags: flagsReducer,
  saleChannels: saleChannelsReducer,
  alerts: alertsReducer,
  salePlans: salePlansReducer,
  daysOff: daysOffReducer,
  weightSchemas: weightSchemaReducer,
  productAuctions: productAuctionsReducer,
  deliveries: deliveriesReducer,
  orders: ordersReducer,
  willLastHistory: willLastHistoryReducer
})

const initialReducer = {
  auth: new Auth(),
  monitoring: new Monitoring(),
  users: new Users(),
  toast: new Toast(),
  allegro: new Allegro(),
  producers: new Producers(),
  comments: new ProductComments(),
  lines: new Lines(),
  settings: new Settings(),
  integrations: new Integrations(),
  searchQueries: new SearchQueries(),
  tags: new Tags(),
  products: new Products(),
  shippingOrders: new ShippingOrders(),
  flags: new ProductFlags(),
  saleChannels: new SaleChannels(),
  alerts: new Alerts(),
  salePlans: new SalePlans(),
  daysOff: new DaysOff(),
  weightSchemas: new WeightSchemas(),
  productAuctions: new ProductAuctions(),
  deliveries: new Deliveries(),
  orders: new Orders(),
  willLastHistory: new WillLastHistory()
}

const rootReducer = (state, action) => {
  if (action.type === logoutUserRoutine.SUCCESS) {
    state = { ...state, ...initialReducer }
  }

  return appReducer(state, action)
}

export default rootReducer

export function * rootSaga () {
  yield all([
    ...authSagas,
    ...monitoringSagas,
    ...toastSagas,
    ...usersSagas,
    ...globalSagas,
    ...allegroSagas,
    ...producersSagas,
    ...commentsSagas,
    ...linesSagas,
    ...integrationsSagas,
    ...settingsSagas,
    ...searchQueriesSagas,
    ...tagsSagas,
    ...productsSagas,
    ...shippingOrdersSagas,
    ...flagsSagas,
    ...saleChannelsSagas,
    ...alertsSagas,
    ...salePlansSagas,
    ...daysOffSagas,
    ...weighSchemasSagas,
    ...productAuctionsSagas,
    ...deliveriesSagas,
    ...ordersSagas,
    ...willLastHistorySagas,
    fork(routinePromiseWatcherSaga)
  ])
}
