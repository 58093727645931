import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import background from 'assets/images/wave-bg.svg'
import backgroundLight from 'assets/images/wave-bg-light.svg'
import backgroundDark from 'assets/images/wave-bg-dark.svg'
import { THEME_MODES } from 'utils/theme'

import { getSelectedTheme } from 'ducks/global/selectors'

export const PublicLayout = (props) => {
  const selectedThemeMode = useSelector(getSelectedTheme)

  return (
    <PublicLayoutContainer image={background} themeMode={selectedThemeMode}>
      {props.children}
    </PublicLayoutContainer>
  )
}

export default withRouter(PublicLayout)

const PublicLayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${props => props.themeMode === THEME_MODES.light ? backgroundLight : backgroundDark});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
`
