import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  validateSearchQueryField,
  validateSearchQueryValues
} from 'features/searchQueries/ducks/schema'

import { getIsSearchQueriesLoading } from 'features/searchQueries/ducks/selectors'
import { createSearchQueryRoutine } from 'features/searchQueries/ducks/actions'

import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import { prepareSearchQueryPayload } from 'utils/filters'
import usePrevious from 'hooks/usePrevious'
import { equals, not } from 'ramda'

const emptyValues = {
  name: '',
  content: ''
}

export const CreateSearchQueryForm = (props) => {
  const { onSubmit } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSearchQueriesLoading)
  const { location: { search } } = useHistory()
  const prevSearch = usePrevious(search)

  const createSearchQuery = useCallback(
    payload => dispatch(createSearchQueryRoutine(payload)),
    [dispatch]
  )

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    createSearchQuery({
      values,
      callback: onSubmit
    })
  }

  useEffect(() => {
    const prev = prepareSearchQueryPayload(prevSearch)
    const curr = prepareSearchQueryPayload(search)

    if (not(equals(prev, curr))) {
      handleOnChange('content', curr)
    }
  }, [search])

  useEffect(() => {
    validateSearchQueryValues(values, _validateSchema)
  }, [values])

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        required
        name='name'
        label={t('form.name.label')}
        onChange={handleOnChange}
        validate={validateSearchQueryField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('filters.createSearchQuery.createCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

CreateSearchQueryForm.defaultProps = {
  onSubmit: () => {}
}

export default CreateSearchQueryForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
