import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  validateCreateWeightSchemaField,
  validateCreateWeightSchemaValues
} from 'features/weightSchemas/ducks/schema'

import { createWeightSchemaRoutine } from 'features/weightSchemas/ducks/actions'
import { generateFileSignature } from 'services/FileService'

import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import FileUpload from 'components/atoms/FileUpload'
import { isNilOrEmpty } from 'utils/ramda'

const emptyValues = {
  name: ''
}

export const CreateWeightSchemaForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [file, setFile] = useState(null)
  const [reset, setReset] = useState(false)
  const dispatch = useDispatch()

  const createShippingOrder = useCallback(
    payload => dispatch(createWeightSchemaRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateCreateWeightSchemaValues(values, _validateSchema)
  }, [values])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    const handleCallback = () => {
      props.onSubmit()
      setReset(true)
      setFile(null)
    }

    try {
      const { data: { signature } } = await generateFileSignature(file)
      await createShippingOrder({
        values: {
          name: values.name,
          signature: signature,
          file: file
        },
        callback: handleCallback
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleFileChange = file => setFile(file)

  return (
    <Form>
      <TextField
        required
        name='name'
        label={t('form.weightSchemaName.label')}
        onChange={handleOnChange}
        validate={validateCreateWeightSchemaField(values)}
      />
      <div>
        <FileUpload reset={reset} onChange={handleFileChange} name='weight-schema-csv' />
      </div>
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid || isNilOrEmpty(file)}
          onClick={handleSubmit}
        >
          {t('settingsPage.weightSchema.createWeightSchema.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default CreateWeightSchemaForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
