import React from 'react'
import Icon from 'components/Icon'

export const AllegroBargain = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 340 321'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          d='M260.2,60.9L188.5,8.6c-14.3-10.5-33.6-10.5-47.9,0L68.7,60.9C57,69.4,50.1,83,50.1,97.4v174.4c0,26.7,21.7,48.5,48.5,48.5
      h131.7c26.7,0,48.5-21.7,48.5-48.5V97.4C278.7,83,271.8,69.4,260.2,60.9L260.2,60.9z M258.6,271.8c0,15.6-12.7,28.3-28.3,28.3H98.6
      c-15.6,0-28.3-12.7-28.3-28.3V97.4c0-8,3.8-15.5,10.3-20.2l71.9-52.3c7.2-5.2,16.9-5.3,24.2,0l71.7,52.3
      c6.4,4.7,10.3,12.2,10.3,20.2L258.6,271.8z'
        />
      </g>
      <g>
        <circle cx='164.9' cy='58.5' r='10.6' />
      </g>
      <g>
        <path
          d='M247.6,176.3c-5.3-6.9-15.3-8.1-22.1-2.8c-1,0.8-2,1.7-2.8,2.8c-2,2.8-3.1,6.2-2.9,9.7c0,3.5,1,6.9,2.9,9.7
      c5.3,6.9,15.3,8.1,22.1,2.8c1-0.8,2-1.7,2.8-2.8c2-2.8,3.1-6.2,2.9-9.7C250.7,182.5,249.6,179.1,247.6,176.3z M242.7,189.5
      c-1.1,3.4-3.8,6-7.6,6.2s-6.4-2.7-7.6-6.2c-0.8-2.4-0.8-4.9,0-7.3c1.1-3.4,3.8-6,7.6-6.2c3.6-0.1,6.4,2.7,7.6,6.2
      C243.6,184.6,243.6,187.1,242.7,189.5L242.7,189.5z M129.2,200.8c0,0.6-0.4,0.8-0.8,0.8l0,0h-5.2c-0.6,0-0.8-0.4-0.8-0.8l0,0v-42.6
      c0-0.6,0.4-0.8,0.8-0.8l0,0h5.2c0.6,0,0.8,0.4,0.8,0.8l0,0V200.8z M117.2,200.8c0,0.6-0.4,0.8-0.8,0.8l0,0h-5.2
      c-0.6,0-0.8-0.4-0.8-0.8l0,0v-42.6c0-0.4,0.4-0.8,0.8-0.8h5.2c0.6,0,0.8,0.4,0.8,0.8l0,0V200.8L117.2,200.8z M218.7,172v3.6
      c0,0.6-0.3,1-0.8,1.1c-0.1,0-0.3,0-0.4,0c-5.9-1.3-9.5,1.4-9.5,6.9v17.5c0,0.6-0.4,0.8-0.8,0.8h-5.2c-0.6,0-0.8-0.4-0.8-0.8V183
      c0-3.4,1.3-6.7,3.8-9c2.5-2.5,5.9-3.9,9.5-3.8c1.3,0,2.4,0.1,3.6,0.3C218.5,170.7,218.7,171,218.7,172L218.7,172z M196.4,185.8
      c0-3.5-1-6.9-2.9-9.7c-5.3-6.9-15.3-8.1-22.1-2.8c-1,0.8-2,1.7-2.8,2.8c-4.1,5.7-4.1,13.4,0,19.3c2.9,3.9,7.6,6.2,12.5,6.2
      c3.2,0,6.3-1,8.8-2.7v3.8c0,5.2-4.5,6.2-7.4,6.3c-2,0-3.9,0-5.9-0.3c-0.8-0.1-1.4,0-1.4,0.8v4.6c0,0.4,0.4,0.7,0.8,0.7l0,0
      c6.7,0.6,11.2,0.4,14.6-1.5c2.4-1.4,4.2-3.6,4.9-6.3c0.6-2.1,0.8-4.2,0.8-6.3L196.4,185.8L196.4,185.8z M181,195.6
      c-3.8,0-6.4-2.7-7.6-6.2c-0.8-2.4-0.8-4.9,0-7.3c1.1-3.4,3.8-6.2,7.6-6.2c8.3,0,8.5,8.5,8.5,9.7C189.5,186.8,189.4,195.6,181,195.6z
       M105.4,182.8c0-4.1-1-7.3-2.9-9.4c-2-2.1-5.9-3.2-9.5-3.2s-7.3,1-10.5,2.8c-0.1,0.1-0.3,0.3-0.3,0.6l0.3,4.5c0.1,0.4,0.6,0.7,1,0.7
      c0.1,0,0.3-0.1,0.4-0.1c4.5-2.8,9.9-3.4,12.7-2c1.5,0.8,2.5,2.5,2.4,4.3v0.7h-7.6c-3.2,0-13.2,1.3-13.2,10.4v0.1
      c-0.1,2.8,1,5.6,3.2,7.3c2.4,1.7,5.2,2.5,8,2.2h15.3c0.6,0,0.8-0.4,0.8-0.8l0,0L105.4,182.8L105.4,182.8z M98.7,196.1h-9
      c-1.1,0-2.2-0.4-3.2-1.1c-1-0.7-1.5-1.8-1.5-2.9c0-1.7,1.1-4.9,6.6-4.9h7.1V196.1z M148.4,170.2c-9.2,0-13.3,6.2-14.6,11.3
      c-0.4,1.4-0.6,2.8-0.6,4.3c0,3.5,1,6.9,2.9,9.7c2.9,3.8,7.6,6.2,12.5,6.2c5,0.1,8.3-0.7,10.8-2.1c0.6-0.3,0.7-1,0.6-1.5V194
      c0-0.8-0.6-1.3-1.3-0.7c-2.9,2-6.4,2.8-10.1,2.4c-4.2,0-7.6-3.2-8-7.3h20.4c0.4,0,0.8-0.4,0.8-0.8
      C162.5,181.9,160.8,170.2,148.4,170.2z M141,182.8c0.1-3.8,3.4-6.9,7.3-6.6c0,0,0,0,0.1,0c3.4-0.1,6.3,2.4,6.6,5.7
      c0,0.3,0,0.6,0,0.8H141z'
        />
      </g>
    </Icon>
  )
}

export default AllegroBargain
