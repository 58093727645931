import { SearchQueries, SearchQuery } from 'features/searchQueries/ducks/records'
import {
  fetchSearchQueriesRoutine,
  createSearchQueryRoutine,
  deleteSearchQueryRoutine
} from 'features/searchQueries/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new SearchQueries(), action) => {
  switch (action.type) {
    case createSearchQueryRoutine.REQUEST:
    case deleteSearchQueryRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case createSearchQueryRoutine.FAILURE:
    case deleteSearchQueryRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchSearchQueriesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('searchQueries', transferCollectionToImmutable(action.payload.data, SearchQuery))
    default:
      return state
  }
}
