import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import { FORMATS, formatDate } from 'utils/date'

import { getUsersArray, getUsersMeta, getIsUsersLoading } from 'features/users/ducks/selectors'
import { fetchUsersRoutine } from 'features/users/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import UserAvatar from 'components/UserAvatar'
import UpdateUserModal from 'features/users/components/UpdateUserModal'

export const UsersTable = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const data = useSelector(getUsersArray)
  const isLoading = useSelector(getIsUsersLoading)
  const pagination = useSelector(getUsersMeta)
  const fetchUsers = useCallback(
    query => dispatch(fetchUsersRoutine(query)),
    [dispatch]
  )

  const headers = [
    {
      id: 'email',
      sortable: false,
      cell: t('usersPage.table.headers.email')
    },
    {
      id: 'login',
      sortable: false,
      cell: t('usersPage.table.headers.login')
    },
    {
      id: 'role',
      sortable: false,
      cell: t('usersPage.table.headers.role')
    },
    {
      id: 'createdAt',
      sortable: false,
      cell: t('usersPage.table.headers.createdAt')
    },
    {
      id: 'actions',
      sortable: false,
      cell: ''
    }
  ]

  const renderItems = data.map((data, index) => (
    <TableRow hover key={propOr(`users-row-${index}`, 'id', data)}>
      <TableCell>
        <EmailCellContainer>
          <UserAvatar small user={data.toObject()} />
          <div className='auth-user__email'>
            {propOr('-', 'email', data)}
          </div>
        </EmailCellContainer>
      </TableCell>
      <TableCell>
        {propOr('-', 'login', data)}
      </TableCell>
      <TableCell>
        {t(`userRoles.${propOr('user', 'role', data)}`)}
      </TableCell>
      <TableCell>
        {formatDate(data.created_at, FORMATS.slash)}
      </TableCell>
      <TableCell align='right'>
        <ActionsCell>
          <UpdateUserModal data={data} />
        </ActionsCell>
      </TableCell>
    </TableRow>
  ))

  return (
    <EntitiesList
      emptyStateText={t('usersPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchUsers}
      isLoading={isLoading}
    />
  )
}

export default UsersTable

const EmailCellContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 10px;
  
  .auth-user__email {
    margin-left: 10px;
  }
`

const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
