import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PATHS from 'utils/paths'
import { useTranslation } from 'react-i18next'
import { SECTION_IDS } from 'utils/productPage'

import { getProductRoutine } from 'features/products/ducks/actions'
import { getProductDetails } from 'features/products/ducks/selectors'

import CommentsSection from 'features/comments/CommentsSection'
import AddProductToCartToggle from 'features/products/AddProductToCartToggle'
import Grid from 'components/atoms/Grid'
import Paper from 'components/atoms/Paper'
import ProductMonitoringRow from 'features/products/components/ProductMonitoringRow'
import ProductActivityFlag from 'features/products/components/ProductActivityFlag'
import ProductImage from 'features/products/components/ProductImage'
import ProductIdentification from 'features/products/components/ProductIdentification'
import ProductPrice from 'features/products/components/ProductPrice'
import UpdateSingleEstimatedShippingTimePopover from 'features/products/components/UpdateSingleEstimatedShippingTimePopover'
import ProductTags from 'features/products/components/ProductTags'
import ProductSection from 'features/products/components/ProductSection'
import SectionsMenu from 'features/products/components/SectionsMenu'
import ProductStockInfo from 'features/products/components/ProductStockInfo'
import BackButton from 'components/BackButton'
import SyncProduct from 'features/products/SyncProduct'
import FlagsSection from 'features/flags/FlagsSection'
import PriceUpFlag from 'features/flags/PriceUpFlag'
import BundlesSection from 'features/products/components/BundlesSection'
import SaleChannelsSection from 'features/products/components/SaleChannelsSection'
import PromoSection from 'features/products/components/PromoSection'
import SaleSection from 'features/products/components/SaleSection'
import AutoDecisionFlag from 'features/flags/AutoDecisionFlag'
import DeliverySection from 'features/products/components/DeliverySection'
import NotesSection from 'features/products/components/NotesSection'
import PromoDiffFlag from 'features/flags/PromoDiffFlag'
import TodayOrdersSection from 'features/products/components/TodayOrdersSection'
import WillLastHistorySection from 'features/products/components/WillLastHistorySection'
import PriceDownFlag from 'features/flags/PriceDownFlag'

export const ProductPage = (props) => {
  const { t } = useTranslation()
  const { match: { params: { id } } } = props
  const product = useSelector(state => getProductDetails(state, id))
  const dispatch = useDispatch()
  const TopSectionRef = useRef(null)
  const TopSectionContainerPlaceholderRef = useRef(null)
  const SideSectionContainerPlaceholderRef = useRef(null)

  const [topSectionHeight, setTopSectionHeight] = useState(0)
  const [viewWidth, setViewWidth] = useState(0)
  const [sideSectionWidth, setSideSectionWidth] = useState(0)

  const handleSaveRequiredDimensions = () => {
    setTopSectionHeight(TopSectionRef.current.clientHeight)
    setViewWidth(TopSectionContainerPlaceholderRef.current.clientWidth)
    setSideSectionWidth(SideSectionContainerPlaceholderRef.current.clientWidth)
  }

  useEffect(() => {
    handleSaveRequiredDimensions()
    window.addEventListener('resize', handleSaveRequiredDimensions)
  }, [product, id])

  const getProduct = useCallback(
    payload => dispatch(getProductRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    getProduct({ id })

    return () => {
      window.removeEventListener('resize', handleSaveRequiredDimensions)
    }
  }, [id])

  return (
    <>
      <TopSectionContainer viewWidth={viewWidth} ref={TopSectionRef}>
        <div className='button-container'>
          <BackButton defaultPath={PATHS.monitoring} />
        </div>
        <StyledPaper>
          <FlexContainer>
            <ImageContainer>
              <ProductImage />
            </ImageContainer>
            <FlexGrow>
              <Grid container spacing={2}>
                <Grid item s={12} sm={7}>
                  <ProductIdentification />
                  <FlexContainer>
                    <ProductPrice />
                    <ProductActions>
                      <AddProductToCartToggle />
                      <UpdateSingleEstimatedShippingTimePopover />
                      <SyncProduct />
                    </ProductActions>
                  </FlexContainer>
                  <ProductStockInfo />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <AlignRight>
                    <div>
                      <ProductFlagsContainer>
                        <AutoDecisionFlag />
                        <PriceUpFlag />
                        <PriceDownFlag />
                        <PromoDiffFlag />
                        <ProductActivityFlag />
                      </ProductFlagsContainer>
                      <ProductTags />
                    </div>
                  </AlignRight>
                </Grid>
              </Grid>
            </FlexGrow>
          </FlexContainer>
          <ProductMonitoringRow />
        </StyledPaper>
      </TopSectionContainer>
      <TopSectionContainerPlaceholder ref={TopSectionContainerPlaceholderRef} topSectionHeight={topSectionHeight} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={2} sm={1}>
              <SideSectionContainer sideSectionWidth={sideSectionWidth}>
                <SectionsMenu />
              </SideSectionContainer>
              <SideSectionContainerPlaceholder ref={SideSectionContainerPlaceholderRef} />
            </Grid>
            <Grid item xs={12} sm={11}>
              <StyledPaper>
                <NotesSection />
                <ProductSection
                  id={SECTION_IDS.sale}
                  title={t('productPage.menu.sale')}
                  component={SaleSection}
                />
                <ProductSection
                  id={SECTION_IDS.todayOrders}
                  title={t('productPage.menu.todayOrders')}
                  component={TodayOrdersSection}
                />
                <ProductSection
                  id={SECTION_IDS.comments}
                  title={t('productPage.menu.comments')}
                  component={CommentsSection}
                />
                <ProductSection
                  id={SECTION_IDS.flags}
                  title={t('productPage.menu.flags')}
                  component={FlagsSection}
                />
                <ProductSection
                  id={SECTION_IDS.bundles}
                  title={t('productPage.menu.bundles')}
                  component={BundlesSection}
                />
                <ProductSection
                  id={SECTION_IDS.saleChannels}
                  title={t('productPage.menu.saleChannels')}
                  component={SaleChannelsSection}
                />
                <ProductSection
                  id={SECTION_IDS.promotions}
                  title={t('productPage.menu.promotions')}
                  component={PromoSection}
                />
                <ProductSection
                  id={SECTION_IDS.delivery}
                  title={t('productPage.menu.delivery')}
                  component={DeliverySection}
                />
                <ProductSection
                  id={SECTION_IDS.willLastHistory}
                  title={t('productPage.menu.willLastHistory')}
                  component={WillLastHistorySection}
                />
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(ProductPage)

export const AlignRight = styled.div`
  text-align: right;

  ${props => props.theme.breakpointsMedia.mobile} {
    text-align: left;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    align-items: flex-start;
    flex-direction: column;
  }
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

const ImageContainer = styled.div`
  margin-right: 10px;
`

const ProductActions = styled.div`
  margin-left: 30px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-left: 0;
  }
`

const ProductFlagsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    justify-content: flex-start;
  }
`

const StyledPaper = styled(Paper)`
  padding: 15px;
`

const TopSectionContainer = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.sticky};
  width: ${props => props.viewWidth}px;
  background-color: ${props => props.theme.palette.common.white};
  padding-top: 20px;
  margin-top: -20px;
  
  .button-container {
    margin-bottom: 10px;
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    position: static;
    width: 100%;
  }
`

const TopSectionContainerPlaceholder = styled.div`
  height: ${props => props.topSectionHeight}px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`

const SideSectionContainer = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.sticky};
  width: ${props => props.sideSectionWidth}px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    position: static;
  }
`

const SideSectionContainerPlaceholder = styled.div`
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`
