import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Switch, Redirect, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import PATHS from 'utils/paths'

import Tabs from 'components/atoms/Tabs'
import Tab from 'components/atoms/Tab'
import Cart from 'components/icons/Cart'
import Transit from 'components/icons/Transit'
import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import PageColumnContainer from 'components/PageColumnContainer'
import CartTable from 'features/monitoring/CartTable'
import ShippingOrdersTable from 'features/shippingOrders/ShippingOrdersTable'

export const OrdersPage = (props) => {
  const { history: { push, location: { pathname } } } = props
  const [openedTab, setOpenedTab] = useState(0)
  const { t } = useTranslation()

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      push(PATHS.cart)
      setOpenedTab(0)
    } else {
      push(PATHS.ordersHistory)
      setOpenedTab(1)
    }
  }

  useEffect(() => {
    switch (pathname) {
      case PATHS.ordersHistory:
        return setOpenedTab(1)
      default:
        return setOpenedTab(0)
    }
  }, [])

  return (
    <PageColumnContainer>
      <TabsContainer>
        <Tabs
          value={openedTab}
          onChange={handleTabChange}
          variant='fullWidth'
          indicatorColor='secondary'
          textColor='secondary'
        >
          <Tab id='tab-cart' icon={<Cart />} label={t('ordersPage.tabs.cart')} />
          <Tab id='tab-orders' icon={<Transit />} label={t('ordersPage.tabs.orders')} />
        </Tabs>
      </TabsContainer>
      <TablePaperContainer>
        <Switch>
          <Route exact path={PATHS.cart} component={CartTable} />
          <Route exact path={PATHS.ordersHistory} component={ShippingOrdersTable} />
          <Redirect to={PATHS.cart} />
        </Switch>
      </TablePaperContainer>
    </PageColumnContainer>
  )
}

export default withRouter(OrdersPage)

const TabsContainer = styled.div`
  flex: none;
`
