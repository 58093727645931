export default {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      gray100: '#F8F8F9',
      gray200: '#F1F1F3',
      gray300: '#D8D9DB',
      gray400: '#B5B6B8',
      gray500: '#868789',
      gray600: '#616775',
      gray700: '#434B62',
      gray800: '#2A334F',
      gray900: '#192141'
    },
    primary: {
      light: '#5DBCE2',
      main: '#2D97D0',
      dark: '#2076B2',
      contrastText: '#fff',
      gradient: 'linear-gradient(to bottom, #2d97d0, #3aa2da, #47aee4, #52b9ee, #5ec5f8)'
    },
    secondary: {
      light: '#FDCE61',
      main: '#FCB72E',
      dark: '#D89521',
      contrastText: '#fff'
    },
    error: {
      light: '#FF8075',
      main: '#FF4747',
      dark: '#DB3342',
      contrastText: '#fff'
    },
    warning: {
      light: '#FFB867',
      main: '#FF9635',
      dark: '#DB7426',
      contrastText: '#192141'
    },
    info: {
      light: '#69CEFF',
      main: '#38B2FF',
      dark: '#288BDB',
      contrastText: '#fff'
    },
    success: {
      light: '#99D24A',
      main: '#6CB519',
      dark: '#549B12',
      contrastText: '#192141'
    },
    text: {
      main: '#192141'
    },
    constants: {
      black: '#000',
      white: '#fff'
    },
    type: 'light'
  },
  shadows: {
    main: '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    paper: '0 4px 24px 0 rgba(37, 38, 94, 0.1)'
  }
}
