import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as linesService from 'services/LinesService'
import { pathOr } from 'ramda'
import { getCreateLineError } from 'features/lines/ducks/errors'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { generateCurrentQuery } from 'utils/query'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

// ROUTINES

export const fetchLinesRoutine = createRoutine('FETCH_LINES')
export const assignLinesToUserRoutine = createRoutine('ASSIGN_LINES_TO_USER')
export const createLineRoutine = createRoutine('CREATE_LINE')
export const editLineRoutine = createRoutine('EDIT_LINE')
export const deleteLineRoutine = createRoutine('DELETE_LINE')

// ACTIONS

function * fetchLines ({ payload }) {
  yield put(fetchLinesRoutine.request())
  try {
    const result = yield call(linesService.fetchLines, payload)
    yield put(fetchLinesRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchLinesRoutine.failure(e))
    console.error(e)
  }
}

function * assignLinesToUser ({ payload }) {
  yield put(assignLinesToUserRoutine.request())
  try {
    yield call(linesService.assignLinesToUser, payload)
    yield put(fetchLinesRoutine(generateCurrentQuery()))
    yield put(handleResetSelectionRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.assignLinesToUserSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(assignLinesToUserRoutine.success())
  } catch (e) {
    yield put(assignLinesToUserRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * createLine ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createLineRoutine.request())
  try {
    yield call(linesService.createLine, values)
    yield put(
      showToastRoutine({
        key: 'toast.createLineSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchLinesRoutine(generateCurrentQuery()))
    yield put(createLineRoutine.success())
    callback()
  } catch (e) {
    yield put(createLineRoutine.failure(e))
    yield put(
      showToastRoutine(getCreateLineError(e))
    )
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * editLine ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editLineRoutine.request())
  try {
    yield call(linesService.editLine, values)
    yield put(
      showToastRoutine({
        key: 'toast.createLineSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchLinesRoutine(generateCurrentQuery()))
    yield put(editLineRoutine.success())
    callback()
  } catch (e) {
    yield put(editLineRoutine.failure(e))
    yield put(
      showToastRoutine(getCreateLineError(e))
    )
  }
}

function * deleteLine ({ payload }) {
  yield put(deleteLineRoutine.request())
  try {
    yield call(linesService.deleteLine, payload)
    yield put(
      showToastRoutine({
        key: 'toast.deleteLineSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchLinesRoutine(generateCurrentQuery()))
    yield put(deleteLineRoutine.success())
  } catch (e) {
    yield put(deleteLineRoutine.failure(e))
    yield put(
      showToastRoutine(getCreateLineError(e))
    )
  }
}

// WATCHERS

export function * fetchLinesWatcher () {
  yield takeLatest(fetchLinesRoutine.TRIGGER, fetchLines)
}

export function * assignLinesToUserWatcher () {
  yield takeLatest(assignLinesToUserRoutine.TRIGGER, assignLinesToUser)
}

export function * createLineWatcher () {
  yield takeLatest(createLineRoutine.TRIGGER, createLine)
}

export function * editLineWatcher () {
  yield takeLatest(editLineRoutine.TRIGGER, editLine)
}

export function * deleteLineWatcher () {
  yield takeLatest(deleteLineRoutine.TRIGGER, deleteLine)
}

// SAGAS

export const linesSagas = [
  fork(fetchLinesWatcher),
  fork(assignLinesToUserWatcher),
  fork(createLineWatcher),
  fork(editLineWatcher),
  fork(deleteLineWatcher)
]
