import React from 'react'
import Icon from 'components/Icon'

export const AllegroNameLogo = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 128 43'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          d='M123.5,14.4c-3.8-4.9-10.9-5.8-15.8-2c-0.7,0.6-1.4,1.2-2,2c-1.4,2-2.2,4.4-2.1,6.9c0,2.5,0.7,4.9,2.1,6.9
    c3.8,4.9,10.9,5.8,15.8,2c0.7-0.6,1.4-1.2,2-2c1.4-2,2.2-4.4,2.1-6.9C125.7,18.8,124.9,16.4,123.5,14.4z M120,23.8
    c-0.8,2.4-2.7,4.3-5.4,4.4c-2.7,0.1-4.6-1.9-5.4-4.4c-0.6-1.7-0.6-3.5,0-5.2c0.8-2.4,2.7-4.3,5.4-4.4c2.6-0.1,4.6,1.9,5.4,4.4
    C120.6,20.3,120.6,22.1,120,23.8L120,23.8z M38.9,31.9c0,0.4-0.3,0.6-0.6,0.6l0,0h-3.7c-0.4,0-0.6-0.3-0.6-0.6v0V1.5
    c0-0.4,0.3-0.6,0.6-0.6l0,0h3.7c0.4,0,0.6,0.3,0.6,0.6c0,0,0,0,0,0V31.9z M30.3,31.9c0,0.4-0.3,0.6-0.6,0.6h0h-3.7
    c-0.4,0-0.6-0.3-0.6-0.6l0,0V1.5c0-0.3,0.3-0.6,0.6-0.6h3.7c0.4,0,0.6,0.3,0.6,0.6c0,0,0,0,0,0L30.3,31.9L30.3,31.9z M102.8,11.3
    v2.6c0,0.4-0.2,0.7-0.6,0.8c-0.1,0-0.2,0-0.3,0c-4.2-0.9-6.8,1-6.8,4.9v12.5c0,0.4-0.3,0.6-0.6,0.6h-3.7c-0.4,0-0.6-0.3-0.6-0.6
    V19.2c0-2.4,0.9-4.8,2.7-6.4c1.8-1.8,4.2-2.8,6.8-2.7c0.9,0,1.7,0.1,2.6,0.2C102.7,10.4,102.8,10.6,102.8,11.3L102.8,11.3z
     M86.9,21.2c0-2.5-0.7-4.9-2.1-6.9C81,9.4,73.9,8.5,69,12.3c-0.7,0.6-1.4,1.2-2,2c-2.9,4.1-2.9,9.6,0,13.8c2.1,2.8,5.4,4.4,8.9,4.4
    c2.3,0,4.5-0.7,6.3-1.9v2.7c0,3.7-3.2,4.4-5.3,4.5c-1.4,0-2.8,0-4.2-0.2c-0.6-0.1-1,0-1,0.6v3.3c0,0.3,0.3,0.5,0.6,0.5c0,0,0,0,0,0
    c4.8,0.4,8,0.3,10.4-1.1c1.7-1,3-2.6,3.5-4.5c0.4-1.5,0.6-3,0.6-4.5L86.9,21.2L86.9,21.2z M75.9,28.2c-2.7,0-4.6-1.9-5.4-4.4
    c-0.6-1.7-0.6-3.5,0-5.2c0.8-2.4,2.7-4.4,5.4-4.4c5.9,0,6.1,6.1,6.1,6.9S81.9,28.2,75.9,28.2z M21.9,19c0-2.9-0.7-5.2-2.1-6.7
    C18.4,10.8,15.6,10,13,10c-2.6,0-5.2,0.7-7.5,2c-0.1,0.1-0.2,0.2-0.2,0.4l0.2,3.2c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2-0.1,0.3-0.1
    c3.2-2,7.1-2.4,9.1-1.4c1.1,0.6,1.8,1.8,1.7,3.1v0.5h-5.4c-2.3,0-9.4,0.9-9.4,7.4v0.1c-0.1,2,0.7,4,2.3,5.2c1.7,1.2,3.7,1.8,5.7,1.6
    h10.9c0.4,0,0.6-0.3,0.6-0.6l0,0L21.9,19L21.9,19z M17.1,28.5h-6.4c-0.8,0-1.6-0.3-2.3-0.8c-0.7-0.5-1.1-1.3-1.1-2.1
    c0-1.2,0.8-3.5,4.7-3.5h5.1V28.5z M52.6,10c-6.6,0-9.5,4.4-10.4,8.1c-0.3,1-0.4,2-0.4,3.1c0,2.5,0.7,4.9,2.1,6.9
    c2.1,2.7,5.4,4.4,8.9,4.4c3.6,0.1,5.9-0.5,7.7-1.5c0.4-0.2,0.5-0.7,0.4-1.1V27c0-0.6-0.4-0.9-0.9-0.5c-2.1,1.4-4.6,2-7.2,1.7
    c-3,0-5.4-2.3-5.7-5.2h14.6c0.3,0,0.6-0.3,0.6-0.6C62.7,18.4,61.5,10,52.6,10z M47.3,19c0.1-2.7,2.4-4.9,5.2-4.7c0,0,0,0,0.1,0
    c2.4-0.1,4.5,1.7,4.7,4.1c0,0.2,0,0.4,0,0.6H47.3z'
        />
      </g>
    </Icon>
  )
}

export default AllegroNameLogo
