import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { propEq } from 'ramda'
import { useTranslation } from 'react-i18next'
import { getSelectedTheme } from 'ducks/global/selectors'
import { THEME_MODES } from 'utils/theme'

import Cart from 'components/icons/Cart'
import Tooltip from 'components/atoms/Tooltip'
import { SHIPPING_CART_STATUS } from 'utils/shippingOrders'

export const CartStatus = (props) => {
  const { data } = props
  const { t } = useTranslation()
  const selectedThemeMode = useSelector(getSelectedTheme)

  const isInCart = propEq('shippingStatus', SHIPPING_CART_STATUS.inCart)(data)

  return isInCart && (
    <Tooltip title={t('monitoringPage.table.headers.shippingStatus')}>
      <IconContainer themeMode={selectedThemeMode}><Cart fontSize='small' /></IconContainer>
    </Tooltip>
  )
}

export default CartStatus

const IconContainer = styled.div`
  display: inline-block;
  font-size: 10px;
  color: ${props => props.themeMode === THEME_MODES.light ? props.theme.palette.common.gray500 : props.theme.palette.common.gray600};
`
