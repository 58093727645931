import avatar1 from 'assets/avatars/avatar_1.png'
import avatar2 from 'assets/avatars/avatar_2.png'
import avatar3 from 'assets/avatars/avatar_3.png'
import avatar4 from 'assets/avatars/avatar_4.png'
import avatar5 from 'assets/avatars/avatar_5.png'
import avatar6 from 'assets/avatars/avatar_6.png'
import avatar7 from 'assets/avatars/avatar_7.png'
import avatar8 from 'assets/avatars/avatar_8.png'
import avatar9 from 'assets/avatars/avatar_9.png'
import avatarSystem from 'assets/avatars/avatar_system.png'

export const AVATARS = {
  avatar_1: avatar1,
  avatar_2: avatar2,
  avatar_3: avatar3,
  avatar_4: avatar4,
  avatar_5: avatar5,
  avatar_6: avatar6,
  avatar_7: avatar7,
  avatar_8: avatar8,
  avatar_9: avatar9,
  avatar_system: avatarSystem
}
