import { ProductFlags, ProductFlag } from 'features/flags/ducks/records'
import { createProductTaskRoutine, fetchProductTasksRoutine } from 'features/flags/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new ProductFlags(), action) => {
  switch (action.type) {
    case createProductTaskRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case createProductTaskRoutine.FAILURE:
    case createProductTaskRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
    case fetchProductTasksRoutine.SUCCESS:
      return state
        .set('flags', {
          ...state.flags,
          [action.payload.productId]: transferCollectionToImmutable(action.payload.result.data, ProductFlag)
        })
    default:
      return state
  }
}
