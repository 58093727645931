import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getHasAllSelectedItemsActualShippingQty } from 'ducks/global/selectors'

import CreateShippingOrderForm from 'features/shippingOrders/CreateShippingOrderForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Tooltip from 'components/atoms/Tooltip'

export const CreateShippingOrderModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const hasAllSelectedItemsActualShippingQty = useSelector(getHasAllSelectedItemsActualShippingQty)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ModalTriggerContainer>
      {
        hasAllSelectedItemsActualShippingQty ? (
          <Button id='create-order-button' onClick={handleOpen}>{t('ordersPage.createShippingOrder.createCTA')}</Button>
        ) : (
          <Tooltip title={t('ordersPage.createShippingOrder.createCTADisabled')}>
            <div>
              <Button disabled>{t('ordersPage.createShippingOrder.createCTA')}</Button>
            </div>
          </Tooltip>
        )
      }
      <Modal
        title={t('ordersPage.createShippingOrder.createCTA')}
        open={open}
        handleClose={handleClose}
      >
        <CreateShippingOrderForm onSubmit={handleClose} />
      </Modal>
    </ModalTriggerContainer>
  )
}

export default CreateShippingOrderModal

export const ModalTriggerContainer = styled.div`
  flex: none;
  margin: 0 10px 0 40px;
`
