import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork } from '@redux-saga/core/effects'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

// ROUTINES

export const changeThemeRoutine = createRoutine('CHANGE_THEME')
export const handleSelectItemRoutine = createRoutine('HANDLE_SELECT_ITEM')
export const handleSelectCollectionRoutine = createRoutine('HANDLE_SELECT_COLLECTION')
export const handleResetSelectionRoutine = createRoutine('HANDLE_RESET_SELECTION')

// ACTIONS

function * changeTheme ({ payload }) {
  yield put(changeThemeRoutine.request())
  try {
    yield put(changeThemeRoutine.success(payload))
    LocalStorageService.set(LOCAL_STORAGE_KEYS.selectedTheme, payload)
  } catch (e) {
    yield put(changeThemeRoutine.failure(e))
  }
}

function * handleSelectItem ({ payload: { item, data = {} } }) {
  yield put(handleSelectItemRoutine.request())
  try {
    yield put(handleSelectItemRoutine.success({ item, data }))
  } catch (e) {
    yield put(handleSelectItemRoutine.failure(e))
  }
}

function * handleSelectCollection ({ payload: { collection, collectionData = [] } }) {
  yield put(handleSelectCollectionRoutine.request())
  try {
    yield put(handleSelectCollectionRoutine.success({ collection, collectionData }))
  } catch (e) {
    yield put(handleSelectCollectionRoutine.failure(e))
  }
}

function * handleResetSelection ({ payload }) {
  yield put(handleResetSelectionRoutine.request())
  try {
    yield put(handleResetSelectionRoutine.success(payload))
  } catch (e) {
    yield put(handleResetSelectionRoutine.failure(e))
  }
}

// WATCHERS

export function * changeThemeWatcher () {
  yield takeLatest(changeThemeRoutine.TRIGGER, changeTheme)
}

export function * handleSelectItemWatcher () {
  yield takeLatest(handleSelectItemRoutine.TRIGGER, handleSelectItem)
}

export function * handleSelectCollectionWatcher () {
  yield takeLatest(handleSelectCollectionRoutine.TRIGGER, handleSelectCollection)
}

export function * handleResetSelectionWatcher () {
  yield takeLatest(handleResetSelectionRoutine.TRIGGER, handleResetSelection)
}

// SAGAS

export const globalSagas = [
  fork(changeThemeWatcher),
  fork(handleSelectItemWatcher),
  fork(handleSelectCollectionWatcher),
  fork(handleResetSelectionWatcher)
]
