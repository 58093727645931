import { string, object, array } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Assign Line to producers form validator

export const assignLineToProducersSchema = object().shape({
  lineId: string()
    .required(() => ({ key: 'form.line.errors.required' })),
  producerIds: array().required()
})

export const validateAssignLineToProducersField = values => validateField(assignLineToProducersSchema, values)
export const validateAssignLineToProducersValues = validateValues(assignLineToProducersSchema)
