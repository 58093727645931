import { object, number } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Save settings form validator

export const saveSettingsSchema = object().shape({
  soldShort: number()
    .required(() => ({ key: 'form.soldX.errors.required' })),
  soldLong: number()
    .required(() => ({ key: 'form.soldX.errors.required' }))
})

export const validateSaveSettingsSchemaField = values => validateField(saveSettingsSchema, values)
export const validateSaveSettingsSchemaValues = validateValues(saveSettingsSchema)

// Save RecalculateSalePlanForm form validator

export const recalculateSalePlanFormSchema = object().shape({
  stockDiffPercentage: number()
    .required(() => ({ key: 'form.percentage.errors.required' })),
  stockDiffAmount: number()
    .required(() => ({ key: 'form.quantity.errors.required' }))
})

export const validateRecalculateSalePlanSchemaField = values => validateField(recalculateSalePlanFormSchema, values)
export const validateRecalculateSalePlanSchemaValues = validateValues(recalculateSalePlanFormSchema)

// Save DeliveredProductsSyncIntervalForm form validator

export const deliveredProductsSyncIntervalFormSchema = object().shape({
  deliveredProductsSyncInterval: number()
    .required(() => ({ key: 'form.minutes.errors.required' }))
})

export const validateDeliveredProductsSyncIntervalFormSchemaField = values => validateField(deliveredProductsSyncIntervalFormSchema, values)
export const validateDeliveredProductsSyncIntervalFormSchemaValues = validateValues(deliveredProductsSyncIntervalFormSchema)

// Save RecalculateSalePlanForm form validator

export const priceDownStockDiffPercentageFormSchema = object().shape({
  priceDownStockDiffPercentage: number()
    .required(() => ({ key: 'form.percentage.errors.required' }))
})

export const validatePriceDownStockDiffPercentageSchemaField = values => validateField(priceDownStockDiffPercentageFormSchema, values)
export const validatePriceDownStockDiffPercentageSchemaValues = validateValues(priceDownStockDiffPercentageFormSchema)
