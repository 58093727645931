import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateAddLineField,
  validateAddLineValues
} from 'features/lines/ducks/schema'

import { editLineRoutine } from 'features/lines/ducks/actions'
import { getIsLinesLoading } from 'features/lines/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  id: '',
  name: ''
}

export const AddLineForm = (props) => {
  const { onSubmit, data: { id, name } } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLinesLoading)

  const editLine = useCallback(
    payload => dispatch(editLineRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateAddLineValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ id, name })
  }, [])

  const handleOnChange = (name, value) => setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    editLine({
      values,
      callback: onSubmit
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        required
        value={name}
        name='name'
        label={t('form.lineName.label')}
        onChange={handleOnChange}
        validate={validateAddLineField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('linesPage.editLine.editLineCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

AddLineForm.defaultProps = {
  onSubmit: () => {}
}

export default AddLineForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
