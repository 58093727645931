import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UpdateUserForm from 'features/users/UpdateUserForm'
import Modal from 'components/atoms/Modal'
import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'

export const UpdateUserModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal
        title={t('usersPage.updateUserCTA')}
        open={open}
        handleClose={handleClose}
      >
        <UpdateUserForm data={props.data} onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default UpdateUserModal
