import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import IconButton from 'components/atoms/IconButton'
import Popover from 'components/atoms/Popover'
import Weight from 'components/icons/Weight'
import Tooltip from 'components/atoms/Tooltip'
import AssignWeightSchemaToProductsForm from 'features/weightSchemas/assignWeightSchemaToProductsForm'

export const AssignWeightSchemaPopover = (props) => {
  const { t } = useTranslation()
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? 'assign-weight-schema' : undefined

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const AssignWeightSchemaPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <PopoverContainer>
        <AssignWeightSchemaToProductsForm onSubmit={handleClose} />
      </PopoverContainer>
    </Popover>
  )

  return (
    <Tooltip title={t('monitoringPage.weightSchema.label')}>
      <div>
        <IconButton
          type='button'
          id='button-assign-tag'
          onClick={handleOpen}
          color='primary'
        >
          <Weight />
        </IconButton>
        {AssignWeightSchemaPopup}
      </div>
    </Tooltip>
  )
}

export default AssignWeightSchemaPopover

export const PopoverContainer = styled.div`
  padding: 20px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
     width: calc(100vw - 32px);
  }
`
