import React from 'react'
import styled from 'styled-components'
import Logo from 'components/Logo'

import TopNavAuthorisedUser from 'components/TopNavAuthorisedUser'
import ThemeModeToggle from 'components/ThemeModeToggle'

export const TopNavigation = (props) => {
  return (
    <TopNavigationContainer>
      <TopNavigationLogo>
        <Logo />
      </TopNavigationLogo>
      <TopNavigationContent>
        <TopNavAuthorisedUser />
        <ThemeModeToggle />
      </TopNavigationContent>
    </TopNavigationContainer>
  )
}

export default TopNavigation

export const TopNavigationContainer = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily};
  z-index: ${(props) => props.theme.zIndex.appBar};
  position: fixed;
  top: 0;
  right: 0;
  height: ${(props) => props.theme.dimensions.navHeight};
  line-height: ${(props) => props.theme.dimensions.navHeight};
  width: 100vw;
  padding: 0 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadows.main};
  background-color: ${(props) => props.theme.palette.common.white};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 0 17px;
  }
`

export const TopNavigationLogo = styled.div`
  display: inline-flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
      position: fixed;
      width: 100%;
      justify-content: center;
  }
`

export const TopNavigationContent = styled.div`
  display: inline-flex;
  align-items: center;
  padding-right: 10px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`
