import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatDate, FORMATS } from 'utils/date'

import { getProductDetails, getProductShippingDetails } from 'features/products/ducks/selectors'
import { useTranslation } from 'react-i18next'
import { always, identity, ifElse, isNil, pipe, propOr } from 'ramda'
import { numberWithSpaces, twoDecimal } from 'utils/number'

import Transit from 'components/icons/Transit'
import Snooze from 'components/icons/Snooze'
import SnoozeAutoDecision from 'components/icons/SnoozeAutoDecision'
import { getSelectedTheme } from 'ducks/global/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import Tooltip from 'components/atoms/Tooltip'

export const ProductStockInfo = (props) => {
  const { t } = useTranslation()
  const { match: { params: { id } } } = props
  const selectedThemeMode = useSelector(getSelectedTheme)
  const data = useSelector(state => getProductDetails(state, id))
  const shippingDetails = useSelector(state => getProductShippingDetails(state, id))

  const getNumberPropAndFormat =
    pipe(
      ifElse(
        isNil,
        always(twoDecimal(0)),
        identity
      ),
      numberWithSpaces
    )

  const renderShippingDetails = shippingDetails.map(
    (shippingDetail, id) =>
      <div className='stock__shipping-detail' key={`shipping-detail-${id}`}>{t('productPage.stock.details', { quantity: shippingDetail.quantity, date: formatDate(shippingDetail.toBeShippedAt, FORMATS.slash) })}</div>
  )

  return (
    <ProductStockInfoContainer themeMode={selectedThemeMode}>
      <div className='stock__available'>
        {t('productPage.stock.available')}: {getNumberPropAndFormat(propOr(twoDecimal(0), 'stock', data))}
      </div>
      {isNotNilOrEmpty(data.snoozed_until) && (
        <Tooltip title={t('monitoringPage.table.tooltip.snoozedUntil', { date: formatDate(data.snoozed_until, FORMATS.slash) })}>
          <div className='stock__snooze'>
            <Snooze fontSize='small' />
          </div>
        </Tooltip>
      )}
      {isNotNilOrEmpty(data.auto_decision_snoozed_until) && (
        <Tooltip title={t('monitoringPage.table.tooltip.autoDecisionSnoozedUntil', { date: formatDate(data.auto_decision_snoozed_until, FORMATS.slash) })}>
          <div className='stock__auto-decision-snooze'>
            <SnoozeAutoDecision />
          </div>
        </Tooltip>
      )}
      {data.virtualStock > 0 && (
        <Tooltip title={t('monitoringPage.table.tooltip.virtualStock', { count: getNumberPropAndFormat(propOr(twoDecimal(0), 'virtualStock', data)) })}>
          <div className='stock__virtual'>
            &nbsp;<Transit fontSize='small' />&nbsp;
            <div className='stock__shipping-details-container'>{renderShippingDetails}</div>
          </div>
        </Tooltip>
      )}
    </ProductStockInfoContainer>
  )
}

export default withRouter(ProductStockInfo)

const ProductStockInfoContainer = styled.div`
  font-size: 10px;
  color: ${props => props.theme.palette.common.gray500};
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  
  .stock__available {
    white-space: nowrap;
  }
  
  .stock__virtual {
    display: inline-flex;
    align-items: center;
  }
  
  .stock__snooze {
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
  }
  
  .stock__auto-decision-snooze {
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
  }
  
  .stock__shipping-details-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .stock__shipping-detail {
    margin: 2px 0;
  }
  
  .stock__shipping-detail:not(:last-child)::after {
      content: ', ';
      margin-right: 3px;
    }
  }
`
