import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  deleteAllegroAccountRoutine
} from 'features/allegro/ducks/actions'

import DeleteWithConfirm from 'components/DeleteWithConfirm'

export const AllegroAccountDeleteButton = (props) => {
  const { data } = props
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const deleteAccount = useCallback(
    payload => dispatch(deleteAllegroAccountRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = id => {
    deleteAccount({ id })
  }

  return (
    <DeleteWithConfirm
      text={t('integrationsPage.deleteAccount.text')}
      submitText={t('integrationsPage.deleteAccount.submitCTA')}
      cancelText={t('integrationsPage.deleteAccount.cancelCTA')}
      id={data.id}
      onSubmit={handleSubmit}
    />
  )
}

export default AllegroAccountDeleteButton
