import qs from 'qs'
import { dissoc, has, pick } from 'ramda'
import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory()

  const getSearchQuery = () => {
    const { location: { search } } = history
    return qs.parse(search, { ignoreQueryPrefix: true })
  }

  const setSearchQuery = query => {
    const { location: { pathname } } = history
    const currentSearch = getSearchQuery()
    const newSearch = qs.stringify({ ...currentSearch, ...query })
    return history.push(`${pathname}?${newSearch}`)
  }

  const removeSearchQuery = name => {
    const { location: { pathname } } = history
    const currentSearch = getSearchQuery()
    const newSearch = qs.stringify(dissoc(name, currentSearch))
    return history.push(`${pathname}?${newSearch}`)
  }

  const hasSearchQuery = name => {
    const currentSearch = getSearchQuery()
    return has(name, currentSearch)
  }

  const keepPaginationWithNewQuery = query => {
    const { location: { pathname } } = history
    const paginationQuery = pick([
      'rowsPerPage',
      'page'
    ])(getSearchQuery())
    const newSearch = qs.stringify({ ...paginationQuery, ...query })
    return history.push(`${pathname}?${newSearch}`)
  }

  return {
    setSearchQuery,
    getSearchQuery,
    removeSearchQuery,
    hasSearchQuery,
    keepPaginationWithNewQuery
  }
}
