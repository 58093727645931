import React from 'react'
import { includes, omit, replace } from 'ramda'
import styled from 'styled-components'
import PATHS from 'utils/paths'

import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from 'components/atoms/Tooltip'
import { useSelector } from 'react-redux'
import { getUsersHiddenColumns } from 'features/auth/ducks/selectors'
import { useHistory } from 'react-router-dom'

export const TableHeaderCell = (props) => {
  const history = useHistory()
  const { sortable, children, sortDirection, sortedColumnId, onChangeSort, columnId, tooltip, disableSticky } = props
  const classes = useCellStyles()
  const hiddenColumns = useSelector(getUsersHiddenColumns)

  const handleSort = e => {
    onChangeSort(columnId)
  }
  const isActive = sortedColumnId === columnId

  const renderChildren = tooltip ? (
    <Tooltip title={tooltip}>
      <div>
        {children}
      </div>
    </Tooltip>
  ) : children

  const cellId = columnId ? { id: replace('.', '-', columnId) } : {}

  const SortableHeader = (
    <StyledTableSortLabel
      active={isActive}
      direction={sortDirection}
      onClick={handleSort}
      {...cellId}
    >
      <span className='sortable-header__label'>
        {renderChildren}
      </span>
    </StyledTableSortLabel>
  )

  const cellProps = omit(['sortable', 'children', 'sortDirection', 'sortedColumnId', 'onChangeSort', 'columnId'])(props)

  const headerName = columnId ? `${replace('.', '-', columnId)}` : ''

  return (
    <StyledCell
      classes={classes}
      disableSticky={disableSticky}
      {...cellProps}
      hidden={includes(headerName, hiddenColumns) && history.location.pathname === PATHS.monitoring}
    >
      {sortable ? SortableHeader : <div className='cell-content--non-sortable'>{renderChildren}</div>}
    </StyledCell>
  )
}

export default TableHeaderCell

const StyledCell = styled(TableCell)`
  position: ${props => props.disableSticky ? 'static' : 'sticky'};
  z-index: ${props => props.theme.zIndex.sticky};
  top: 0;
  background-color: ${props => props.theme.palette.common.white};
  display: ${props => props.hidden ? 'none' : 'table-cell'} !important;
  
  .cell-content--non-sortable,
  .sortable-header__label {
    padding: 3px 0;
  }
  
  .cell-content--non-sortable {
    align: inherit;
  }
`

const StyledTableSortLabel = styled(TableSortLabel)`

  .MuiTableSortLabel-icon {
    color: ${props => props.theme.palette.secondary.main} !important;
  }
`

const useCellStyles = makeStyles(muiTheme => ({
  root: {
    padding: '0 6px 3px',
    '&:last-child': {
      padding: '0 6px 3px'
    },
    '& .MuiTableSortLabel-icon': {
      margin: 0,
      padding: '3px 0'
    }
  }
}))
