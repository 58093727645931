import { object, string } from 'yup'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dateIsBeforeOrSame } from 'utils/date'
import { validateField, validateValues } from 'utils/form'

// Import orders form validator

export const importOrdersForm = object().shape({
  dateFrom: string()
    .required(() => ({ key: 'form.dateFrom.errors.required' }))
    .when('dateTo', (dateTo, schema) => {
      return schema.test(
        'validate-date-to',
        () => ({ key: 'form.dateFrom.errors.afterDateTo' }),
        dateFrom => {
          return isNotNilOrEmpty(dateTo) ? dateIsBeforeOrSame(dateFrom, dateTo) : true
        }
      )
    }),
  dateTo: string()
    .required(() => ({ key: 'form.dateTo.errors.required' }))
    .when('dateFrom', (dateFrom, schema) => {
      return schema.test(
        'validate-date-from',
        () => ({ key: 'form.dateTo.errors.beforeDateFrom' }),
        dateTo => {
          return isNotNilOrEmpty(dateFrom) ? dateIsBeforeOrSame(dateFrom, dateTo) : true
        }
      )
    })
}, ['dateFrom', 'dateTo'])

export const validateImportOrdersSchemaField = values => validateField(importOrdersForm, values)
export const validateImportOrdersSchemaValues = validateValues(importOrdersForm)
