import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { formatISODate, nDaysAgo, today, resetTime } from 'utils/date'
import { useTranslation } from 'react-i18next'
import Select from 'components/atoms/SingleSelect'
import { allPass, find, pipe, propEq, propOr, values as getValues } from 'ramda'

export const SelectChartDateRange = (props) => {
  const { t } = useTranslation()
  const { onSelectRange, values } = props
  const [value, setValue] = useState('30days')

  const isDateToToday = () => {
    const dateTo = formatISODate(resetTime(values.dateTo))
    const todayISO = formatISODate(resetTime(today()))

    return dateTo === todayISO
  }

  const getSelectedRangeValue = () => {
    const dateFrom = formatISODate(resetTime(values.dateFrom))
    return pipe(
      getValues,
      find(
        allPass([
          propEq('dateFrom', dateFrom),
          () => isDateToToday()
        ])
      ),
      propOr(null, 'value')
    )(valuesMap)
  }

  useEffect(() => {
    const selectedValue = getSelectedRangeValue()
    setValue(selectedValue)
  }, [values])

  const valuesMap = {
    '7days': {
      value: '7days',
      dateFrom: formatISODate(nDaysAgo(7)),
      dateTo: formatISODate(today())
    },
    '14days': {
      value: '14days',
      dateFrom: formatISODate(nDaysAgo(14)),
      dateTo: formatISODate(today())
    },
    '30days': {
      value: '30days',
      dateFrom: formatISODate(nDaysAgo(30)),
      dateTo: formatISODate(today())
    }
  }

  const options = () => [
    {
      label: t('productPage.charts.dateRanges.7days'),
      value: '7days'
    },
    {
      label: t('productPage.charts.dateRanges.14days'),
      value: '14days'
    },
    {
      label: t('productPage.charts.dateRanges.30days'),
      value: '30days'
    }
  ]

  const handleChange = e => {
    const { dateFrom, dateTo } = valuesMap[e.target.value]
    onSelectRange({ dateFrom, dateTo })
  }

  return (
    <SelectRangeContainer>
      <Select
        name='range'
        options={options()}
        label={t('productPage.charts.range')}
        value={value}
        onChange={handleChange}
      />
    </SelectRangeContainer>
  )
}

export default SelectChartDateRange

const SelectRangeContainer = styled.div`
  min-width: 100px;
`
