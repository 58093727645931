import { includes, mergeDeepRight } from 'ramda'
import axios from 'axios'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import errors, { getErrorName } from 'utils/errors'

const apiUrl = process.env.REACT_APP_API_URL

export const mergeConfig = config => {
  const authToken = LocalStorageService.get(LOCAL_STORAGE_KEYS.token)
  const authHeader = authToken ? { Authorization: authToken } : {}

  const defaultConfig = {
    headers: {
      Pragma: 'no-cache',
      ...authHeader
    }
  }

  return mergeDeepRight(defaultConfig)(config)
}

const get = instance => async (url, config = {}) => {
  return instance.get(url, mergeConfig(config))
}

const post = instance => async (url, data = {}, config = {}) => {
  return instance.post(url, data, mergeConfig(config))
}

const put = instance => async (url, data = {}, config = {}) => {
  return instance.put(url, data, mergeConfig(config))
}

const patch = instance => async (url, data = {}, config = {}) => {
  return instance.patch(url, data, mergeConfig(config))
}

const handleDelete = instance => async (url, config = {}) => {
  return instance.delete(url, mergeConfig(config))
}

export default (baseURL = apiUrl) => {
  const instance = axios.create({ baseURL })
  instance.interceptors.response.use(
    successRes => successRes,
    error => {
      if (getErrorName(error) === errors.accountInactive || getErrorName(error) === errors.tokenExpired) {
        eventEmitter.emit(events.logoutUser)
      }

      if (getErrorName(error) === errors.entityNotFound && includes('/products/', error.request.responseURL)) {
        eventEmitter.emit(events.notFound)
      }

      return Promise.reject(error)
    }
  )

  return {
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  }
}
