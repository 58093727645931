import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Order = Record({
  status: '',
  price: 0,
  quantity: 0,
  created_at: '',
  iai_order_id: '',
  sold_in_bundle: false,
  shipped_at: ''
})

export const OrdersMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Orders = Record({
  state: API_STATES.PRISTINE,
  ordersMeta: new OrdersMeta(),
  orders: []
})
