import APIService from 'services/APIService'

const api = APIService()

export const generateFileSignature = file => {
  const formData = new window.FormData()
  formData.append('purchasePrices', file)
  return api.post('/generate/signature', formData)
}
export const uploadSalePlanCSV = ({ file, signature }) => {
  const formData = new window.FormData()
  formData.append('purchasePrices', file)
  formData.append('signature', signature)
  return api.post('/upload-plan-csv', formData)
}

export const uploadDaysOffCSV = ({ file, signature }) => {
  const formData = new window.FormData()
  formData.append('daysOff', file)
  formData.append('signature', signature)
  return api.post('/days-off/import', formData)
}
