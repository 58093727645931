import React from 'react'
import CreateCommentForm from 'features/comments/CreateCommentForm'
import ProductCommentsList from 'features/comments/CommentsList'

export const CommentsSection = (props) => {
  return (
    <div>
      <ProductCommentsList />
      <CreateCommentForm />
    </div>
  )
}

export default CommentsSection
