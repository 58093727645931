import React from 'react'
import { useTranslation } from 'react-i18next'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { API_FIELD_NAME } from 'utils/apiFilterFields'

import IconButton from 'components/atoms/IconButton'
import MaxPrice from 'components/icons/MaxPrice'
import Tooltip from 'components/atoms/Tooltip'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const MaxPriceFilter = (props) => {
  const { t } = useTranslation()
  const urlSearchQuery = useUrlSearchQuery()

  const urlQuery = propOr('', API_FIELD_NAME.maxPrice)(urlSearchQuery.getSearchQuery())
  const isSelected = isNotNilOrEmpty(urlQuery)

  const handleSelect = () => {
    if (isSelected) {
      urlSearchQuery.removeSearchQuery(API_FIELD_NAME.maxPrice)
    } else {
      urlSearchQuery.setSearchQuery({
        [API_FIELD_NAME.maxPrice]: true
      })
    }
  }

  return (
    <Tooltip title={t('monitoringPage.table.headers.maxPrice')}>
      <IconButton id='max-price-filter' onClick={handleSelect} color={isSelected ? 'primary' : 'default'}>
        <MaxPrice />
      </IconButton>
    </Tooltip>
  )
}

export default MaxPriceFilter
