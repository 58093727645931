import { length, lt, propOr, pipe, prop, equals, or, any, not } from 'ramda'
import { createSelector } from 'reselect'
import { isNilOrEmpty } from 'utils/ramda'

export const selectGlobal = state => state.global

export const getSelectedTheme = createSelector(
  selectGlobal,
  propOr('light', 'theme')
)

export const getSelectedItems = createSelector(
  selectGlobal,
  propOr([], ['selectedItems'])
)

export const getSelectedItemsData = createSelector(
  selectGlobal,
  propOr([], ['selectedItemsData'])
)

export const getHasSomeItemsSelectedGlobally = createSelector(
  selectGlobal,
  pipe(
    propOr([], ['selectedItems']),
    length,
    lt(0)
  )
)

export const getHasAllSelectedItemsActualShippingQty = createSelector(
  selectGlobal,
  pipe(
    propOr([], ['selectedItemsData']),
    any(
      pipe(
        prop('actualShippingQty'),
        value => or(isNilOrEmpty(value), equals(0, value))
      )
    ),
    not
  )
)
