import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const AlertItem = Record({
  id: '',
  user_id: '',
  product_id: '',
  type: '',
  line_name: '',
  created_at: '',
  seen_at: null,
  is_archived: false,
  archived_at: '',
  archived_by_user: '',
  content: {},
  ean: '',
  name: '',
  price_up_task_enabled: false,
  login: ''
})

export const AlertsMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Alerts = Record({
  state: API_STATES.PRISTINE,
  collection: [],
  alertsMeta: new AlertsMeta(),
  summary: {},
  unarchivedCount: 0
})
