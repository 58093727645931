import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { getSelectedItems } from 'ducks/global/selectors'

import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import LinesTable from 'features/lines/LinesTable'
import AssignUserToLinesForm from 'features/lines/AssignUserToLinesForm'
import AddLineModal from 'features/lines/components/AddLineModal'
import PageColumnContainer from 'components/PageColumnContainer'
import Container from 'components/Container'
import Paper from 'components/atoms/Paper'

export const LinesPage = (props) => {
  const selectedItems = useSelector(getSelectedItems)

  return (
    <Container>
      <PageColumnContainer>
        {
          selectedItems.length > 0
            ? <AssignUserToLinesForm />
            : (
              <StyledPaper>
                <AddLineModal />
              </StyledPaper>
            )
        }
        <TablePaperContainer>
          <LinesTable />
        </TablePaperContainer>
      </PageColumnContainer>
    </Container>
  )
}

export default LinesPage

const StyledPaper = styled(Paper)`
  margin-bottom: 20px;
  text-align: right;
`
