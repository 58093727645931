import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as deliveriesService from 'services/DeliveriesService'
import { propOr } from 'ramda'

// ROUTINES

export const fetchProductDeliveriesRoutine = createRoutine('FETCH_PRODUCT_DELIVERIES')

// ACTIONS

function * fetchProductDeliveries ({ payload }) {
  yield put(fetchProductDeliveriesRoutine.request())
  try {
    const result = yield call(deliveriesService.fetchProductDeliveries, payload)
    yield put(fetchProductDeliveriesRoutine.success({ productId: payload.productId, result: propOr([], 'data', result) }))
  } catch (e) {
    console.error(e)
    yield put(fetchProductDeliveriesRoutine.failure(e))
  }
}

// WATCHERS

export function * fetchProductDeliveriesWatcher () {
  yield takeLatest(fetchProductDeliveriesRoutine.TRIGGER, fetchProductDeliveries)
}

// SAGAS

export const deliveriesSagas = [
  fork(fetchProductDeliveriesWatcher)
]
