import { propOr, pipe, equals, map, applySpec, prop } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES, transformById } from 'utils/redux'

export const selectTags = state => state.tags

export const getIsTagsLoading = createSelector(
  selectTags,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getTagsMeta = createSelector(
  selectTags,
  propOr({}, 'tagsMeta')
)

export const getTagsArray = createSelector(
  selectTags,
  propOr([], 'tags')
)

export const getTags = createSelector(
  selectTags,
  pipe(
    propOr([], 'tags'),
    transformById
  )
)

export const getTagsAsOptions = createSelector(
  selectTags,
  pipe(
    propOr([], 'tags'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('id')
      })
    )
  )
)

export const getTagNamesAsOptions = createSelector(
  selectTags,
  pipe(
    propOr([], 'tags'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('name')
      })
    )
  )
)
