import React from 'react'
import { useTranslation } from 'react-i18next'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { API_FIELD_NAME } from 'utils/apiFilterFields'

import IconButton from 'components/atoms/IconButton'
import MinMargin from 'components/icons/MinMargin'
import Tooltip from 'components/atoms/Tooltip'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const MinMarginFilter = (props) => {
  const { t } = useTranslation()
  const urlSearchQuery = useUrlSearchQuery()

  const urlQuery = propOr('', API_FIELD_NAME.minMargin)(urlSearchQuery.getSearchQuery())
  const isSelected = isNotNilOrEmpty(urlQuery)

  const handleSelect = () => {
    if (isSelected) {
      urlSearchQuery.removeSearchQuery(API_FIELD_NAME.minMargin)
    } else {
      urlSearchQuery.setSearchQuery({
        [API_FIELD_NAME.minMargin]: true
      })
    }
  }

  return (
    <Tooltip title={t('monitoringPage.table.headers.minMargin')}>
      <IconButton id='min-margin-filter' onClick={handleSelect} color={isSelected ? 'primary' : 'default'}>
        <MinMargin />
      </IconButton>
    </Tooltip>
  )
}

export default MinMarginFilter
