import { useHistory } from 'react-router-dom'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { pipe, pick, mergeRight, equals, propOr } from 'ramda'
import qs from 'qs'
import { useSelector } from 'react-redux'
import { getUserLogin, getUserRole } from 'features/auth/ducks/selectors'
import { USER_ROLES } from 'utils/user'
import { defaultAdminFiltersByPath, defaultUserFiltersByPath } from 'utils/filters'

export default () => {
  const history = useHistory()
  const urlSearchQuery = useUrlSearchQuery()
  const userLogin = useSelector(getUserLogin)
  const userRole = useSelector(getUserRole)

  const defaultFilters = equals(userRole, USER_ROLES.admin) ? propOr({}, history.location.pathname, defaultAdminFiltersByPath(userLogin)) : propOr({}, history.location.pathname, defaultUserFiltersByPath(userLogin))

  const queryAfterResetFilters = () =>
    pipe(
      pick(['rowsPerPage', 'page', 'sortBy', 'dir', 'search']),
      mergeRight(defaultFilters),
      qs.stringify
    )(urlSearchQuery.getSearchQuery())

  return () => history.push({ pathname: history.location.pathname, search: queryAfterResetFilters() })
}
