import { propOr, pipe, equals } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'

export const selectSearchQueries = state => state.searchQueries

export const getIsSearchQueriesLoading = createSelector(
  selectSearchQueries,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getSearchQueriesArray = createSelector(
  selectSearchQueries,
  propOr([], 'searchQueries')
)
