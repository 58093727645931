import React from 'react'
import Icon from 'components/Icon'

export const AllegroPackagePromo = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 340.000000 321.000000'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g transform='translate(0.000000,321.000000) scale(0.100000,-0.100000)' stroke='none'>
        <path
          d='M542 3203 c-12 -5 -270 -316 -450 -545 l-92 -116 0 -1025 c0 -919 2
          -1027 16 -1041 14 -14 109 -16 897 -16 485 0 892 3 905 6 20 6 22 12 22 83 0
          46 -5 84 -12 93 -12 15 -93 17 -820 24 l-808 7 0 833 0 834 389 0 389 0 7
          -205 7 -205 438 0 438 0 5 23 c3 12 9 104 13 205 l7 182 438 0 439 0 0 -443
          c0 -331 3 -446 12 -455 7 -7 42 -12 89 -12 71 0 77 2 83 23 3 12 6 259 6 549
          l0 527 -108 128 c-59 70 -130 157 -157 193 -27 36 -96 120 -152 188 -56 67
          -110 133 -119 147 l-16 25 -927 -1 c-509 0 -932 -3 -939 -6z m1764 -225 c42
          -45 98 -110 199 -234 33 -40 84 -100 113 -132 41 -48 49 -61 36 -66 -8 -3
          -193 -6 -410 -6 -307 0 -396 3 -399 13 -5 13 -43 305 -52 399 l-6 57 44 4 c24
          2 132 4 239 5 l196 2 40 -42z m-1196 -13 c0 -56 -19 -144 -36 -169 -7 -10 -21
          -63 -30 -117 -10 -54 -21 -104 -25 -111 -6 -10 -88 -14 -364 -17 -195 -1 -355
          0 -355 4 0 6 205 272 317 413 l34 42 230 0 229 0 0 -45z m493 -15 c4 -30 18
          -132 30 -227 l23 -172 -51 -6 c-62 -8 -371 -3 -380 6 -6 7 70 433 81 450 3 6
          66 8 147 7 l142 -3 8 -55z m77 -715 l0 -105 -245 0 -245 0 0 105 0 105 245 0
          245 0 0 -105z'
        />
        <path
          d='M2556 1515 c-9 -9 -59 -102 -112 -208 -53 -106 -107 -208 -120 -228
          -26 -42 -18 -40 -329 -84 -215 -31 -222 -32 -230 -52 -8 -23 19 -59 104 -138
          42 -38 120 -113 173 -165 l98 -95 -24 -135 c-44 -250 -58 -345 -55 -375 7 -56
          39 -47 269 75 115 62 218 114 228 117 12 3 110 -41 250 -111 128 -64 241 -116
          252 -116 42 0 39 36 -40 508 l-7 43 105 97 c317 292 323 302 232 321 -27 6
          -105 18 -172 26 -214 27 -309 43 -325 57 -9 7 -65 111 -124 231 -59 120 -113
          225 -120 233 -15 18 -34 18 -53 -1z m143 -560 c10 -22 25 -46 34 -54 10 -8 84
          -22 175 -34 86 -11 159 -22 161 -23 2 -2 -50 -54 -115 -117 l-118 -113 12 -85
          c26 -183 33 -242 27 -247 -3 -4 -66 24 -140 61 -73 37 -144 67 -158 67 -13 0
          -89 -32 -167 -70 l-143 -71 7 43 c11 69 26 178 33 240 l6 56 -53 56 c-29 30
          -83 81 -121 112 -38 32 -67 59 -66 61 2 2 66 10 143 19 213 25 196 13 284 189
          l75 150 53 -100 c29 -55 61 -118 71 -140z'
        />
      </g>
    </Icon>
  )
}

export default AllegroPackagePromo
