import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateUpdateUserField,
  validateUpdateUserValues
} from 'features/users/ducks/schema'

import { updateUserRoutine } from 'features/users/ducks/actions'
import { getIsUsersLoading } from 'features/users/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'
import SingleSelect from 'components/formElements/SingleSelectField'
import { userRoleOptions } from 'utils/user'

const emptyValues = {
  email: '',
  login: '',
  role: '',
  id: ''
}

export const UpdateUserForm = (props) => {
  const { onSubmit, data } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsUsersLoading)

  const updateUser = useCallback(
    payload => dispatch(updateUserRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateUpdateUserValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      id: data.id,
      email: data.email,
      login: data.login,
      role: data.role
    })
  }, [data])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    updateUser({
      values,
      callback: onSubmit
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        value={values.email}
        required
        name='email'
        label={t('form.email.label')}
        onChange={handleOnChange}
        validate={validateUpdateUserField(values)}
      />
      <TextField
        value={values.login}
        required
        name='login'
        label={t('form.login.label')}
        onChange={handleOnChange}
        validate={validateUpdateUserField(values)}
      />
      <SingleSelect
        value={values.role}
        options={userRoleOptions()}
        required
        name='role'
        label={t('form.role.label')}
        onChange={handleOnChange}
        validate={validateUpdateUserField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('usersPage.updateUserCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

UpdateUserForm.defaultProps = {
  onSubmit: () => {}
}

export default UpdateUserForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
