import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'

import { getProductDetails } from 'features/products/ducks/selectors'

export const ProductImage = props => {
  const { match: { params: { id } } } = props
  const data = useSelector(state => getProductDetails(state, id))

  return (
    <ImageContainer imageSrc={propOr('', 'photoUrl')(data)}>
      <img src={propOr('', 'photoUrl')(data)} alt='product-image' className='image-placeholder' />
    </ImageContainer>
  )
}

export default withRouter(ProductImage)

export const ImageContainer = styled.div`
  margin: 0 auto;
  background-image: url(${props => props.imageSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background-color: ${props => props.theme.palette.constants.white};
  color: ${props => props.theme.palette.secondary.main};
  border-radius: 4px;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: ${props => props.theme.shadows.main};
  
  .image-placeholder {
    width: 100%;
    visibility: hidden;
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`
