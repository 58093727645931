import React from 'react'
import Icon from 'components/Icon'

export const AutoDecision = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <g>
          <path d='m266.365 330h-90v-40.548c-4.955-2.372-9.715-5.136-14.259-8.279l-35.155 20.298-45-77.943 34.813-20.1c-.531-5.753-.535-11.06 0-16.858l-34.813-20.099 45-77.943 35.155 20.298c4.544-3.143 9.305-5.908 14.259-8.28v-40.546h90v40.546c4.954 2.372 9.715 5.137 14.259 8.28l35.155-20.298 45 77.943-34.813 20.099c.532 5.764.534 11.072 0 16.858l34.813 20.1-45 77.943-35.155-20.298c-4.543 3.143-9.306 5.908-14.259 8.28zm-60-30h30v-31.105l9.963-3.552c8.512-3.035 16.379-7.604 23.384-13.58l8.061-6.878 27.026 15.605 15-25.98-26.764-15.452 1.928-10.421c1.867-10.091 1.87-17.167 0-27.274l-1.928-10.421 26.764-15.452-15-25.98-27.026 15.605-8.061-6.878c-7.004-5.976-14.872-10.545-23.384-13.58l-9.963-3.552v-31.105h-30v31.105l-9.963 3.552c-8.512 3.034-16.379 7.603-23.384 13.58l-8.061 6.878-27.026-15.605-15 25.98 26.764 15.452-1.928 10.421c-1.867 10.091-1.87 17.167 0 27.274l1.928 10.421-26.764 15.452 15 25.98 27.026-15.605 8.061 6.878c7.002 5.974 14.869 10.543 23.383 13.578l9.963 3.552v31.107z' />
        </g>
        <g>
          <path d='m221.365 240c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45zm0-60c-8.271 0-15 6.729-15 15s6.729 15 15 15 15-6.729 15-15-6.729-15-15-15z' />
        </g>
        <g>
          <path d='m296.365 512h-210v-176.569c-38.214-36.744-60-87.541-60-140.431 0-107.523 87.477-195 195-195 106.316 0 193.033 85.531 194.967 191.393l69.303 138.607h-69.271v120h-120v62zm-180-30h150v-62h120v-120h50.729l-50.729-101.459v-3.541c0-90.981-74.019-165-165-165s-165 74.019-165 165c0 46.638 20.052 91.371 55.015 122.727l4.985 4.471z' />
        </g>
      </g>
    </Icon>
  )
}

export default AutoDecision
