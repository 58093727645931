import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatDate, FORMATS, isTodayOrPast } from 'utils/date'

import { fetchSalePlanRoutine } from 'features/salePlans/ducks/actions'
import { getProductSalePlan } from 'features/salePlans/ducks/selectors'

import CreateSalePlanModal from 'features/salePlans/components/CreateSalePlanModal'
import DeleteSalePlan from 'features/salePlans/DeleteSalePlan'
import { isNotNilOrEmpty } from 'utils/ramda'

export const CurrentSalePlan = (props) => {
  const { match: { params: { id } } } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const salePlan = useSelector(state => getProductSalePlan(state, id))
  const hasCurrentPlanValid = isNotNilOrEmpty(salePlan) && isTodayOrPast(salePlan.expires_at) === false

  const fetchProductSalePlan = useCallback(
    () => dispatch(fetchSalePlanRoutine({ productId: id })),
    [dispatch]
  )

  useEffect(() => {
    fetchProductSalePlan()
  }, [])

  const salePlanDetails = (
    <div className='current-sale-plan__description'>
      <div className='current-sale-plan__description-item'>
        <span className='current-sale-plan__description-item-label'>{t('productPage.salePlan.startsAt')}</span>
        <span>{formatDate(salePlan.starts_at, FORMATS.slash)}</span>
      </div>
      <div className='current-sale-plan__description-item'>
        <span className='current-sale-plan__description-item-label'>{t('productPage.salePlan.expiresAt')}</span>
        <span>{formatDate(salePlan.expires_at, FORMATS.slash)}</span>
      </div>
      <div className='current-sale-plan__description-item'>
        <span className='current-sale-plan__description-item-label'>{t('productPage.salePlan.stockAfter')}</span>
        <span>{salePlan.stock_after}</span>
      </div>
    </div>
  )

  return (
    <CurrentSalePlanContainer>
      <div className='current-sale-plan__title'>
        {t('productPage.salePlan.title')}:
      </div>
      {hasCurrentPlanValid && salePlanDetails}
      <div>
        <CreateSalePlanModal />
      </div>
      <div>
        {hasCurrentPlanValid && <DeleteSalePlan />}
      </div>
    </CurrentSalePlanContainer>
  )
}

export default withRouter(CurrentSalePlan)

export const CurrentSalePlanContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
  }

  .current-sale-plan__title {
    display: inline-block;
    font-weight: 500;
    font-size: 17px;
    margin-right: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  .current-sale-plan__description {
    display: inline-flex;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .current-sale-plan__description-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  .current-sale-plan__description-item-label {
    font-size: 10px;
    color: ${props => props.theme.palette.common.gray500};
    margin-bottom: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      text-align: center;
    }
  }
`
