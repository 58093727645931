import React from 'react'
import Icon from 'components/Icon'

export const DeliveryTime = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <g>
          <path d='M405.787,333.617c-36.042,0-65.362,29.32-65.362,65.362c0,36.042,29.32,65.362,65.362,65.362s65.362-29.32,65.362-65.362
        C471.149,362.937,441.829,333.617,405.787,333.617z M405.787,431.66c-18.023,0-32.681-14.657-32.681-32.681
        c0-18.023,14.657-32.681,32.681-32.681s32.681,14.657,32.681,32.681C438.468,417.002,423.811,431.66,405.787,431.66z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M111.66,333.617c-36.042,0-65.362,29.32-65.362,65.362c0,36.042,29.32,65.362,65.362,65.362
        c36.042,0,65.362-29.32,65.362-65.362C177.021,362.937,147.701,333.617,111.66,333.617z M111.66,431.66
        c-18.023,0-32.681-14.657-32.681-32.681c0-18.023,14.657-32.681,32.681-32.681s32.681,14.657,32.681,32.681
        C144.34,417.002,129.683,431.66,111.66,431.66z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M509.189,252.558l-46.843-69.174c-3.039-4.488-8.11-7.179-13.53-7.179H319.728v32.681h120.423l39.168,57.845v115.908
        h-24.511v32.681h40.851c9.025,0,16.34-7.315,16.34-16.34v-137.26C512,258.451,511.02,255.259,509.189,252.558z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='160.681' y='382.638' width='194.451' height='32.681' />
        </g>
      </g>
      <g>
        <g>
          <path d='M319.728,130.451h-53.379v32.681h37.038v235.847h32.681V146.791C336.068,137.766,328.753,130.451,319.728,130.451z' />
        </g>
      </g>
      <g>
        <g>
          <path d='M73.532,163.132v-32.681H16.34c-9.025,0-16.34,7.315-16.34,16.34v252.187c0,9.025,7.315,16.34,16.34,16.34h46.298v-32.681
        H32.681V163.132H73.532z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M459.264,269.731l-23.961-37.038c-3.012-4.657-8.176-7.468-13.72-7.468h-49.021c-9.025,0-16.34,7.315-16.34,16.34v62.638
        c0,9.025,7.315,16.34,16.34,16.34h72.987c9.025,0,16.34-7.315,16.335-16.34V278.61
        C461.884,275.461,460.974,272.373,459.264,269.731z M429.209,287.864h-40.306v-29.952h23.797l16.509,25.524V287.864z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M169.94,47.66c-62.469,0-113.294,50.824-113.294,113.294s50.824,113.294,113.294,113.294s113.294-50.824,113.294-113.294
        S232.41,47.66,169.94,47.66z M169.94,241.566c-44.451,0-80.613-36.161-80.613-80.613S125.489,80.34,169.94,80.34
        s80.613,36.161,80.613,80.613S214.392,241.566,169.94,241.566z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M202.191,170.098l-15.91-15.916v-35.17c0-9.025-7.315-16.34-16.34-16.34s-16.34,7.315-16.34,16.34v41.94
        c0,4.33,1.716,8.486,4.782,11.553l20.698,20.698c3.192,3.192,7.375,4.788,11.558,4.788s8.366-1.596,11.553-4.782
        C208.569,186.826,208.569,176.477,202.191,170.098z'
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </Icon>
  )
}

export default DeliveryTime
