import { generateSelectedTheme } from 'utils/theme'

const inputHeight = '29.6px'

export const REACT_SELECT_STYLES = (theme = generateSelectedTheme()) => ({
  control: (provided, state) => {
    const { selectProps: { error }, isDisabled } = state

    return {
      margin: '0',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      position: 'relative',
      borderWidth: '0 0 1px 0',
      borderStyle: 'solid',
      fontSize: '13px',
      padding: '0 14px',
      borderColor: error ? theme.palette.error.main : theme.palette.common.gray500,
      fontFamily: theme.typography.fontFamily,
      minHeight: inputHeight,
      color: theme.palette.text.main,
      backgroundColor: isDisabled ? theme.palette.common.gray100 : 'transparent',
      transition: 'all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      '&:hover': {
        cursor: 'text',
        borderColor: error ? theme.palette.error.main : theme.palette.text.main
      },
      '&:focus-within': {
        borderWidth: '0 0 2px 0',
        borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
        color: error ? theme.palette.error.main : theme.palette.text.main
      }
    }
  },
  input: (provided, state) => {
    return {
      fontSize: '13px',
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.text.main,
      padding: '0',
      margin: '0',
      outline: 'none',
      width: 'auto'
    }
  },
  container: (provided, state) => {
    return {
      boxSizing: 'border-box',
      position: 'relative',
      width: '100%'
    }
  },
  menu: (provided, state) => {
    return {
      position: 'absolute',
      width: '100%',
      top: '100%',
      left: '0',
      transformOrigin: '0px 24.5px',
      transform: 'none',
      margin: '8px 0',
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows.main,
      zIndex: theme.zIndex.dropdown
    }
  },
  menuList: (provided, state) => {
    return {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      maxHeight: '200px',
      overflowY: 'auto',
      boxSizing: 'border-box',
      zIndex: theme.zIndex.dropdown
    }
  },
  option: (provided, state) => {
    const { isFocused } = state
    return {
      color: theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
      zIndex: theme.zIndex.dropdown,
      cursor: 'pointer',
      fontSize: '13px',
      padding: '6px 16px',
      lineHeight: '1.5',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      backgroundColor: isFocused
        ? theme.palette.common.gray200
        : theme.palette.common.white,
      transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: theme.palette.common.gray200
      },
      '&:active': {
        backgroundColor: theme.palette.common.gray200
      },
      '&:focus': {
        backgroundColor: theme.palette.common.gray300
      }
    }
  },
  valueContainer: (provided, state) => {
    return {
      boxSizing: 'border-box',
      padding: '3px 0',
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexGrow: '1'
    }
  },
  singleValue: (provided, state) => {
    return {
      wordBreak: 'keep-all'
    }
  },
  multiValue: (provided, state) => {
    return {
      margin: '3px 0',
      display: 'flex',
      alignItems: 'center',
      padding: '6px',
      backgroundColor: theme.palette.common.gray100,
      borderRadius: theme.shape.borderRadius,
      marginRight: '9px',
      wordBreak: 'keep-all'
    }
  },
  multiValueLabel: (provided, state) => {
    return {
      whiteSpace: 'nowrap'
    }
  },
  multiValueRemove: (provided, state) => {
    return {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '6px',
      cursor: 'pointer',
      transition: 'all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  },
  noOptionsMessage: (provided, state) => {
    return {
      color: theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
      zIndex: theme.zIndex.dropdown,
      cursor: 'pointer',
      fontSize: '13px',
      padding: '6px 16px',
      lineHeight: '1.5',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.common.white
    }
  },
  loadingMessage: (provided, state) => {
    return {
      color: theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
      zIndex: theme.zIndex.dropdown,
      cursor: 'pointer',
      fontSize: '13px',
      padding: '6px 16px',
      lineHeight: '1.5',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.common.white
    }
  },
  placeholder: (provided, state) => {
    return { display: 'none' }
  },
  indicatorSeparator: (provided, state) => {
    return { display: 'none' }
  },
  dropdownIndicator: (provided, state) => {
    return { display: 'none' }
  },
  indicatorsContainer: (provided, state) => {},
  clearIndicator: (provided, state) => {
    return {
      color: theme.palette.common.gray500,
      cursor: 'pointer'
    }
  },
  loadingIndicator: (provided, state) => {
    return {
      color: theme.palette.common.gray500,
      display: 'flex',
      alignItems: 'center',
      fontSize: '4px'
    }
  },
  menuPortal: (provided, state) => {},
  group: (provided, state) => {},
  groupHeading: (provided, state) => {}
})
