import errors, { getErrorName } from 'utils/errors'
import { SEVERITY } from 'utils/toast'

export const getCreateUserError = error => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.emailExists:
      errorTranslationKey = 'toast.emailTaken'
      break
    case errors.loginExists:
      errorTranslationKey = 'toast.loginTaken'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}
