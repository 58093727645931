import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import UpdateAuctionPriceForm from 'features/productAuctions/UpdateAuctionPriceForm'
import Modal from 'components/atoms/Modal'
import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'

export const UpdateAuctionPriceModal = (props) => {
  const { data, bundleProductId, match: { params: { id } } } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal
        title={t('productPage.saleChannels.auctions.changePrice.title')}
        open={open}
        handleClose={handleClose}
      >
        <UpdateAuctionPriceForm id={bundleProductId || id} data={data} onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default withRouter(UpdateAuctionPriceModal)
