import { Users, User, UsersMeta } from 'features/users/ducks/records'
import {
  createUserRoutine,
  fetchUsersRoutine,
  updateUserRoutine
} from 'features/users/ducks/actions'
import { API_STATES, transferEntityToImmutableById, transferCollectionToImmutableById } from 'utils/redux'
import { mergeRight } from 'ramda'

export default (state = new Users(), action) => {
  switch (action.type) {
    case createUserRoutine.REQUEST:
    case fetchUsersRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case createUserRoutine.FAILURE:
    case fetchUsersRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchUsersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('users', transferCollectionToImmutableById(action.payload.data, User))
        .set('usersMeta', new UsersMeta(action.payload.meta))
    case createUserRoutine.SUCCESS:
    case updateUserRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('users',
          mergeRight(
            state.users,
            transferEntityToImmutableById(action.payload, User)
          )
        )
    default:
      return state
  }
}
