import React, { useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

export const ReCaptcha = (props) => {
  const { name, onChange, failedAttempts } = props
  const captchaRef = useRef(null)
  const shouldRender = failedAttempts > 5

  useEffect(() => {
    if (shouldRender && failedAttempts % 5 === 0) {
      captchaRef.current.reset()
    }
  }, [failedAttempts])

  const handleChange = value => onChange(name, value)
  const handleError = () => onChange(name, null)

  return shouldRender ? (
    <ReCAPTCHA
      ref={captchaRef}
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      onChange={handleChange}
      onErrored={handleError}
    />
  ) : ''
}

export default ReCaptcha
