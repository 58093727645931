import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import theme from 'theme/theme'
import paletteLight from 'theme/paletteLight'
import paletteDark from 'theme/paletteDark'

export const THEME_MODES = {
  light: 'light',
  dark: 'dark'
}

export const generateSelectedTheme = (themeMode) => {
  const selectedThemeMode = themeMode || LocalStorageService.get(LOCAL_STORAGE_KEYS.selectedTheme)

  const themeLight = {
    ...theme,
    ...paletteLight
  }

  const themeDark = {
    ...theme,
    ...paletteDark
  }

  return selectedThemeMode === THEME_MODES.light ? themeLight : themeDark
}
