import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import { importProductsFromIAI } from 'services/IAIService'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

export const ImportProductsButton = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = e => {
    e.preventDefault()
    importProductsFromIAI()
    showToast({
      key: 'toast.importProductsSuccess',
      severity: SEVERITY.success
    })
  }
  return (
    <ButtonContainer>
      <Button
        type='submit'
        id='button-submit'
        onClick={handleSubmit}
      >
        {t('settingsPage.importProducts.CTA')}
      </Button>
    </ButtonContainer>
  )
}

export default ImportProductsButton

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
