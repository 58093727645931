import React from 'react'
import styled from 'styled-components'

import Paper from 'components/atoms/Paper'

export const TablePaperContainer = (props) => {
  return (
    <TablePaperContainerWrapper>
      <StyledPaper>
        {props.children}
      </StyledPaper>
    </TablePaperContainerWrapper>
  )
}

export default TablePaperContainer

const TablePaperContainerWrapper = styled.div`
    flex-grow: 1;
    min-height: 0;
`

const StyledPaper = styled(Paper)`
  height: 100%;
`
