import i18n from 'providers/i18n'

export const SHIPPING_STATUS = {
  confirmed: 'confirmed',
  shipped: 'shipped'
}

export const shippingStatusOptions = () => ([
  {
    label: i18n.t('ordersPage.shippingOrderStatus.confirmed'),
    value: SHIPPING_STATUS.confirmed
  },
  {
    label: i18n.t('ordersPage.shippingOrderStatus.shipped'),
    value: SHIPPING_STATUS.shipped
  }
])

export const SHIPPING_CART_STATUS = {
  inCart: 'in_cart'
}
