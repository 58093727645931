import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'

export const Button = (props) => {
  const classes = useButtonStyles()

  return (
    <MuiButton {...props} className={classes.root}>
      {props.children}
    </MuiButton>
  )
}

Button.defaultProps = {
  disabled: false,
  type: 'button',
  size: 'large',
  variant: 'contained',
  color: 'primary'
}

export default Button

const useButtonStyles = makeStyles(muiTheme => ({
  root: {
    textTransform: 'inherit',
    fontWeight: '400'
  }
}))
