import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import SaveSettingsForm from 'features/settings/SaveSettingsForm'
import Paper from 'components/atoms/Paper'
import FormTitle from 'components/atoms/FormTitle'
import UploadSalePlansCSV from 'features/salePlans/UploadSalePlansCSV'
import UploadDaysOffCSV from 'features/daysOff/UploadDaysOffCSV'
import CurrentDaysOff from 'features/daysOff/CurrentDaysOff'
import Grid from 'components/atoms/Grid'
import CreateWeightSchemaModal from 'features/weightSchemas/components/CreateWeightSchemaModal'
import WeightSchemasList from 'features/weightSchemas/WeightSchemasList'
import AutoDecisionForm from 'features/flags/AutoDecisionForm'
import ImportOrdersFromIaiForm from 'features/integrations/ImportOrdersFromIaiForm'
import RecalculateSalePlanForm from 'features/settings/RecalculateSalePlanForm'
import DeliveredProductsSyncIntervalForm from 'features/settings/DeliveredProductsSyncIntervalForm'
import ImportProductsButton from 'features/settings/ImportProductsButton'
import UpdateSoldNewButton from 'features/settings/UpdateSoldNewButton'
import PriceDownSettings from 'features/settings/PriceDownSettings'
import AutoDecisionPriceDownForm from 'features/flags/AutoDecisionPriceDownForm'

export const SettingsPage = (props) => {
  const { t } = useTranslation()

  return (
    <SettingsContainer>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={12}>
          <SectionTitle>{t('settingsPage.sections.general')}</SectionTitle>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs={12} sm={4}>
            <CalendarPaper>
              <FormTitle>{t('settingsPage.daysOffSettings.title')}</FormTitle>
              <UploadDaysOffCSV />
              <CurrentDaysOff />
            </CalendarPaper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.importOrders.title')}</FormTitle>
                  <ImportOrdersFromIaiForm />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.importProducts.title')}</FormTitle>
                  <ImportProductsButton />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.updateSoldNew.title')}</FormTitle>
                  <UpdateSoldNewButton />
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.deliveredProductsSyncInterval.title')}</FormTitle>
                  <DeliveredProductsSyncIntervalForm />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.saveSettings.title')}</FormTitle>
                  <SaveSettingsForm />
                </Paper>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} sm={12}>
          <SectionTitle>{t('settingsPage.sections.flags')}</SectionTitle>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.autoDecisionSettings.title')}</FormTitle>
                  <AutoDecisionForm />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.autoDecisionPriceDownSettings.title')}</FormTitle>
                  <AutoDecisionPriceDownForm />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.priceDownStockDiffPercentage.title')}</FormTitle>
                  <PriceDownSettings />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <SectionTitle>{t('settingsPage.sections.plans')}</SectionTitle>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.weightSchema.title')}</FormTitle>
                  <CreateWeightSchemaModal />
                  <WeightSchemasList />
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.salePlansSettings.title')}</FormTitle>
                  <UploadSalePlansCSV />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <FormTitle>{t('settingsPage.recalculateSalePlan.title')}</FormTitle>
                  <RecalculateSalePlanForm />
                </Paper>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </SettingsContainer>
  )
}

export default SettingsPage

const SettingsContainer = styled.div`
  height: 100%;
`

const CalendarPaper = styled(Paper)`
  overflow-x: auto;
`

const SectionTitle = styled.div`
  margin: 20px 0;
  font-size: 30px;
  font-weight: 500;
`
