import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { SORT_ORDER } from 'utils/table'
import { shippingStatusOptions } from 'utils/shippingOrders'

import { fetchUsersRoutine } from 'features/users/ducks/actions'
import { getUserLoginAsOptions } from 'features/users/ducks/selectors'

import SingleSelectFilter from 'components/tableFilters/SingleSelectFilter'
import Grid from 'components/atoms/Grid'

export const ShippingOrdersFilters = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userOptions = useSelector(getUserLoginAsOptions)

  const fetchUsers = useCallback(
    query => dispatch(fetchUsersRoutine(query)),
    [dispatch]
  )

  useEffect(() => {
    fetchUsers(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'login',
        dir: SORT_ORDER.asc
      }
    }))
  }, [])

  return (
    <StyledContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <SingleSelectFilter options={shippingStatusOptions()} label={t('ordersPage.table.headers.status')} name={API_FIELD_NAME.orderStatus} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SingleSelectFilter options={userOptions} label={t('ordersPage.table.headers.userLogin')} name={API_FIELD_NAME.shippingOrderUsers} />
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default ShippingOrdersFilters

const StyledContainer = styled.div`
  max-width: 700px; 
  
  ${props => props.theme.breakpointsMedia.mobile} {
    max-width: 100%;
  }
`
