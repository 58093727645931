import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const ProductComment = Record({
  type: '',
  user_id: '',
  product_id: '',
  content: '',
  id: '',
  userId: '',
  created_at: '',
  author: {}
})

export const ProductCommentMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const ProductComments = Record({
  state: API_STATES.PRISTINE,
  products: {}
})
