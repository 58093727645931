import { includes, propOr } from 'ramda'

export const SECTION_IDS = {
  comments: 'comments',
  flags: 'flags',
  bundles: 'bundles',
  saleChannels: 'saleChannels',
  promotions: 'promo',
  sale: 'sale',
  delivery: 'delivery',
  todayOrders: 'todayOrders',
  willLastHistory: 'willLastHistory'
}

export const isSectionExpanded = (sectionId, searchQuery) => {
  const currentState = propOr([], 'sections', searchQuery)
  return includes(sectionId, currentState)
}
