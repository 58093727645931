import { string, object, array, number } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Create shipping order form validator

export const createShippingOrderSchema = object().shape({
  shippingDate: string()
    .required(() => ({ key: 'form.shippingDate.errors.required' })),
  name: string()
    .required(() => ({ key: 'form.orderName.errors.required' })),
  productIds: array().required()
})

export const validateCreateShippingOrderSchemaField = values => validateField(createShippingOrderSchema, values)
export const validateCreateShippingOrderSchemaValues = validateValues(createShippingOrderSchema)

// Edit shipping order form validator

export const editShippingOrderSchema = object().shape({
  name: string()
    .required(() => ({ key: 'form.orderName.errors.required' })),
  toBeShippedAt: string()
    .required(() => ({ key: 'form.shippingDate.errors.required' })),
  id: string().required(),
  items: array().of(
    object().shape({
      quantity: number()
        .required(() => ({ key: 'form.shippingQuantity.errors.required' }))
        .min(1, () => ({
          key: 'form.shippingQuantity.errors.numberMin',
          options: { number: 1 }
        }))
    })
  )
})

export const validateEditShippingOrderSchemaField = values => validateField(editShippingOrderSchema, values)
export const validateEditShippingOrderSchemaValues = validateValues(editShippingOrderSchema)

export const editShippingOrderDateSchema = object().shape({
  toBeShippedAt: string()
    .required(() => ({ key: 'form.shippingDate.errors.required' })),
  id: string().required()
})

export const validateEditShippingOrderDateSchemaField = values => validateField(editShippingOrderDateSchema, values)
export const validateEditShippingOrderDateSchemaValues = validateValues(editShippingOrderDateSchema)
