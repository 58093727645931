import { createRoutine } from 'redux-saga-routines'
import { pathOr } from 'ramda'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as monitoringService from 'services/MonitoringService'

// ROUTINES

export const fetchMonitoringRoutine = createRoutine('FETCH_MONITORING')

// ACTIONS

function * fetchMonitoring ({ payload }) {
  yield put(fetchMonitoringRoutine.request())
  try {
    const result = yield call(monitoringService.fetchMonitoring, payload)
    yield put(fetchMonitoringRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchMonitoringRoutine.failure(e))
  }
}

// WATCHERS

export function * fetchMonitoringWatcher () {
  yield takeLatest(fetchMonitoringRoutine.TRIGGER, fetchMonitoring)
}

// SAGAS

export const monitoringSagas = [
  fork(fetchMonitoringWatcher)
]
