import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  validateUpdateAuctionPriceField,
  validateUpdateAuctionPriceValues
} from 'features/productAuctions/ducks/schema'
import { propOr } from 'ramda'
import { withRouter } from 'react-router-dom'
import { isSectionExpanded, SECTION_IDS } from 'utils/productPage'

import { updateAuctionPriceRoutine } from 'features/productAuctions/ducks/actions'

import TextField from 'components/formElements/TextField'
import Popover from 'components/atoms/Popover'
import Button from 'components/atoms/Button'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

const emptyValues = {
  price: 0,
  productId: '',
  offerId: ''
}

export const UpdateAuctionPriceForm = (props) => {
  const { id, match: { params: { id: productId } } } = props
  const { onSubmit, data } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const offerId = propOr('', 'offerId', data)
  const price = propOr('', 'price', data)
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? id : undefined
  const urlSearchQuery = useUrlSearchQuery()

  const updateAuctionPrice = useCallback(
    payload => dispatch(updateAuctionPriceRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateUpdateAuctionPriceValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      price,
      offerId,
      productId: id
    })
  }, [data])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    showConfirmation ? handleOpen(e) : handleSubmitForm()
  }

  const handleCallback = () => {
    handleClose()
    onSubmit()
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id: productId,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleSubmitForm = () => updateAuctionPrice({ values, callback: handleCallback })

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const deviation = ((values.price * 100) / price) - 100
  const showConfirmation = Math.abs(deviation) >= 20

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('productPage.retailPrice.confirmation.content', { currentPrice: price, nextPrice: values.price })}
        <ConfirmationButtonsContainer>
          <Button disabled={!valid || price === values.price} onClick={handleSubmitForm} variant='outlined'>
            {t('productPage.retailPrice.confirmation.submitCTA')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            {t('productPage.retailPrice.confirmation.cancelCTA')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormFieldsContainer>
        <FlexContainer>
          <TextField
            required
            type='number'
            value={values.price}
            name='price'
            label={t('form.retailPrice.label')}
            onChange={handleOnChange}
            validate={validateUpdateAuctionPriceField(values)}
          />
        </FlexContainer>
        {ConfirmationPopup}
      </FormFieldsContainer>
      <FormButtonsContainer>
        <Button
          type='button'
          id='button-cancel'
          onClick={onSubmit}
          variant='outlined'
        >
          {t('productPage.saleChannels.auctions.changePrice.cancel')}
        </Button>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid || price === values.price}
          onClick={handleSubmit}
        >
          {t('productPage.saleChannels.auctions.changePrice.submitCTA')}
        </Button>
      </FormButtonsContainer>
    </StyledForm>
  )
}

export default withRouter(UpdateAuctionPriceForm)

const FormFieldsContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FormButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  
  & button + button {
    margin-left: 10px;
  }
`

const StyledForm = styled.form`
  display: block;
`

const FlexContainer = styled.div`
  align-items: center;
  width: 100%;
`

export const ConfirmationContainer = styled.div`
  padding: 20px;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  
  &+& {
    margin-left: 10px;
  }
`
