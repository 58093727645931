import React from 'react'
import IconButton from 'components/atoms/IconButton'
import Refresh from 'components/icons/Refresh'

export const IntegrationRefreshButton = (props) => {
  const { handleRefresh } = props

  return (
    <IconButton size='small' onClick={handleRefresh}>
      <Refresh />
    </IconButton>
  )
}

export default IntegrationRefreshButton
