import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getProductSimpleSaleChart } from 'features/products/ducks/selectors'
import { fetchSimpleSaleChartRoutine } from 'features/products/ducks/actions'

import Chart from 'components/Chart'
import ChartDatesForm from 'features/products/ChartDatesForm'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  all,
  equals,
  find, includes,
  isNil,
  length,
  map,
  pipe,
  propEq,
  propOr,
  uniq
} from 'ramda'
import { formatDate, FORMATS } from 'utils/date'
import { getDaysOffArray } from 'features/daysOff/ducks/selectors'
import { getSelectedTheme } from 'ducks/global/selectors'
import { generateSelectedTheme } from 'utils/theme'

export const SaleChart = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const data = useSelector(state => getProductSimpleSaleChart(state, id))
  const selectedThemeMode = useSelector(getSelectedTheme)
  const selectedTheme = generateSelectedTheme(selectedThemeMode)

  const getXaxisCategories = () => {
    const xaxisValues = propOr([], 'xaxis', data)
    const isTodayChart = pipe(
      map(date => formatDate(date, FORMATS.dashedReverse)),
      uniq,
      length,
      equals(1)
    )(xaxisValues)

    const timesArray = map(date => formatDate(date, FORMATS.time))(xaxisValues)

    return isTodayChart ? timesArray : xaxisValues
  }

  const daysOff = useSelector(getDaysOffArray)

  const xAxisLabelColors = () => {
    const data = getXaxisCategories()
    return data.map(date =>
      includes(date, daysOff)
        ? selectedTheme.palette.common.gray300
        : selectedTheme.palette.common.black
    )
  }

  const isSeriesAllNullable = seriesName => pipe(
    propOr([], 'yaxis'),
    find(propEq('name', seriesName)),
    propOr([], 'data'),
    all(isNil)
  )(data)

  const options = {
    markers: {
      size: [3, 3, 3]
    },
    stroke: {
      width: 1
    },
    chart: {
      id: 'sale-chart'
    },
    xaxis: {
      categories: getXaxisCategories(),
      labels: {
        style: {
          colors: xAxisLabelColors()
        }
      }
    },
    yaxis: [
      {
        forceNiceScale: true,
        decimalsInFloat: 0,
        seriesName: t('productPage.charts.saleChart.seriesNames.sale'),
        title: {
          text: 'Ilość (szt.)'
        }
      },
      {
        forceNiceScale: true,
        decimalsInFloat: 2,
        show: !isSeriesAllNullable('margin'),
        seriesName: t('productPage.charts.saleChart.seriesNames.margin'),
        opposite: true,
        title: {
          text: 'Marża (%)'
        }
      },
      {
        forceNiceScale: true,
        decimalsInFloat: 2,
        seriesName: t('productPage.charts.saleChart.seriesNames.sale'),
        show: false,
        title: {
          text: 'Ilość (szt.)'
        }
      }
    ]
  }

  const getSeriesData = seriesName => pipe(
    propOr([], 'yaxis'),
    find(propEq('name', seriesName)),
    propOr([], 'data')
  )(data)

  const series = [
    {
      data: getSeriesData('sale').map(value => value === null ? 0 : Number(value)),
      name: t('productPage.charts.saleChart.seriesNames.sale'),
      type: 'line'
    },
    {
      data: getSeriesData('margin').map(value => value === null ? null : Number(value)),
      name: t('productPage.charts.saleChart.seriesNames.margin'),
      type: 'line'
    },
    {
      data: getSeriesData('plan'),
      name: t('productPage.charts.saleChart.seriesNames.plan'),
      type: 'line'
    }
  ]

  return (
    <ChartContainer>
      <ChartTitle>{t('productPage.charts.saleChart.title')}</ChartTitle>
      {
        isNotNilOrEmpty(data) && (
          <Chart options={options} series={series} type='line' width='100%' />
        )
      }
      <ChartDatesForm fetchChartRoutine={fetchSimpleSaleChartRoutine} />
    </ChartContainer>
  )
}

export default withRouter(SaleChart)

const ChartContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
`

const ChartTitle = styled.div`
  font-size: 23px;
  font-weight: 500;
`
