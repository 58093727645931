import React from 'react'
import Icon from 'components/Icon'

export const CancelledChart = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.032 0-5.5 2.468-5.5 5.5s2.468 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.468-5.5-5.5-5.5z' />
        <path d='m15.379 20.121c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l4.242-4.242c.195-.195.512-.195.707 0s.195.512 0 .707l-4.242 4.242c-.097.097-.225.146-.353.146z' />
        <path d='m19.621 20.121c-.128 0-.256-.049-.354-.146l-4.242-4.242c-.195-.195-.195-.512 0-.707s.512-.195.707 0l4.242 4.242c.195.195.195.512 0 .707-.097.097-.225.146-.353.146z' />
        <path d='m8.5 21h-6c-1.379 0-2.5-1.121-2.5-2.5v-16c0-1.378 1.121-2.5 2.5-2.5h11c1.379 0 2.5 1.122 2.5 2.5v6c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-6c0-.827-.673-1.5-1.5-1.5h-11c-.827 0-1.5.673-1.5 1.5v16c0 .827.673 1.5 1.5 1.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z' />
        <path d='m9.5 13.89c-.276 0-.5-.224-.5-.5v-7.89c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v4.52c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-4.02h-2v7.39c0 .276-.224.5-.5.5z' />
        <path d='m6.5 15h-3c-.276 0-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v5c0 .276-.224.5-.5.5zm-2.5-1h2v-4h-2z' />
        <path d='m9.02 15h-6.52c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6.52c.276 0 .5.224.5.5s-.224.5-.5.5z' />
      </g>
    </Icon>
  )
}

export default CancelledChart
