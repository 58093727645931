import { always, equals, identity, ifElse } from 'ramda'
import PriceUp from 'components/icons/PriceUp'
import PriceDown from 'components/icons/PriceDown'
import AutoDecision from 'components/icons/AutoDecision'
import PromoDiff from 'components/icons/PromoDiff'
import i18n from 'providers/i18n'
import { convertFromPenniesValue } from 'utils/price'

export const UNIT_FORMATS = {
  percentage: '%',
  currency: 'zł'
}

export const PRODUCT_TASKS_NAMES = {
  priceUp: 'priceUp',
  promoDiff: 'promoDiff',
  autoDecision: 'autoDecision',
  priceDown: 'priceDown'
}

// definition of all available flags in the system to handle them in:
// filters, selectedFilters, flags column
export const PRODUCT_TASKS_API_FIELDS = [
  {
    name: 'price_up_task_enabled',
    key: 'priceUpTaskEnabled',
    translation: i18n.t('monitoringPage.table.flags.price_up_task_enabled'),
    icon: PriceUp
  },
  {
    name: 'auto_decision_task_enabled',
    key: 'autoDecisionTaskEnabled',
    translation: i18n.t('monitoringPage.table.flags.auto_decision_task_enabled'),
    icon: AutoDecision
  },
  {
    name: 'promo_diff_task_enabled',
    key: 'promoDiffTaskEnabled',
    translation: i18n.t('monitoringPage.table.flags.promo_diff_task_enabled'),
    icon: PromoDiff
  },
  {
    name: 'price_down_task_enabled',
    key: 'priceDownTaskEnabled',
    translation: i18n.t('monitoringPage.table.flags.price_down_task_enabled'),
    icon: PriceDown
  }
]

export const unitOptions = () => [
  {
    label: UNIT_FORMATS.currency,
    value: UNIT_FORMATS.currency
  },
  {
    label: UNIT_FORMATS.percentage,
    value: UNIT_FORMATS.percentage
  }
]

export const getPriceUpAmountByUnit = (amount, compareUnit) =>
  ifElse(
    equals(compareUnit),
    always(amount),
    always(0)
  )

export const getPriceUpUnitByValues = percentage =>
  ifElse(
    equals(0),
    always(UNIT_FORMATS.currency),
    always(UNIT_FORMATS.percentage)
  )(percentage)

export const getPriceUpAmountByValues = (percentage, value) =>
  ifElse(
    equals(0),
    always(value),
    always(percentage)
  )(percentage)

export const convertFromPenniesIfPrice = (unit, amount) =>
  ifElse(
    () => equals(UNIT_FORMATS.currency, unit),
    convertFromPenniesValue,
    identity
  )(amount)
