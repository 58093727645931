import React from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import styled from 'styled-components'

export const BundleItem = (props) => {
  const { data } = props
  const history = useHistory()
  const { match: { params: { id } } } = props

  return (
    <BundleItemConateinr isCurrent={id === data.id}>
      <Link
        to={{
          pathname: `/product/${data.id}`,
          state: {
            from: `${history.location.pathname}${history.location.search}`
          }
        }}
      >
        <div className='bundle-item__qty'>{data.quantity}x</div>
        <span>{data.ean}</span>:&nbsp;
        <span>{data.name}</span>&nbsp;
      </Link>
    </BundleItemConateinr>
  )
}

export default withRouter(BundleItem)

const BundleItemConateinr = styled.div`
  font-size: 12px;
  margin-bottom: 2px;
  font-weight ${props => props.isCurrent ? '500' : '400'};
  
  .bundle-item__qty {
    display: inline-block;
    width: 30px;
  }
`
