import moment from 'moment'

export const FORMATS = {
  dashed: 'DD-MM-YYYY',
  slash: 'DD/MM/YYYY',
  slashTime: 'DD/MM/YYYY HH:mm',
  dashedReverse: 'YYYY-MM-DD',
  time: 'HH:mm'
}

export const formatDate = (date, format) => moment(date).format(format)
export const formatISODate = (date) => moment(date).toISOString()
export const isTodayOrPast = (date) => {
  const today = moment().toISOString()
  return moment(date).isSameOrBefore(today)
}
export const dateIsBeforeComparedDate = (date1, date2) => {
  const date = formatDate(date1, FORMATS.dashedReverse)
  const compareDate = formatDate(date2, FORMATS.dashedReverse)
  return moment(date).isBefore(compareDate)
}
export const nDaysAgo = (days) => moment().subtract(days, 'd').startOf('day')
export const resetTime = date => moment(date).startOf('day')
export const today = () => moment()

export const dateIsBeforeOrSame = (date1, date2) => {
  const date = formatDate(date1, FORMATS.dashedReverse)
  const compareDate = formatDate(date2, FORMATS.dashedReverse)
  return moment(date).isSameOrBefore(compareDate)
}
