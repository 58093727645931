import { fetchAlertsRoutine, fetchUnseenAlertsSummaryRoutine, fetchUnarchivedAlertsRoutine } from 'features/alerts/ducks/actions'
import { Alerts, AlertsMeta, AlertItem } from 'features/alerts/ducks/records'
import { transferCollectionToImmutable, API_STATES } from 'utils/redux'
import { pathOr } from 'ramda'

export default (state = new Alerts(), action) => {
  switch (action.type) {
    case fetchAlertsRoutine.REQUEST:
    case fetchUnseenAlertsSummaryRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case fetchAlertsRoutine.FAILURE:
    case fetchUnseenAlertsSummaryRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case fetchAlertsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('collection', transferCollectionToImmutable(action.payload.data, AlertItem))
        .set('alertsMeta', new AlertsMeta(action.payload.meta))
    case fetchUnseenAlertsSummaryRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('summary', action.payload)
    case fetchUnarchivedAlertsRoutine.SUCCESS:
      return state
        .set('unarchivedCount', pathOr(0, ['payload', 'meta', 'recordsTotal'], action))
    default:
      return state
  }
}
