import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateSaveSettingsSchemaField,
  validateSaveSettingsSchemaValues
} from 'features/settings/ducks/schema'

import { saveSettingsRoutine } from 'features/settings/ducks/actions'
import { getIsSettingsLoading, getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  soldShort: 0,
  soldLong: 0
}

export const SaveSettingsForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSettingsLoading)
  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)

  const saveSettings = useCallback(
    payload => dispatch(saveSettingsRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateSaveSettingsSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      soldShort: soldShortValue,
      soldLong: soldLongValue
    })
  }, [soldShortValue, soldLongValue])

  useEffect(() => {}, [])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    saveSettings(values)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        required
        value={values.soldShort}
        name='soldShort'
        label={t('form.soldX.label', { count: soldShortValue })}
        onChange={handleOnChange}
        validate={validateSaveSettingsSchemaField(values)}
      />
      <TextField
        required
        name='soldLong'
        value={values.soldLong}
        label={t('form.soldX.label', { count: soldLongValue })}
        onChange={handleOnChange}
        validate={validateSaveSettingsSchemaField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('settingsPage.saveSettings.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default SaveSettingsForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
