import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import useSelectionSaga from 'hooks/useSelectionSaga'

import { getProducersArray, getProducersMeta, getIsProducersLoading } from 'features/producers/ducks/selectors'
import { fetchProducersRoutine } from 'features/producers/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import Checkbox from 'components/atoms/Checkbox'
import ProducersFilters from 'features/producers/components/ProducersFilters'

export const ProducersTable = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(getProducersArray)
  const isLoading = useSelector(getIsProducersLoading)
  const pagination = useSelector(getProducersMeta)
  const fetchProducers = useCallback(
    query => dispatch(fetchProducersRoutine(query)),
    [dispatch]
  )

  const visibleItemsIds = data.map(data => data.id)

  const {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem,
    handleSelectCollection
  } = useSelectionSaga(visibleItemsIds)

  const headers = [
    {
      id: 'selectAll',
      sortable: false,
      cell: (
        <Checkbox
          indeterminate={hasSomeItemSelected}
          checked={hasAllVisibleItemsSelected}
          onChange={handleSelectCollection}
        />
      )
    },
    {
      id: 'name',
      sortable: false,
      cell: t('producersPage.table.headers.name')
    },
    {
      id: 'lines.name',
      sortable: true,
      cell: t('producersPage.table.headers.line'),
      align: 'right'
    }
  ]

  const renderItems = data.map((data, index) => (
    <TableRow checked={isItemSelected(data.id)} hover key={propOr(`producers-row-${index}`, 'id', data)}>
      <TableCell width='2%'>
        <Checkbox
          checked={isItemSelected(data.id)}
          onChange={handleSelectItem(data.id)}
        />
      </TableCell>
      <TableCell>
        {propOr('-', 'name', data)}
      </TableCell>
      <TableCell align='right'>
        {propOr('-', 'lineName', data)}
      </TableCell>
    </TableRow>
  ))

  return (
    <EntitiesList
      emptyStateText={t('producersPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchProducers}
      isLoading={isLoading}
      defaultSortedColumnId='name'
      filters={<ProducersFilters />}
    />
  )
}

export default ProducersTable
