import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validatePriceDownStockDiffPercentageSchemaField,
  validatePriceDownStockDiffPercentageSchemaValues
} from 'features/settings/ducks/schema'

import { saveSettingsRoutine } from 'features/settings/ducks/actions'
import {
  getIsSettingsLoading,
  gePriceDownStockDiffPercentage
} from 'features/settings/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  priceDownStockDiffPercentage: 0
}

export const RecalculateSalePlanForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSettingsLoading)
  const priceDownStockDiffPercentage = useSelector(gePriceDownStockDiffPercentage)

  const saveSettings = useCallback(
    payload => dispatch(saveSettingsRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validatePriceDownStockDiffPercentageSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      priceDownStockDiffPercentage
    })
  }, [priceDownStockDiffPercentage])

  useEffect(() => {}, [])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    saveSettings(values)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <SettingsContainer>
        <span>
          {t('settingsPage.priceDownStockDiffPercentage.condition1')}&nbsp;
        </span>
      </SettingsContainer>
      <SettingsContainer>
        <span>
          {t('settingsPage.priceDownStockDiffPercentage.condition2')}&nbsp;
        </span>
        <TextField
          required
          value={values.priceDownStockDiffPercentage}
          name='priceDownStockDiffPercentage'
          label={t('form.percentage.label')}
          onChange={handleOnChange}
          validate={validatePriceDownStockDiffPercentageSchemaField(values)}
        />
        <span>
          &nbsp;{t('settingsPage.priceDownStockDiffPercentage.unit')}&nbsp;
        </span>
      </SettingsContainer>
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('settingsPage.priceDownStockDiffPercentage.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default RecalculateSalePlanForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  
  .settings__connector {
    font-size: 16px;
  }
`
