import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PATHS from 'utils/paths'

import Button from 'components/atoms/Button'

export const NotFoundPage = (props) => {
  const { t } = useTranslation()

  return (
    <NotFoundContainer>
      <div className='not-found__code'>
        404
      </div>
      <div>
        {t('notFoundPage.description')}
      </div>
      <div className='not-found__back'>
        <Link to={PATHS.monitoring}>
          <Button>
            {t('notFoundPage.cta')}
          </Button>
        </Link>
      </div>
    </NotFoundContainer>
  )
}

export default NotFoundPage

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  font-size: 20px;

  .not-found__code {
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .not-found__back {
    margin-top: 20px;
  }
`
