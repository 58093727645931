import React from 'react'

import MuiSwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export const Switch = props => {
  const {
    label,
    disabled,
    value,
    onChange,
    color,
    name,
    labelPlacement
  } = props

  return (
    <FormControlLabel
      labelPlacement={labelPlacement || 'start'}
      disabled={disabled}
      label={label}
      control={(
        <MuiSwitch
          onChange={onChange}
          checked={value}
          color={color || 'primary'}
          name={name}
        />
      )}
    />
  )
}

export default Switch
