import React from 'react'
import Icon from 'components/Icon'

export const AddAllegro = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 495.5 488.6'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          id='add-allegro-path0'
          d='M327.6,121.2c-0.2,0.5-1,0.6-3.6,0.6c-2.9,0-3.5,0.1-4,0.8c-0.5,0.6-1.2,0.8-2.4,0.8
      c-1.2,0-1.8,0.2-2,0.6c-0.2,0.4-0.8,0.6-2.2,0.6c-1.5,0-2,0.1-2,0.6c0,0.4-0.4,0.6-1.6,0.6c-1.2,0-1.7,0.2-2.1,0.8
      c-0.3,0.4-0.7,0.8-1,0.8c-0.3,0-0.5,0.3-0.5,0.6c0,0.4-0.4,0.6-1.6,0.6c-0.9,0-1.8,0.3-2,0.6c-0.3,0.3-0.7,0.6-1,0.6
      s-0.7,0.4-1,0.8c-0.3,0.5-0.9,0.8-1.5,0.8c-0.6,0-1,0.2-1,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0.4-0.4,0.6-1.4,0.6
      c-1.1,0-1.4,0.2-1.4,0.8c0,0.4-0.3,0.8-0.6,0.8c-0.3,0-0.6,0.3-0.6,0.6c0,0.3-0.4,0.6-0.8,0.6s-0.8,0.3-0.8,0.6
      c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6,0.4-0.6,0.8c0,0.6-0.3,0.8-1.2,0.8c-0.7,0-1.3,0.2-1.4,0.6c-0.1,0.3-0.5,0.6-0.8,0.6
      c-0.3,0-0.6,0.4-0.6,0.8c0,0.4-0.3,0.8-0.6,0.8c-0.3,0-0.6,0.3-0.6,0.6s-0.3,0.6-0.6,0.6c-0.4,0-0.7,0.5-0.9,1.2
      c-0.1,0.7-0.5,1.2-0.8,1.2c-0.3,0-0.6,0.4-0.6,0.8c0,0.4-0.3,0.8-0.6,0.8c-0.3,0-0.6,0.3-0.6,0.6s-0.3,0.6-0.6,0.6
      c-0.3,0-0.7,0.3-0.9,0.7s-0.5,0.9-0.8,1.2c-0.3,0.3-0.5,0.9-0.5,1.3c0,0.4-0.3,0.8-0.6,0.8c-0.4,0-0.6,0.4-0.6,1.4
      c0,1.1-0.2,1.4-0.8,1.4c-0.4,0-0.8,0.3-0.8,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.4,0-0.6,0.4-0.6,1.4s-0.2,1.4-0.6,1.4
      c-0.4,0-0.6,0.4-0.6,1.4c0,0.9-0.2,1.4-0.6,1.4c-0.6,0-1,1.2-1,2.7c0,0.5-0.3,1-0.6,1.1c-0.4,0.1-0.6,0.7-0.6,1.4
      c0,0.8-0.2,1.2-0.6,1.2c-0.7,0-0.9,2.6-0.2,3c0.6,0.4,0.5,2.4-0.4,6c-0.4,1.9-0.8,5.2-0.9,8l-0.1,4.8l9,0.1
      c10.4,0.1,9.5,0.5,9.9-4.1c0.3-4.8,2.5-13,3.7-14.2c0.2-0.2,0.9-0.6,1.4-0.9c0.6-0.3,1-0.9,1.1-1.6c0.1-0.6,0.4-1.1,0.7-1.1
      c0.4,0,0.6-0.5,0.6-1.4c0-1,0.2-1.4,0.6-1.4c0.4,0,0.6-0.4,0.6-1.2c0-0.8,0.2-1.2,0.6-1.2c0.4,0,0.7-0.4,0.8-0.8
      c0.1-0.4,0.5-0.8,0.8-0.8c0.3,0,0.6-0.3,0.6-0.6c0-0.3,0.3-0.6,0.6-0.6s0.6-0.2,0.6-0.6c0-0.3,0.4-0.6,0.8-0.8
      c0.6-0.2,0.8-0.5,0.8-1.4c0-1.1,0.1-1.2,1.2-1.2c0.8,0,1.2-0.2,1.2-0.6c0-0.3,0.3-0.6,0.6-0.6c0.4,0,0.7-0.4,0.8-0.8
      c0.1-0.4,0.5-0.8,0.8-0.8c0.3,0,0.6-0.3,0.6-0.6c0-0.3,0.3-0.6,0.6-0.6s0.6-0.3,0.6-0.6c0-0.4,0.4-0.6,1.2-0.6
      c0.9,0,1.3-0.2,1.4-0.8c0.1-0.4,0.5-0.8,0.8-0.8c0.3,0,0.6-0.3,0.6-0.6c0-0.4,0.4-0.6,1.4-0.6s1.4-0.2,1.4-0.6
      c0-0.4,0.4-0.6,1.2-0.6c0.9,0,1.3-0.2,1.4-0.8c0.2-0.6,0.5-0.8,2-0.8c1.2,0,1.8-0.2,2-0.6c0.1-0.3,0.7-0.6,1.4-0.6
      c0.8,0,1.2-0.2,1.2-0.6c0-0.5,0.7-0.6,3.7-0.6c4.2,0,5.5-0.3,5.5-1c0-1,8.2-0.8,8.5,0.2c0.2,0.7,0.5,0.8,4,0.8
      c3.1,0,3.8,0.1,3.8,0.6c0,0.5,0.5,0.6,2,0.6c1.5,0,2,0.1,2,0.6c0,0.4,0.4,0.6,1.2,0.6c0.8,0,1.2,0.2,1.4,0.8c0.2,0.7,0.5,0.8,2,0.8
      c1.4,0,1.8,0.2,1.8,0.6c0,0.3,0.4,0.6,0.8,0.6s0.8,0.3,0.8,0.6c0,0.4,0.4,0.6,1.2,0.6c0.8,0,1.2,0.2,1.4,0.8
      c0.1,0.4,0.5,0.8,0.8,0.8c0.4,0,0.6,0.3,0.6,0.6c0,0.4,0.4,0.6,1.2,0.6c0.8,0,1.2,0.2,1.2,0.6c0,0.3,0.4,0.6,0.8,0.6
      c0.5,0,0.8,0.3,0.8,0.8c0,0.4,0.3,0.8,0.6,0.8c0.3,0,0.6,0.3,0.6,0.6s0.3,0.6,0.6,0.6s0.6,0.3,0.6,0.6c0,0.3,0.4,0.6,0.8,0.6
      c0.6,0,0.8,0.3,0.8,1.2c0,0.7,0.2,1.3,0.6,1.4c0.3,0.1,0.6,0.5,0.6,0.8c0,0.3,0.2,0.6,0.6,0.6c0.3,0,0.6,0.4,0.8,0.8
      s0.5,0.8,0.8,0.8c0.4,0,0.6,0.4,0.6,1.2c0,0.8,0.2,1.2,0.6,1.2c0.3,0,0.6,0.4,0.6,0.8s0.3,0.8,0.6,0.8c0.4,0,0.6,0.4,0.6,1.2
      c0,0.9,0.2,1.2,0.8,1.2c0.5,0,0.8,0.3,0.8,0.8c0,0.4,0.3,0.9,0.7,1c1.6,0.5,4.9,12.4,4.9,17.9l0,1.1l9.1-0.1l9.1-0.1l0.1-1.8
      c0.2-2.5-0.7-11.1-1.4-12.9c-0.4-1.1-0.5-2.5-0.3-4.9c0.2-3,0.2-3.4-0.4-3.4c-0.5,0-0.7-0.4-0.7-1.4c0-1-0.2-1.4-0.6-1.4
      c-0.4,0-0.6-0.4-0.6-1.2c0-0.9-0.2-1.3-0.8-1.4c-0.6-0.2-0.8-0.5-0.8-2c0-1.2-0.2-1.8-0.6-2c-0.3-0.1-0.6-0.5-0.6-0.8
      c0-0.3-0.3-0.6-0.6-0.6c-0.4,0-0.6-0.4-0.6-1.2c0-0.9-0.2-1.3-0.8-1.4c-0.6-0.1-0.8-0.5-0.8-1.4c0-0.8-0.2-1.2-0.6-1.2
      c-0.3,0-0.6-0.4-0.6-0.8s-0.3-0.8-0.6-0.8c-0.3,0-0.6-0.3-0.6-0.6c0-0.3-0.4-0.6-0.8-0.6c-0.6,0-0.8-0.3-0.8-1.2
      c0-0.7-0.2-1.3-0.6-1.4c-0.3-0.1-0.6-0.5-0.6-0.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3-0.4-0.6-0.8-0.6
      c-0.5,0-0.8-0.3-0.8-0.8c0-0.4-0.3-0.8-0.6-0.8c-0.3,0-0.6-0.3-0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6-0.3-0.6-0.6
      c0-0.3-0.4-0.6-0.8-0.6c-0.5,0-0.8-0.3-0.8-0.8c0-0.4-0.3-0.8-0.6-0.8c-0.3,0-0.6-0.3-0.6-0.6c0-0.3-0.3-0.6-0.6-0.6
      c-0.3,0-0.7-0.3-0.8-0.6c-0.1-0.4-0.7-0.6-1.4-0.6c-0.9,0-1.2-0.2-1.2-0.8c0-0.4-0.3-0.8-0.6-0.8c-0.3,0-0.7-0.3-0.8-0.6
      c-0.1-0.4-0.7-0.6-1.4-0.6c-0.8,0-1.2-0.2-1.2-0.6c0-0.3-0.2-0.6-0.6-0.6c-0.3,0-0.6-0.4-0.8-0.8c-0.2-0.6-0.5-0.8-1.4-0.8
      c-0.8,0-1.2-0.2-1.2-0.6c0-0.4-0.4-0.6-1.4-0.6s-1.4-0.2-1.4-0.6c0-0.4-0.4-0.6-1.2-0.6c-0.8,0-1.2-0.2-1.4-0.8
      c-0.2-0.7-0.5-0.8-2-0.8c-1.4,0-1.8-0.2-1.8-0.6c0-0.5-0.5-0.6-2-0.6c-1.5,0-2-0.1-2-0.6c0-0.4-0.5-0.6-1.9-0.6
      c-1.5,0-2-0.2-2.4-0.8c-0.4-0.7-0.9-0.8-4.1-0.8c-2.8,0-3.6-0.1-3.8-0.6C344.6,120.3,328,120.3,327.6,121.2'
        />
        <path
          id='add-allegro-path1'
          d='M197.1,172c0,0.3-0.4,0.6-0.8,0.6c-0.4,0-0.8,0.3-0.8,0.6c0,0.3-0.3,0.6-0.6,0.6
      c-0.3,0-0.6,0.4-0.6,0.8s-0.3,0.8-0.6,0.8c-0.5,0-0.6,1.1-0.6,7.2v7.2l-5.5,0c-3,0-5.7,0-5.8,0c-0.2,0-0.4,0.3-0.5,0.8
      c-0.1,0.4-0.5,0.8-0.8,0.8c-0.3,0-0.7,0.3-0.8,0.6c-0.1,0.3-0.5,0.6-0.8,0.6c-0.4,0-0.6,0.5-0.6,1.4c0,1-0.2,1.4-0.6,1.4
      c-0.5,0-0.6,15.1-0.6,133.2s0.1,133.2,0.6,133.2c0.5,0,0.6,0.6,0.6,2.6c0,2,0.2,2.7,0.6,2.8c0.3,0.1,0.6,0.7,0.6,1.2
      c0,0.7,0.3,1.1,0.8,1.2c0.6,0.2,0.8,0.5,0.8,1.6c0,0.9,0.2,1.4,0.6,1.4c0.3,0,0.6,0.3,0.6,0.6c0,0.3,0.3,0.6,0.6,0.6
      s0.6,0.3,0.6,0.6c0,0.4,0.4,0.7,0.8,0.8c0.6,0.1,0.8,0.5,0.8,1.4c0,0.8,0.2,1.2,0.6,1.2c0.3,0,0.6,0.4,0.6,0.8s0.3,0.8,0.6,0.8
      c0.3,0,0.6,0.3,0.6,0.6c0,0.3,0.4,0.6,0.8,0.6s0.8,0.3,0.8,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6,0.3,0.6,0.6c0,0.6,0.8,1,2,1
      c0.4,0,0.8,0.3,0.8,0.6c0,0.4,0.4,0.6,1.2,0.6s1.2,0.2,1.2,0.6c0,0.3,0.4,0.6,0.8,0.6c0.4,0,0.8,0.3,0.8,0.6c0,0.7,0.9,1,2.6,1
      c0.9,0,1.4,0.2,1.4,0.6c0,0.5,15.3,0.6,135.2,0.6s135.2-0.1,135.2-0.6c0-0.4,0.4-0.6,1.4-0.6c1.8,0,2.6-0.3,2.6-1
      c0-0.3,0.4-0.6,0.8-0.6c0.4,0,0.8-0.3,0.8-0.6c0-0.4,0.4-0.6,1.2-0.6s1.2-0.2,1.2-0.6c0-0.3,0.3-0.6,0.8-0.6c1.2,0,2-0.4,2-1
      c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3,0.4-0.6,0.8-0.6s0.8-0.3,0.8-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6-0.4,0.6-0.8
      s0.3-0.8,0.6-0.8c0.4,0,0.6-0.4,0.6-1.2c0-0.8,0.2-1.2,0.8-1.4c0.4-0.1,0.8-0.5,0.8-0.8c0-0.4,0.3-0.6,0.6-0.6
      c0.3,0,0.6-0.3,0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.4,0,0.6-0.4,0.6-1.4c0-1,0.2-1.4,0.8-1.6c0.5-0.1,0.8-0.5,0.8-1.2
      c0-0.5,0.3-1.1,0.6-1.2c0.4-0.2,0.6-0.9,0.6-2.8c0-2.1,0.1-2.6,0.6-2.6c0.5,0,0.6-15.1,0.6-133.2s-0.1-133.2-0.6-133.2
      c-0.4,0-0.6-0.4-0.6-1.4s-0.2-1.4-0.6-1.4c-0.3,0-0.6-0.3-0.6-0.6s-0.3-0.6-0.8-0.6c-0.4,0-0.8-0.4-1-0.8c-0.1-0.4-0.4-0.8-0.5-0.8
      c-0.2,0-2.8,0-5.8,0l-5.5,0v-7.2c0-6.1-0.1-7.2-0.6-7.2c-0.3,0-0.6-0.4-0.6-0.8s-0.3-0.8-0.6-0.8c-0.3,0-0.6-0.3-0.6-0.6
      c0-0.3-0.4-0.6-0.8-0.6c-0.4,0-0.8-0.3-0.8-0.6c0-0.5-4.2-0.6-36.1-0.5l-36.1,0.1l-0.1,1.1c-0.1,0.6,0.1,2,0.5,3
      c0.7,2.3,1.6,9.9,1.4,12.7l-0.1,2l-9.1,0.1l-9.1,0.1l0-1.1c0-4.9-3.1-16.8-4.6-17.8c-1-0.7-90.9-0.4-91.5,0.3
      c-1.4,1.6-3.4,9.4-3.7,14.3c-0.3,4.6,0.5,4.2-9.9,4.1l-9-0.1l0.1-4.6c0.1-2.7,0.5-6.1,0.9-8.2c0.4-2,0.8-4.2,0.7-4.8l-0.1-1.2
      l-36.3-0.1C201.3,171.4,197.1,171.4,197.1,172 M263.2,322.7c1.1,0.8,1.1,66.9,0,67.7c-1.3,1-8.7,0.7-9.5-0.3
      c-1-1.4-0.9-66.1,0-67.2C254.6,322,262,321.8,263.2,322.7 M281.7,322.6l1,0.5v33.5V390l-1,0.5c-1.2,0.6-8.1,0.6-9,0.1
      c-0.9-0.6-1.1-66.8-0.2-67.8C273.3,322,280.1,321.9,281.7,322.6 M231.9,342.5c6.5,0.8,10.7,4.3,12.9,10.5c0.8,2.3,0.9,3.4,1,19.7
      l0.2,17.3l-0.9,0.5c-1.6,0.8-28.9,0.3-31-0.6c-5.3-2.3-7.2-3.9-8.9-7.2c-1.5-3-1.6-10.6-0.1-13.6c3.3-6.5,9-9.1,21-9.3
      c10-0.2,9.6-0.1,9.2-2.4c-0.8-5.4-5.8-7.6-13.9-5.9c-2.9,0.6-3.8,0.9-7.7,2.8c-3.2,1.5-3.8,0.7-3.9-4.7l0-3.1l1.7-1
      c2.9-1.7,6.3-2.8,10.5-3.3C226.2,341.9,226.6,341.9,231.9,342.5 M416.8,342.3c3.4,0.7,3.4,0.8,3.5,5.4c0,4.4,0.4,4.1-5.6,4.2
      c-5.3,0.1-6.9,0.9-9.4,4.2l-1.2,1.7l-0.2,15.9c-0.2,12.3-0.3,16.1-0.7,16.5c-0.7,0.7-9,0.7-9.8,0c-1.5-1.5-0.7-33.2,1-36.2
      c3.7-6.8,8.7-10.6,15-11.7C413.4,341.7,413.6,341.7,416.8,342.3 M316.6,342.6c1.6,0.2,3.2,0.6,3.6,0.8c0.4,0.2,1.4,0.7,2.1,1
      c5.2,2.3,9.3,9.1,10.5,17.6c0.8,5.6,0.8,6.3-0.2,7.5l-0.8,1.1h-15.5c-17.5,0-16.6-0.2-14.8,3.4c3.9,8,15,9.9,25.2,4.4
      c2.9-1.6,3.2-1.2,3.1,4.2l-0.1,4.2l-1.4,0.9c-0.8,0.5-2.1,1.1-3,1.4c-0.9,0.2-2,0.6-2.4,0.9c-3.6,2.1-18,0.3-23.3-2.8
      c-10.5-6.2-14.1-21.5-7.8-33.5C296.4,344.7,304.8,341,316.6,342.6 M366.9,342.5c8,1,14.9,6.9,17.9,15.4c1.3,3.6,1.4,37.1,0.1,40.6
      c-3.6,9.9-12.2,14.2-26.1,12.9c-5.3-0.5-5.3-0.5-5.3-5.2c0-5.3-0.2-5.1,5.1-4.5c12,1.3,17.3-2.3,17.3-11.5v-3.2l-1.5,0.7
      c-8.7,3.8-14.3,4-22.3,0.9c-4.8-1.9-10.6-8-11.7-12.2c-0.1-0.6-0.5-1.5-0.8-1.9c-1.6-2.6-1.4-12.6,0.4-17.2c1.8-5,6.9-10.6,11-12.5
      c1.2-0.5,2.6-1.2,3.1-1.4C356.2,342.3,362.3,341.9,366.9,342.5 M455.1,343.9c6.3,2.7,13.1,10.5,13.1,14.8c0,0.5,0.3,1.7,0.6,2.6
      c0.3,1,0.6,3.1,0.6,5c0,1.9-0.3,4-0.6,5c-0.3,0.9-0.6,2.1-0.6,2.6c0,4.8-10.3,15.4-15,15.4c-0.5,0-1.1,0.2-1.3,0.5
      c-0.3,0.3-2.2,0.5-5.5,0.6c-18.9,0.5-30.4-18.3-22-35.7C429.7,344,443.8,339,455.1,343.9 M306.6,352.7c-2.3,1.1-4.2,3.3-5.2,5.7
      c-1.3,3.1-1.6,3,10,2.9l10.3-0.1l-0.1-1.7c-0.1-2.2-2-5.2-4.3-6.7C315.3,351.5,309.1,351.4,306.6,352.7 M358.9,352
      c-1.9,0.5-6.6,4.4-6.6,5.5c0,0.2-0.2,0.7-0.5,1c-1.2,1.4-1.9,5-1.7,8.5c1,19,24.4,19.3,25,0.4C375.4,356.3,368.4,349.6,358.9,352
       M440.8,352.7c-1.9,1-5.7,4.7-5.7,5.6c0,0.2-0.3,0.9-0.6,1.6c-2.6,4.9-0.7,14.4,3.5,18.3c8.2,7.4,20,0.4,20-11.9
      C457.9,355.9,448.9,348.8,440.8,352.7 M220.5,369.1c-5.7,1.7-7.7,7-4,10.7c2.1,2.1,3.8,2.4,11.6,2.3l6.8-0.1l0.3-6
      c0.1-3.5,0.1-6.3-0.1-6.7C234.6,368.4,223.3,368.3,220.5,369.1'
        />
      </g>
      <g>
        <path d='M122.5,0v88.8l81.5,0v35h-81.5V213h-36v-89.2H0v-35h86.5V0H122.5z' />
      </g>
    </Icon>
  )
}

export default AddAllegro
