import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { pipe, propOr } from 'ramda'
import { Link } from 'react-router-dom'
import PATHS from 'utils/paths'

import { getTagsArray, getTagsMeta, getIsTagsLoading } from 'features/tags/ducks/selectors'
import { fetchTagsRoutine } from 'features/tags/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import AddTagModal from 'features/tags/components/AddTagModal'
import DeleteTagButton from 'features/tags/DeleteTagButton'
import EditTagModal from 'features/tags/components/EditTagModal'
import { numberWithSpaces } from 'utils/number'

export const TagsTable = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(getTagsArray)
  const isLoading = useSelector(getIsTagsLoading)
  const pagination = useSelector(getTagsMeta)
  const fetchTags = useCallback(
    query => dispatch(fetchTagsRoutine(query)),
    [dispatch]
  )

  const headers = [
    {
      id: 'name',
      sortable: true,
      cell: t('tagsPage.table.headers.name')
    },
    {
      id: 'product_count',
      sortable: true,
      cell: t('tagsPage.table.headers.productCount'),
      align: 'right'
    },
    {
      id: 'actions',
      sortable: false,
      cell: '',
      align: 'right'
    }
  ]

  const generateLinkToMonitoringFilteredByTag = tagName =>
    `${PATHS.monitoring}?sortBy=pmd.stock&dir=desc&rowsPerPage=20&page=1&tags.name=${tagName}`

  const renderItems = data.map((data, index) => (
    <TableRow key={propOr(`tags-row-${index}`, 'id', data)}>
      <TableCell>
        {propOr('-', 'name', data)}
      </TableCell>
      <TableCell align='right'>
        <Link to={generateLinkToMonitoringFilteredByTag(data.name)} target='_blank'>
          {
            pipe(
              propOr(0, 'productCount'),
              numberWithSpaces
            )(data)
          }
        </Link>
      </TableCell>
      <TableCell align='right'>
        <ActionsCell>
          <DeleteTagButton data={data} />
          <EditTagModal data={data} />
        </ActionsCell>
      </TableCell>
    </TableRow>
  ))

  return (
    <EntitiesList
      emptyStateText={t('tagsPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchTags}
      isLoading={isLoading}
      defaultSortedColumnId='name'
      withSearch
      additionalActionButtons={(
        <>
          <AddTagButtonContainer>
            <AddTagModal />
          </AddTagButtonContainer>
        </>
      )}
    />
  )
}

export default TagsTable

const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const AddTagButtonContainer = styled.div`
  margin-right: 10px;
`
