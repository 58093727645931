import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  validateEditShippingOrderSchemaField,
  validateEditShippingOrderDateSchemaField,
  validateEditShippingOrderDateSchemaValues,
  validateEditShippingOrderSchemaValues
} from 'features/shippingOrders/ducks/schema'
import { FORMATS, formatDate } from 'utils/date'

import {
  editShippingOrderRoutine,
  getShippingOrderItemsRoutine,
  editShippingOrderDateRoutine
} from 'features/shippingOrders/ducks/actions'

import Button from 'components/atoms/Button'
import IconButton from 'components/atoms/IconButton'
import Trash from 'components/icons/Trash'

import Form from 'components/atoms/Form'
import DatePickerField from 'components/formElements/DatePickerField'
import TextField from 'components/formElements/TextField'
import { getShippingOrdersItems } from 'features/shippingOrders/ducks/selectors'
import { applySpec, find, map, pipe, prop, propEq, propOr } from 'ramda'
import { reactToItemSelection } from 'utils/array'

const emptyValues = {
  name: '',
  toBeShippedAt: '',
  id: '',
  items: []
}

export const EditShippingOrderForm = (props) => {
  const { data, isShipped } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const shippingOrderItems = useSelector(state => getShippingOrdersItems(state, data.id))
  const validateField = isShipped ? validateEditShippingOrderDateSchemaField : validateEditShippingOrderSchemaField
  const validateValues = isShipped ? validateEditShippingOrderDateSchemaValues : validateEditShippingOrderSchemaValues
  const editRoutine = isShipped ? editShippingOrderDateRoutine : editShippingOrderRoutine

  const getShippingOrderItems = useCallback(
    () => dispatch(getShippingOrderItemsRoutine({ id: data.id })),
    [dispatch]
  )

  const editShippingOrder = useCallback(
    payload => dispatch(editRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    getShippingOrderItems()
  }, [])

  useEffect(() => {
    validateValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      id: data.id,
      toBeShippedAt: data.toBeShippedAt,
      name: data.name,
      items: shippingOrderItems
    })
  }, [data, shippingOrderItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    props.onSubmit()
  }

  const handleSubmit = e => {
    const preparePayload = {
      ...values,
      toBeShippedAt: formatDate(values.toBeShippedAt, FORMATS.dashedReverse),
      items: map(applySpec({
        productId: prop('product_id'),
        quantity: prop('quantity')
      }))(values.items)
    }

    e.preventDefault()
    editShippingOrder({
      values: preparePayload,
      callback: handleCallback
    })
  }

  const findShippingItemQuantityInValues = id =>
    pipe(
      propOr([], 'items'),
      find(propEq('id', id)),
      propOr('0', 'quantity')
    )(values)

  const removeShippingItem = item => () => {
    const newValue = reactToItemSelection(item)(values.items)
    handleOnChange('items', newValue)
  }

  const handleItemFieldChange = id => (name, value) => {
    const newValue =
      map(item =>
        item.id === id ? { ...item, quantity: value } : item
      )(values.items)
    handleOnChange('items', newValue)
  }

  const renderItemsFields = values.items.map((item, index) => (
    <ShippingItem key={`shipping-item-values-${index}`}>
      <div className='shipping-item-details'>
        <div className='shipping-item-detail shipping-item-detail-code'>{item.ean}</div>
        <div className='shipping-item-detail'>{item.name}</div>
      </div>
      <div className='shipping-item-form-fields' id={`shipping-order-item-form-fields-${index}`}>
        <TextField
          required
          name={`items[${index}].quantity`}
          type='number'
          label={t('form.shippingQuantity.label')}
          validate={validateField(values)}
          onChange={handleItemFieldChange(item.id)}
          value={findShippingItemQuantityInValues(item.id)}
        />
        <IconButton
          onClick={removeShippingItem(item)}
          id={`remove-shipping-item-${index}`}
        >
          <Trash />
        </IconButton>
      </div>
    </ShippingItem>
  ))

  const shippedFormFields = (
    <DatePickerField
      required
      name='toBeShippedAt'
      label={t('form.shippingDate.label')}
      onChange={handleOnChange}
      validate={validateField(values)}
      value={values.toBeShippedAt}
    />
  )

  const confirmedFormFields = (
    <>
      <TextField
        required
        name='name'
        label={t('form.orderName.label')}
        validate={validateField(values)}
        onChange={handleOnChange}
        value={values.name}
      />
      <DatePickerField
        required
        name='toBeShippedAt'
        label={t('form.shippingDate.label')}
        onChange={handleOnChange}
        validate={validateField(values)}
        value={values.toBeShippedAt}
      />
      <div>
        {renderItemsFields}
      </div>
    </>
  )

  return (
    <Form>
      {
        isShipped ? shippedFormFields : confirmedFormFields
      }
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          {t('ordersPage.editShippingOrder.save')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default EditShippingOrderForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const ShippingItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .shipping-item-details {
    flex: none;
    display: flex;
    align-items: center;
    max-width: 60%;
  }
  
  .shipping-item-detail-code {
    min-width: 100px;
  }
  
  .shipping-item-detail {
    font-weight: 500;
    padding: 0 10px;
    flex: none;
  }
  
  .shipping-item-form-fields {
    flex: none;
    display: flex;
    align-items: center;
    
    div {
      flex: none;
      flex-grow: 1;
      min-width: 80px;
      max-width: 80px;
    }
    
    button {
      flex: none;
    }
  }
`
