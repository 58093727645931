import React from 'react'
import styled from 'styled-components'

import Tooltip from 'components/atoms/Tooltip'

export const FlagCellIcon = ({ name, icon: Icon }) => {
  return (
    <Tooltip title={name}>
      <StyledPromoContainer>
        <div className='flag-cell-icon__icon'>
          <Icon />
        </div>
      </StyledPromoContainer>
    </Tooltip>
  )
}

export default FlagCellIcon

const StyledPromoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${props => props.theme.palette.text.main};
  margin-right: 3px;
  
  .flag-cell-icon__icon {
    height: auto;
    display: flex;
    align-items: center;
  
    svg {
      font-size: 20px;
    }
  }
`
