import { createSelector } from 'reselect'
import { propOr, pipe, equals, pathOr } from 'ramda'
import { API_STATES } from 'utils/redux'
import { convertPriceWithLabel } from 'utils/price'
import { numberWithSpaces, roundNumber } from 'utils/number'

export const selectMonitoring = state => state.monitoring

export const getIsMonitoringLoading = createSelector(
  selectMonitoring,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getMonitoringArray = createSelector(
  selectMonitoring,
  pipe(propOr([], 'collection'))
)

export const getMonitoringMeta = createSelector(
  selectMonitoring,
  propOr({}, 'monitoringMeta')
)

export const getMonitoringFrozenMoneySum = createSelector(
  selectMonitoring,
  pipe(
    pathOr(0, ['monitoringMeta', 'frozenMoneySum']),
    convertPriceWithLabel,
    roundNumber,
    numberWithSpaces
  )
)
