import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Table from 'components/atoms/Table/Table'
import TableBody from 'components/atoms/Table/TableBody'
import TableHeader from 'components/atoms/Table/TableHeader'
import TableHeaderCell from 'components/atoms/Table/TableHeaderCell'
import TableRow from 'components/atoms/Table/TableRow'
import TableCell from 'components/atoms/Table/TableCell'
import SadFace from 'components/icons/SadFace'
import { generateAllegroAuctionLink } from 'utils/general'
import { pathOr } from 'ramda'
import AllegroPromo from 'features/allegro/components/AllegroPromo'
import AllegroAds from 'components/icons/AllegroAds'
import Star from 'components/icons/Star'
import AllegroPackagePromo from 'components/icons/AllegroPackagePromo'
import AllegroBargain from 'components/icons/AllegroBargain'
import AllegroHalfPercent from 'components/icons/AllegroHalfPercent'
import UpdateAuctionPriceModal from 'features/productAuctions/components/UpdateAuctionPriceModal'
import SyncSingleAuction from 'features/productAuctions/SyncSingleAuction'

export const ProductBundleAuctionsList = (props) => {
  const { data, bundleProductId } = props
  const { t } = useTranslation()

  const headers = [
    {
      id: 'auction-id',
      sortable: false,
      cell: t('productPage.saleChannels.auctions.auctionId')
    },
    {
      id: 'account',
      sortable: false,
      cell: t('productPage.saleChannels.auctions.account')
    },
    {
      id: 'title',
      sortable: false,
      cell: t('productPage.saleChannels.auctions.title')
    },
    {
      id: 'price',
      sortable: false,
      cell: t('productPage.saleChannels.auctions.price'),
      align: 'right'
    },
    {
      id: 'promo',
      sortable: false,
      cell: t('productPage.saleChannels.auctions.promo')
    },
    {
      id: 'sync',
      sortable: false,
      cell: ''
    }
  ]

  const renderHeaders = headers.map(header => (
    <TableHeaderCell
      disableSticky
      key={header.id}
      columnId={header.id}
      align={header.align || 'left'}
    >
      {header.cell}
    </TableHeaderCell>
  ))

  const emptyState = (
    <EmptyState>
      <SadFace />&nbsp;
      {t('productPage.saleChannels.auctions.emptyState')}
    </EmptyState>
  )

  const renderRows = data.map(data => <Row data={data} key={`auction-details-${data.id}`} bundleProductId={bundleProductId} />)

  return data.length > 0 ? (
    <ListContainer>
      <Table padding='none'>
        <TableHeader>
          <TableRow>
            {renderHeaders}
          </TableRow>
        </TableHeader>
        <TableBody>
          {renderRows}
        </TableBody>
      </Table>
    </ListContainer>
  ) : emptyState
}

export default withRouter(ProductBundleAuctionsList)

const Row = (props) => {
  const { t } = useTranslation()

  return (
    <TableRow hover>
      <TableCell>
        <a href={generateAllegroAuctionLink(pathOr('', ['data', 'offerId'], props))} target='_blank' rel='noopener noreferrer'>
          {pathOr('', ['data', 'offerId'], props)}
        </a>
      </TableCell>
      <TableCell>
        {pathOr('', ['data', 'integration', 'login'], props)}
      </TableCell>
      <TableCell>
        {pathOr('', ['data', 'title'], props)}
      </TableCell>
      <TableCell align='right'>
        {pathOr('0.00', ['data', 'price'], props)}
        <UpdateAuctionPriceModal bundleProductId={props.bundleProductId} data={props.data} />
      </TableCell>
      <TableCell>
        <PromoCellContainer>
          {pathOr(false, ['data', 'promotions', 'ads'], props) && <AllegroPromo logo={<AllegroAds />} promoType={t('monitoringPage.table.allegroPromoTypes.hasAds')} />}
          {pathOr(false, ['data', 'promotions', 'emphasized'], props) && <AllegroPromo logo={<Star />} promoType={t('monitoringPage.table.allegroPromoTypes.isEmphasized')} />}
          {pathOr(false, ['data', 'promotions', 'promotionPackage'], props) && <AllegroPromo logo={<AllegroPackagePromo />} promoType={t('monitoringPage.table.allegroPromoTypes.hasPromotionPackage')} />}
          {pathOr(false, ['data', 'promotions', 'bargain'], props) && <AllegroPromo logo={<AllegroBargain />} promoType={t('monitoringPage.table.allegroPromoTypes.isBargain')} />}
          {pathOr(false, ['data', 'promotions', 'halfPercent'], props) && <AllegroPromo logo={<AllegroHalfPercent />} promoType={t('monitoringPage.table.allegroPromoTypes.hasHalfPercent')} />}
        </PromoCellContainer>
      </TableCell>
      <TableCell align='right'>
        <SyncSingleAuction offerId={pathOr('', ['data', 'offerId'], props)} />
      </TableCell>
    </TableRow>
  )
}

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
`

const PromoCellContainer = styled.div`
  display: flex;
`

const ListContainer = styled.div`
  margin-bottom: 30px;
`
