import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Account = Record({
  id: '',
  created_at: '',
  updated_at: '',
  seller_id: '',
  login: '',
  name: '',
  token_type: '',
  access_token: '',
  refresh_token: '',
  is_default: true,
  is_active: true
})

export const AccountsMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Allegro = Record({
  state: API_STATES.PRISTINE,
  accountsMeta: new AccountsMeta(),
  accounts: {}
})
