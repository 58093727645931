import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const WeightSchema = Record({
  created_at: '',
  file_name: '',
  id: '',
  name: '',
  slug: '',
  is_default: null
})

export const WeightSchemas = Record({
  state: API_STATES.PRISTINE,
  weightSchemas: []
})
