import { Lines, Line, LinesMeta } from 'features/lines/ducks/records'
import { fetchLinesRoutine } from 'features/lines/ducks/actions'
import {
  API_STATES,
  transferCollectionToImmutable
} from 'utils/redux'

export default (state = new Lines(), action) => {
  switch (action.type) {
    case fetchLinesRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchLinesRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchLinesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('lines', transferCollectionToImmutable(action.payload.data, Line))
        .set('linesMeta', new LinesMeta(action.payload.meta))
    default:
      return state
  }
}
