import { ProductComments, ProductComment, ProductCommentMeta } from 'features/comments/ducks/records'
import { createProductCommentRoutine, fetchProductCommentsRoutine } from 'features/comments/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new ProductComments(), action) => {
  switch (action.type) {
    case createProductCommentRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case createProductCommentRoutine.FAILURE:
    case createProductCommentRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
    case fetchProductCommentsRoutine.SUCCESS:
      return state
        .set('products', {
          ...state.products,
          [action.payload.productId]: {
            comments: transferCollectionToImmutable(action.payload.result.data, ProductComment),
            meta: new ProductCommentMeta(action.payload.result.meta)
          }
        })
    default:
      return state
  }
}
