import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { SORT_ORDER } from 'utils/table'

import { fetchUsersRoutine } from 'features/users/ducks/actions'
import { fetchLinesRoutine } from 'features/lines/ducks/actions'
import { getUserLoginAsOptions } from 'features/users/ducks/selectors'

import SingleSelectFilter from 'components/tableFilters/SingleSelectFilter'
import Grid from 'components/atoms/Grid'
import { booleanFilterOptions } from 'utils/filters'
import { getUserRole } from 'features/auth/ducks/selectors'
import { equals } from 'ramda'
import { USER_ROLES } from 'utils/user'

export const AlertMentionsFilters = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userOptions = useSelector(getUserLoginAsOptions)
  const userRole = useSelector(getUserRole)
  const isAdmin = equals(userRole, USER_ROLES.admin)

  const fetchUsers = useCallback(
    query => dispatch(fetchUsersRoutine(query)),
    [dispatch]
  )
  const fetchLines = useCallback(
    query => dispatch(fetchLinesRoutine(query)),
    [dispatch]
  )

  useEffect(() => {
    fetchUsers(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'login',
        dir: SORT_ORDER.asc
      }
    }))
    fetchLines(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'name',
        dir: SORT_ORDER.asc
      }
    }))
  }, [])

  return (
    <StyledContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <SingleSelectFilter options={userOptions} label={t('monitoringPage.table.headers.userLogin')} name={API_FIELD_NAME.alertsUserLogin} />
        </Grid>
        {
          isAdmin && (
            <Grid item xs={12} sm={12}>
              <SingleSelectFilter options={booleanFilterOptions()} label={t('alertsPage.statuses.archived')} name={API_FIELD_NAME.isAlertArchived} />
            </Grid>
          )
        }
      </Grid>
    </StyledContainer>
  )
}

export default AlertMentionsFilters

const StyledContainer = styled.div`
  max-width: 400px; 
  
  ${props => props.theme.breakpointsMedia.mobile} {
    max-width: 100%;
  }
`
