import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const SaleChannel = Record({
  id: '',
  name: ''
})

export const ProductSaleChannel = Record({
  id: '',
  sale_channel_id: '',
  product_id: '',
  status: '',
  link: '',
  saleChannel: {
    id: '',
    name: ''
  }
})

export const SaleChannelsMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const SaleChannels = Record({
  state: API_STATES.PRISTINE,
  saleChannelsMeta: new SaleChannelsMeta(),
  saleChannels: [],
  productSaleChannels: []
})
