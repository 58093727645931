import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getProductTags } from 'features/products/ducks/selectors'

import AssignTagToProductModal from 'features/tags/components/AssignTagToProductModal'
import ProductTag from 'features/products/components/ProductTag'
import { compose, prop, sortBy, toLower } from 'ramda'

export const ProductTags = (props) => {
  const { match: { params: { id } } } = props
  const tags = useSelector(state => getProductTags(state, id))
  const sortedTags = sortBy(compose(toLower, prop('name')))(tags)

  const renderTags = sortedTags.map(tag => <ProductTag key={tag.id} tagId={tag.id} name={tag.name} />)

  return (
    <ProductTagsContainer>
      <AssignTagToProductModal />
      {renderTags}
    </ProductTagsContainer>
  )
}

export default withRouter(ProductTags)

const ProductTagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    justify-content: flex-start;
  }
`
