import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  validateCreateShippingOrderSchemaField,
  validateCreateShippingOrderSchemaValues
} from 'features/shippingOrders/ducks/schema'

import { getSelectedItems } from 'ducks/global/selectors'
import { createShippingOrderRoutine } from 'features/shippingOrders/ducks/actions'

import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import DatePickerField from 'components/formElements/DatePickerField'
import { handleResetSelectionRoutine } from 'ducks/global/actions'
import TextField from 'components/formElements/TextField'

const emptyValues = {
  name: '',
  shippingDate: '',
  productIds: []
}

export const CreateShippingOrderForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [loading, _setLoading] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const selectedItems = useSelector(getSelectedItems)
  const dispatch = useDispatch()

  const createShippingOrder = useCallback(
    payload => dispatch(createShippingOrderRoutine(payload)),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    validateCreateShippingOrderSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('productIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    props.onSubmit()
    handleResetSelection()
    _setLoading(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    _setLoading(true)
    createShippingOrder({
      values,
      callback: handleCallback
    })
  }

  return (
    <Form>
      <TextField
        required
        name='name'
        label={t('form.orderName.label')}
        onChange={handleOnChange}
        validate={validateCreateShippingOrderSchemaField(values)}
      />
      <DatePickerField
        disablePast
        required
        name='shippingDate'
        label={t('form.shippingDate.label')}
        onChange={handleOnChange}
        validate={validateCreateShippingOrderSchemaField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit-create-order'
          disabled={!valid || loading}
          onClick={handleSubmit}
        >
          {t('ordersPage.createShippingOrder.save')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default CreateShippingOrderForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
