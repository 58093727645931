import { WillLastHistory, WillLastHistoryMeta, WillLastHistoryRecord } from 'features/willLastHistory/ducks/records'
import { fetchWillLastHistoryRoutine } from 'features/willLastHistory/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new WillLastHistory(), action) => {
  switch (action.type) {
    case fetchWillLastHistoryRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchWillLastHistoryRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchWillLastHistoryRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('willLastHistory', transferCollectionToImmutable(action.payload.data, WillLastHistoryRecord))
        .set('willLastHistoryMeta', new WillLastHistoryMeta(action.payload.meta))
    default:
      return state
  }
}
