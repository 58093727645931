import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getProductCumulativeSaleChart } from 'features/products/ducks/selectors'
import { fetchCumulativeSaleChartRoutine } from 'features/products/ducks/actions'

import Chart from 'components/Chart'
import CumulativeSaleChartDatesForm from 'features/products/CumulativeSaleChartDatesForm'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  all, applySpec,
  equals,
  find,
  findIndex,
  includes,
  isNil,
  length,
  map,
  pipe, prop,
  propEq,
  propOr, slice,
  uniq
} from 'ramda'
import { formatDate, FORMATS } from 'utils/date'
import { getHasCurrentSalePlanValid } from 'features/salePlans/ducks/selectors'
import { getSelectedTheme } from 'ducks/global/selectors'
import { generateSelectedTheme } from 'utils/theme'
import { getDaysOffArray } from 'features/daysOff/ducks/selectors'

export const CumulativeSaleChart = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const data = useSelector(state => getProductCumulativeSaleChart(state, id))
  const hasCurrentSalePlanValid = useSelector(state => getHasCurrentSalePlanValid(state, id))
  const selectedThemeMode = useSelector(getSelectedTheme)
  const selectedTheme = generateSelectedTheme(selectedThemeMode)
  const [dateRanges, setDateRanges] = useState({ dateFrom: '', dateTo: '' })

  const findDateIndex = (date) => {
    const formattedDate = formatDate(date, FORMATS.dashedReverse)
    return pipe(
      propOr([], 'xaxis'),
      findIndex(equals(formattedDate))
    )(data)
  }

  const sliceArray = propName => data => pipe(
    propOr([], propName),
    slice(findDateIndex(dateRanges.dateFrom), findDateIndex(dateRanges.dateTo) + 1)
  )(data)

  const dataFromRanges = isNotNilOrEmpty(data)
    ? {
      xaxis: sliceArray('xaxis')(data),
      yaxis: pipe(
        propOr([], 'yaxis'),
        map(
          applySpec({
            name: prop('name'),
            data: sliceArray('data')
          })
        )
      )(data)
    } : data

  const getXaxisCategories = () => {
    const xaxisValues = propOr([], 'xaxis', dataFromRanges)
    const isTodayChart = pipe(
      map(date => formatDate(date, FORMATS.dashedReverse)),
      uniq,
      length,
      equals(1)
    )(xaxisValues)

    const timesArray = map(date => formatDate(date, FORMATS.time))(xaxisValues)

    return isTodayChart ? timesArray : xaxisValues
  }

  const daysOff = useSelector(getDaysOffArray)

  const xAxisLabelColors = () => {
    const dataFromRanges = getXaxisCategories()
    return dataFromRanges.map(date =>
      includes(date, daysOff)
        ? selectedTheme.palette.common.gray300
        : selectedTheme.palette.common.black
    )
  }

  const isSeriesAllNullable = seriesName => pipe(
    propOr([], 'yaxis'),
    find(propEq('name', seriesName)),
    propOr([], 'data'),
    all(isNil)
  )(dataFromRanges)

  const options = {
    markers: {
      size: [3, 3, 3]
    },
    stroke: {
      width: 1
    },
    chart: {
      id: 'cumulative-sale-chart'
    },
    xaxis: {
      categories: getXaxisCategories(),
      labels: {
        style: {
          colors: xAxisLabelColors()
        }
      }
    },
    yaxis: [
      {
        forceNiceScale: true,
        decimalsInFloat: 0,
        seriesName: t('productPage.charts.saleChart.seriesNames.sale'),
        title: {
          text: 'Ilość (szt.)'
        }
      },
      {
        forceNiceScale: true,
        decimalsInFloat: 2,
        show: !isSeriesAllNullable('margin'),
        seriesName: t('productPage.charts.saleChart.seriesNames.margin'),
        opposite: true,
        title: {
          text: 'Marża (%)'
        }
      },
      {
        forceNiceScale: true,
        decimalsInFloat: 2,
        seriesName: t('productPage.charts.saleChart.seriesNames.sale'),
        show: false,
        title: {
          text: 'Ilość (szt.)'
        }
      }
    ]
  }

  const getSeriesData = seriesName => pipe(
    propOr([], 'yaxis'),
    find(propEq('name', seriesName)),
    propOr([], 'data')
  )(dataFromRanges)

  const series = [
    {
      data: getSeriesData('sale').map(value => value === null ? 0 : Number(value)),
      name: t('productPage.charts.saleChart.seriesNames.sale'),
      type: 'line'
    },
    {
      data: getSeriesData('margin').map(value => value === null ? null : Number(value)),
      name: t('productPage.charts.saleChart.seriesNames.margin'),
      type: 'line'
    },
    {
      data: getSeriesData('plan'),
      name: t('productPage.charts.saleChart.seriesNames.plan'),
      type: 'line'
    }
  ]

  return hasCurrentSalePlanValid && (
    <ChartContainer>
      <ChartTitle>{t('productPage.charts.cumulativeSaleChart.title')}</ChartTitle>
      {
        isNotNilOrEmpty(dataFromRanges) && (
          <Chart options={options} series={series} type='line' width='100%' />
        )
      }
      <CumulativeSaleChartDatesForm setDateRanges={setDateRanges} enableFuture fetchChartRoutine={fetchCumulativeSaleChartRoutine} />
    </ChartContainer>
  )
}

export default withRouter(CumulativeSaleChart)

const ChartContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
`

const ChartTitle = styled.div`
  font-size: 23px;
  font-weight: 500;
`
