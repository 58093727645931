import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIaiErrorExists, getIaiErrorMessage, getIsIntegrationsLoading } from 'features/integrations/ducks/selectors'
import {
  checkIaiConnectionRoutine
} from 'features/integrations/ducks/actions'

import IntegrationAccount from 'features/integrations/components/IntegrationAccount'
import IntegrationsContainer from 'features/integrations/components/IntegrationsContainer'

export const IaiAccount = (props) => {
  const dispatch = useDispatch()
  const hasError = useSelector(getIaiErrorExists)
  const isLoading = useSelector(getIsIntegrationsLoading)
  const errorMessage = useSelector(getIaiErrorMessage)

  const refresh = useCallback(
    () => dispatch(checkIaiConnectionRoutine()),
    [dispatch]
  )

  const handleRefresh = () => refresh()

  return (
    <IntegrationsContainer>
      <IntegrationAccount name='IAI (IdoSell)' details={errorMessage} isActive={!hasError} loading={isLoading} handleRefresh={handleRefresh} />
    </IntegrationsContainer>
  )
}

export default IaiAccount
