import { Integrations, Iai, Allegro } from 'features/integrations/ducks/records'
import { getIaiIntegrationStatusRoutine, checkIaiConnectionRoutine, getAllegroIntegrationStatusRoutine } from 'features/integrations/ducks/actions'
import { API_STATES } from 'utils/redux'

export default (state = new Integrations(), action) => {
  switch (action.type) {
    case checkIaiConnectionRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case checkIaiConnectionRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case getIaiIntegrationStatusRoutine.SUCCESS:
    case checkIaiConnectionRoutine.SUCCESS:
      return state
        .set('iai', new Iai(action.payload))
        .set('state', API_STATES.DONE)
    case getAllegroIntegrationStatusRoutine.SUCCESS:
      return state
        .set('allegro', new Allegro(action.payload))
    default:
      return state
  }
}
