export default {
  shape: {
    borderRadius: '4px'
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
  },
  zIndex: {
    appBar: 1100,
    sideBar: 1050,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    dropdown: 1700,
    sticky: 1000,
    loadingOverlay: 1001
  },
  typography: {
    fontFamily: '\'Jost\', \'Open Sans\', sans-serif'
  },
  dimensions: {
    notificationHeight: '40px',
    navHeight: '60px',
    inputHeight: '48px',
    buttonNormalHeight: '48px',
    buttonSmallHeight: '32px',
    buttonLargeHeight: '60px',
    sideNavFullWidth: '240px',
    sideNavShrinkWidth: '59px'
  },
  breakpointsMedia: {
    mobile: '@media (max-width: 768px)',
    smallDesktop: '@media (max-width: 1024px)'
  }
}
