import { Tags, Tag, TagsMeta } from 'features/tags/ducks/records'
import { fetchTagsRoutine } from 'features/tags/ducks/actions'
import {
  API_STATES,
  transferCollectionToImmutable
} from 'utils/redux'

export default (state = new Tags(), action) => {
  switch (action.type) {
    case fetchTagsRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchTagsRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchTagsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('tags', transferCollectionToImmutable(action.payload.data, Tag))
        .set('tagsMeta', new TagsMeta(action.payload.meta))
    default:
      return state
  }
}
