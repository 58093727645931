import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import {
  booleanFilterOptions,
  allegroMultiSelectFilterOptions,
  handleSearchTagFilterOptions,
  transitFilterOptions,
  flagsMultiSelectFilterOptions, nullableFilterOptions
} from 'utils/filters'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { SORT_ORDER } from 'utils/table'

import { getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'
import { fetchUsersRoutine } from 'features/users/ducks/actions'
import { fetchLinesRoutine } from 'features/lines/ducks/actions'
import { getUserLoginAsOptions } from 'features/users/ducks/selectors'
import { getLineNamesAsOptions } from 'features/lines/ducks/selectors'
import { getSaleChannelsNamesAsOptions } from 'features/saleChannels/ducks/selectors'

import NumericFilter from 'components/tableFilters/NumericFilter'
import MultiSelectFilter from 'components/tableFilters/MultiSelectFilter'
import SingleSelectFilter from 'components/tableFilters/SingleSelectFilter'
import BooleanMultiSelectFilter from 'components/tableFilters/BooleanMultiSelectFilter'
import DateFilter from 'components/tableFilters/DateFilter'
import AsyncSelectFilter from 'components/tableFilters/AsyncSelectFilter'
import Grid from 'components/atoms/Grid'
import { getWeightSchemasAsFilterOptions } from 'features/weightSchemas/ducks/selectors'
import { fetchWeightSchemasRoutine } from 'features/weightSchemas/ducks/actions'

export const CartFilters = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)
  const userOptions = useSelector(getUserLoginAsOptions)
  const lineOptions = useSelector(getLineNamesAsOptions)
  const saleChannelsOptions = useSelector(getSaleChannelsNamesAsOptions)
  const weightSchemaOptions = useSelector(getWeightSchemasAsFilterOptions)

  const fetchUsers = useCallback(
    query => dispatch(fetchUsersRoutine(query)),
    [dispatch]
  )
  const fetchLines = useCallback(
    query => dispatch(fetchLinesRoutine(query)),
    [dispatch]
  )

  const fetchWeightSchemas = useCallback(
    () => dispatch(fetchWeightSchemasRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchUsers(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'login',
        dir: SORT_ORDER.asc
      }
    }))
    fetchLines(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'name',
        dir: SORT_ORDER.asc
      }
    }))
    fetchWeightSchemas()
  }, [])

  return (
    <StyledContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.stock')} name={API_FIELD_NAME.stock} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter shouldBeChangedToPennies label={t('monitoringPage.table.headers.retailPrice')} name={API_FIELD_NAME.retailPrice} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter shouldBeChangedToPennies label={t('monitoringPage.table.headers.purchasePrice')} name={API_FIELD_NAME.purchasePrice} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter shouldBeChangedToPennies label={t('monitoringPage.table.headers.frozenMoney')} name={API_FIELD_NAME.frozenMoney} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.margin')} name={API_FIELD_NAME.margin} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.soldToday')} name={API_FIELD_NAME.soldToday} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.sold1')} name={API_FIELD_NAME.sold1} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.soldX', { count: soldShortValue })} name={API_FIELD_NAME.soldShort} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.soldX', { count: soldLongValue })} name={API_FIELD_NAME.soldLong} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.willLastX', { count: soldShortValue })} name={API_FIELD_NAME.willLastShort} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.willLastX', { count: soldLongValue })} name={API_FIELD_NAME.willLastLong} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.salesDynamics')} name={API_FIELD_NAME.salesDynamics} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter shouldBeChangedToPennies label={t('monitoringPage.table.headers.allegroFee')} name={API_FIELD_NAME.allegroFee} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={userOptions} label={t('monitoringPage.table.headers.userLogin')} name={API_FIELD_NAME.userLogin} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={booleanFilterOptions()} label={t('monitoringPage.table.headers.isBundled')} name={API_FIELD_NAME.isBundled} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <BooleanMultiSelectFilter options={allegroMultiSelectFilterOptions()} label={t('monitoringPage.table.headers.promo')} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MultiSelectFilter options={lineOptions} label={t('monitoringPage.table.headers.line')} name={API_FIELD_NAME.linesName} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AsyncSelectFilter label={t('monitoringPage.table.headers.tags')} name={API_FIELD_NAME.tagsName} loadOptions={handleSearchTagFilterOptions} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AsyncSelectFilter isMulti disablseArray loadOptions={handleSearchTagFilterOptions} label={t('monitoringPage.table.headers.tagsExcluded')} name={API_FIELD_NAME.tagsExcluded} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={transitFilterOptions()} label={t('monitoringPage.table.headers.virtualStock')} name={API_FIELD_NAME.virtualStock} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={nullableFilterOptions()} label={t('monitoringPage.table.headers.autoDecisionSnoozedUntil')} name={API_FIELD_NAME.autoDecisionSnoozedUntil} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={booleanFilterOptions()} label={t('monitoringPage.table.headers.isActive')} name={API_FIELD_NAME.isActive} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <BooleanMultiSelectFilter options={flagsMultiSelectFilterOptions()} label={t('monitoringPage.table.headers.flags')} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <BooleanMultiSelectFilter exclude options={flagsMultiSelectFilterOptions()} label={t('monitoringPage.table.headers.flagsExcluded')} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumericFilter label={t('monitoringPage.table.headers.estimatedShippingTime')} name={API_FIELD_NAME.estimatedShippingTime} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MultiSelectFilter disableArray options={saleChannelsOptions} label={t('monitoringPage.table.headers.saleChannelExists')} name={API_FIELD_NAME.saleChannelExists} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={weightSchemaOptions} label={t('monitoringPage.table.headers.weightSchema')} name={API_FIELD_NAME.weightSchema} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SingleSelectFilter options={booleanFilterOptions()} label={t('monitoringPage.table.headers.activeSalePlan')} name={API_FIELD_NAME.activeSalePlan} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateFilter label={t('monitoringPage.table.headers.lastChange')} name={API_FIELD_NAME.lastChange} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateFilter label={t('monitoringPage.table.headers.iaiCreatedAt')} name={API_FIELD_NAME.iaiCreatedAt} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateFilter label={t('monitoringPage.table.headers.activatedAt')} name={API_FIELD_NAME.activatedAt} />
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default CartFilters

const StyledContainer = styled.div`
  max-width: 1000px; 
  
  ${props => props.theme.breakpointsMedia.mobile} {
    max-width: 100%;
  }
`
