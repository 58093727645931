import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  fetchWeightSchemasRoutine,
  setWeightSchemaAsDefaultRoutine,
  unsetDefaultWeightSchemaRoutine
} from 'features/weightSchemas/ducks/actions'
import { getWeightSchemasArray } from 'features/weightSchemas/ducks/selectors'

import DeleteWeightSchema from 'features/weightSchemas/DeleteWeightSchema'
import EditWeightSchemaModal from 'features/weightSchemas/components/EditWeightSchemaModal'
import SadFace from 'components/icons/SadFace'
import Close from 'components/icons/Close'
import Button from 'components/atoms/Button'
import { useTranslation } from 'react-i18next'
import { formatDate, FORMATS } from 'utils/date'

export const WeightSchemasList = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const data = useSelector(getWeightSchemasArray)

  const fetchWeightSchemas = useCallback(
    () => dispatch(fetchWeightSchemasRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchWeightSchemas()
  }, [])

  const renderWeightSchemas = data.map((schema, index) => <WeightSchemaItem key={schema.id} index={index} data={schema} />)

  const emptyState = (
    <EmptyState>
      <SadFace />&nbsp;
      {t('settingsPage.weightSchema.emptyState')}
    </EmptyState>
  )

  return (
    <ListContainer>{data.length > 0 ? renderWeightSchemas : emptyState}</ListContainer>
  )
}

export default WeightSchemasList

const SetAsDefaultButton = props => {
  const { isDefault, id, index } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const setAsDefault = useCallback(
    () => dispatch(setWeightSchemaAsDefaultRoutine({ id })),
    [dispatch]
  )
  const unsetDefault = useCallback(
    () => dispatch(unsetDefaultWeightSchemaRoutine()),
    [dispatch]
  )

  return isDefault
    ? (
      <Button
        variant='outlined'
        size='small'
        endIcon={<Close />}
        onClick={unsetDefault}
        id={`unset-default-weight-schema-${index}`}
      >
        {t('settingsPage.weightSchema.default')}
      </Button>
    ) : (
      <Button
        variant='outlined'
        color='default'
        size='small'
        onClick={setAsDefault}
        id={`set-default-weight-schema-${index}`}
      >
        {t('settingsPage.weightSchema.setAsDefault')}
      </Button>
    )
}

const WeightSchemaItem = props => {
  return (
    <ItemContainer>
      <div>
        <div>{props.data.name}</div>
        <div className='item__date'>{formatDate(props.data.created_at, FORMATS.slashTime)}</div>
      </div>
      <div>
        <SetAsDefaultButton
          index={props.index}
          isDefault={props.data.is_default}
          id={props.data.id}
        />
        <EditWeightSchemaModal data={props.data} />
        <DeleteWeightSchema id={props.data.id} />
      </div>
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  
  .item__date {
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: .8px;
    color: ${props => props.theme.palette.common.gray400};
  }
`

const ListContainer = styled.div`
  margin-top: 20px;
`

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
`
