import { propOr, pipe, equals } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'

export const selectProducers = state => state.producers

export const getIsProducersLoading = createSelector(
  selectProducers,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProducersMeta = createSelector(
  selectProducers,
  propOr({}, 'producersMeta')
)

export const getProducersArray = createSelector(
  selectProducers,
  propOr([], 'producers')
)
