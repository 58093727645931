import React from 'react'
import Icon from 'components/Icon'

export const PriceDown = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 12 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='M4.6,3.5c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.3-0.3,0.5-0.3l0-0.7l0.9,0l0,0.7c0.6,0.2,1,0.7,1,1.4l-0.9,0C6.5,3.3,6.3,3,6,3
          C5.7,3,5.5,3.3,5.5,3.5c0,0.2,0.2,0.3,0.7,0.6l0,0c0.4,0.2,1.1,0.6,1.1,1.4c0,0.5-0.3,1-0.8,1.2l0,0.8l-0.9,0l0-0.8
          C5.1,6.6,4.7,6.1,4.7,5.6l0.9,0c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.2,0.4-0.3c0-0.3-0.1-0.4-0.6-0.6l0,0C5.5,4.8,5.2,4.7,5,4.5
          C4.7,4.2,4.6,3.9,4.6,3.5z M6,12l6-6L9.3,6l0-2.5l-0.9,0l0,3.4l1.4,0L6,10.7L2.3,6.9l1.4,0l0-3.4l-0.9,0l0,2.5L0,6L6,12z M9.3,0
          L8.4,0l0,0.9l0.9,0L9.3,0z M3.7,0L2.7,0l0,0.9l0.9,0L3.7,0z M9.3,1.6l-0.9,0l0,1.2l0.9,0L9.3,1.6z M3.7,1.6l-0.9,0l0,1.2l0.9,0
          L3.7,1.6z'
        />
      </g>
    </Icon>
  )
}

export default PriceDown
