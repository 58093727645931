import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SECTION_IDS } from 'utils/productPage'

import SectionMenuItem from 'features/products/components/SectionMenuItem'
import Paper from 'components/atoms/Paper'

export const SectionsMenu = (props) => {
  const { t } = useTranslation()

  return (
    <SectionsMenuContainer>
      <SectionMenuItem
        text={t('productPage.menu.sale')}
        id={SECTION_IDS.sale}
      />
      <SectionMenuItem
        text={t('productPage.menu.todayOrders')}
        id={SECTION_IDS.todayOrders}
      />
      <SectionMenuItem
        text={t('productPage.menu.comments')}
        id={SECTION_IDS.comments}
      />
      <SectionMenuItem
        text={t('productPage.menu.flags')}
        id={SECTION_IDS.flags}
      />
      <SectionMenuItem
        text={t('productPage.menu.bundles')}
        id={SECTION_IDS.bundles}
      />
      <SectionMenuItem
        text={t('productPage.menu.saleChannels')}
        id={SECTION_IDS.saleChannels}
      />
      <SectionMenuItem
        text={t('productPage.menu.promotions')}
        id={SECTION_IDS.promotions}
      />
      <SectionMenuItem
        text={t('productPage.menu.delivery')}
        id={SECTION_IDS.delivery}
      />
      <SectionMenuItem
        text={t('productPage.menu.willLastHistory')}
        id={SECTION_IDS.willLastHistory}
      />
    </SectionsMenuContainer>
  )
}

export default SectionsMenu

const SectionsMenuContainer = styled(Paper)`
  display: flex;
  padding: 15px;
  flex-direction: column;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`
