import React from 'react'
import ProductDeliveriesList from 'features/deliveries/ProductDeliveriesList'

export const DeliverySection = (props) => {
  return (
    <div>
      <ProductDeliveriesList />
    </div>
  )
}

export default DeliverySection
