import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import qs from 'qs'
import {
  validateAssignUserToLinesField,
  validateAssignUserToLinesValues
} from 'features/lines/ducks/schema'

import { getSelectedItems } from 'ducks/global/selectors'
import { fetchUsersRoutine } from 'features/users/ducks/actions'
import { getUsersAsOptions } from 'features/users/ducks/selectors'
import { assignLinesToUserRoutine } from 'features/lines/ducks/actions'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

import Grid from 'components/atoms/Grid'
import SingleSelect from 'components/formElements/SingleSelectField'
import Button from 'components/atoms/Button'
import Paper from 'components/atoms/Paper'
import IconButton from 'components/atoms/IconButton'
import Close from 'components/icons/Close'

const emptyValues = {
  userId: '',
  lineIds: []
}

export const AssignUserToLinesForm = (props) => {
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const userOptions = useSelector(getUsersAsOptions)
  const dispatch = useDispatch()
  const fetchUsers = useCallback(
    query => dispatch(fetchUsersRoutine(query)),
    [dispatch]
  )
  const assignLinesToUser = useCallback(
    payload => dispatch(assignLinesToUserRoutine(payload)),
    [dispatch]
  )
  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchUsers(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      }
    }))
  }, [])

  useEffect(() => {
    validateAssignUserToLinesValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('lineIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    assignLinesToUser(values)
  }

  return (
    <StyledPaper>
      <AssignUserContainer>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className='assign-user__summary'>
              <span>
                {t('linesPage.assignLine.selectedItems', { count: selectedItems.length })}
              </span>
              {
                selectedItems.length > 0 && (
                  <div className='assign-user__reset'>
                    <IconButton size='small' onClick={handleResetSelection}>
                      <Close />
                    </IconButton>
                  </div>
                )
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <form className='assign-user__form' onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <SingleSelect
                    options={userOptions}
                    required
                    name='userId'
                    label={t('form.user.label')}
                    onChange={handleOnChange}
                    validate={validateAssignUserToLinesField(values)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className='assign-user__submit'>
                    <Button
                      type='submit'
                      id='button-submit'
                      disabled={!valid}
                      onClick={handleSubmit}
                    >
                      {t('linesPage.assignLine.assignCTA')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </AssignUserContainer>
    </StyledPaper>
  )
}

export default AssignUserToLinesForm

const StyledPaper = styled(Paper)`
  padding: 10px 33px;
  margin-bottom: 20px;
`

const AssignUserContainer = styled.div`
  .assign-user__summary {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-bottom: 10px;
    }
  }
  
  .assign-user__reset {
    margin-right: 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-right: 0;
    }
  }
  
  .assign-user__form {
    
  }
  
  .assign-user__submit {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin: 0;
    }
  }
`
