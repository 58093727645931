import { useEffect, useRef } from 'react'
import qs from 'qs'
import { omit, pipe, propOr } from 'ramda'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { SORT_ORDER, TABLE_ROWS_LENGTH } from 'utils/table'
import { isNotNilOrEmpty, isNilOrEmpty, notEquals } from 'utils/ramda'
import debounce from 'lodash.debounce'
import { useHistory } from 'react-router-dom'
import usePrevious from 'hooks/usePrevious'
import PATHS from 'utils/paths'
import { generateCurrentQuery, generateCurrentResetPaginationQuery } from 'utils/query'

export default ({ defaultSortDirection, defaultSortedColumnId, fetchRecords }) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { location: { search, pathname } } = useHistory()
  const prevSearch = usePrevious(search)

  const currentSearch = () => urlSearchQuery.getSearchQuery()

  // Set required pagination and sort defaults if needed

  useEffect(() => {
    const sortBy = propOr(defaultSortedColumnId, 'sortBy', urlSearchQuery.getSearchQuery())
    const dir = propOr(defaultSortDirection, 'dir', urlSearchQuery.getSearchQuery())
    const rowsPerPage = propOr(TABLE_ROWS_LENGTH, 'rowsPerPage', urlSearchQuery.getSearchQuery())
    const page = propOr(1, 'page', urlSearchQuery.getSearchQuery())

    if (isNotNilOrEmpty(defaultSortedColumnId)) {
      urlSearchQuery.setSearchQuery({
        sortBy: sortBy,
        dir: dir || SORT_ORDER.asc
      })
    }

    if (isNilOrEmpty(currentSearch().rowsPerPage)) {
      urlSearchQuery.setSearchQuery({
        rowsPerPage: rowsPerPage
      })
    }

    if (isNilOrEmpty(currentSearch().page)) {
      urlSearchQuery.setSearchQuery({
        page: page
      })
    }
  }, [])

  // helpers

  const getSelectedFiltersFromQuery =
    pathname === PATHS.ordersHistory
      ? omit([
        'rowsPerPage',
        'page',
        'sortBy',
        'dir'
      ])
      : omit([
        'rowsPerPage',
        'page',
        'sortBy',
        'dir',
        'search'
      ])

  const selectedFilters = () => getSelectedFiltersFromQuery(urlSearchQuery.getSearchQuery())

  const dropPaginationFields = omit([
    'rowsPerPage',
    'page'
  ])

  const getQueryFieldsWithoutPagination = pipe(
    search => qs.parse(search, { ignoreQueryPrefix: true }),
    dropPaginationFields
  )

  // on change handlers

  const onPageChange = page => urlSearchQuery.setSearchQuery({ page })

  const onRowsPerPageChange = rowsPerPage => urlSearchQuery.setSearchQuery({ rowsPerPage })

  const onChangeSort = (columnId) => {
    const isAsc = currentSearch().sortBy === columnId && currentSearch().dir === SORT_ORDER.asc
    const newDirection = isAsc ? SORT_ORDER.desc : SORT_ORDER.asc
    urlSearchQuery.setSearchQuery({
      dir: newDirection,
      sortBy: columnId
    })
  }

  const onChangeSearchInput = e =>
    urlSearchQuery.setSearchQuery({
      search: e.target.value
    })

  const onResetSearchInput = () =>
    urlSearchQuery.setSearchQuery({
      search: ''
    })

  // Handle fetch records on query change

  const initialQuery =
    defaultSortedColumnId ? {
      sortBy: defaultSortedColumnId,
      dir: defaultSortDirection || SORT_ORDER.asc,
      page: 1,
      rowsPerPage: TABLE_ROWS_LENGTH
    } : {
      page: 1,
      rowsPerPage: TABLE_ROWS_LENGTH
    }

  const fetchRecordsWithCurrentQueries = query => () => fetchRecords(query())
  const debouncedFetchRecordsAndResetPagination = useRef(debounce(fetchRecordsWithCurrentQueries(generateCurrentResetPaginationQuery), 400))
  const debouncedFetchRecordsWithCurrentPagination = useRef(debounce(fetchRecordsWithCurrentQueries(generateCurrentQuery), 400))

  useEffect(() => {
    const prev = getQueryFieldsWithoutPagination(prevSearch)
    const curr = getQueryFieldsWithoutPagination(search)

    if (isNilOrEmpty(search)) {
      urlSearchQuery.setSearchQuery(initialQuery)
      debouncedFetchRecordsAndResetPagination.current()
    } else if (isNotNilOrEmpty(prevSearch) && notEquals(prev)(curr)) {
      urlSearchQuery.setSearchQuery({
        page: 1
      })
      debouncedFetchRecordsAndResetPagination.current()
    } else {
      debouncedFetchRecordsWithCurrentPagination.current()
    }
  }, [search])

  return {
    // current entities list search state
    currentSearch,
    // on change handlers
    onPageChange,
    onRowsPerPageChange,
    onResetSearchInput,
    onChangeSearchInput,
    onChangeSort,
    // filters helper
    selectedFilters
  }
}
