import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const WillLastHistoryRecord = Record({
  id: '',
  product_id: '',
  will_last_7: '',
  will_last_30: '',
  day: ''
})

export const WillLastHistoryMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const WillLastHistory = Record({
  state: API_STATES.PRISTINE,
  willLastHistoryMeta: new WillLastHistoryMeta(),
  willLastHistory: []
})
