import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SECTION_IDS, isSectionExpanded } from 'utils/productPage'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

import { removeTagFromProductRoutine } from 'features/tags/ducks/actions'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

import Chip from 'components/atoms/Chip'
import Popover from 'components/atoms/Popover'
import Button from 'components/atoms/Button'
import {
  ConfirmationButtonsContainer,
  ConfirmationContainer
} from 'features/products/components/ProductActivityFlag'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'

export const ProductTag = (props) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { match: { params: { id } }, tagId, name } = props
  const [anchor, setAnchor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const open = Boolean(anchor)
  const componentId = open ? tagId : undefined

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const handleCallback = () => {
    handleClose()
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleRemoveTag = useCallback(
    () => dispatch(removeTagFromProductRoutine({
      values: { id: tagId, productId: id },
      callback: handleCallback
    })),
    [dispatch]
  )

  const remove = async () => {
    setIsLoading(true)
    await handleRemoveTag()
    setIsLoading(false)
  }

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('productPage.removeTag.confirmation.content')}
        <ConfirmationButtonsContainer>
          <Button
            disabled={isLoading}
            onClick={remove}
            variant='outlined'
          >
            {t('productPage.removeTag.confirmation.submitCTA')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
            disabled={isLoading}
          >
            {t('productPage.removeTag.confirmation.cancelCTA')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  return (
    <ChipContainer>
      <Chip size='small' label={name} onDelete={handleOpen} color='secondary' />
      {ConfirmationPopup}
    </ChipContainer>
  )
}

export default withRouter(ProductTag)

const ChipContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
`
