import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { useDispatch } from 'react-redux'
import qs from 'qs'

import { deleteSearchQueryRoutine } from 'features/searchQueries/ducks/actions'

import MenuItem from 'components/atoms/MenuItem'
import DeleteWithConfirm from 'components/DeleteWithConfirm'

export const SearchQueryItem = (props) => {
  const { data, closeMenu } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const urlSearchQuery = useUrlSearchQuery()

  const deleteQuery = useCallback(
    payload => dispatch(deleteSearchQueryRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = id => {
    deleteQuery({ id })
  }

  const applySearchQuery = () => {
    const queryObject = qs.parse(data.content, { ignoreQueryPrefix: true })
    urlSearchQuery.keepPaginationWithNewQuery(queryObject)
    return closeMenu()
  }

  return (
    <MenuItem>
      <QueryContainer>
        <div className='query-name' onClick={applySearchQuery}>{data.name}</div>
        <DeleteWithConfirm
          text={t('filters.deleteSearchQuery.text')}
          submitText={t('filters.deleteSearchQuery.submitCTA')}
          cancelText={t('filters.deleteSearchQuery.cancelCTA')}
          id={data.id}
          onSubmit={handleSubmit}
        />
      </QueryContainer>
    </MenuItem>
  )
}

SearchQueryItem.defaultProps = {
  closeMenu: () => {}
}

export default SearchQueryItem

const QueryContainer = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  
  .query-name {
    white-space: break-spaces;
    flex-grow: 1;
    height: 100%;
  }
`
