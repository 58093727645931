import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const SalePlan = Record({
  id: '',
  product_id: '',
  created_at: '',
  starts_at: '',
  expires_at: '',
  stock_after: 0
})

export const SalePlans = Record({
  state: API_STATES.PRISTINE,
  salePlans: {}
})
