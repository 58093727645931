import { Product, Products, Bundle } from 'features/products/ducks/records'
import {
  getProductRoutine,
  fetchProductBundlesRoutine,
  syncProductRoutine,
  syncAllegroRoutine,
  syncBundlesRoutine,
  fetchSimpleSaleChartRoutine,
  fetchCumulativeSaleChartRoutine
} from 'features/products/ducks/actions'
import { API_STATES, transferEntityToImmutableById, transferCollectionToImmutable } from 'utils/redux'
import { mergeRight } from 'ramda'

export default (state = new Products(), action) => {
  switch (action.type) {
    case fetchSimpleSaleChartRoutine.REQUEST:
    case fetchCumulativeSaleChartRoutine.REQUEST:
      return state
        .set('chartsState', API_STATES.IN_PROGRESS)
    case getProductRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case syncProductRoutine.REQUEST:
    case syncAllegroRoutine.REQUEST:
    case syncBundlesRoutine.REQUEST:
      return state
        .set('syncState', API_STATES.IN_PROGRESS)
    case getProductRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchSimpleSaleChartRoutine.FAILURE:
    case fetchCumulativeSaleChartRoutine.FAILURE:
      return state
        .set('chartsState', API_STATES.DONE)
    case syncProductRoutine.SUCCESS:
    case syncProductRoutine.FAILURE:
    case syncAllegroRoutine.SUCCESS:
    case syncAllegroRoutine.FAILURE:
    case syncBundlesRoutine.SUCCESS:
    case syncBundlesRoutine.FAILURE:
      return state
        .set('syncState', API_STATES.DONE)
    case getProductRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('products',
          mergeRight(
            state.products,
            transferEntityToImmutableById(action.payload, Product, 'productId')
          )
        )
    case fetchProductBundlesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('bundles',
          {
            [action.payload.id]: transferCollectionToImmutable(action.payload.data.data, Bundle)
          }
        )
    case fetchSimpleSaleChartRoutine.SUCCESS:
      return state
        .set('chartsState', API_STATES.DONE)
        .set('simpleSaleChart',
          {
            ...state.simpleSaleChart,
            [action.payload.productId]: action.payload.data
          }
        )
    case fetchCumulativeSaleChartRoutine.SUCCESS:
      return state
        .set('chartsState', API_STATES.DONE)
        .set('cumulativeSaleChart',
          {
            ...state.cumulativeSaleChart,
            [action.payload.productId]: action.payload.data
          }
        )
    default:
      return state
  }
}
