import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import useEntitiesListState from 'hooks/useEntitiesListState'

import Table from 'components/atoms/Table/Table'
import TableBody from 'components/atoms/Table/TableBody'
import TableFooter from 'components/atoms/Table/TableFooter'
import TableHeader from 'components/atoms/Table/TableHeader'
import TableHeaderCell from 'components/atoms/Table/TableHeaderCell'
import TableCell from 'components/atoms/Table/TableCell'
import TablePagination from 'components/atoms/Table/TablePagination'
import TableRow from 'components/atoms/Table/TableRow'
import SadFace from 'components/icons/SadFace'
import Loader from 'components/atoms/Loader'
import Input from 'components/atoms/Input'
import Search from 'components/icons/Search'
import Close from 'components/icons/Close'
import FiltersPopover from 'components/FiltersPopover'
import SelectedFilters from 'components/SelectedFilters'
import { length, lt, pipe, propOr } from 'ramda'

export const EntitiesList = (props) => {
  const {
    additionalActionButtons,
    pagination,
    searchLabel,
    fetchRecords,
    headers,
    tableRows,
    emptyStateText,
    defaultSortDirection,
    defaultSortedColumnId,
    isLoading,
    withSearch,
    filters,
    hasSomeItemSelected,
    selectionActions,
    excludedSelectedFilters,
    disableSticky
  } = props
  const {
    currentSearch,
    onRowsPerPageChange,
    onPageChange,
    onChangeSort,
    onChangeSearchInput,
    onResetSearchInput,
    selectedFilters
  } = useEntitiesListState({ defaultSortDirection, defaultSortedColumnId, fetchRecords })
  const [paginationHeight, setPaginationHeight] = useState(0)
  const [filtersHeight, setFiltersHeight] = useState(0)

  const PaginationRef = useRef(null)
  const FiltersContainerRef = useRef(null)

  useEffect(() => {
    setPaginationHeight(PaginationRef.current.clientHeight)
    setFiltersHeight(FiltersContainerRef.current.clientHeight)
  }, [tableRows])

  const renderHeaders = headers.map(header => (
    <TableHeaderCell
      key={header.id}
      onChangeSort={onChangeSort}
      sortable={header.sortable}
      columnId={header.id}
      sortDirection={currentSearch().dir}
      sortedColumnId={currentSearch().sortBy}
      align={header.align || 'left'}
      tooltip={header.tooltip || null}
      disableSticky={disableSticky}
    >
      {header.cell}
    </TableHeaderCell>
  ))

  const emptyState = (
    <TableRow>
      <TableCell
        colSpan={headers.length}
      >
        <TableEmptyState>
          <SadFace />&nbsp;
          {emptyStateText}
        </TableEmptyState>
      </TableCell>
    </TableRow>
  )

  const loadingState = (
    <LoadingStateContainer>
      <Loader />
    </LoadingStateContainer>
  )

  const filtersSections = (
    <>
      <div className='filters__left-container'>
        {
          filters && <SelectedFilters excludedSelectedFilters={excludedSelectedFilters} selectedFilters={selectedFilters()} />
        }
      </div>
      <div className='filters__right-container'>
        {additionalActionButtons && additionalActionButtons}
        {
          filters && (
            <FiltersPopover selectedFilters={selectedFilters()}>
              {filters}
            </FiltersPopover>
          )
        }
        {
          withSearch && (
            <SearchInputContainer>
              <Input
                label={searchLabel}
                value={propOr('', 'search')(currentSearch())}
                onChange={onChangeSearchInput}
                endAdornment={
                  pipe(
                    propOr('', 'search'),
                    length,
                    lt(0)
                  )(currentSearch())
                    ? <StyledClose onClick={onResetSearchInput} />
                    : <StyledSearch />
                }
              />
            </SearchInputContainer>
          )
        }
      </div>
    </>
  )

  const selectionActionsSection = (
    <div className='selection-actions__container'>
      {selectionActions}
    </div>
  )

  return (
    <>
      <TableFiltersContainer ref={FiltersContainerRef}>
        {
          hasSomeItemSelected && selectionActions
            ? selectionActionsSection
            : filtersSections
        }
      </TableFiltersContainer>
      <TableContainer paginationHeight={paginationHeight} filtersHeight={filtersHeight}>
        <Table padding='none'>
          <TableHeader>
            <TableRow>
              {renderHeaders}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Number(pagination.recordsTotal) === 0 ? emptyState : tableRows}
          </TableBody>
        </Table>
        {isLoading && loadingState}
      </TableContainer>
      <Table ref={PaginationRef}>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={currentSearch().rowsPerPage}
              page={currentSearch().page}
              setPaginationPage={onPageChange}
              setRowsPerPage={onRowsPerPageChange}
              pagination={pagination}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}

export default EntitiesList

const TableContainer = styled.div`
  position: relative;
  height: calc(100% - (${props => props.paginationHeight}px + ${props => props.filtersHeight}px));
  overflow: auto;
`

const TableEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
`

const LoadingStateContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => transparentize('0.3', props.theme.palette.common.white)};
  z-index: ${props => props.theme.zIndex.loadingOverlay};
`

const SearchInputContainer = styled.div`
  min-width: 400px;
  max-width: 100%;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    min-width: 100%;
  }
`

const StyledSearch = styled(Search)`
  color: ${props => props.theme.palette.common.gray400};
`

const StyledClose = styled(Close)`
  cursor: pointer;
  color: ${props => props.theme.palette.common.gray400};
  
  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
`

const TableFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    align-items: flex-start;
    flex-direction: column;
  }
  
  .filters__left-container {
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .filters__right-container {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  
  .selection-actions__container {
    width: 100%;
    min-height: calc(${props => props.theme.dimensions.inputHeight} + 20px);
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`
