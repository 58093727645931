import React from 'react'
import styled from 'styled-components'
import { propOr, prop } from 'ramda'
import { AVATARS } from 'utils/avatar'

export const UserAvatar = props => {
  const { user, small } = props
  const userAvatar = propOr('avatar_1', 'avatar')(user)

  return <AvatarContainer small={small} imageSrc={prop(userAvatar)(AVATARS)} />
}

export default UserAvatar

export const AvatarContainer = styled.div`
  background-image: url(${props => props.imageSrc});
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.secondary.main};
  border-radius: 50%;
  width: ${props => props.small ? '30px' : '40px'};
  height: ${props => props.small ? '30px' : '40px'};
  font-size: 16px;
  font-weight: 500;
  box-shadow: ${props => props.theme.shadows.main};
`
