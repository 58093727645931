import { createSelector } from 'reselect'
import { propOr, pipe, equals, values, sum, pick, lt } from 'ramda'
import { API_STATES } from 'utils/redux'
import { ALERT_TYPES } from 'utils/alerts'

export const selectAlerts = state => state.alerts

export const getIsAlertsLoading = createSelector(
  selectAlerts,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getAlertsArray = createSelector(
  selectAlerts,
  pipe(propOr([], 'collection'))
)

export const getAlertsMeta = createSelector(
  selectAlerts,
  propOr({}, 'alertsMeta')
)

export const getAlertsSummaryCount = createSelector(
  selectAlerts,
  pipe(
    propOr({}, 'summary'),
    values,
    sum
  )
)

export const getHasUnarchivedAlerts = createSelector(
  selectAlerts,
  pipe(
    propOr(0, 'unarchivedCount'),
    lt(0)
  )
)

export const getOrderAlertsSummaryCount = createSelector(
  selectAlerts,
  pipe(
    propOr({}, 'summary'),
    pick([ALERT_TYPES.priceChange, ALERT_TYPES.stockChange]),
    values,
    sum
  )
)

export const getSaleAlertsSummaryCount = createSelector(
  selectAlerts,
  pipe(
    propOr({}, 'summary'),
    pick([ALERT_TYPES.suddenSale]),
    values,
    sum
  )
)

export const getPriceDownAlertsSummaryCount = createSelector(
  selectAlerts,
  pipe(
    propOr({}, 'summary'),
    pick([ALERT_TYPES.notSelling]),
    values,
    sum
  )
)

export const getMentionsAlertsSummaryCount = createSelector(
  selectAlerts,
  pipe(
    propOr({}, 'summary'),
    pick([ALERT_TYPES.mentions]),
    values,
    sum
  )
)
