import i18n from 'providers/i18n'

export const deliveriesRangeOptions = () => [
  {
    label: i18n.t('productPage.deliveries.filters.dateRange.7days'),
    value: 7
  },
  {
    label: i18n.t('productPage.deliveries.filters.dateRange.14days'),
    value: 14
  },
  {
    label: i18n.t('productPage.deliveries.filters.dateRange.30days'),
    value: 30
  },
  {
    label: i18n.t('productPage.deliveries.filters.dateRange.60days'),
    value: 60
  },
  {
    label: i18n.t('productPage.deliveries.filters.dateRange.all'),
    value: 'all'
  }
]
