import qs from 'qs'
import { always, applySpec, mergeAll, objOf, omit, pick, pipe, prop } from 'ramda'

export const parseUrlQuery = query => qs.parse(query, { ignoreQueryPrefix: true })
export const stringifyQuery = query => qs.stringify(query)

export const getPaginationQuery = (query = window.location.search) =>
  pipe(
    parseUrlQuery,
    pick(['rowsPerPage', 'page']),
    applySpec({
      page: prop('page'),
      take: prop('rowsPerPage')
    }),
    objOf('limit')
  )(query)

export const getResetPaginationQuery = (query = window.location.search) =>
  pipe(
    parseUrlQuery,
    pick(['rowsPerPage', 'page']),
    applySpec({
      page: always(1),
      take: prop('rowsPerPage')
    }),
    objOf('limit')
  )(query)

export const getOrderQuery = (query = window.location.search) =>
  pipe(
    parseUrlQuery,
    pick(['sortBy', 'dir']),
    applySpec({
      by: prop('sortBy'),
      dir: prop('dir')
    }),
    objOf('order')
  )(query)

export const getSearchQuery = (query = window.location.search) =>
  pipe(
    parseUrlQuery,
    pick(['search'])
  )(query)

export const getFiltersQuery = (query = window.location.search) =>
  pipe(
    parseUrlQuery,
    omit(['rowsPerPage', 'page', 'sortBy', 'dir', 'search']),
    objOf('filter')
  )(query)

export const generateCurrentQuery = (query = window.location.search) =>
  pipe(
    mergeAll,
    stringifyQuery
  )([getPaginationQuery(query), getOrderQuery(query), getSearchQuery(query), getFiltersQuery(query)])

export const generateCurrentResetPaginationQuery = (query = window.location.search) =>
  pipe(
    mergeAll,
    stringifyQuery
  )([getResetPaginationQuery(query), getOrderQuery(query), getSearchQuery(query), getFiltersQuery(query)])
