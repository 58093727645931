import React from 'react'
import Icon from 'components/Icon'

export const AssignTag = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='M17.6,5.8C17.3,5.3,16.7,5,16,5L5,5C3.9,5,3,5.9,3,7v10c0,1.1,0.9,2,2,2l11,0c0.7,0,1.3-0.3,1.6-0.8L22,12 C22,12,17.6,5.8,17.6,5.8z M16,17H5V7h11l3.5,5L16,17z' />
      </g>
      <g>
        <path d='M9.7,9v2.5H12v1H9.7V15h-1v-2.5H6.3v-1h2.4V9H9.7z' />
      </g>
    </Icon>
  )
}

export default AssignTag
