import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SEVERITY } from 'utils/toast'
import {
  validateResetPasswordInitField,
  validateResetPasswordInitValues
} from 'features/auth/ducks/schema'
import { resetPasswordInit } from 'services/AuthService'
import { getEmailError } from 'features/auth/ducks/errors'

import { showToastRoutine } from 'features/toast/ducks/actions'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  email: ''
}

export const ResetPasswordInit = props => {
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    validateResetPasswordInitValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      await resetPasswordInit(values)
      showToast({ key: 'toast.initResetPassword', severity: SEVERITY.success })
    } catch (e) {
      showToast(getEmailError(e))
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <TextField
          required
          name='email'
          label={t('form.email.label')}
          onChange={handleOnChange}
          validate={validateResetPasswordInitField(values)}
        />
        <ButtonContainer>
          <Button type='submit' disabled={!valid} onClick={handleSubmit}>
            {t('resetPasswordPage.init.resetPasswordCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default ResetPasswordInit

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
