import React, { useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isSectionExpanded, SECTION_IDS } from 'utils/productPage'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import { getProductAutoDecisionFlag } from 'features/products/ducks/selectors'
import { toggleProductTasksRoutine } from 'features/flags/ducks/actions'

import Chip from 'components/atoms/Chip'
import Popover from 'components/atoms/Popover'
import Button from 'components/atoms/Button'
import ChipContainer from 'components/atoms/ChipContainer'
import AutoDecision from 'components/icons/AutoDecision'
import { PRODUCT_TASKS_NAMES } from 'utils/flags'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

export const AutoDecisionFlag = (props) => {
  const { match: { params: { id } } } = props
  const urlSearchQuery = useUrlSearchQuery()

  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? id : undefined
  const isActive = useSelector(state => getProductAutoDecisionFlag(state, id))

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const handleCallbacks = () => {
    handleClose()
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const disableAutoDecision = useCallback(
    () => dispatch(toggleProductTasksRoutine({
      values: { id, isEnabled: false, taskName: PRODUCT_TASKS_NAMES.autoDecision },
      callback: handleCallbacks
    })),
    [dispatch]
  )

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('productPage.flags.autoDecision.confirmation.content')}
        <ConfirmationButtonsContainer>
          <Button onClick={disableAutoDecision} variant='outlined'>
            {t('productPage.flags.autoDecision.confirmation.submitCTA')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            {t('productPage.flags.autoDecision.confirmation.cancelCTA')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  return isActive && (
    <ChipContainer>
      <Chip icon={<StyledIcon />} color='primary' label={t('productPage.flags.autoDecision.label')} onDelete={handleOpen} />
      {ConfirmationPopup}
    </ChipContainer>
  )
}

export default withRouter(AutoDecisionFlag)

export const ConfirmationContainer = styled.div`
  padding: 20px;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`

export const StyledIcon = styled(AutoDecision)`
  margin-left: 10px !important;
`
