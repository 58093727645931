import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import CreateWeightSchemaForm from 'features/weightSchemas/CreateWeightSchemaForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

export const CreateWeightSchemaModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ModalTriggerContainer>
      <Button onClick={handleOpen}>{t('settingsPage.weightSchema.createWeightSchema.createCTA')}</Button>
      <Modal
        title={t('settingsPage.weightSchema.createWeightSchema.createCTA')}
        open={open}
        handleClose={handleClose}
      >
        <CreateWeightSchemaForm onSubmit={handleClose} />
      </Modal>
    </ModalTriggerContainer>
  )
}

export default CreateWeightSchemaModal

export const ModalTriggerContainer = styled.div`
  text-align: right;
`
