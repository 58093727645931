import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ALERT_TYPES,
  isAlertSeen,
  prepareFetchAlertsCurrentQuery
} from 'utils/alerts'
import { formatDate, FORMATS } from 'utils/date'
import { Link, useHistory } from 'react-router-dom'
import useSelectionSaga from 'hooks/useSelectionSaga'
import { equals, propOr, not } from 'ramda'

import { fetchAlertsRoutine } from 'features/alerts/ducks/actions'
import { getIsAlertsLoading, getAlertsArray, getAlertsMeta } from 'features/alerts/ducks/selectors'
import { getHasSomeItemsSelectedGlobally } from 'ducks/global/selectors'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import NewAlertRowNotification from 'features/alerts/components/NewAlertRowNotification'
import ArchiveAlertButton from 'features/alerts/components/ArchiveAlertButton'
import AlertsOrdersFilters from 'features/alerts/components/AlertsOrdersFilters'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { getUserLogin, getUserRole } from 'features/auth/ducks/selectors'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import CopyToClipboard from 'components/CopyToClipboard'
import Checkbox from 'components/atoms/Checkbox'
import SelectedAlertsState from 'features/alerts/components/SelectedAlertsState'
import SnoozeAutoDecisionPopover from 'features/products/components/SnoozeAutoDecisionPopover'
import { USER_ROLES } from 'utils/user'
import PriceDown from 'components/icons/PriceDown'

export const AlertsSale = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const data = useSelector(getAlertsArray)
  const pagination = useSelector(getAlertsMeta)
  const isLoading = useSelector(getIsAlertsLoading)
  const currentAlertTypes = [ALERT_TYPES.notSelling]
  const urlSearchQuery = useUrlSearchQuery()
  const userLogin = useSelector(getUserLogin)
  const userRole = useSelector(getUserRole)
  const hasSomeItemsSelectedGlobally = useSelector(getHasSomeItemsSelectedGlobally)
  const isNotFilteredByUserLogin = urlSearchQuery.hasSearchQuery(API_FIELD_NAME.userLogin) === false
  const visibleItemsIds = data.map(data => data.id)

  const isAdmin = equals(userRole, USER_ROLES.admin)

  const {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem,
    handleSelectCollection
  } = useSelectionSaga(visibleItemsIds, data)

  const adminFilters = isAdmin ? { [API_FIELD_NAME.isAlertArchived]: false } : {}

  useEffect(() => {
    if (userLogin && isNotFilteredByUserLogin) {
      urlSearchQuery.setSearchQuery({
        [API_FIELD_NAME.alertsUserLogin]: userLogin,
        ...adminFilters
      })
    }
  }, [userLogin])

  const fetchSaleAlerts = useCallback(
    query => dispatch(
      fetchAlertsRoutine(
        prepareFetchAlertsCurrentQuery(query, currentAlertTypes)
      )),
    [dispatch]
  )

  const adminColumns = isAdmin ? [
    {
      id: API_FIELD_NAME.isAlertArchived,
      sortable: false,
      cell: t('alertsPage.table.headers.status')
    },
    {
      id: 'archivedAt',
      sortable: false,
      cell: t('alertsPage.table.headers.archivedAt')
    },
    {
      id: 'archivedBy',
      sortable: false,
      cell: t('alertsPage.table.headers.archivedBy')
    }
  ] : []

  const headers = [
    {
      id: 'new',
      sortable: false,
      cell: ''
    },
    {
      id: 'type',
      sortable: false,
      cell: t('alertsPage.table.headers.type')
    },
    {
      id: 'createdAt',
      sortable: false,
      cell: t('alertsPage.table.headers.createdAt')
    },
    {
      id: 'ean',
      sortable: false,
      cell: t('alertsPage.table.headers.ean')
    },
    {
      id: 'name',
      sortable: false,
      cell: t('alertsPage.table.headers.name')
    },
    ...adminColumns,
    {
      id: 'selectAll',
      sortable: false,
      align: 'right',
      cell: (
        <Checkbox
          indeterminate={hasSomeItemSelected}
          checked={hasAllVisibleItemsSelected}
          onChange={handleSelectCollection}
        />
      )
    }
  ]

  const getTypeIcon = data => {
    switch (data.type) {
      case ALERT_TYPES.notSelling:
        return <PriceDown />
      default:
        return ''
    }
  }

  const getStatus = isArchived => isArchived ? t('alertsPage.statuses.archived') : t('alertsPage.statuses.active')

  const renderItems = data.map(data => {
    const isNew = !isAlertSeen(data)

    return (
      <TableRow checked={isItemSelected(data.id)} hover key={`sale-alert-row-${data.id}`}>
        <TableCell width='1%'>
          <IconContainer>
            {isNew && <NewAlertRowNotification id={data.id} />}
          </IconContainer>
        </TableCell>
        <TableCell width='2%'>
          <IconContainer>
            {getTypeIcon(data)}
          </IconContainer>
        </TableCell>
        <TableCell width='10%'>
          <CellContainer isNew={isNew}>
            {formatDate(data.created_at, FORMATS.slashTime)}
          </CellContainer>
        </TableCell>
        <TableCell width='5%'>
          <CellContainer isNew={isNew}>
            <CopyToClipboard textToCopy={propOr('', 'ean', data)}>
              {propOr('', 'ean', data)}
            </CopyToClipboard>
          </CellContainer>
        </TableCell>
        <TableCell width='30%'>
          <CellContainer isNew={isNew}>
            <Link
              to={{
                pathname: `/product/${data.product_id}`,
                state: {
                  from: `${history.location.pathname}${history.location.search}`
                }
              }}
            >
              {propOr('-', 'name', data)}
            </Link>
          </CellContainer>
        </TableCell>
        {
          isAdmin && (
            <>
              <TableCell>
                {getStatus(propOr(false, 'is_archived', data))}
              </TableCell>
              <TableCell>
                {data.archived_at ? formatDate(data.archived_at, FORMATS.slashTime) : '-'}
              </TableCell>
              <TableCell>
                {propOr('-', 'archived_by_user', data)}
              </TableCell>
            </>
          )
        }
        <TableCell align='right'>
          {
            not(propOr(false, 'is_archived', data)) && (
              <Checkbox
                checked={isItemSelected(data.id)}
                onChange={handleSelectItem(data.id, data)}
              />
            )
          }
        </TableCell>
      </TableRow>
    )
  })

  return (
    <EntitiesList
      emptyStateText={t('alertsPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchSaleAlerts}
      isLoading={isLoading}
      filters={<AlertsOrdersFilters />}
      hasSomeItemSelected={hasSomeItemsSelectedGlobally}
      selectionActions={
        <>
          <SelectedAlertsState />
          <SelectedItemsActionsContainer>
            <SnoozeAutoDecisionPopover selectedItemsDataProp='product_id' />
            <ArchiveAlertButton types={currentAlertTypes} />
          </SelectedItemsActionsContainer>
        </>
      }
    />
  )
}

export default AlertsSale

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 10px 0;
`

const CellContainer = styled.div`
  font-weight: ${props => props.isNew ? '500' : '400'};
`

const SelectedItemsActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
