import { Producers, Producer, ProducersMeta } from 'features/producers/ducks/records'
import { fetchProducersRoutine } from 'features/producers/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new Producers(), action) => {
  switch (action.type) {
    case fetchProducersRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchProducersRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchProducersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('producers', transferCollectionToImmutable(action.payload.data, Producer))
        .set('producersMeta', new ProducersMeta(action.payload.meta))
    default:
      return state
  }
}
