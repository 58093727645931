import React from 'react'
import { formatDate, FORMATS } from 'utils/date'
import styled from 'styled-components'
import UserAvatar from 'components/UserAvatar'
import { COMMENT_TYPE } from 'utils/comments'
import { pipe, split, map, ifElse, head, equals, identity, join, and, drop, includes } from 'ramda'
import { useSelector } from 'react-redux'
import { getUsersLoginArray } from 'features/users/ducks/selectors'

export const Comment = (props) => {
  const { data } = props
  const userLogins = useSelector(getUsersLoginArray)

  const isManual = data.type === COMMENT_TYPE.manual

  const author = isManual ? data.author : {
    login: 'System',
    avatar: 'avatar_system'
  }

  const boldMentionedUsers = pipe(
    split(' '),
    map(
      ifElse(
        and(
          pipe(
            head,
            equals('@')
          ),
          pipe(
            drop(1),
            mention => includes(mention, userLogins)
          )
        ),
        word => `<strong>${word}</strong>`,
        identity
      )
    ),
    join(' ')
  )

  return (
    <ProductCommentContainer isManual={isManual}>
      <div className='product-comment__avatar'>
        <UserAvatar small user={author} />
      </div>
      <div className='product-comment__aside'>
        <div className='product-comment__author'>
          <div>{author.login}</div>
          <div>
            {formatDate(data.created_at, FORMATS.slashTime)}
          </div>
        </div>
        <div className='product-comment__content' dangerouslySetInnerHTML={{ __html: boldMentionedUsers(data.content) }} />
      </div>
    </ProductCommentContainer>
  )
}

export default Comment

const ProductCommentContainer = styled.div`
  display: flex;
  padding: 5px;
  border-radius: ${props => props.theme.shape.borderRadius};
  background-color: ${props => props.theme.palette.common.gray100};

  & + & {
    margin-top: 10px;
  }

  .product-comment__avatar {
    margin-right: 10px;
  }

  .product-comment__aside {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .product-comment__author {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .8px;
    color: ${props => props.theme.palette.primary.main};
    margin-right: 10px;
  }

  .product-comment__content {
    color: ${props => props.theme.palette.text.main};
    font-size: 12px;
  }
`
