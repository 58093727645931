export default {
  login: '/',
  dashboard: '/dashboard',
  monitoring: '/monitoring',
  users: '/users',
  resetPasswordInit: '/reset-password/init',
  resetPasswordFinish: '/reset-password/:id/:token',
  producers: '/producers',
  product: '/product/:id',
  integrations: '/integrations',
  lines: '/lines',
  settings: '/settings',
  tags: '/tags',
  orders: '/orders',
  cart: '/orders/cart',
  ordersHistory: '/orders/history',
  saleChannels: '/sale-channels',
  alerts: '/alerts',
  alertsOrders: '/alerts/orders',
  alertsSale: '/alerts/sale',
  alertsPriceDown: '/alerts/price-down',
  alertsMentions: '/alerts/mentions',
  notFound: '/not-found'
}
