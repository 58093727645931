import i18n from 'providers/i18n'
import { any, pipe, propEq, propOr } from 'ramda'

export const PRODUCT_SALE_CHANNEL_STATUSES = {
  published: 'published',
  notPublished: 'new',
  noProductSheet: 'noProductSheet',
  mix: 'mix',
  differentCollor: 'differentCollor',
  anotherLanguage: 'anotherLanguage',
  anotherPicture: 'anotherPicture',
  oversized: 'oversized',
  anotherEan: 'anotherEan',
  notApplicable: 'n/a'
}

export const PRODUCT_SALE_CHANNEL_STATUS_TRANSLATIONS = () => ({
  [PRODUCT_SALE_CHANNEL_STATUSES.published]: i18n.t('productPage.saleChannels.options.published'),
  [PRODUCT_SALE_CHANNEL_STATUSES.notPublished]: i18n.t('productPage.saleChannels.options.notPublished'),
  [PRODUCT_SALE_CHANNEL_STATUSES.noProductSheet]: i18n.t('productPage.saleChannels.options.noProductSheet'),
  [PRODUCT_SALE_CHANNEL_STATUSES.mix]: i18n.t('productPage.saleChannels.options.mix'),
  [PRODUCT_SALE_CHANNEL_STATUSES.differentCollor]: i18n.t('productPage.saleChannels.options.differentCollor'),
  [PRODUCT_SALE_CHANNEL_STATUSES.anotherLanguage]: i18n.t('productPage.saleChannels.options.anotherLanguage'),
  [PRODUCT_SALE_CHANNEL_STATUSES.anotherPicture]: i18n.t('productPage.saleChannels.options.anotherPicture'),
  [PRODUCT_SALE_CHANNEL_STATUSES.oversized]: i18n.t('productPage.saleChannels.options.oversized'),
  [PRODUCT_SALE_CHANNEL_STATUSES.anotherEan]: i18n.t('productPage.saleChannels.options.anotherEan'),
  [PRODUCT_SALE_CHANNEL_STATUSES.notApplicable]: i18n.t('productPage.saleChannels.options.notApplicable')
})

export const productSaleChannelStatusOptions = () => [
  {
    label: i18n.t('productPage.saleChannels.options.published'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.published
  },
  {
    label: i18n.t('productPage.saleChannels.options.notPublished'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.notPublished
  },
  {
    label: i18n.t('productPage.saleChannels.options.noProductSheet'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.noProductSheet
  },
  {
    label: i18n.t('productPage.saleChannels.options.mix'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.mix
  },
  {
    label: i18n.t('productPage.saleChannels.options.differentCollor'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.differentCollor
  },
  {
    label: i18n.t('productPage.saleChannels.options.anotherLanguage'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.anotherLanguage
  },
  {
    label: i18n.t('productPage.saleChannels.options.anotherPicture'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.anotherPicture
  },
  {
    label: i18n.t('productPage.saleChannels.options.oversized'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.oversized
  },
  {
    label: i18n.t('productPage.saleChannels.options.anotherEan'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.anotherEan
  },
  {
    label: i18n.t('productPage.saleChannels.options.notApplicable'),
    value: PRODUCT_SALE_CHANNEL_STATUSES.notApplicable
  }
]

export const isProductSaleChannelPublished = (productSaleChannels, channelName) =>
  pipe(
    propOr([], channelName),
    any(
      propEq('status', PRODUCT_SALE_CHANNEL_STATUSES.published)
    )
  )(productSaleChannels)
