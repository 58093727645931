import React from 'react'
import styled from 'styled-components'

import PageColumnContainer from 'components/PageColumnContainer'
import AddUserModal from 'features/users/components/AddUserModal'
import UsersTable from 'features/users/UsersTable'
import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import Container from 'components/Container'

export const UsersPage = (props) => {
  return (
    <Container>
      <PageColumnContainer>
        <ActionsContainer>
          <AddUserModal />
        </ActionsContainer>
        <TablePaperContainer>
          <UsersTable />
        </TablePaperContainer>
      </PageColumnContainer>
    </Container>
  )
}

export default UsersPage

const ActionsContainer = styled.div`
  text-align: right;
  margin-bottom: 20px;
`
