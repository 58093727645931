import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Iai = Record({
  errorsExist: false,
  errors: ''
})

export const Allegro = Record({
  errorsExist: false,
  errors: []
})

export const Integrations = Record({
  state: API_STATES.PRISTINE,
  iai: new Iai(),
  allegro: new Allegro()
})
