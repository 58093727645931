import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getSelectedItems } from 'ducks/global/selectors'

import IconButton from 'components/atoms/IconButton'
import Close from 'components/icons/Close'
import { handleResetSelectionRoutine } from 'ducks/global/actions'
import styled from 'styled-components'

export const MonitoringSelectedItemsState = (props) => {
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const dispatch = useDispatch()

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  return (
    <MonitoringSelectedItemsStateContainer>
      <span>
        {t('monitoringPage.tag.assignTag.selectedItems', { count: selectedItems.length })}
      </span>
      {
        selectedItems.length > 0 && (
          <div className='selected-items__reset'>
            <IconButton size='small' onClick={handleResetSelection}>
              <Close />
            </IconButton>
          </div>
        )
      }
    </MonitoringSelectedItemsStateContainer>
  )
}

export default MonitoringSelectedItemsState

const MonitoringSelectedItemsStateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  border-right: 1px solid ${props => props.theme.palette.common.gray200};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    border-right: none;
    font-size: 16px;
  }
  
  .selected-items__reset {
    margin-right: 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-right: 0;
    }
  }
`
