import React, { useEffect, useState } from 'react'
import AsyncCreatableSelect from 'components/atoms/AsyncCreatableSelect'
import { useTranslation } from 'react-i18next'
import { getHeadErrorOrEmptyObject } from 'utils/form'
import { propOr } from 'ramda'

export const AsyncCreatableSelectField = props => {
  const {
    options,
    name,
    label,
    onChange,
    validate,
    value: initialValue,
    required,
    handleSearchOptions,
    handleCreateNewOption,
    values
  } = props
  const [value, _setValue] = useState(initialValue)
  const [touched, _setTouched] = useState(false)
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: {}
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (touched) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObject(v) })
      })
    }
  }, [value, touched, values])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  const handleFocus = () => _setTouched(true)
  const handleChange = option => {
    _setValue(option)
    onChange(name, propOr(null, 'value', option))
  }

  const handleSearch = inputValue => handleSearchOptions(inputValue)
  const handleCreateOption = async inputValue => {
    const newOption = await handleCreateNewOption(inputValue)
    handleChange(newOption)
  }

  return (
    <AsyncCreatableSelect
      required={required}
      options={options}
      onFocus={handleFocus}
      label={label}
      value={value}
      error={!valid}
      errorText={valid ? '' : t(error.key, error.options)}
      onChange={handleChange}
      defaultOptions
      loadOptions={handleSearch}
      onCreateOption={handleCreateOption}
    />
  )
}

AsyncCreatableSelectField.defaultProps = {
  validate: () => {},
  onChange: () => {}
}

export default AsyncCreatableSelectField
