import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { archiveAlertsRoutine } from 'features/alerts/ducks/actions'

import Popover from 'components/atoms/Popover'
import Button from 'components/atoms/Button'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getSelectedItems } from 'ducks/global/selectors'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

export const ArchiveAlertButton = (props) => {
  const { t } = useTranslation()
  const { types } = props
  const dispatch = useDispatch()
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? 'archive-alert-button' : undefined
  const selectedItems = useSelector(getSelectedItems)

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const archiveAlerts = useCallback(
    payload => dispatch(archiveAlertsRoutine(payload)),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  const handleCallback = () => {
    handleClose()
    handleResetSelection()
  }

  const handleArchive = () => {
    archiveAlerts({
      values: { alertIds: selectedItems },
      types,
      callback: handleCallback
    })
  }

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('alertsPage.archiveAlert.confirmation.content')}
        <ConfirmationButtonsContainer>
          <Button
            variant='outlined'
            onClick={handleArchive}
          >
            {t('alertsPage.archiveAlert.confirmation.submit')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            {t('alertsPage.archiveAlert.confirmation.cancel')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  return (
    <div>
      <Button size='small' onClick={handleOpen}>
        {t('alertsPage.archiveAlert.CTA')}
      </Button>
      {ConfirmationPopup}
    </div>
  )
}

export default ArchiveAlertButton

export const ConfirmationContainer = styled.div`
  padding: 20px;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  
  &+& {
    margin-left: 10px;
  }
`
