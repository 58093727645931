import APIService from 'services/APIService'

const api = APIService()

export const fetchSaleChannels = query => api.get(`/sale-channels?${query}`)
export const createSaleChannel = payload => api.post('/sale-channels', payload)
export const editSaleChannel = ({ id, name }) => api.patch(`/sale-channels/${id}`, { name })
export const deleteSaleChannel = id => api.delete(`/sale-channels/${id}`)
export const attachProductToSaleChannel = ({ saleChannelId, productId, status, link }) => api.post(`/sale-channels/${saleChannelId}/attach-product`, { productId, status, link })
export const editProductToSaleChannel = ({ id, status, link }) => api.patch(`/sale-channels/product-channel/${id}`, { status, link })
export const deleteProductSaleChannel = ({ id }) => api.delete(`sale-channels/product-channel/${id}`)
export const fetchProductSaleChannels = ({ id }) => api.get(`/sale-channels/fetch-for-product/${id}`)
