import React from 'react'
import Icon from 'components/Icon'

export const PromoDiff = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='m21.208 24c-.098 0-.196-.029-.281-.086l-3.427-2.332-3.427 2.332c-.171.117-.397.115-.568-.004s-.249-.331-.198-.532l1.061-4.188-3.192-2.726c-.155-.133-.214-.347-.149-.54s.241-.328.445-.339l4.083-.231 1.477-4.025c.145-.394.794-.394.938 0l1.477 4.025 4.083.231c.204.011.38.146.445.339s.006.407-.149.54l-3.192 2.726 1.061 4.188c.051.201-.028.413-.198.532-.088.06-.188.09-.289.09zm-3.708-3.523c.098 0 .196.029.281.086l2.619 1.782-.813-3.211c-.046-.184.016-.379.16-.503l2.481-2.119-3.167-.18c-.199-.011-.373-.14-.441-.327l-1.12-3.054-1.12 3.054c-.068.187-.242.315-.441.327l-3.167.18 2.481 2.119c.145.124.207.319.16.503l-.813 3.211 2.619-1.782c.085-.058.183-.086.281-.086z' />
        <path d='m11.5 21h-9c-1.378 0-2.5-1.122-2.5-2.5v-16c0-1.378 1.122-2.5 2.5-2.5h11c1.378 0 2.5 1.122 2.5 2.5v6c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-6c0-.827-.673-1.5-1.5-1.5h-11c-.827 0-1.5.673-1.5 1.5v16c0 .827.673 1.5 1.5 1.5h9c.276 0 .5.224.5.5s-.224.5-.5.5z' />
        <path d='m3.5 13c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l2.5-2.5c.152-.153.383-.19.577-.094l1.678.839 3.245-3.245c.195-.195.512-.195.707 0s.195.512 0 .707l-3.5 3.5c-.153.152-.385.19-.577.094l-1.678-.839-2.245 2.245c-.097.097-.225.146-.353.146z' />
        <path d='m11.5 11c-.276 0-.5-.224-.5-.5v-2.5h-2.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5z' />
      </g>
    </Icon>
  )
}

export default PromoDiff
