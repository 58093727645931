import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { CURRENCY_SYMBOL } from 'utils/general'

import { getProductRetailPrice } from 'features/products/ducks/selectors'

import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'
import UpdateRetailPriceForm from 'features/products/UpdateRetailPriceForm'
import Modal from 'components/atoms/Modal'
import { useTranslation } from 'react-i18next'

export const ProductPrice = (props) => {
  const { match: { params: { id } } } = props
  const [open, setOpen] = useState(false)
  const retailPrice = useSelector(state => getProductRetailPrice(state, id))
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ProductPriceContainer>
      <div className='product-price__price'>
        {retailPrice}{CURRENCY_SYMBOL}
      </div>
      <IconButton
        size='small'
        id='button-edit-retail-price'
        onClick={handleOpen}
      >
        <Edit />
      </IconButton>
      <Modal
        title={t('productPage.retailPrice.title')}
        open={open}
        handleClose={handleClose}
      >
        <UpdateRetailPriceForm onSubmit={handleClose} />
      </Modal>
    </ProductPriceContainer>
  )
}

export default withRouter(ProductPrice)

const ProductPriceContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
     margin-left: 12px;
  }
  
  .product-price__price {
    line-height: 23px;
    font-size: 23px;
    font-weight: 700;
    margin: 0 10px 5px 0;
  }
`
