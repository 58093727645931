import { propOr, pipe, filter, propEq, length, pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'utils/redux'

export const selectAllegro = state => state.allegro

export const getAllegroAccountsArray = createSelector(
  selectAllegro,
  pipe(propOr({}, 'accounts'), transformToArray)
)

export const getActiveAllegroAccountsCount = createSelector(
  selectAllegro,
  pipe(
    propOr({}, 'accounts'),
    transformToArray,
    filter(
      propEq('is_active', true)
    ),
    length
  )
)

export const getAllAllegroAccountsCount = createSelector(
  selectAllegro,
  pipe(
    pathOr(0, ['accountsMeta', 'recordsTotal']),
    Number
  )
)
