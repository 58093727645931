import { Record } from 'immutable'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { THEME_MODES } from 'utils/theme'

export const Global = Record({
  theme: LocalStorageService.get(LOCAL_STORAGE_KEYS.selectedTheme) || THEME_MODES.light,
  selectedItems: [],
  selectedItemsData: []
})
