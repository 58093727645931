import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateCreateSaleChannelField,
  validateCreateSaleChannelValues
} from 'features/saleChannels/ducks/schema'

import { editSaleChannelRoutine } from 'features/saleChannels/ducks/actions'
import { getIsSaleChannelsLoading } from 'features/saleChannels/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  id: '',
  name: ''
}

export const EditSaleChannelForm = props => {
  const { onSubmit, data: { id, name } } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSaleChannelsLoading)

  const editSaleChannel = useCallback(
    payload => dispatch(editSaleChannelRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateCreateSaleChannelValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ id, name })
  }, [])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    editSaleChannel({
      values,
      callback: onSubmit
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ButtonContainer>
        <TextField
          required
          name='name'
          label={t('form.saleChannelName.label')}
          onChange={handleOnChange}
          validate={validateCreateSaleChannelField(values)}
          value={values.name}
        />
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('saleChannelsPage.editSaleChannel.editCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default EditSaleChannelForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
