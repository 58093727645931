import { Settings, SettingsData } from 'features/settings/ducks/records'
import { fetchSettingsRoutine, saveSettingsRoutine } from 'features/settings/ducks/actions'
import { API_STATES } from 'utils/redux'

export default (state = new Settings(), action) => {
  switch (action.type) {
    case saveSettingsRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case saveSettingsRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchSettingsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('settings', new SettingsData(action.payload))
    default:
      return state
  }
}
