import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { useDispatch } from 'react-redux'
import events from 'utils/events'
import eventEmitter from 'providers/eventEmitter'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'

import { fetchAuthUserRoutine, logoutUserRoutine } from 'features/auth/ducks/actions'

import TopNavigation from 'components/TopNavigation'
import SideNavigation from 'components/SideNavigation'
import AllIntegrationsConnectionStatusNotification from 'features/integrations/AllIntegrationsConnectionStatusNotification'
import AlertsNotificationListener from 'features/alerts/AlertsNotificationListener'

export const Layout = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchAuthUser = useCallback(() => dispatch(fetchAuthUserRoutine()), [
    dispatch
  ])
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [
    dispatch
  ])

  const redirectToNotFoundPage = () => history.push(PATHS.notFound)

  useEffect(() => {
    fetchAuthUser()
    eventEmitter.on(events.logoutUser, logoutUser)
    eventEmitter.on(events.notFound, redirectToNotFoundPage)

    return () => {
      eventEmitter.off(events.logoutUser)
      eventEmitter.off(events.notFound)
    }
  }, [])

  return (
    <>
      <AlertsNotificationListener />
      <AllIntegrationsConnectionStatusNotification />
      <LayoutContainer>
        <TopNavigation />
        <LayoutContent>
          <SideNavigation />
          {props.children}
        </LayoutContent>
      </LayoutContainer>
    </>
  )
}

export default withRouter(Layout)

const LayoutContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-height: calc(100vh - ${props => props.theme.dimensions.navHeight});
  width: 100vw;
  margin-top: ${props => props.theme.dimensions.navHeight};
  background-color: ${props => props.theme.palette.common.white};
  position: relative;
`

const LayoutContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  margin-left: ${(props) => props.theme.dimensions.sideNavShrinkWidth};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-left: 0;
  }
`
