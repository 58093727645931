import { SalePlan, SalePlans } from 'features/salePlans/ducks/records'
import { fetchSalePlanRoutine, createSalePlanRoutine, deleteSalePlanRoutine } from 'features/salePlans/ducks/actions'
import { API_STATES } from 'utils/redux'
import { mergeRight } from 'ramda'

export default (state = new SalePlans(), action) => {
  switch (action.type) {
    case fetchSalePlanRoutine.REQUEST:
    case createSalePlanRoutine.REQUEST:
    case deleteSalePlanRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchSalePlanRoutine.FAILURE:
    case createSalePlanRoutine.FAILURE:
    case deleteSalePlanRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchSalePlanRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('salePlans',
          mergeRight(
            state.salePlans,
            {
              [action.payload.product_id]: new SalePlan(action.payload)
            }
          )
        )
    default:
      return state
  }
}
