import {
  propOr,
  pipe,
  equals,
  map,
  applySpec,
  prop,
  filter,
  propEq,
  ifElse,
  identity
} from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES, transformById } from 'utils/redux'
import { getUserId, getUserRole } from 'features/auth/ducks/selectors'
import { USER_ROLES } from 'utils/user'

export const selectLines = state => state.lines

export const getIsLinesLoading = createSelector(
  selectLines,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getLinesMeta = createSelector(
  selectLines,
  propOr({}, 'linesMeta')
)

export const getLinesArray = createSelector(
  selectLines,
  propOr([], 'lines')
)

export const getLines = createSelector(
  selectLines,
  pipe(
    propOr([], 'lines'),
    transformById
  )
)

export const getLinesAsOptions = createSelector(
  selectLines,
  pipe(
    propOr([], 'lines'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('id')
      })
    )
  )
)

export const getLineNamesAsOptions = createSelector(
  selectLines,
  getUserId,
  getUserRole,
  (lines, userId, userRole) =>
    pipe(
      propOr([], 'lines'),
      ifElse(
        () => equals(userRole, USER_ROLES.admin),
        identity,
        filter(propEq('userId', userId))
      ),
      map(
        applySpec({
          label: prop('name'),
          value: prop('name')
        })
      )
    )(lines)
)
