import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditTagForm from 'features/tags/EditTagForm'
import Modal from 'components/atoms/Modal'
import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'

export const EditTagModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal
        title={t('tagsPage.editTag.editTagTitle')}
        open={open}
        handleClose={handleClose}
      >
        <EditTagForm onSubmit={handleClose} data={props.data} />
      </Modal>
    </>
  )
}

export default EditTagModal
