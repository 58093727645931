import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  deleteWeightSchemaRoutine
} from 'features/weightSchemas/ducks/actions'

import DeleteWithConfirm from 'components/DeleteWithConfirm'

export const DeleteWeightSchema = (props) => {
  const { id } = props
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleDelete = useCallback(
    payload => dispatch(deleteWeightSchemaRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = id => handleDelete(id)

  return (
    <DeleteWithConfirm
      text={t('settingsPage.weightSchema.deleteSchema.confirmation.content')}
      submitText={t('settingsPage.weightSchema.deleteSchema.confirmation.submitCTA')}
      cancelText={t('settingsPage.weightSchema.deleteSchema.confirmation.cancelCTA')}
      id={id}
      onSubmit={handleSubmit}
    />
  )
}

export default withRouter(DeleteWeightSchema)
