import React, { useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import ChipContainer from 'components/atoms/ChipContainer'

import { deactivateProductRoutine } from 'features/products/ducks/actions'
import { getIsProductInStock, getIsProductActive } from 'features/products/ducks/selectors'

import Chip from 'components/atoms/Chip'
import Popover from 'components/atoms/Popover'
import Button from 'components/atoms/Button'

export const ProductActivityFlag = (props) => {
  const { match: { params: { id } } } = props
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? id : undefined

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isInStock = useSelector(state => getIsProductInStock(state, id))
  const isActive = useSelector(state => getIsProductActive(state, id))

  const deactivateProduct = useCallback(
    () => dispatch(deactivateProductRoutine({ id, callback: handleClose })),
    [dispatch]
  )
  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('productPage.activityToggle.confirmation.content')}
        <ConfirmationButtonsContainer>
          <Button onClick={deactivateProduct} variant='outlined'>
            {t('productPage.activityToggle.confirmation.submitCTA')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            {t('productPage.activityToggle.confirmation.cancelCTA')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  const getInactiveActionsIfNeeded =
    isInStock
      ? {}
      : {
        onDelete: handleOpen
      }

  return (
    <ChipContainer>
      {
        isActive ? (
          <Chip color='primary' label={t('productPage.activityToggle.isActive')} {...getInactiveActionsIfNeeded} />
        ) : (
          <Chip label={t('productPage.activityToggle.isInactive')} />
        )
      }
      {ConfirmationPopup}
    </ChipContainer>
  )
}

export default withRouter(ProductActivityFlag)

export const ConfirmationContainer = styled.div`
  padding: 20px;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`
