import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router'
import { pathOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { SEVERITY } from 'utils/toast'
import PATHS from 'utils/paths'
import {
  validateResetPasswordFinishField,
  validateResetPasswordFinishValues
} from 'features/auth/ducks/schema'

import {
  resetPasswordFinish,
  validateResetPasswordToken
} from 'services/AuthService'

import { showToastRoutine } from 'features/toast/ducks/actions'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  password: '',
  repeatPassword: ''
}

export const ResetPasswordInit = props => {
  const { history: { push }, match: { params: { id, token } } } = props
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  const validateToken = async e => {
    try {
      const response = await validateResetPasswordToken({ token, id })
      const isValid = pathOr(false, ['data', 'isValid'])(response)
      !isValid && push(PATHS.login)
      !isValid &&
        showToast({
          key: 'toast.resetPasswordLinkInvalid',
          severity: SEVERITY.error
        })
    } catch (e) {
      console.error(e)
      push(PATHS.login)
      showToast({
        key: 'toast.resetPasswordLinkInvalid',
        severity: SEVERITY.error
      })
    }
  }

  useEffect(() => {
    validateToken()
  }, [])

  useEffect(() => {
    validateResetPasswordFinishValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    const { password } = values
    e.preventDefault()
    try {
      await resetPasswordFinish({ password, token, id })
      push(PATHS.login)
      showToast({
        key: 'toast.finishResetPassword',
        severity: SEVERITY.success
      })
    } catch (e) {
      showToast({ key: 'toast.somethingWentWrong', severity: SEVERITY.error })
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <TextField
          required
          name='password'
          type='password'
          label={t('form.password.label')}
          onChange={handleOnChange}
          validate={validateResetPasswordFinishField(values)}
        />
        <TextField
          required
          name='repeatPassword'
          type='password'
          label={t('form.repeatPassword.label')}
          onChange={handleOnChange}
          validate={validateResetPasswordFinishField(values)}
        />
        <ButtonContainer>
          <Button type='submit' disabled={!valid} onClick={handleSubmit}>
            {t('resetPasswordPage.finish.resetPasswordCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default withRouter(ResetPasswordInit)

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
