import { pipe, values, map, flatten, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectDaysOff = state => state.daysOff

export const getDaysOffArray = createSelector(
  selectDaysOff,
  pipe(
    propOr({}, 'daysOff'),
    values,
    flatten,
    map(dayOff => propOr('', 'full_date', dayOff))
  )
)
