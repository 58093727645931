import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import PATHS from 'utils/paths'
import { USER_ROLES } from 'utils/user'
import { THEME_MODES } from 'utils/theme'

import { getSelectedTheme } from 'ducks/global/selectors'
import { logoutUserRoutine } from 'features/auth/ducks/actions'
import { getHasAnyConnectionError } from 'features/integrations/ducks/selectors'
import { getAlertsSummaryCount, getHasUnarchivedAlerts } from 'features/alerts/ducks/selectors'

import SideNavigationListItem from 'components/SideNavigationListItem'
import Logout from 'components/icons/Logout'
import List from 'components/icons/List'
import Users from 'components/icons/Users'
import Menu from 'components/icons/Menu'
import Plug from 'components/icons/Plug'
import Label from 'components/icons/Label'
import Pallet from 'components/icons/Pallet'
import Settings from 'components/icons/Settings'
import Notification from 'components/icons/Notification'
import Tag from 'components/icons/Tag'
import SaleChannels from 'components/icons/SaleChannels'
import PackageChecked from 'components/icons/PackageChecked'
import TopNavAuthorisedUser from 'components/TopNavAuthorisedUser'
import ThemeModeToggle from 'components/ThemeModeToggle'

import SwipeableDrawer from 'components/SwipeableDrawer'

export const SideNavigation = props => {
  const { history: { location: { pathname } } } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [
    dispatch
  ])
  const selectedThemeMode = useSelector(getSelectedTheme)
  const hasAnyConnectionError = useSelector(getHasAnyConnectionError)
  const alertsSummary = useSelector(getAlertsSummaryCount)
  const hasUnarchivedAlerts = useSelector(getHasUnarchivedAlerts)

  useEffect(() => {
    handleCloseDrawer()
  }, [pathname])

  const handleOpenDrawer = () => setOpen(true)
  const handleCloseDrawer = () => setOpen(false)

  const TopNavItems = (
    <>
      <SideNavigationListItem
        isActive={props.match.path === PATHS.alerts}
        icon={Notification}
        notificationContent={alertsSummary}
        hasNotification={alertsSummary > 0 || hasUnarchivedAlerts}
        path={PATHS.alerts}
        text={t('navigation.alerts')}
        id='navigate-alerts'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.monitoring}
        icon={List}
        path={PATHS.monitoring}
        text={t('navigation.monitoring')}
        id='navigate-monitoring'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.tags}
        icon={Tag}
        path={PATHS.tags}
        text={t('navigation.tags')}
        id='navigate-tags'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.orders}
        icon={PackageChecked}
        path={PATHS.orders}
        text={t('navigation.orders')}
        id='navigate-orders'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.integrations}
        hasNotification={hasAnyConnectionError}
        icon={Plug}
        path={PATHS.integrations}
        text={t('navigation.integrations')}
        id='navigate-integrations'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.saleChannels}
        icon={SaleChannels}
        path={PATHS.saleChannels}
        text={t('navigation.saleChannels')}
        allowedRoles={[USER_ROLES.admin]}
        id='navigate-sale-channels'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.lines}
        icon={Pallet}
        path={PATHS.lines}
        allowedRoles={[USER_ROLES.admin]}
        text={t('navigation.lines')}
        id='navigate-lines'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.producers}
        icon={Label}
        path={PATHS.producers}
        allowedRoles={[USER_ROLES.admin]}
        text={t('navigation.producers')}
        id='navigate-producers'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.users}
        icon={Users}
        path={PATHS.users}
        text={t('navigation.users')}
        allowedRoles={[USER_ROLES.admin]}
        id='navigate-users'
      />
      <SideNavigationListItem
        isActive={props.match.path === PATHS.settings}
        icon={Settings}
        path={PATHS.settings}
        text={t('navigation.settings')}
        allowedRoles={[USER_ROLES.admin]}
        id='navigate-settings'
      />
    </>
  )

  const BottomNavItems = (
    <>
      <SideNavigationListItem
        icon={Logout}
        onClick={logoutUser}
        text={t('navigation.logout')}
        id='navigate-logout'
      />
    </>
  )

  return (
    <>
      <SideNavigationContainer themeMode={selectedThemeMode}>
        <SideNavigationContent>
          {TopNavItems}
        </SideNavigationContent>
        <SideNavigationBottomElements>
          {BottomNavItems}
        </SideNavigationBottomElements>
      </SideNavigationContainer>
      <MobileNavigationContainer themeMode={selectedThemeMode}>
        <MobileNavigationTrigger onClick={handleOpenDrawer}>
          <Menu />
        </MobileNavigationTrigger>
        <SwipeableDrawer
          isOpen={open}
          onOpen={handleOpenDrawer}
          onClose={handleCloseDrawer}
        >
          <MobileNavigationContent>
            <div>
              <div className='auth-user__element'>
                <TopNavAuthorisedUser />
                <ThemeModeToggle />
              </div>
              {TopNavItems}
            </div>
            <div>
              {BottomNavItems}
            </div>
          </MobileNavigationContent>
        </SwipeableDrawer>
      </MobileNavigationContainer>
    </>
  )
}

export default withRouter(SideNavigation)

const SideNavigationContainer = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  z-index: ${props => props.theme.zIndex.sideBar};
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  margin-top: ${props => props.theme.dimensions.navHeight};
  height: calc(100vh - ${props => props.theme.dimensions.navHeight});
  width: ${props => props.theme.dimensions.sideNavShrinkWidth};
  background-color: ${props => props.themeMode === THEME_MODES.light ? props.theme.palette.primary.main : props.theme.palette.common.white};
  color: ${props => props.theme.palette.primary.contrastText};
  overflow: hidden;
  box-shadow: ${props => props.theme.shadows.main};
  transition: ${props => `width 300ms ${props.theme.transitions.easing.easeInOut}`};

  &:hover {
    width: ${props => props.theme.dimensions.sideNavFullWidth};
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`

const SideNavigationContent = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`

const SideNavigationBottomElements = styled.div``

const MobileNavigationContainer = styled.div`
  display: none

  ${props => props.theme.breakpointsMedia.mobile} {
    display: block;
  }
`

const MobileNavigationContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  height: 100%;
  
  .auth-user__element {
    color: ${props => props.theme.palette.primary.contrastText};
    margin-bottom: 30px;
    display: flex;
  }
`

const MobileNavigationTrigger = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${props => props.theme.dimensions.navHeight};
  z-index: ${props => props.theme.zIndex.drawer};
  display: inline-flex;
  align-items: center;
  padding: 0 17px;
  
  svg {
    font-size: 30px;
  }
`
