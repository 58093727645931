import { string, object, number } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'
import { UNIT_FORMATS } from 'utils/flags'

// PriceUp form validator

export const priceUpFormSchema = object().shape({
  unit: string().required(() => ({ key: 'form.unit.errors.required' })),
  amount: number()
    .when('unit', {
      is: UNIT_FORMATS.percentage,
      then: number().integer(() => ({ key: 'form.priceIncreaseAmount.errors.integer' }))
    })
    .required(() => ({ key: 'form.priceIncreaseAmount.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.priceIncreaseAmount.errors.numberMin',
      options: { number: 0 }
    })),
  qty: number()
    .required(() => ({ key: 'form.quantityThreshold.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.quantityThreshold.errors.numberMin',
      options: { number: 0 }
    })),
  maxPrice: number()
    .max(99999.99, () => ({ key: 'form.number.errors.maxNumber' }))
    .required(() => ({ key: 'form.maxPrice.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.maxPrice.errors.numberMin',
      options: { number: 0 }
    })),
  notSellingThreshold: number()
    .required(() => ({ key: 'form.notSellingThreshold.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.notSellingThreshold.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validatePriceUpFormField = values => validateField(priceUpFormSchema, values)
export const validatePriceUpFormValues = validateValues(priceUpFormSchema)
export const validatePriceUpCurrentValues = validateValues(priceUpFormSchema)

// Auto decision PriceUp form validator

export const autoDecisionPriceUpFormSchema = object().shape({
  unit: string().required(() => ({ key: 'form.unit.errors.required' })),
  amount: number()
    .when('unit', {
      is: UNIT_FORMATS.percentage,
      then: number().integer(() => ({ key: 'form.priceIncreaseAmount.errors.integer' }))
    })
    .required(() => ({ key: 'form.priceIncreaseAmount.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.priceIncreaseAmount.errors.numberMin',
      options: { number: 0 }
    })),
  qty: number()
    .required(() => ({ key: 'form.quantityThreshold.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.quantityThreshold.errors.numberMin',
      options: { number: 0 }
    })),
  notSellingThreshold: number()
    .required(() => ({ key: 'form.notSellingThreshold.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.notSellingThreshold.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateAutoDecisionPriceUpFormField = values => validateField(autoDecisionPriceUpFormSchema, values)
export const validateAutoDecisionPriceUpFormValues = validateValues(autoDecisionPriceUpFormSchema)
export const validateAutoDecisionPriceUpFormCurrentValues = validateValues(autoDecisionPriceUpFormSchema)

// PromoDiff form validator

export const promoDiffFormSchema = object().shape({
  unit: string().required(() => ({ key: 'form.unit.errors.required' })),
  amount: number()
    .when('unit', {
      is: UNIT_FORMATS.percentage,
      then: number().integer(() => ({ key: 'form.priceIncreaseAmount.errors.integer' }))
    })
    .required(() => ({ key: 'form.priceIncreaseAmount.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.priceIncreaseAmount.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validatePromoDiffFormField = values => validateField(promoDiffFormSchema, values)
export const validatePromoDiffFormValues = validateValues(promoDiffFormSchema)
export const validatePromoDiffCurrentValues = validateValues(promoDiffFormSchema)

export const autoDecisionPriceDownFormSchema = object().shape({
  unit: string().required(() => ({ key: 'form.unit.errors.required' })),
  amount: number()
    .when('unit', {
      is: UNIT_FORMATS.percentage,
      then: number().integer(() => ({ key: 'form.priceDecreaseAmount.errors.integer' }))
    })
    .required(() => ({ key: 'form.priceDecreaseAmount.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.priceDecreaseAmount.errors.numberMin',
      options: { number: 0 }
    })),
  qty: number()
    .required(() => ({ key: 'form.quantityThreshold.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.quantityThreshold.errors.numberMin',
      options: { number: 0 }
    })),
  minMargin: number()
    .required(() => ({ key: 'form.minMargin.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.minMargin.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateAutoDecisionPriceDownFormField = values => validateField(autoDecisionPriceDownFormSchema, values)
export const validateAutoDecisionPriceDownFormValues = validateValues(autoDecisionPriceDownFormSchema)
export const validateAutoDecisionPriceDownFormCurrentValues = validateValues(autoDecisionPriceDownFormSchema)

export const priceDownFormSchema = object().shape({
  unit: string().required(() => ({ key: 'form.unit.errors.required' })),
  amount: number()
    .when('unit', {
      is: UNIT_FORMATS.percentage,
      then: number().integer(() => ({ key: 'form.priceDecreaseAmount.errors.integer' }))
    })
    .required(() => ({ key: 'form.priceDecreaseAmount.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.priceDecreaseAmount.errors.numberMin',
      options: { number: 0 }
    })),
  qty: number()
    .required(() => ({ key: 'form.daysThreshold.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.daysThreshold.errors.numberMin',
      options: { number: 0 }
    })),
  minMargin: number()
    .required(() => ({ key: 'form.minMargin.errors.required' }))
    .moreThan(0, () => ({
      key: 'form.minMargin.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validatePriceDownFormField = values => validateField(priceDownFormSchema, values)
export const validatePriceDownFormValues = validateValues(priceDownFormSchema)
export const validatePriceDownFormCurrentValues = validateValues(priceDownFormSchema)
