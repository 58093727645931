import APIService from 'services/APIService'

const api = APIService()

export const createShippingOrders = payload => api.post('/shipping-orders', payload)
export const fetchShippingOrders = query => api.get(`/shipping-orders?${query}`)
export const getShippingOrderCSV = ({ id }) => api.get(`/shipping-orders/${id}/csv`)
export const editShippingOrder = ({ id, toBeShippedAt, name, items }) => api.patch(`/shipping-orders/${id}/edit`, { toBeShippedAt, name, items })
export const editShippingOrderDate = ({ id, toBeShippedAt }) => api.patch(`/shipping-orders/${id}/shipping-date`, { toBeShippedAt })
export const getShippingOrderItems = ({ id }) => api.get(`/shipping-orders/${id}/items`)
