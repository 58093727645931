import React from 'react'
import Icon from 'components/Icon'

export const SnoozeAutoDecision = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 192 192'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path transform='scale(-1, 1) translate(-200, 0)' d='M161.045,121.591C166.356,114.422,176,97.848,176,72c0-42.393-32.9-72-80-72A72.081,72.081,0,0,0,24,72V86.111L8.845,116.422a8,8,0,0,0,3.577,10.733l12.5,6.25,7.232,36.164A8,8,0,0,0,40,176H82.584l5.988,14.971,14.856-5.942-8-20A8,8,0,0,0,88,160H46.559l-6.714-33.569a8,8,0,0,0-4.267-5.586l-8.845-4.423L39.155,91.578A7.994,7.994,0,0,0,40,88V72A56.063,56.063,0,0,1,96,16c31.856,0,64,17.315,64,56,0,27.93-13.141,41.81-13.657,42.343a8,8,0,0,0-1.887,8.319l24,68,15.088-5.324Z' />
        <path transform='scale(-1, 1) translate(-200, 0)' d='M64.946,91.774A8,8,0,0,0,72,96H96V80H86.948l7.708-11.562A8,8,0,0,0,88,56H64V72h9.052L65.344,83.563A8,8,0,0,0,64.946,91.774Z' />
        <path transform='scale(-1, 1) translate(-200, 0)' d='M134.656,44.438A8,8,0,0,0,128,32H104V48h9.052l-7.708,11.563A8,8,0,0,0,112,72h24V56h-9.052Z' />
      </g>
    </Icon>
  )
}

export default SnoozeAutoDecision
