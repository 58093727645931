import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, delay, select } from '@redux-saga/core/effects'
import * as saleChannelsService from 'services/SaleChannelsService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { propOr } from 'ramda'
import { generateCurrentQuery } from 'utils/query'
import { getUnpublishedProductChannels, getAllOtherProductChannelsById } from 'features/saleChannels/ducks/selectors'
import { PRODUCT_SALE_CHANNEL_STATUSES } from 'utils/saleChannels'
import { getEntityExistsError } from 'utils/errors'

// ROUTINES

export const fetchSaleChannelsRoutine = createRoutine('FETCH_SALE_CHANNELS')
export const createSaleChannelRoutine = createRoutine('CREATE_SALE_CHANNEL')
export const editSaleChannelRoutine = createRoutine('EDIT_SALE_CHANNEL')
export const deleteSaleChannelRoutine = createRoutine('DELETE_SALE_CHANNEL')
export const attachProductToSaleChannelRoutine = createRoutine('ATTACH_PRODUCT_TO_SALE_CHANNEL')
export const editProductSaleChannelRoutine = createRoutine('EDIT_PRODUCT_SALE_CHANNEL')
export const deleteProductSaleChannelRoutine = createRoutine('DELETE_PRODUCT_SALE_CHANNEL')
export const fetchProductSaleChannelsRoutine = createRoutine('FETCH_PRODUCT_SALE_CHANNELS')

// ACTIONS

function * fetchSaleChannels ({ payload }) {
  yield put(fetchSaleChannelsRoutine.request())
  try {
    const result = yield call(saleChannelsService.fetchSaleChannels, payload)
    yield put(fetchSaleChannelsRoutine.success(propOr({}, 'data')(result)))
  } catch (e) {
    yield put(fetchSaleChannelsRoutine.failure(e))
    console.error(e)
  }
}

function * createSaleChannel ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createSaleChannelRoutine.request())
  try {
    yield call(saleChannelsService.createSaleChannel, values)
    delay(500)
    yield put(fetchSaleChannelsRoutine(generateCurrentQuery()))
    yield put(
      showToastRoutine({
        key: 'toast.createSaleChannelSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(createSaleChannelRoutine.success())
    callback()
  } catch (e) {
    yield put(createSaleChannelRoutine.failure(e))
    yield put(
      showToastRoutine(getEntityExistsError(e))
    )
  }
}

function * editSaleChannel ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editSaleChannelRoutine.request())
  try {
    yield call(saleChannelsService.editSaleChannel, values)
    yield put(
      showToastRoutine({
        key: 'toast.editSaleChannelSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchSaleChannelsRoutine(generateCurrentQuery()))
    yield put(editSaleChannelRoutine.success())
    callback()
  } catch (e) {
    yield put(editSaleChannelRoutine.failure(e))
    yield put(
      showToastRoutine(getEntityExistsError(e))
    )
  }
}

function * deleteSaleChannel ({ payload }) {
  yield put(deleteSaleChannelRoutine.request())
  try {
    yield call(saleChannelsService.deleteSaleChannel, payload)
    yield put(
      showToastRoutine({
        key: 'toast.deleteSaleChannelSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchSaleChannelsRoutine(generateCurrentQuery()))
    yield put(deleteSaleChannelRoutine.success())
  } catch (e) {
    yield put(deleteSaleChannelRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.deleteSaleChannelFailure',
        severity: SEVERITY.error
      })
    )
  }
}

function * fetchProductSaleChannels ({ payload }) {
  yield put(fetchProductSaleChannelsRoutine.request())
  try {
    const result = yield call(saleChannelsService.fetchProductSaleChannels, payload)
    yield put(fetchProductSaleChannelsRoutine.success(propOr([], 'data')(result)))
  } catch (e) {
    yield put(fetchProductSaleChannelsRoutine.failure(e))
    console.error(e)
  }
}

function * attachProductToSaleChannel ({ payload: { values = {}, callback = () => {} } }) {
  yield put(attachProductToSaleChannelRoutine.request())
  try {
    const result = yield call(saleChannelsService.attachProductToSaleChannel, values)
    yield put(
      showToastRoutine({
        key: 'toast.attachProductToSaleChannelSuccess',
        severity: SEVERITY.success
      })
    )
    const newProductSaleChannelId = result.data.id
    const unpublishedChannelsList = yield select(state => getUnpublishedProductChannels(state, values.productId, values.saleChannelId))
    const allOtherChannelsList = yield select(state => getAllOtherProductChannelsById(state, values.productId, values.saleChannelId, newProductSaleChannelId))
    if (values.status === PRODUCT_SALE_CHANNEL_STATUSES.published) {
      yield Promise.all(unpublishedChannelsList.map(channel => saleChannelsService.deleteProductSaleChannel({ id: channel.id })))
    } else {
      yield Promise.all(allOtherChannelsList.map(channel => saleChannelsService.deleteProductSaleChannel({ id: channel.id })))
    }
    delay(500)
    yield put(fetchProductSaleChannelsRoutine({ id: values.productId }))
    yield put(attachProductToSaleChannelRoutine.success())
    callback()
  } catch (e) {
    yield put(attachProductToSaleChannelRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.deleteSaleChannelFailure',
        severity: SEVERITY.error
      })
    )
  }
}

function * editProductSaleChannel ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editProductSaleChannelRoutine.request())
  try {
    yield call(saleChannelsService.editProductToSaleChannel, values)
    yield put(
      showToastRoutine({
        key: 'toast.attachProductToSaleChannelSuccess',
        severity: SEVERITY.success
      })
    )
    delay(500)
    yield put(fetchProductSaleChannelsRoutine({ id: values.productId }))
    yield put(editProductSaleChannelRoutine.success())
    callback()
  } catch (e) {
    yield put(editProductSaleChannelRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.deleteSaleChannelFailure',
        severity: SEVERITY.error
      })
    )
  }
}

function * deleteProductSaleChannel ({ payload }) {
  yield put(deleteProductSaleChannelRoutine.request())
  try {
    yield call(saleChannelsService.deleteProductSaleChannel, payload)
    yield put(
      showToastRoutine({
        key: 'toast.deleteProductSaleChannelSuccess',
        severity: SEVERITY.success
      })
    )
    delay(500)
    yield put(fetchProductSaleChannelsRoutine({ id: payload.productId }))
    yield put(deleteProductSaleChannelRoutine.success())
  } catch (e) {
    yield put(deleteProductSaleChannelRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.deleteSaleChannelFailure',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * fetchSaleChannelsWatcher () {
  yield takeLatest(fetchSaleChannelsRoutine.TRIGGER, fetchSaleChannels)
}

export function * createSaleChannelWatcher () {
  yield takeLatest(createSaleChannelRoutine.TRIGGER, createSaleChannel)
}

export function * editSaleChannelWatcher () {
  yield takeLatest(editSaleChannelRoutine.TRIGGER, editSaleChannel)
}

export function * deleteSaleChannelWatcher () {
  yield takeLatest(deleteSaleChannelRoutine.TRIGGER, deleteSaleChannel)
}

export function * attachProductToSaleChannelWatcher () {
  yield takeLatest(attachProductToSaleChannelRoutine.TRIGGER, attachProductToSaleChannel)
}

export function * fetchProductSaleChannelsWatcher () {
  yield takeLatest(fetchProductSaleChannelsRoutine.TRIGGER, fetchProductSaleChannels)
}

export function * editProductSaleChannelWatcher () {
  yield takeLatest(editProductSaleChannelRoutine.TRIGGER, editProductSaleChannel)
}

export function * deleteProductSaleChannelWatcher () {
  yield takeLatest(deleteProductSaleChannelRoutine.TRIGGER, deleteProductSaleChannel)
}

// SAGAS

export const saleChannelsSagas = [
  fork(fetchSaleChannelsWatcher),
  fork(createSaleChannelWatcher),
  fork(editSaleChannelWatcher),
  fork(deleteSaleChannelWatcher),
  fork(attachProductToSaleChannelWatcher),
  fork(fetchProductSaleChannelsWatcher),
  fork(editProductSaleChannelWatcher),
  fork(deleteProductSaleChannelWatcher)
]
