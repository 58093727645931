import React from 'react'

import WillLastHistoryTable from 'features/willLastHistory/willLastHistoryTable'

export const WillLastHistorySection = () => {
  return (
    <WillLastHistoryTable />
  )
}

export default WillLastHistorySection
