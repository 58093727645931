import React from 'react'
import Icon from 'components/Icon'

export const MaxPrice = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 470 437.3'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='M224.8,437.3V407c-17.4-0.3-35.4-5.7-45.9-13.2l7.2-20.1c10.8,7.2,26.4,12.9,43.2,12.9c21.3,0,35.7-12.3,35.7-29.4 c0-16.5-11.7-26.7-33.9-35.7c-30.6-12-49.5-25.8-49.5-51.9c0-24.9,17.7-43.8,45.3-48.3V191h18.6v29.1c18,0.6,30.3,5.4,39,10.5 l-7.5,19.8c-6.3-3.6-18.6-10.2-37.8-10.2c-23.1,0-31.8,13.8-31.8,25.8c0,15.6,11.1,23.4,37.2,34.2c30.9,12.6,46.5,28.2,46.5,54.9 c0,23.7-16.5,45.9-47.4,51v31.2H224.8z' />
      </g>
      <g>
        <path d='M146.5,94.5c-1.2-23.5-2.8-51.8-2.5-72.8h-0.8c-5.8,19.8-12.8,40.8-21.2,64l-29.8,81.8H75.8L48.5,87.2 c-8-23.8-14.8-45.5-19.5-65.5h-0.5c-0.5,21-1.8,49.2-3.2,74.5l-4.5,72.2H0L11.8,0h27.8l28.8,81.5c7,20.8,12.8,39.2,17,56.8H86 c4.2-17,10.2-35.5,17.8-56.8l30-81.5h27.8L172,168.5h-21.2L146.5,94.5z' />
        <path d='M230.2,115.5l-17.5,53h-22.5L247.5,0h26.2l57.5,168.5H308l-18-53H230.2z M285.5,98.5L269,50c-3.8-11-6.2-21-8.8-30.8h-0.5 c-2.5,10-5.2,20.2-8.5,30.5l-16.5,48.8H285.5z' />
        <path d='M444.8,168.5l-21.5-37.2C414.5,117,409,107.8,403.8,98h-0.5c-4.8,9.8-9.5,18.8-18.2,33.5l-20.2,37h-25l51.5-85.2L341.8,0 H367l22.2,39.5c6.2,11,11,19.5,15.5,28.5h0.8c4.8-10,9-17.8,15.2-28.5l23-39.5h25l-51.2,82l52.5,86.5H444.8z' />
      </g>
    </Icon>
  )
}

export default MaxPrice
