import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedItems } from 'ducks/global/selectors'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'
import { generateCurrentQuery } from 'utils/query'

import IconButton from 'components/atoms/IconButton'
import AddToCart from 'components/icons/AddToCart'
import { addProductsToCartRoutine } from 'features/products/ducks/actions'
import Tooltip from 'components/atoms/Tooltip'
import { handleResetSelectionRoutine } from 'ducks/global/actions'

export const AddProductsToCart = (props) => {
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const dispatch = useDispatch()

  const addProductsToCart = useCallback(
    payload => dispatch(addProductsToCartRoutine(payload)),
    [dispatch]
  )

  const fetchMonitoring = useCallback(
    () => dispatch(fetchMonitoringRoutine(generateCurrentQuery())),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  const handleCallback = () => {
    fetchMonitoring()
    handleResetSelection()
  }

  const handleAddToCart = () =>
    addProductsToCart({
      values: { productIds: selectedItems },
      callback: handleCallback
    })

  return (
    <Tooltip title={t('ordersPage.addToCartCTA')}>
      <IconButton
        type='button'
        id='button-add-to-cart'
        onClick={handleAddToCart}
        color='primary'
      >
        <AddToCart />
      </IconButton>
    </Tooltip>
  )
}

export default AddProductsToCart
