import React from 'react'
import Icon from 'components/atoms/Icon'

export const Value = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 480.002 480.002'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <g>
          <path d='M464.002,272.001h-96c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h55.712L317.57,396.865l-118.4-59.2
c-4.672-2.304-10.208-2.24-14.816,0.288l-176,96c-7.776,4.224-10.624,13.952-6.4,21.696c2.912,5.344,8.384,8.352,14.048,8.352
c2.592,0,5.216-0.64,7.648-1.984l168.672-91.968l120.512,60.256c5.824,2.848,12.768,2.016,17.696-2.272l117.472-102.784v58.752
c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16v-96C480.002,279.169,472.834,272.001,464.002,272.001z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M176.002,16.001c-97.216,0-176,78.784-176,176c0,68.768,39.552,128.16,97.024,157.12l71.968-39.264
c13.6-7.456,30.208-7.84,44.352-0.864l59.616,29.824c47.616-31.52,79.04-85.472,79.04-146.816
C352.002,94.785,273.218,16.001,176.002,16.001z M176.002,176.001c26.464,0,48,21.536,48,48c0,20.832-13.408,38.432-32,45.056
v18.944c0,8.832-7.168,16-16,16c-8.832,0-16-7.168-16-16v-16h-16c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h32
c8.832,0,16-7.168,16-16c0-8.832-7.168-16-16-16c-26.464,0-48-21.536-48-48c0-20.832,13.408-38.432,32-45.056V96.001
c0-8.832,7.168-16,16-16c8.832,0,16,7.168,16,16v16h16c8.832,0,16,7.168,16,16c0,8.832-7.168,16-16,16h-32
c-8.832,0-16,7.168-16,16C160.002,168.833,167.17,176.001,176.002,176.001z'
          />
        </g>
      </g>
    </Icon>
  )
}

export default Value
