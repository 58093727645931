import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getHeadErrorOrEmptyObject } from 'utils/form'
import DatePicker from 'components/atoms/DatePicker'
import { formatISODate } from 'utils/date'

export const DatePickerField = props => {
  const {
    name,
    type,
    onChange,
    validate,
    label,
    value: initialValue,
    required,
    disabled,
    reset,
    disablePast,
    disableFuture,
    dependentValue,
    minDate,
    maxDate
  } = props
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: {}
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (touched && !reset) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObject(v) })
      })
    }
  }, [value, touched, reset, dependentValue])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (reset) {
      _setValue(initialValue || '')
    }
  }, [reset])

  const handleFocus = () => _setTouched(true)
  const handleChange = e => {
    const isoFormat = formatISODate(e)
    _setTouched(true)
    _setValue(isoFormat)
    onChange(name, isoFormat)
  }

  return (
    <DatePicker
      disabled={disabled}
      required={required}
      name={name}
      label={label}
      value={value}
      errorText={valid || disabled ? '' : t(error.key, error.options)}
      type={type}
      error={!valid && !disabled}
      onFocus={handleFocus}
      onChange={handleChange}
      disablePast={disablePast}
      disableFuture={disableFuture}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}

DatePickerField.defaultProps = {
  onChange: () => {},
  validate: () => {}
}

export default DatePickerField
