import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Delivery = Record({
  contractor: '',
  createdAt: '',
  id: '',
  namePZ: '',
  price: '',
  priceNet: '',
  productId: '',
  qty: 0,
  warehouseInfo: ''
})

export const DeliveriesMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Deliveries = Record({
  state: API_STATES.PRISTINE,
  deliveriesMeta: new DeliveriesMeta(),
  deliveries: []
})
