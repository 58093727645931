import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as producersService from 'services/ProducersService'
import { pathOr } from 'ramda'
import { generateCurrentQuery } from 'utils/query'
import { handleResetSelectionRoutine } from 'ducks/global/actions'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

// ROUTINES

export const fetchProducersRoutine = createRoutine('FETCH_PRODUCERS')
export const assignProducersToLineRoutine = createRoutine('ASSIGN_PRODUCERS_TO_LINE')

// ACTIONS

function * fetchProducers ({ payload }) {
  yield put(fetchProducersRoutine.request())
  try {
    const result = yield call(producersService.fetchProducers, payload)
    yield put(fetchProducersRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchProducersRoutine.failure(e))
    console.error(e)
  }
}

function * assignProducersToLine ({ payload }) {
  yield put(assignProducersToLineRoutine.request())
  try {
    yield call(producersService.assignProducersToLine, payload)
    yield put(fetchProducersRoutine(generateCurrentQuery()))
    yield put(handleResetSelectionRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.assignLinesToUserSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(assignProducersToLineRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

// WATCHERS

export function * fetchProducersWatcher () {
  yield takeLatest(fetchProducersRoutine.TRIGGER, fetchProducers)
}

export function * assignProducersToLineWatcher () {
  yield takeLatest(assignProducersToLineRoutine.TRIGGER, assignProducersToLine)
}

// SAGAS

export const producersSagas = [
  fork(fetchProducersWatcher),
  fork(assignProducersToLineWatcher)
]
