import React from 'react'
import ApexChart from 'react-apexcharts'
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { getSelectedTheme } from 'ducks/global/selectors'
import { THEME_MODES } from 'utils/theme'

export const Chart = (props) => {
  const selectedThemeMode = useSelector(getSelectedTheme)

  return (
    <ApexChart
      series={props.series}
      options={{
        ...props.options,
        theme: {
          mode: selectedThemeMode === THEME_MODES.light ? 'light' : 'dark'
        },
        chart: {
          ...propOr({}, 'chart', props.options),
          fontFamily: '\'Jost\', \'Open Sans\', sans-serif',
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        }
      }}
      type={props.type}
      width={props.width}
    />
  )
}

export default Chart
