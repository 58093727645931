import { Calendar as MuiCalendar } from '@material-ui/pickers'
import styled from 'styled-components'
import React from 'react'
import moment from 'moment'
import { includes } from 'ramda'
import { formatDate, FORMATS } from 'utils/date'

export const DayOffCalendar = (props) => {
  const { date, disabledDays } = props

  const shouldDisableDay = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const convertedDisabledDays = disabledDays.map(day => formatDate(day, FORMATS.slash))
    const isDayIncludedInDisabledDays = includes(formatDate(date, FORMATS.slash), convertedDisabledDays)
    return isDayIncludedInDisabledDays ? <DisabledDayContainer>{dayComponent}</DisabledDayContainer> : dayComponent
  }

  return (
    <MuiCalendar
      date={moment(date)}
      allowKeyboardControl
      renderDay={shouldDisableDay}
    />
  )
}

export default DayOffCalendar

const DisabledDayContainer = styled.div`
  .MuiTypography-root {
    color: ${props => props.theme.palette.secondary.main} !important;
  }
`
