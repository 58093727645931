import APIService from 'services/APIService'

const api = APIService()

/**
 * Authenticates users
 * @param {Object} payload - auth data
 * @param {string} payload.email - users email address
 * @param {string} payload.password - users password
 */
export const loginUser = payload => api.post('/users/auth', payload)
export const fetchAuthUser = () => api.get('/users/profile')

/**
 * Sends email address with a link to reset password page
 * @param {Object} payload - request data object
 * @param {string} payload.email - users email address
 */
export const resetPasswordInit = payload =>
  api.post('/users/reset-password-init', payload)

/**
 * Resets user password
 * @param {Object} payload - request data object
 * @param {string} payload.id - users id
 * @param {string} payload.token - reset password token
 * @param {string} payload.password - new password
 */
export const resetPasswordFinish = payload =>
  api.post('/users/reset-password-finish', payload)

/**
 * Checks if authentication token is still valid
 * @param {Object} payload - validate password payload
 * @param {string} payload.id - users id
 * @param {string} payload.token - reset password token
 */
export const validateResetPasswordToken = payload =>
  api.post('/users/reset-password-validate', payload)
