import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CreateSaleChannelForm from 'features/saleChannels/CreateSaleChannelForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import SaleChannels from 'components/icons/SaleChannels'

export const CreateSalechannelModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button startIcon={<SaleChannels />} onClick={handleOpen}>{t('saleChannelsPage.createSaleChannel.title')}</Button>
      <Modal
        title={t('saleChannelsPage.createSaleChannel.title')}
        open={open}
        handleClose={handleClose}
      >
        <CreateSaleChannelForm onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default CreateSalechannelModal
