import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { MentionsInput, Mention } from 'react-mentions'
import { useTranslation } from 'react-i18next'
import { getHeadErrorOrEmptyObject } from 'utils/form'
import { fetchUsersRoutine } from 'features/users/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { SORT_ORDER } from 'utils/table'
import { getUsersAsMentions } from 'features/users/ducks/selectors'
import {
  includes,
  map,
  not,
  pipe,
  split,
  join, uniq, toLower
} from 'ramda'
import { generateSelectedTheme } from 'utils/theme'
import { getSelectedTheme } from 'ducks/global/selectors'
import {
  getMentionWithoutCharacter,
  shouldAddNewMention,
  findUserByDisplay
} from 'utils/mentions'

export const UserMentionsField = props => {
  const selectedThemeMode = useSelector(getSelectedTheme)
  const {
    required,
    name,
    onChange,
    validate,
    reset,
    disabled,
    singleLine,
    label,
    mentions
  } = props
  const [touched, _setTouched] = useState(false)
  const [focused, _setFocused] = useState(false)
  const [value, _setValue] = useState('')
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: {}
  })

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const users = useSelector(getUsersAsMentions)

  const fetchUsers = useCallback(
    () => dispatch(fetchUsersRoutine(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'login',
        dir: SORT_ORDER.asc
      }
    }))),
    [dispatch]
  )

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    if (touched && !reset) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObject(v) })
      })
    }
  }, [value, touched, reset])

  useEffect(() => {
    if (reset) {
      _setValue('')
    }
  }, [reset])

  const handleFocus = () => {
    _setTouched(true)
    _setFocused(true)
  }
  const handleBlur = () => _setFocused(false)

  const handleChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    const selectedUsers = mentions.map(mention => mention.id)

    const testValue = pipe(
      split(' '),
      map(
        word => {
          if (shouldAddNewMention(word, users)) {
            const userOption = findUserByDisplay(users)(getMentionWithoutCharacter(word))
            onChange(newPlainTextValue, uniq([...selectedUsers, userOption.id]))
            return `@[${userOption.display}](${userOption.id})`
          } else {
            onChange(newPlainTextValue, selectedUsers)
            return word
          }
        }
      ),
      join(' ')
    )(newValue)

    _setValue(testValue)
  }

  const searchUser = (query, callback) => {
    if (query) {
      const usersToSelect = users.filter(user => includes(toLower(query), toLower(user.display)) && not(includes(user.id, mentions)))
      return callback(usersToSelect)
    }
  }

  return (
    <>
      <InputContainer error={!valid && !disabled}>
        <InputLabel error={!valid && !disabled} isFocused={focused} isFocusedOrHasValue={focused || value.length > 0}>
          {label}{required && ' *'}
        </InputLabel>
        <StyledMentionsInput
          allowSpaceInQuery
          allowSuggestionsAboveCursor
          singleLine={singleLine}
          name={name}
          error={!valid && !disabled}
          disabled={disabled}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          style={MentionsInputStyles(generateSelectedTheme(selectedThemeMode))}
          id={`${name}-user-mentions-field`}
        >
          <Mention
            trigger='@'
            markup='@[__display__](__id__)'
            displayTransform={(id, display) => `@${display}`}
            data={searchUser}
          />
        </StyledMentionsInput>
      </InputContainer>
      {
        !valid && !disabled && (
          <ErrorText>
            {valid || disabled ? '' : t(error.key, error.options)}
          </ErrorText>
        )
      }
    </>
  )
}

UserMentionsField.defaultProps = {
  onChange: () => {},
  validate: () => {}
}

export default UserMentionsField

const ErrorText = styled.div`
  color: ${props => props.theme.palette.error.main};
  font-size: 0.75rem;
  margin: 3px 14px 0;
  line-height: 1;
`
const InputLabel = styled.div`
  color: ${props => {
  switch (true) {
    case props.error:
      return props.theme.palette.error.main
    case props.isFocused:
      return props.theme.palette.primary.main
    default:
      return props.theme.palette.text.main
  }
}};
  position: absolute;
  left: 10px;
  top: ${props => props.isFocusedOrHasValue ? '-7px' : '18px'};
  font-size: ${props => props.isFocusedOrHasValue ? '11px' : '1rem'};
  line-height: ${props => props.isFocusedOrHasValue ? '11px' : '1rem'};
  padding: ${props => props.isFocusedOrHasValue ? '0 5px' : '0'};
  background-color: ${props => props.isFocusedOrHasValue ? props.theme.palette.common.white : 'transparent'};
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  display: inline-block;
  width: auto !important;
`

const MentionsInputStyles = theme => ({
  highlighter: {
    color: theme.palette.text.main,
    border: 'none'
  }
})

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding 0 14px;
  border-radius: ${props => props.theme.shape.borderRadius} !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: ${props => props.error ? props.theme.palette.error.main : props.theme.palette.common.gray400} !important;
  padding: 0 14px !important;
  line-height: 50px !important;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: ${props => props.disabled ? props.theme.palette.common.gray300 : 'transparent'} !important;
  
  & > div {
    width: 100%;
  }
  
  &:hover {
    cursor: text;
    border-color: ${props => props.error ? props.theme.palette.error.main : props.theme.palette.common.gray600} !important;
  }
  
  &:focus-within {
    border-width: 2px !important;
    border-color: ${props => props.error ? props.theme.palette.error.main : props.theme.palette.primary.main} !important;
    color: ${props => props.error ? props.theme.palette.error.main : props.theme.palette.text.main} !important;
  }
`

const StyledMentionsInput = styled(MentionsInput)`
  strong {
    font-weight: 400 !important;
    text-shadow: 0.3px 0.3px 0.3px ${props => props.theme.palette.text.main} !important;
  }

  input {
    border: none !important;
    padding: 0 !important;
    outline: none !important;
    width: 100% !important;
    line-height: 50px !important;
    color: ${props => props.theme.palette.text.main} !important;
  }
  
  div {
    min-width: auto !important;
  }
  
  ul,
  ol {
    background-color: ${props => props.theme.palette.common.white} !important;
    border-radius: ${props => props.theme.shape.borderRadius} !important;
    padding: 8px 0 !important;
    box-shadow: ${props => props.theme.shadows.main} !important;
    max-height: 200px !important;
    min-width: auto !important;
    overflow-y: auto !important;
  }
  
  li {
    padding: 6px 16px !important;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
    line-height: 20px !important;
  }
  
  li:hover,
  li:focus {
    background-color: ${props => props.theme.palette.common.gray100} !important;
  }
  
  li[class$="focused"] {
    background-color: ${props => props.theme.palette.common.gray100} !important;
  }
`
