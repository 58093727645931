import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditProductSaleChannelForm from 'features/saleChannels/EditProductSaleChannelForm'
import Modal from 'components/atoms/Modal'
import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'

export const EditProductSaleChannelModal = (props) => {
  const { data, saleChannelId } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal
        title={t('productPage.saleChannels.editSaleChannel.title')}
        open={open}
        handleClose={handleClose}
      >
        <EditProductSaleChannelForm data={data} saleChannelId={saleChannelId} onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default EditProductSaleChannelModal
