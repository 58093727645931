import React, { useCallback, useEffect } from 'react'
import DayOffCalendar from 'components/atoms/DayOffCalendar'
import { useSelector, useDispatch } from 'react-redux'

import { fetchDaysOffRoutine } from 'features/daysOff/ducks/actions'
import { getDaysOffArray } from 'features/daysOff/ducks/selectors'

export const CurrentDaysOff = (props) => {
  const daysOff = useSelector(getDaysOffArray)
  const dispatch = useDispatch()

  const fetchDaysOff = useCallback(
    () => dispatch(fetchDaysOffRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchDaysOff()
  }, [])

  return (
    <DayOffCalendar date={new Date()} disabledDays={daysOff} />
  )
}

export default CurrentDaysOff
