import React from 'react'
import ReactAsyncCreatableSelect from 'react-select/async-creatable'
import { CustomInput, REACT_SELECT_STYLES } from 'utils/reactSelectOutlined'
import { useTranslation } from 'react-i18next'
import { generateSelectedTheme } from 'utils/theme'
import { useSelector } from 'react-redux'
import { getSelectedTheme } from 'ducks/global/selectors'

const customComponents = {
  Input: CustomInput
}

export const AsyncCreatableSelect = (props) => {
  const selectedThemeMode = useSelector(getSelectedTheme)
  const { required, label, error, errorText, disabled } = props
  const { t } = useTranslation()

  return (
    <ReactAsyncCreatableSelect
      styles={REACT_SELECT_STYLES(generateSelectedTheme(selectedThemeMode))}
      components={customComponents}
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      isDisabled={disabled}
      isClearable
      formatCreateLabel={label => `${t('form.addNew.label')} "${label}"`}
      {...props}
    />
  )
}

AsyncCreatableSelect.defaultProps = {
  required: false,
  isSearchable: true,
  options: [],
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  label: '',
  error: false,
  errorText: ''
}

export default AsyncCreatableSelect
