import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateCreateSaleChannelField,
  validateCreateSaleChannelValues
} from 'features/saleChannels/ducks/schema'

import { createSaleChannelRoutine } from 'features/saleChannels/ducks/actions'
import { getIsSaleChannelsLoading } from 'features/saleChannels/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  name: ''
}

export const CreateSaleChannelForm = props => {
  const { onSubmit } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSaleChannelsLoading)

  const createSaleChannel = useCallback(
    payload => dispatch(createSaleChannelRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateCreateSaleChannelValues(values, _validateSchema)
  }, [values])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    createSaleChannel({
      values,
      callback: onSubmit
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ButtonContainer>
        <TextField
          required
          name='name'
          label={t('form.saleChannelName.label')}
          onChange={handleOnChange}
          validate={validateCreateSaleChannelField(values)}
        />
        <ChannelHint>
          {t('saleChannelsPage.createSaleChannel.hint')}
        </ChannelHint>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('saleChannelsPage.createSaleChannel.createCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default CreateSaleChannelForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const ChannelHint = styled.div`
  font-size: 11px;
  margin: 5px 0 20px;
  color: ${props => props.theme.palette.common.gray600};
`
