import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { join, pipe, propEq, propOr, split } from 'ramda'
import { formatDate, FORMATS } from 'utils/date'
import { USER_ROLES } from 'utils/user'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { SHIPPING_STATUS } from 'utils/shippingOrders'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

import { getUserLogin, getUserRole } from 'features/auth/ducks/selectors'
import { getShippingOrdersArray, getShippingOrdersMeta, getIsShippingOrdersLoading, getShippingOrdersItems } from 'features/shippingOrders/ducks/selectors'
import { fetchShippingOrdersRoutine, getShippingOrderItemsRoutine } from 'features/shippingOrders/ducks/actions'

import Collapse from 'components/atoms/Collapse'
import ArrowUp from 'components/icons/ArrowUp'
import ArrowDown from 'components/icons/ArrowDown'
import IconButton from 'components/atoms/IconButton'

import Table from 'components/atoms/Table/Table'
import TableBody from 'components/atoms/Table/TableBody'
import TableHeader from 'components/atoms/Table/TableHeader'
import TableHeaderCell from 'components/atoms/Table/TableHeaderCell'
import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import DownloadShippingOrderCSV from 'features/shippingOrders/components/DownloadShippingOrderCSV'
import EditShippingOrderModal from 'features/shippingOrders/components/EditShippingOrderModal'
import ShippingOrdersFilters from 'features/shippingOrders/components/ShippingOrdersFilters'

export const ShippingOrdersTable = (props) => {
  const [expandedRow, setExpandedRow] = useState('')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const urlSearchQuery = useUrlSearchQuery()
  const data = useSelector(getShippingOrdersArray)
  const isLoading = useSelector(getIsShippingOrdersLoading)
  const pagination = useSelector(getShippingOrdersMeta)
  const userLogin = useSelector(getUserLogin)
  const userRole = useSelector(getUserRole)
  const fetchShippingOrders = useCallback(
    query => dispatch(fetchShippingOrdersRoutine(query)),
    [dispatch]
  )

  const getShippingOrderItems = useCallback(
    id => dispatch(getShippingOrderItemsRoutine({ id })),
    [dispatch]
  )

  const shippingOrderItems = useSelector(state => getShippingOrdersItems(state, expandedRow))

  useEffect(() => {
    if (userRole !== USER_ROLES.admin && userLogin && urlSearchQuery.hasSearchQuery(API_FIELD_NAME.userLogin) === false) {
      urlSearchQuery.setSearchQuery({
        [API_FIELD_NAME.shippingOrderUsers]: userLogin
      })
    }
  }, [userLogin])

  useEffect(() => {
    urlSearchQuery.setSearchQuery({
      [API_FIELD_NAME.orderStatus]: SHIPPING_STATUS.confirmed
    })
  }, [userLogin])

  const headers = [
    {
      id: 'expand',
      cell: ''
    },
    {
      id: 'name',
      sortable: true,
      cell: t('ordersPage.table.headers.name')
    },
    {
      id: 'created_at',
      sortable: true,
      cell: t('ordersPage.table.headers.createdAt')
    },
    {
      id: 'to_be_shipped_at',
      sortable: true,
      cell: t('ordersPage.table.headers.toBeShippedAt')
    },
    {
      id: 'status',
      sortable: true,
      cell: t('ordersPage.table.headers.status')
    },
    {
      id: 'users',
      sortable: true,
      cell: t('ordersPage.table.headers.userLogin')
    },
    {
      id: 'actions',
      sortable: false,
      cell: '',
      align: 'right'
    }
  ]

  const handleCollapseRow = id => () => {
    if (expandedRow === id) {
      setExpandedRow('')
    } else {
      getShippingOrderItems(id)
      setExpandedRow(id)
    }
  }

  const renderItems = data.map((data, index) => (
    <>
      <TableRow id={`shipping-orders-row-${index}`} hover key={propOr(`shipping-orders-row-${index}`, 'id', data)}>
        <TableCell width='2%'>
          <IconButton id={`collapse-order-items-${index}`} onClick={handleCollapseRow(data.id)}>
            {expandedRow === data.id ? <ArrowUp /> : <ArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          {propOr('-', 'name', data)}
        </TableCell>
        <TableCell>
          {formatDate(data.createdAt, FORMATS.slash)}
        </TableCell>
        <TableCell>
          {formatDate(data.toBeShippedAt, FORMATS.slash)}
        </TableCell>
        <TableCell>
          {t(`ordersPage.shippingOrderStatus.${data.status}`)}
        </TableCell>
        <TableCell>
          {
            pipe(
              propOr('-', 'users'),
              split(','),
              join(', ')
            )(data)
          }
        </TableCell>
        <TableCell align='right'>
          <ActionsCell>
            <EditShippingOrderModal
              isShipped={propEq('status', SHIPPING_STATUS.shipped, data)}
              data={data}
            />
            <DownloadShippingOrderCSV
              id={data.id}
              filename={`${t('ordersPage.filename')}-${formatDate(data.createdAt, FORMATS.slash)}-${formatDate(data.toBeShippedAt, FORMATS.slash)}`}
            />
          </ActionsCell>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7}>
          <Collapse in={expandedRow === data.id} timeout='auto' unmountOnExit>
            <CollapseContentContainer id={`order-items-list-${index}`}>
              <CollapseContentHeader>{t('ordersPage.shippingOrderStatus.orderedProducts')}</CollapseContentHeader>
              <Table size='small'>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell disableSticky>
                      {t('monitoringPage.table.headers.id')}
                    </TableHeaderCell>
                    <TableHeaderCell disableSticky>
                      {t('monitoringPage.table.headers.name')}
                    </TableHeaderCell>
                    <TableHeaderCell disableSticky>
                      {t('monitoringPage.table.headers.shippingQty')}
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {
                    shippingOrderItems.map((item, itemIndex) => (
                      <TableRow key={item.id}>
                        <TableCell width='10%'>
                          {item.ean}
                        </TableCell>
                        <TableCell>
                          {item.name}
                        </TableCell>
                        <TableCell width='2%' id={`shipping-order-${index}-item-${itemIndex}-quantity`}>
                          {item.quantity}
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </CollapseContentContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ))

  return (
    <EntitiesList
      emptyStateText={t('linesPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchShippingOrders}
      isLoading={isLoading}
      defaultSortedColumnId='created_at'
      filters={<ShippingOrdersFilters />}
    />
  )
}

export default ShippingOrdersTable

const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const CollapseContentContainer = styled.div`
  padding: 20px;
  
  th.MuiTableCell-sizeSmall {
    background-color: transparent !important;
    padding: 6px 24px 6px 22px !important;
  }
  
  table {
   border-radius: ${props => props.theme.shape.borderRadius};
   background-color: ${props => props.theme.palette.common.gray200};
  }
  
  tbody tr:last-child td {
    border-bottom: none !important;
  }
`

const CollapseContentHeader = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
`
