import { string, object, ref } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Log in form validator

export const loginSchema = object().shape({
  login: string()
    .required(() => ({ key: 'form.login.errors.required' })),
  password: string().required(() => ({ key: 'form.password.errors.required' })),
  captcha: string().when('failedLoginAttempts', {
    is: value => value > 5,
    then: string().required()
  })
})

export const validateLoginField = values => validateField(loginSchema, values)
export const validateLoginValues = validateValues(loginSchema)

// Reset password init schema

export const resetPasswordInitSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' }))
})

export const validateResetPasswordInitField = values =>
  validateField(resetPasswordInitSchema, values)
export const validateResetPasswordInitValues = validateValues(
  resetPasswordInitSchema
)

// Reset password finish schema

export const resetPasswordFinishSchema = object().shape({
  password: string()
    .required(() => ({ key: 'form.password.errors.required' }))
    .matches(/[0-9]/g, () => ({ key: 'form.password.errors.numericCharacter' }))
    .matches(/[^a-zA-Z0-9]/g, () => ({ key: 'form.password.errors.specialCharacter' }))
    .min(8, () => ({
      key: 'form.password.errors.tooShort',
      options: { number: 8 }
    })),
  repeatPassword: string()
    .required(() => ({ key: 'form.repeatPassword.errors.required' }))
    .oneOf([ref('password'), null], () => ({
      key: 'form.repeatPassword.errors.dontMatch'
    }))
})

export const validateResetPasswordFinishField = values =>
  validateField(resetPasswordFinishSchema, values)
export const validateResetPasswordFinishValues = validateValues(
  resetPasswordFinishSchema
)
