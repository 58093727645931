import React from 'react'

import PriceUpForm from 'features/flags/PriceUpForm'
import PriceDownForm from 'features/flags/PriceDownForm'
import PromoDiffForm from 'features/flags/PromoDiffForm'

export const FlagsSection = (props) => {
  return (
    <div>
      <PriceUpForm />
      <PriceDownForm />
      <PromoDiffForm />
    </div>
  )
}

export default FlagsSection
