import debounce from 'lodash.debounce'
import { identity, head, pipe, ifElse, propOr, isEmpty, always, find, propEq, isNil } from 'ramda'

export const validateField = (schema, values) =>
  debounce(
    (name, callback = () => {}) =>
      schema
        .validateAt(name, values)
        // eslint-disable-next-line standard/no-callback-literal
        .then(() => callback({ valid: true, errors: [] }))
        .catch(callback),
    200
  )

export const validateValues = schema =>
  debounce((values, callback) => schema.isValid(values).then(callback), 400)

export const getHeadErrorOrEmptyObject = pipe(
  propOr([], 'errors'),
  ifElse(isEmpty, always({}), head)
)

export const getOptionByValue = value => options => pipe(
  find(
    propEq('value', value)
  ),
  ifElse(
    isNil,
    always({}),
    identity
  )
)(options)
