import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { fetchUnseenAlertsSummaryRoutine, fetchUnarchivedAlertsRoutine } from 'features/alerts/ducks/actions'
import { getUserLogin } from 'features/auth/ducks/selectors'

export const AlertsNotificationListener = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userLogin = useSelector(getUserLogin)

  const fetchUnseenAlertsSummary = useCallback(
    () => dispatch(fetchUnseenAlertsSummaryRoutine()),
    [dispatch]
  )
  const fetchUnarchivedAlerts = useCallback(
    () => dispatch(fetchUnarchivedAlertsRoutine()),
    [dispatch]
  )

  const fetchSummary = () => {
    if (userLogin) {
      fetchUnseenAlertsSummary()
      fetchUnarchivedAlerts()
    }
  }

  useEffect(() => {
    fetchSummary()
    history.listen(fetchSummary)
  }, [userLogin])

  return <div />
}

export default AlertsNotificationListener
