import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Switch, Redirect, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import PATHS from 'utils/paths'
import { useSelector } from 'react-redux'

import {
  getOrderAlertsSummaryCount,
  getSaleAlertsSummaryCount,
  getPriceDownAlertsSummaryCount,
  getMentionsAlertsSummaryCount
} from 'features/alerts/ducks/selectors'

import Tabs from 'components/atoms/Tabs'
import Tab from 'components/atoms/Tab'
import PageColumnContainer from 'components/PageColumnContainer'
import TablePaperContainer from 'components/atoms/Table/TablePaperContainer'
import AlertsOrders from 'features/alerts/AlertsOrders'
import Badge from 'components/atoms/Badge'
import AlertsSale from 'features/alerts/AlertsSale'
import AlertsPriceDown from 'features/alerts/AlertsPriceDown'
import AlertsMentions from 'features/alerts/AlertsMentions'

export const AlertsPage = (props) => {
  const { history: { push, location: { pathname } } } = props
  const [openedTab, setOpenedTab] = useState(0)
  const { t } = useTranslation()
  const orderAlertsSummary = useSelector(getOrderAlertsSummaryCount)
  const saleAlertsSummary = useSelector(getSaleAlertsSummaryCount)
  const priceDownAlertsSummary = useSelector(getPriceDownAlertsSummaryCount)
  const mentionsAlertsSummary = useSelector(getMentionsAlertsSummaryCount)

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        push(PATHS.alertsOrders)
        return setOpenedTab(0)
      case 1:
        push(PATHS.alertsSale)
        return setOpenedTab(1)
      case 2:
        push(PATHS.alertsPriceDown)
        return setOpenedTab(2)
      case 3:
        push(PATHS.alertsMentions)
        return setOpenedTab(3)
      default:
        push(PATHS.alertsSale)
        return setOpenedTab(1)
    }
  }

  useEffect(() => {
    switch (pathname) {
      case PATHS.alertsSale:
        return setOpenedTab(1)
      case PATHS.alertsPriceDown:
        return setOpenedTab(2)
      case PATHS.alertsMentions:
        return setOpenedTab(3)
      default:
        return setOpenedTab(0)
    }
  }, [])

  return (
    <PageColumnContainer>
      <TabsContainer>
        <Tabs
          value={openedTab}
          onChange={handleTabChange}
          variant='fullWidth'
          indicatorColor='secondary'
          textColor='secondary'
        >
          <Tab label={(
            <Badge
              color='error'
              badgeContent={orderAlertsSummary}
            >
              <TabsTitle>{t('alertsPage.tabs.orders')}</TabsTitle>
            </Badge>
          )}
          />
          <Tab label={(
            <Badge
              color='error'
              badgeContent={saleAlertsSummary}
            >
              <TabsTitle>{t('alertsPage.tabs.sale')}</TabsTitle>
            </Badge>
          )}
          />
          <Tab label={(
            <Badge
              color='error'
              badgeContent={priceDownAlertsSummary}
            >
              <TabsTitle>{t('alertsPage.tabs.priceDown')}</TabsTitle>
            </Badge>
          )}
          />
          <Tab label={(
            <Badge
              color='error'
              badgeContent={mentionsAlertsSummary}
            >
              <TabsTitle>{t('alertsPage.tabs.mentions')}</TabsTitle>
            </Badge>
          )}
          />
        </Tabs>
      </TabsContainer>
      <TablePaperContainer>
        <Switch>
          <Route exact path={PATHS.alertsOrders} component={AlertsOrders} />
          <Route exact path={PATHS.alertsSale} component={AlertsSale} />
          <Route exact path={PATHS.alertsPriceDown} component={AlertsPriceDown} />
          <Route exact path={PATHS.alertsMentions} component={AlertsMentions} />
          <Redirect to={PATHS.alertsOrders} />
        </Switch>
      </TablePaperContainer>
    </PageColumnContainer>
  )
}

export default withRouter(AlertsPage)

const TabsContainer = styled.div`
  flex: none;
`

const TabsTitle = styled.div`
  padding-right: 8px;
`
