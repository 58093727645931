import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, delay, select } from '@redux-saga/core/effects'
import * as productsService from 'services/ProductsService'
import { SEVERITY } from 'utils/toast'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { fetchProductTasksRoutine } from 'features/flags/ducks/actions'
import { head, propOr } from 'ramda'
import { formatDate, FORMATS } from 'utils/date'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'
import { getProductCommentsMeta } from 'features/comments/ducks/selectors'
import qs from 'qs'
import { SORT_ORDER } from 'utils/table'

// ROUTINES

export const getProductRoutine = createRoutine('GET_PRODUCT')
export const updateRetailPriceRoutine = createRoutine('UPDATE_RETAIL_PRICE')
export const addProductsToCartRoutine = createRoutine('ADD_PRODUCTS_TO_CART')
export const addProductToCartRoutine = createRoutine('ADD_PRODUCT_TO_CART')
export const removeProductsFromCartRoutine = createRoutine('REMOVE_PRODUCTS_FROM_CART')
export const removeProductFromCartRoutine = createRoutine('REMOVE_PRODUCT_FROM_CART')
export const updateMultipleEstimatedShippingTimeRoutine = createRoutine('UPDATE_MULTIPLE_ESTIMATED_SHIPPING_TIME')
export const updateSingleEstimatedShippingTimeRoutine = createRoutine('UPDATE_SINGLE_ESTIMATED_SHIPPING_TIME')
export const updateMultipleShippingQuantityRoutine = createRoutine('UPDATE_MULTIPLE_SHIPPING_QUANTITY')
export const deactivateProductRoutine = createRoutine('DEACTIVATE_PRODUCT_ROUTINE')
export const snoozeProductsRoutine = createRoutine('SNOOZE_PRODUCTS_ROUTINE')
export const snoozeAutoDecisionRoutine = createRoutine('SNOOZE_AUTO_DECISION_ROUTINE')
export const syncProductRoutine = createRoutine('SYNC_PRODUCT_ROUTINE')
export const syncBundlesRoutine = createRoutine('SYNC_BUNDLES_ROUTINE')
export const syncAllegroRoutine = createRoutine('SYNC_ALLEGRO_ROUTINE')
export const fetchProductBundlesRoutine = createRoutine('FETCH_PRODUCT_BUNDLES')
export const fetchSimpleSaleChartRoutine = createRoutine('FETCH_SIMPLE_SALE_CHART')
export const fetchCumulativeSaleChartRoutine = createRoutine('FETCH_CUMULATIVE_SALE_CHART')
export const addNotesToProductRoutine = createRoutine('ADD_NOTES_TO_PRODUCT')

// ACTIONS

function * getProduct ({ payload }) {
  yield put(getProductRoutine.request())
  try {
    const result = yield call(productsService.fetchProductById, payload)
    const tagsResult = yield call(productsService.fetchProductTagsById, payload)

    // this is a workaround made with API to have the real (non-bugged) array of tags
    const productData = {
      ...result.data,
      tags: tagsResult.data.data
    }

    yield put(getProductRoutine.success(productData))
    yield put(fetchProductTasksRoutine({ id: payload.id }))
  } catch (e) {
    yield put(getProductRoutine.failure(e))
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * updateRetailPrice ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateRetailPriceRoutine.request())
  try {
    const result = yield call(productsService.updateRetailPrice, values)
    yield put(updateRetailPriceRoutine.success(result.data))
    yield delay(500)
    yield put(
      showToastRoutine({
        key: 'toast.updateRetailPriceSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(updateRetailPriceRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * addProductsToCart ({ payload: { values = {}, callback = () => {} } }) {
  yield put(addProductsToCartRoutine.request())
  try {
    const result = yield call(productsService.addProductsToCart, values)
    yield put(addProductsToCartRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.addProductsToCartSuccess',
        severity: SEVERITY.success,
        options: {
          count: values.productIds.length
        }
      })
    )
    callback()
  } catch (e) {
    yield put(addProductsToCartRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * removeProductsFromCart ({ payload: { values = {}, callback = () => {} } }) {
  yield put(removeProductsFromCartRoutine.request())
  try {
    const result = yield call(productsService.removeProductsFromCart, values)
    yield put(removeProductsFromCartRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.removeProductsFromSuccess',
        severity: SEVERITY.success,
        options: {
          count: values.productIds.length
        }
      })
    )
    callback()
  } catch (e) {
    yield put(removeProductsFromCartRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * addProductToCart ({ payload: { values = {}, callback = () => {} } }) {
  yield put(addProductToCartRoutine.request())
  try {
    yield put(addProductsToCartRoutine({ values: { productIds: [values.id] } }))
    yield put(addProductToCartRoutine.success())
    yield delay(500)
    yield put(getProductRoutine(values))
    callback()
  } catch (e) {
    yield put(addProductToCartRoutine.failure(e))
  }
}

function * removeProductFromCart ({ payload: { values = {}, callback = () => {} } }) {
  yield put(removeProductFromCartRoutine.request())
  try {
    yield put(removeProductsFromCartRoutine({ values: { productIds: [values.id] } }))
    yield put(removeProductFromCartRoutine.success())
    yield delay(500)
    yield put(getProductRoutine(values))
    callback()
  } catch (e) {
    yield put(removeProductFromCartRoutine.failure(e))
  }
}

function * updateMultipleEstimatedShippingTime ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateMultipleEstimatedShippingTimeRoutine.request())
  try {
    const result = yield call(productsService.updateEstimatedShippingTime, values)
    yield put(updateMultipleEstimatedShippingTimeRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.updateEstimatedShippingTimeSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(updateMultipleEstimatedShippingTimeRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * updateSingleEstimatedShippingTime ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateSingleEstimatedShippingTimeRoutine.request())
  try {
    yield put(updateMultipleEstimatedShippingTimeRoutine({ values }))
    yield put(updateSingleEstimatedShippingTimeRoutine.success())
    yield delay(500)
    yield put(getProductRoutine({ id: head(values.productIds) }))
    callback()
  } catch (e) {
    yield put(updateSingleEstimatedShippingTimeRoutine.failure(e))
  }
}

function * updateMultipleShippingQuantity ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateMultipleShippingQuantityRoutine.request())
  try {
    const result = yield call(productsService.updateShippingQuantity, values)
    yield put(updateMultipleShippingQuantityRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.updateShippingQuantitySuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(updateMultipleShippingQuantityRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * deactivateProduct ({ payload: { id = '', callback = () => {} } }) {
  yield put(deactivateProductRoutine.request())
  try {
    yield call(productsService.deactivateProducts, { ids: [id] })
    yield put(deactivateProductRoutine.success())
    yield delay(500)
    yield put(getProductRoutine({ id }))
    callback()
  } catch (e) {
    yield put(deactivateProductRoutine.failure(e))
  }
}

function * snoozeProducts ({ payload: { values = {}, callback = () => {} } }) {
  yield put(snoozeProductsRoutine.request())
  try {
    const result = yield call(productsService.snoozeProducts, values)
    yield put(snoozeProductsRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.snoozeProductsSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(snoozeProductsRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * snoozeAutoDecision ({ payload: { values = {}, callback = () => {} } }) {
  yield put(snoozeAutoDecisionRoutine.request())
  try {
    const result = yield call(productsService.snoozeAutoDecision, values)
    yield put(snoozeAutoDecisionRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.snoozeAutoDecisionSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(snoozeAutoDecisionRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * syncProduct ({ payload: { id = '', callback = () => {} } }) {
  yield put(syncProductRoutine.request())
  try {
    yield call(productsService.syncProduct, { id })
    yield put(syncProductRoutine.success())
    yield delay(500)
    yield put(getProductRoutine({ id }))
    yield put(
      showToastRoutine({
        key: 'toast.syncProductSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(syncProductRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * syncBundles () {
  yield put(syncBundlesRoutine.request())
  try {
    yield call(productsService.importBundles)
    yield put(syncBundlesRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.syncBundlesSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(syncBundlesRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.syncBundlesSuccess',
        severity: SEVERITY.success
      })
    )
  }
}

function * syncAllegro () {
  yield put(syncAllegroRoutine.request())
  try {
    yield call(productsService.importAllegro)
    yield put(syncAllegroRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.syncAllegroSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(syncAllegroRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.syncAllegroSuccess',
        severity: SEVERITY.success
      })
    )
  }
}

function * fetchProductBundles ({ payload }) {
  yield put(fetchProductBundlesRoutine.request())
  try {
    const result = yield call(productsService.fetchProductBundles, payload)
    yield put(fetchProductBundlesRoutine.success({ data: result.data, id: payload.id }))
  } catch (e) {
    yield put(fetchProductBundlesRoutine.failure(e))
  }
}

function * fetchSimpleSaleChart ({ payload }) {
  yield put(fetchSimpleSaleChartRoutine.request())
  try {
    const preparePayload = {
      productId: payload.productId,
      dateFrom: formatDate(payload.dateFrom, FORMATS.dashedReverse),
      dateTo: formatDate(payload.dateTo, FORMATS.dashedReverse),
      cumulative: false
    }
    const result = yield call(productsService.fetchSaleChart, preparePayload)
    yield put(fetchSimpleSaleChartRoutine.success({ data: result.data, productId: payload.productId }))
    propOr(false, 'showToast', payload) && (
      yield put(
        showToastRoutine({
          key: 'toast.chartSuccessfullyFetched',
          severity: SEVERITY.success
        })
      )
    )
  } catch (e) {
    yield put(fetchSimpleSaleChartRoutine.failure(e))
    propOr(false, 'showToast', payload) && (
      yield put(
        showToastRoutine({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      )
    )
  }
}

function * fetchCumulativeSaleChart ({ payload }) {
  yield put(fetchCumulativeSaleChartRoutine.request())
  try {
    const preparePayload = {
      productId: payload.productId,
      dateFrom: formatDate(payload.dateFrom, FORMATS.dashedReverse),
      dateTo: formatDate(payload.dateTo, FORMATS.dashedReverse),
      cumulative: true
    }
    const result = yield call(productsService.fetchSaleChart, preparePayload)
    yield put(fetchCumulativeSaleChartRoutine.success({ data: result.data, productId: payload.productId }))
    propOr(false, 'showToast', payload) && (
      yield put(
        showToastRoutine({
          key: 'toast.chartSuccessfullyFetched',
          severity: SEVERITY.success
        })
      )
    )
  } catch (e) {
    yield put(fetchCumulativeSaleChartRoutine.failure(e))
    propOr(false, 'showToast', payload) && (
      yield put(
        showToastRoutine({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      )
    )
  }
}

function * addNotesToProduct ({ payload: { values = {}, callback = () => {} } }) {
  yield put(addNotesToProductRoutine.request())
  const currentPaginationState = yield select(state => getProductCommentsMeta(state, values.id))

  try {
    const result = yield call(productsService.addProductNotes, values)
    yield put(addNotesToProductRoutine.success(result.data))
    yield put(getProductRoutine(values))
    yield put(fetchProductCommentsRoutine({
      id: values.id,
      query: qs.stringify({
        limit: {
          page: currentPaginationState.page,
          take: currentPaginationState.take
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    }))
    yield put(
      showToastRoutine({
        key: 'toast.addNotesToProductSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(addNotesToProductRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * getProductWatcher () {
  yield takeLatest(getProductRoutine.TRIGGER, getProduct)
}

export function * updateRetailPriceWatcher () {
  yield takeLatest(updateRetailPriceRoutine.TRIGGER, updateRetailPrice)
}

export function * addProductsToCartWatcher () {
  yield takeLatest(addProductsToCartRoutine.TRIGGER, addProductsToCart)
}

export function * addProductToCartWatcher () {
  yield takeLatest(addProductToCartRoutine.TRIGGER, addProductToCart)
}

export function * removeProductsFromCartWatcher () {
  yield takeLatest(removeProductsFromCartRoutine.TRIGGER, removeProductsFromCart)
}

export function * removeProductFromCartWatcher () {
  yield takeLatest(removeProductFromCartRoutine.TRIGGER, removeProductFromCart)
}

export function * updateMultipleEstimatedShippingTimeWatcher () {
  yield takeLatest(updateMultipleEstimatedShippingTimeRoutine.TRIGGER, updateMultipleEstimatedShippingTime)
}

export function * updateSingleEstimatedShippingTimeWatcher () {
  yield takeLatest(updateSingleEstimatedShippingTimeRoutine.TRIGGER, updateSingleEstimatedShippingTime)
}

export function * updateMultipleShippingQuantityWatcher () {
  yield takeLatest(updateMultipleShippingQuantityRoutine.TRIGGER, updateMultipleShippingQuantity)
}

export function * deactivateProductWatcher () {
  yield takeLatest(deactivateProductRoutine.TRIGGER, deactivateProduct)
}

export function * snoozeProductsWatcher () {
  yield takeLatest(snoozeProductsRoutine.TRIGGER, snoozeProducts)
}

export function * snoozeAutoDecisionWatcher () {
  yield takeLatest(snoozeAutoDecisionRoutine.TRIGGER, snoozeAutoDecision)
}

export function * syncProductWatcher () {
  yield takeLatest(syncProductRoutine.TRIGGER, syncProduct)
}

export function * fetchProductBundlesWatcher () {
  yield takeLatest(fetchProductBundlesRoutine.TRIGGER, fetchProductBundles)
}

export function * syncBundlesWatcher () {
  yield takeLatest(syncBundlesRoutine.TRIGGER, syncBundles)
}

export function * syncAllegroWatcher () {
  yield takeLatest(syncAllegroRoutine.TRIGGER, syncAllegro)
}

export function * fetchSimpleSaleChartWatcher () {
  yield takeLatest(fetchSimpleSaleChartRoutine.TRIGGER, fetchSimpleSaleChart)
}

export function * fetchCumulativeSaleChartWatcher () {
  yield takeLatest(fetchCumulativeSaleChartRoutine.TRIGGER, fetchCumulativeSaleChart)
}

export function * addNotesToProductWatcher () {
  yield takeLatest(addNotesToProductRoutine.TRIGGER, addNotesToProduct)
}

// SAGAS

export const productsSagas = [
  fork(getProductWatcher),
  fork(updateRetailPriceWatcher),
  fork(addProductsToCartWatcher),
  fork(addProductToCartWatcher),
  fork(removeProductsFromCartWatcher),
  fork(removeProductFromCartWatcher),
  fork(updateMultipleEstimatedShippingTimeWatcher),
  fork(updateSingleEstimatedShippingTimeWatcher),
  fork(updateMultipleShippingQuantityWatcher),
  fork(deactivateProductWatcher),
  fork(snoozeProductsWatcher),
  fork(syncProductWatcher),
  fork(syncBundlesWatcher),
  fork(syncAllegroWatcher),
  fork(fetchProductBundlesWatcher),
  fork(fetchSimpleSaleChartWatcher),
  fork(snoozeAutoDecisionWatcher),
  fork(fetchCumulativeSaleChartWatcher),
  fork(addNotesToProductWatcher)
]
