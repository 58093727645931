import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Edit allegro account form validator

export const editAllegroAccountSchema = object().shape({
  name: string()
    .required(() => ({ key: 'form.name.errors.required' }))
})

export const validateEditAllegroAccountField = values => validateField(editAllegroAccountSchema, values)
export const validateEditAllegroAccountValues = validateValues(editAllegroAccountSchema)
