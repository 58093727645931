import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, select, delay } from '@redux-saga/core/effects'
import * as allegroService from 'services/AllegroService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import qs from 'qs'
import { getAllIntegrationsConnectionStatusRoutine } from 'features/integrations/ducks/actions'
import { hasAllegroIntegrationError } from 'features/integrations/ducks/selectors'
import { getProductRoutine } from 'features/products/ducks/actions'

// ROUTINES

export const fetchAllegroAccountsRoutine = createRoutine('FETCH_ALLEGRO_ACCOUNTS')
export const editAllegroAccountRoutine = createRoutine('EDIT_ALLEGRO_ACCOUNT')
export const refreshAllegroTokenRoutine = createRoutine('REFRESH_ALLEGRO_TOKEN')
export const deleteAllegroAccountRoutine = createRoutine('DELETE_ALLEGRO_ACCOUNT')
export const addPromotionToProductRoutine = createRoutine('ADD_PROMOTION_TO_PRODUCT')
export const removePromotionFromProductRoutine = createRoutine('REMOVE_PROMOTION_FROM_PRODUCT')

// ACTIONS

function * fetchAllegroAccounts ({ payload }) {
  yield put(fetchAllegroAccountsRoutine.request())
  try {
    const result = yield call(allegroService.fetchAllegroAccounts, payload)
    yield put(fetchAllegroAccountsRoutine.success(result.data))
  } catch (e) {
    yield put(fetchAllegroAccountsRoutine.failure(e))
  }
}

function * editAllegroAccount ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editAllegroAccountRoutine.request())
  try {
    const result = yield call(allegroService.editAllegroAccount, values)
    yield put(editAllegroAccountRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.editAllegroAccountNameSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(editAllegroAccountRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * refreshAllegroToken ({ payload }) {
  yield put(refreshAllegroTokenRoutine.request())
  try {
    const result = yield call(allegroService.refreshAllegroToken, payload)
    yield put(refreshAllegroTokenRoutine.success(result.data))
    yield put(getAllIntegrationsConnectionStatusRoutine())
    const hasError = yield select(state =>
      hasAllegroIntegrationError(state, payload.id)
    )
    if (hasError) {
      return yield put(
        showToastRoutine({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      )
    } else {
      yield put(fetchAllegroAccountsRoutine(qs.stringify({
        limit: {
          page: 1,
          take: 100
        }
      })))
      return yield put(
        showToastRoutine({
          key: 'toast.refreshAllegroTokenSuccess',
          severity: SEVERITY.success
        })
      )
    }
  } catch (e) {
    yield put(refreshAllegroTokenRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * deleteAllegroAccount ({ payload }) {
  yield put(deleteAllegroAccountRoutine.request())
  try {
    const result = yield call(allegroService.deleteAllegroAccount, payload)
    yield put(deleteAllegroAccountRoutine.success(result.data))
    yield put(fetchAllegroAccountsRoutine(qs.stringify({
      limit: {
        page: 1,
        take: 100
      }
    })))
    yield put(
      showToastRoutine({
        key: 'toast.deleteAllegroAccountSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(deleteAllegroAccountRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * addPromotionToProduct ({ payload: { values = {}, callback = () => {} } }) {
  yield put(addPromotionToProductRoutine.request())
  try {
    yield call(allegroService.addPromotionToProduct, values)
    yield delay(500)
    yield put(getProductRoutine({ id: values.productId }))
    yield put(addPromotionToProductRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.addPromotionToProductSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(addPromotionToProductRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * removePromotionFromProduct ({ payload: { values = {}, callback = () => {} } }) {
  yield put(removePromotionFromProductRoutine.request())
  try {
    yield call(allegroService.removePromotionFromProduct, values)
    yield delay(500)
    yield put(getProductRoutine({ id: values.productId }))
    yield put(removePromotionFromProductRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.removePromotionFromProductSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(removePromotionFromProductRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * fetchAllegroAccountsWatcher () {
  yield takeLatest(fetchAllegroAccountsRoutine.TRIGGER, fetchAllegroAccounts)
}

export function * editAllegroAccountWatcher () {
  yield takeLatest(editAllegroAccountRoutine.TRIGGER, editAllegroAccount)
}

export function * refreshAllegroTokenWatcher () {
  yield takeLatest(refreshAllegroTokenRoutine.TRIGGER, refreshAllegroToken)
}

export function * deleteAllegroAccountWatcher () {
  yield takeLatest(deleteAllegroAccountRoutine.TRIGGER, deleteAllegroAccount)
}

export function * addPromotionToProductWatcher () {
  yield takeLatest(addPromotionToProductRoutine.TRIGGER, addPromotionToProduct)
}

export function * removePromotionFromProductWatcher () {
  yield takeLatest(removePromotionFromProductRoutine.TRIGGER, removePromotionFromProduct)
}

// SAGAS

export const allegroSagas = [
  fork(fetchAllegroAccountsWatcher),
  fork(editAllegroAccountWatcher),
  fork(refreshAllegroTokenWatcher),
  fork(deleteAllegroAccountWatcher),
  fork(addPromotionToProductWatcher),
  fork(removePromotionFromProductWatcher)
]
