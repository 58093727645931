import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateRecalculateSalePlanSchemaField,
  validateRecalculateSalePlanSchemaValues
} from 'features/settings/ducks/schema'

import { saveSettingsRoutine } from 'features/settings/ducks/actions'
import { getIsSettingsLoading, getStockDiffPercentage, getStockDiffAmount } from 'features/settings/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  stockDiffPercentage: 10,
  stockDiffAmount: 5
}

export const RecalculateSalePlanForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSettingsLoading)
  const stockDiffPercentage = useSelector(getStockDiffPercentage)
  const stockDiffAmount = useSelector(getStockDiffAmount)

  const saveSettings = useCallback(
    payload => dispatch(saveSettingsRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateRecalculateSalePlanSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      stockDiffPercentage,
      stockDiffAmount
    })
  }, [stockDiffPercentage, stockDiffAmount])

  useEffect(() => {}, [])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    saveSettings(values)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <SettingsContainer>
        <span>
          {t('settingsPage.recalculateSalePlan.condition1')}&nbsp;
        </span>
        <TextField
          required
          value={values.stockDiffPercentage}
          name='stockDiffPercentage'
          label={t('form.percentage.label')}
          onChange={handleOnChange}
          validate={validateRecalculateSalePlanSchemaField(values)}
        />
        <span>
          &nbsp;{t('settingsPage.recalculateSalePlan.unit1')}&nbsp;
        </span>
        <span className='settings__connector'>
          <strong>
            {t('settingsPage.recalculateSalePlan.connector')}&nbsp;
          </strong>
        </span>
        <span>
          {t('settingsPage.recalculateSalePlan.condition2')}&nbsp;
        </span>
        <TextField
          required
          name='stockDiffAmount'
          value={values.stockDiffAmount}
          label={t('form.quantity.label')}
          onChange={handleOnChange}
          validate={validateRecalculateSalePlanSchemaField(values)}
        />
        <span>
          &nbsp;{t('settingsPage.recalculateSalePlan.unit2')}
        </span>
      </SettingsContainer>
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('settingsPage.recalculateSalePlan.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default RecalculateSalePlanForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  
  .settings__connector {
    font-size: 16px;
  }
`
