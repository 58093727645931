import { string, object, array } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Assign User to Lines form validator

export const assignUserToLinesSchema = object().shape({
  userId: string()
    .required(() => ({ key: 'form.user.errors.required' })),
  lineIds: array().required()
})

export const validateAssignUserToLinesField = values => validateField(assignUserToLinesSchema, values)
export const validateAssignUserToLinesValues = validateValues(assignUserToLinesSchema)

// Add Line form validator

export const addLineSchema = object().shape({
  name: string()
    .required(() => ({ key: 'form.lineName.errors.required' }))
    .min(3, () => ({
      key: 'form.lineName.errors.lengthMin',
      options: { number: 3 }
    }))
    .max(20, () => ({
      key: 'form.lineName.errors.lengthMax',
      options: { number: 20 }
    }))
})

export const validateAddLineField = values => validateField(addLineSchema, values)
export const validateAddLineValues = validateValues(addLineSchema)
