import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  validateAddTagField,
  validateAddTagValues
} from 'features/tags/ducks/schema'

import { createTagRoutine } from 'features/tags/ducks/actions'
import { getIsTagsLoading } from 'features/tags/ducks/selectors'

import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  name: ''
}

export const AddLineForm = (props) => {
  const { onSubmit } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsTagsLoading)

  const createTag = useCallback(
    payload => dispatch(createTagRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateAddTagValues(values, _validateSchema)
  }, [values])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    createTag({
      values,
      callback: onSubmit
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        required
        name='name'
        label={t('form.tagName.label')}
        onChange={handleOnChange}
        validate={validateAddTagField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('tagsPage.addTag.addTagCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

AddLineForm.defaultProps = {
  onSubmit: () => {}
}

export default AddLineForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
