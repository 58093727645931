import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SECTION_IDS, isSectionExpanded } from 'utils/productPage'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

import { getIsProductInCart } from 'features/products/ducks/selectors'
import { addProductToCartRoutine, removeProductFromCartRoutine } from 'features/products/ducks/actions'

import IconButton from 'components/atoms/IconButton'
import AddToCart from 'components/icons/AddToCart'
import RemoveFromCart from 'components/icons/RemoveFromCart'
import Tooltip from 'components/atoms/Tooltip'

export const AddProductToCartToggle = (props) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isInCart = useSelector(state => getIsProductInCart(state, id))

  const addProductToCart = useCallback(
    payload => dispatch(addProductToCartRoutine(payload)),
    [dispatch]
  )

  const removeProductFromCart = useCallback(
    payload => dispatch(removeProductFromCartRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const handleCallback = () => {
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleAddToCart = () => addProductToCart({
    values: { id },
    callback: handleCallback
  })
  const handleRemoveFromCart = () => removeProductFromCart({
    values: { id },
    callback: handleCallback
  })

  return isInCart ? (
    <Tooltip title={t('ordersPage.removeFromCartCTA')}>
      <IconButton
        type='button'
        id='button-remove-from-cart'
        onClick={handleRemoveFromCart}
        color='primary'
      >
        <RemoveFromCart />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={t('ordersPage.addToCartCTA')}>
      <IconButton
        type='button'
        id='button-add-to-cart'
        onClick={handleAddToCart}
        color='primary'
      >
        <AddToCart />
      </IconButton>
    </Tooltip>
  )
}

export default withRouter(AddProductToCartToggle)
