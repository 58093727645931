import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ALLEGRO_PROMOTIONS } from 'utils/allegro'
import { SECTION_IDS, isSectionExpanded } from 'utils/productPage'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

import { getProductBundleHasAds } from 'features/products/ducks/selectors'
import { removePromotionFromProductRoutine, addPromotionToProductRoutine } from 'features/allegro/ducks/actions'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

import AllegroPromotionSwitch from 'features/allegro/components/AllegroPromotionSwitch'
import AllegroAds from 'components/icons/AllegroAds'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import { fetchProductBundlesRoutine } from 'features/products/ducks/actions'

export const ToggleProductHasAds = (props) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { match: { params: { id: parentProductId } } } = props
  const { id } = props
  const hasAds = useSelector(state => getProductBundleHasAds(state, id, parentProductId))
  const dispatch = useDispatch()

  const removePromotionFromProduct = useCallback(
    payload => dispatch(removePromotionFromProductRoutine(payload)),
    [dispatch]
  )

  const addPromotionToProduct = useCallback(
    payload => dispatch(addPromotionToProductRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const fetchProductBundles = useCallback(
    payload => dispatch(fetchProductBundlesRoutine(payload)),
    [dispatch]
  )

  const handleCallback = () => {
    fetchProductBundles({ id: parentProductId })
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleChange = ({ value, name, productId }) => {
    if (value) {
      addPromotionToProduct({
        values: { name, productId },
        callback: handleCallback
      })
    } else {
      removePromotionFromProduct({
        values: { name, productId },
        callback: handleCallback
      })
    }
  }

  return (
    <AllegroPromotionSwitch
      isSelected={hasAds}
      icon={AllegroAds}
      productId={id}
      name={ALLEGRO_PROMOTIONS.ads}
      handleChange={handleChange}
      label=''
    />
  )
}

export default withRouter(ToggleProductHasAds)
