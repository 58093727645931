import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SEVERITY } from 'utils/toast'
import { fileDownload } from 'utils/download'

import { getShippingOrderCSV } from 'services/ShippingOrdersService'
import { showToastRoutine } from 'features/toast/ducks/actions'

import IconButton from 'components/atoms/IconButton'
import Download from 'components/icons/Download'

export const DownloadShippingOrderCSV = (props) => {
  const { id, filename } = props
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleDownload = async () => {
    try {
      const response = await getShippingOrderCSV({ id })
      fileDownload(response.data, `${filename}.csv`)
      return showToast({
        key: 'toast.downloadCSVSuccess',
        severity: SEVERITY.success
      })
    } catch (e) {
      console.error(e)
      return showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
  }

  return (
    <IconButton size='small' onClick={handleDownload}>
      <Download />
    </IconButton>
  )
}

export default DownloadShippingOrderCSV
