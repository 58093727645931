import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Create product comment form validator

export const createProductCommentSchema = object().shape({
  id: string()
    .required(),
  content: string()
    .required(() => ({ key: 'form.comment.errors.required' }))
    .max(500, () => ({
      key: 'form.comment.errors.lengthMax',
      options: { number: 500 }
    }))
})

export const validateCreateProductCommentField = values => validateField(createProductCommentSchema, values)
export const validateCreateProductCommentValues = validateValues(createProductCommentSchema)
