import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { generateFileSignature, uploadSalePlanCSV } from 'services/FileService'
import { showToastRoutine } from 'features/toast/ducks/actions'

import FileUpload from 'components/atoms/FileUpload'
import Button from 'components/atoms/Button'
import { isNotNilOrEmpty } from 'utils/ramda'
import { SEVERITY } from 'utils/toast'

export const UploadSalePlansCSV = (props) => {
  const [file, setFile] = useState(null)
  const [reset, setReset] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleChange = file => setFile(file)
  const handleSubmit = async () => {
    try {
      const { data: { signature } } = await generateFileSignature(file)
      await uploadSalePlanCSV({ file, signature })
      showToast({
        key: 'toast.salePlanSuccessfullyUploaded',
        severity: SEVERITY.success
      })
      setReset(true)
      setFile(null)
    } catch (e) {
      console.error(e)
      return showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
  }

  return (
    <UploadSalePlansCSVContainer>
      <div>
        <FileUpload reset={reset} onChange={handleChange} name='sale-plans-csv' />
      </div>
      <Button
        size='small'
        type='submit'
        id='button-submit-sale-plans-csv'
        disabled={!isNotNilOrEmpty(file)}
        onClick={handleSubmit}
      >
        {t('settingsPage.salePlansSettings.saveSettingsCTA')}
      </Button>
    </UploadSalePlansCSVContainer>
  )
}

export default UploadSalePlansCSV

export const UploadSalePlansCSVContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
