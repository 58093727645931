import React, { useCallback, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { SORT_ORDER } from 'utils/table'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { getSaleChannelsArray } from 'features/saleChannels/ducks/selectors'
import { fetchSaleChannelsRoutine, fetchProductSaleChannelsRoutine } from 'features/saleChannels/ducks/actions'

import Grid from 'components/atoms/Grid'
import ProductSaleChannel from 'features/saleChannels/components/ProductSaleChannel'
import ProductAuctionsList from 'features/productAuctions/ProductAuctionsList'

export const SaleChannelsSection = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const saleChannels = useSelector(getSaleChannelsArray)

  const fetchSaleChannels = useCallback(
    payload => dispatch(fetchSaleChannelsRoutine(payload)),
    [dispatch]
  )

  const fetchProductSaleChannels = useCallback(
    payload => dispatch(fetchProductSaleChannelsRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    fetchSaleChannels(qs.stringify({
      limit: {
        page: 1,
        take: 1000
      },
      order: {
        by: 'name',
        dir: SORT_ORDER.asc
      }
    }))

    fetchProductSaleChannels({ id })
  }, [id])

  const renderSaleChannelCategories = saleChannels.map(data => (
    <Grid item xs={12} sm={6} key={`sale-channel-${data.id}`}>
      <ProductSaleChannel data={data} />
    </Grid>
  ))

  return (
    <>
      <SectionTitle>{t('productPage.saleChannels.auctions.sectionTitle')}</SectionTitle>
      <ProductAuctionsList />
      <SectionTitle>{t('productPage.saleChannels.channels.sectionTitle')}</SectionTitle>
      <Grid container spacing={2}>
        {renderSaleChannelCategories}
      </Grid>
    </>
  )
}

export default withRouter(SaleChannelsSection)

const SectionTitle = styled.div`
  font-size: 23px;
  font-weight: 500;
  margin: 10px 0;
`
