import { equals, pathOr, pipe, propOr, propEq, filter, head } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'
import { lengthIsGreaterThan } from 'utils/ramda'

export const selectIntegrations = state => state.integrations

export const getIsIntegrationsLoading = createSelector(
  selectIntegrations,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getIaiErrorExists = createSelector(
  selectIntegrations,
  pathOr(false, ['iai', 'errorsExist'])
)

export const getAllegroErrorExists = createSelector(
  selectIntegrations,
  pathOr(false, ['allegro', 'errorsExist'])
)

export const getIaiErrorMessage = createSelector(
  selectIntegrations,
  pathOr('', ['iai', 'errors'])
)

export const getHasAnyConnectionError = createSelector(
  getIaiErrorExists,
  getAllegroErrorExists,
  (iaiErrorExists, allegroErrorExists) => iaiErrorExists || allegroErrorExists
)

export const hasAllegroIntegrationError = createSelector(
  selectIntegrations,
  (state, id) => id,
  (integrations, id) =>
    pipe(
      pathOr([], ['allegro', 'errors']),
      filter(propEq('id', id)),
      lengthIsGreaterThan(0)
    )(integrations)
)

export const getAllegroErrorMessage = createSelector(
  selectIntegrations,
  (state, id) => id,
  (integrations, id) =>
    pipe(
      pathOr([], ['allegro', 'errors']),
      filter(propEq('id', id)),
      head,
      propOr('', 'error_description')
    )(integrations)
)
