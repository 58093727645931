import React, { useState, useEffect } from 'react'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { isNil, map, propOr, split } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'

import PlainAsyncSelect from 'components/atoms/FilterInputs/PlainAsyncSelect'

export const AsyncSelectFilter = (props) => {
  const { name, label, loadOptions, isMulti, disableArray } = props
  const { location: { search } } = useHistory()
  const urlSearchQuery = useUrlSearchQuery()

  const gerUrlQuery = () => isMulti ? propOr(disableArray ? '' : [], name)(urlSearchQuery.getSearchQuery()) : propOr('', name)(urlSearchQuery.getSearchQuery())

  const urlQuery = gerUrlQuery()

  const selectedOptions = () => {
    const transformedUrlQuery = disableArray ? split(',', urlQuery) : urlQuery

    return map(
      option => ({ label: option, value: option })
    )(transformedUrlQuery)
  }

  useEffect(() => {
    setValue(isMulti ? selectedOptions() : urlQuery)
  }, [search])

  const [value, setValue] = useState(isMulti ? selectedOptions() : urlQuery)

  const handleSingleChange = option => {
    const getValue = propOr(null, 'value', option)
    setValue(getValue)
    if (isNotNilOrEmpty(getValue)) {
      urlSearchQuery.setSearchQuery({
        [name]: getValue
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
    }
  }

  const handleMultiChange = e => {
    const parsedNilValue = isNil(e) ? [] : e
    setValue(parsedNilValue)

    const mappedValues = parsedNilValue.map(value => value.value)
    const payload = disableArray ? mappedValues.join(',') : mappedValues

    if (isNotNilOrEmpty(parsedNilValue)) {
      urlSearchQuery.setSearchQuery({
        [name]: payload
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
    }
  }

  const handleChange = e => isMulti ? handleMultiChange(e) : handleSingleChange(e)

  return (
    <PlainAsyncSelect
      isMulti={isMulti}
      label={label}
      loadOptions={loadOptions}
      value={isMulti ? value : {
        value,
        label: value
      }}
      onChange={handleChange}
    />
  )
}

export default AsyncSelectFilter
