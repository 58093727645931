import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'
import { append, mergeAll, pipe, reduce } from 'ramda'
import { PRODUCT_TASKS_API_FIELDS } from 'utils/flags'

const flagValues = () =>
  pipe(
    reduce(
      (acc, value) => append({ [value.key]: false }, acc),
      []
    ),
    mergeAll
  )(PRODUCT_TASKS_API_FIELDS)

export const MonitoringItem = Record({
  id: '',
  iaiId: 0,
  soldToday: 0,
  ean: '',
  stock: '',
  retailPrice: '',
  name: '',
  photoUrl: '',
  isBundled: false,
  createdAt: '',
  promotions: {},
  updatedAt: null,
  producerName: '',
  userLogin: '',
  latestCommentContent: '',
  latestCommentCreatedAt: '',
  latestCommentUserId: '',
  latestCommentAuthor: '',
  hasAds: false,
  isEmphasized: false,
  hasPromotionPackage: false,
  isBargain: false,
  hasHalfPercent: false,
  purchasePrice: 0,
  margin: 0,
  allegroFee: 0,
  soldNew: 0,
  sold1: 0,
  soldShort: 0,
  soldLong: 0,
  willLastShort: null,
  willLastLong: null,
  salesDynamics: null,
  frozenMoney: 0,
  lineName: null,
  estimatedShippingTime: 0,
  suggestedShippingQty: 0,
  actualShippingQty: 0,
  shippingStatus: '',
  activatedAt: null,
  iaiCreatedAt: null,
  virtualStock: 0,
  snoozedUntil: null,
  salePlanDiff: null,
  ...flagValues()
})

export const MonitoringMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1,
  frozenMoneySum: 0
})

export const Monitoring = Record({
  state: API_STATES.PRISTINE,
  collection: [],
  monitoringMeta: MonitoringMeta()
})
