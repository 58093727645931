import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  validateUpdateShippingQuantitySchemaValues,
  validateUpdateShippingQuantitySchemaField
} from 'features/products/ducks/schema'
import { makeStyles } from '@material-ui/core/styles'

import { updateMultipleShippingQuantityRoutine } from 'features/products/ducks/actions'
import { getIsProductLoading } from 'features/products/ducks/selectors'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'

import TextField from 'components/formElements/TextField'
import IconButton from 'components/atoms/IconButton'
import Success from 'components/icons/Success'
import Loader from 'components/atoms/Loader'
import { generateCurrentQuery } from 'utils/query'

const emptyValues = {
  qty: 0,
  productIds: []
}

export const UpdateSingleShippingQuantity = (props) => {
  const { id, currentValue } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const qty = currentValue || 0
  const isLoading = useSelector(getIsProductLoading)
  const iconButtonClasses = useIconButtonStyles()

  const updateShippingQuantity = useCallback(
    payload => dispatch(updateMultipleShippingQuantityRoutine(payload)),
    [dispatch]
  )

  const fetchMonitoring = useCallback(
    () => dispatch(fetchMonitoringRoutine(generateCurrentQuery())),
    [dispatch]
  )

  useEffect(() => {
    validateUpdateShippingQuantitySchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ qty, productIds: [id] })
  }, [id, qty])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    updateShippingQuantity({
      values,
      callback: fetchMonitoring
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextField
        raw
        required
        type='number'
        value={values.qty}
        name='qty'
        label={t('form.shippingQuantity.label')}
        onChange={handleOnChange}
        validate={validateUpdateShippingQuantitySchemaField(values)}
      />
      <IconButton
        classes={iconButtonClasses}
        type='submit'
        id='button-submit'
        disabled={isLoading || !valid || qty === values.qty}
        onClick={handleSubmit}
      >
        {isLoading ? <Loader size={20} /> : <Success />}
      </IconButton>
    </StyledForm>
  )
}

export default withRouter(UpdateSingleShippingQuantity)

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  min-width: 150px;
`

const useIconButtonStyles = makeStyles(theme => ({
  root: {
    padding: '0 0 0 5px !important'
  }
}))
