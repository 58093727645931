import React, { useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { transparentize } from 'polished'
import styled from 'styled-components'
import { formatDate, FORMATS } from 'utils/date'
import { pipe, propOr } from 'ramda'
import { numberWithSpaces, twoDecimal } from 'utils/number'
import { convertPriceWithLabel } from 'utils/price'

import { fetchProductDeliveriesRoutine } from 'features/deliveries/ducks/actions'
import { getIsProductDeliveriesLoading, getProductDeliveriesArray, getProductDeliveriesMeta } from 'features/deliveries/ducks/selectors'

import Table from 'components/atoms/Table/Table'
import TableBody from 'components/atoms/Table/TableBody'
import TableFooter from 'components/atoms/Table/TableFooter'
import TableHeader from 'components/atoms/Table/TableHeader'
import TableRow from 'components/atoms/Table/TableRow'
import Loader from 'components/atoms/Loader'
import TableSelfStatePagination from 'components/atoms/Table/TableSelfStatePagination'
import TableHeaderCell from 'components/atoms/Table/TableHeaderCell'
import TableCell from 'components/atoms/Table/TableCell'
import SadFace from 'components/icons/SadFace'
import DeliveriesDateRangeFilter from 'features/deliveries/components/DeliveriesDateRangeFilter'

export const ProductDeliveriesList = (props) => {
  const { match: { params: { id } } } = props
  const [dateRange, setDateRange] = useState(60)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(state => getProductDeliveriesArray(state, id))
  const pagination = useSelector(getProductDeliveriesMeta)
  const isLoading = useSelector(getIsProductDeliveriesLoading)

  const fetchDeliveries = useCallback(
    query => dispatch(fetchProductDeliveriesRoutine({ productId: id, query })),
    [dispatch]
  )

  const getPricePropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      convertPriceWithLabel,
      numberWithSpaces
    )

  const headers = [
    {
      id: 'deliveryDate',
      sortable: false,
      cell: t('productPage.deliveries.table.headers.deliveryDate')
    },
    {
      id: 'namePZ',
      sortable: false,
      cell: t('productPage.deliveries.table.headers.namePZ')
    },
    {
      id: 'quantity',
      sortable: false,
      cell: t('productPage.deliveries.table.headers.quantity')
    },
    {
      id: 'netPrice',
      sortable: false,
      cell: t('productPage.deliveries.table.headers.netPrice')
    },
    {
      id: 'grossPrice',
      sortable: false,
      cell: t('productPage.deliveries.table.headers.grossPrice')
    },
    {
      id: 'supplier',
      sortable: false,
      cell: t('productPage.deliveries.table.headers.supplier')
    }
  ]

  const rows = data.map((data, index) => (
    <TableRow hover key={`delivery-item-${index}`}>
      <TableCell>
        {formatDate(data.createdAt, FORMATS.slash)}
      </TableCell>
      <TableCell>
        {propOr('-', 'namePZ', data)}
      </TableCell>
      <TableCell>
        {propOr('-', 'qty', data)}
      </TableCell>
      <TableCell>
        {getPricePropAndFormat('priceNet')(data)}
      </TableCell>
      <TableCell>
        {getPricePropAndFormat('price')(data)}
      </TableCell>
      <TableCell>
        {propOr('-', 'contractor', data)}
      </TableCell>
    </TableRow>
  ))

  const renderHeaders = headers.map(header => (
    <TableHeaderCell
      key={header.id}
      sortable={header.sortable}
      columnId={header.id}
      align={header.align || 'left'}
      tooltip={header.tooltip || null}
    >
      {header.cell}
    </TableHeaderCell>
  ))

  const loadingState = (
    <LoadingStateContainer>
      <Loader />
    </LoadingStateContainer>
  )

  const emptyState = (
    <TableRow>
      <TableCell
        colSpan={headers.length}
      >
        <TableEmptyState>
          <SadFace />&nbsp;
          {t('productPage.deliveries.table.emptyState')}
        </TableEmptyState>
      </TableCell>
    </TableRow>
  )

  return (
    <>
      <DeliveriesDateRangeFilter onChange={setDateRange} value={dateRange} />
      <TableContainer>
        <Table padding='none'>
          <TableHeader>
            <TableRow>
              {renderHeaders}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Number(pagination.recordsTotal) === 0 ? emptyState : rows}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableSelfStatePagination
                pagination={pagination}
                fetchRecords={fetchDeliveries}
                additionalFilters={dateRange === 'all' ? {} : { numberOfDays: dateRange }}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {isLoading && loadingState}
      </TableContainer>
    </>
  )
}

export default withRouter(ProductDeliveriesList)

const TableContainer = styled.div`
  position: relative;
  overflow: auto;
`

const LoadingStateContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => transparentize('0.3', props.theme.palette.common.white)};
  z-index: ${props => props.theme.zIndex.loadingOverlay};
`

const TableEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
`
