import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Tag = Record({
  id: '',
  name: '',
  productCount: 0
})

export const TagsMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const Tags = Record({
  state: API_STATES.PRISTINE,
  tagsMeta: new TagsMeta(),
  tags: []
})
