import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import Edit from 'components/icons/Edit'
import AddNotesForm from 'features/products/AddNotesForm'

export const EditNotesModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        onClick={handleOpen}
        id='edit-product-note'
        size='small'
        startIcon={<Edit />}
      >
        {t('productPage.notes.editCTA')}
      </Button>
      <Modal
        title={t('productPage.notes.editCTA')}
        open={open}
        handleClose={handleClose}
      >
        <AddNotesForm onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default EditNotesModal
