import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  validateCreateSalePlanSchemaField,
  validateCreateSalePlanSchemaValues
} from 'features/salePlans/ducks/schema'

import { createSalePlanRoutine } from 'features/salePlans/ducks/actions'

import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import DatePickerField from 'components/formElements/DatePickerField'
import TextField from 'components/formElements/TextField'

const emptyValues = {
  productId: '',
  expiresAt: '',
  stockAfter: 0
}

export const CreateSalePlanForm = (props) => {
  const { onSubmit, match: { params: { id } } } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const createSalePlan = useCallback(
    payload => dispatch(createSalePlanRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateCreateSalePlanSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ productId: id })
  }, [id])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    createSalePlan({
      values,
      callback: onSubmit
    })
  }

  return (
    <Form>
      <DatePickerField
        disablePast
        required
        name='expiresAt'
        label={t('form.expiresAt.label')}
        onChange={handleOnChange}
        validate={validateCreateSalePlanSchemaField(values)}
      />
      <TextField
        required
        type='number'
        name='stockAfter'
        label={t('form.stockAfter.label')}
        onChange={handleOnChange}
        validate={validateCreateSalePlanSchemaField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          {t('productPage.salePlan.createPlan.createCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default withRouter(CreateSalePlanForm)

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
