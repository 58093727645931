import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const ShippingOrder = Record({
  id: '',
  userId: '',
  login: '',
  name: '',
  status: '',
  users: '',
  createdAt: '',
  toBeShippedAt: ''
})

export const ShippingOrdersMeta = Record({
  page: 1,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const ShippingOrders = Record({
  state: API_STATES.PRISTINE,
  shippingOrdersMeta: new ShippingOrdersMeta(),
  shippingOrders: [],
  shippingOrderItems: {}
})
