import React from 'react'
import Icon from 'components/Icon'

export const PackageChecked = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='m135 290h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z' />
        <path d='m135 230h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z' />
        <path d='m428.82 328.109-45.962 45.961-17.678-17.677c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l28.284 28.284c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l56.568-56.568c5.858-5.858 5.858-15.355 0-21.213-5.856-5.858-15.354-5.858-21.211 0z' />
        <path d='m497 262c-18.955 0-35.996-10.532-44.472-27.484l-2.111-4.223c-2.541-5.083-7.735-8.293-13.417-8.293h-7v-127c0-2.329-.542-4.625-1.583-6.708l-40-80c-2.542-5.082-7.735-8.292-13.417-8.292h-320c-5.682 0-10.875 3.21-13.417 8.292l-40 80c-1.041 2.083-1.583 4.379-1.583 6.708v260c0 8.284 6.716 15 15 15h267.179c1.442 30.942 11.918 60.299 30.529 85.313 20.089 27 47.747 46.384 79.982 56.054 1.406.422 2.858.633 4.31.633s2.904-.211 4.31-.633c32.235-9.67 59.893-29.054 79.982-56.054 20.089-27.001 30.708-59.062 30.708-92.716v-85.597c0-8.284-6.716-15-15-15zm-106.271-182h-130.729v-50h105.729zm-190.729 30h30v70h-30zm30-80v50h-30v-50zm-165.729 0h105.729v50h-130.729zm217.729 247v63h-252v-230h140v85c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-85h140v112h-43c-5.682 0-10.876 3.21-13.417 8.292l-2.111 4.222c-8.476 16.954-25.517 27.486-44.472 27.486-8.284 0-15 6.716-15 15zm200 85.597c0 54.256-33.971 101.385-85 118.66-51.029-17.275-85-64.404-85-118.66v-71.995c22.701-4.285 42.437-18.2 54.125-38.603h61.75c11.688 20.402 31.424 34.318 54.125 38.603z' />
      </g>
    </Icon>
  )
}

export default PackageChecked
