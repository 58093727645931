import React from 'react'
import { includes } from 'ramda'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PATHS from 'utils/paths'
import { USER_ROLES } from 'utils/user'
import Layout from 'theme/Layout'

import { getIsAuthorised, getUserRole, isAuthLoaded } from 'features/auth/ducks/selectors'

export const PrivateRoute = (props) => {
  const { component: Component, allowedRoles, ...rest } = props

  const isAuthFetched = useSelector(isAuthLoaded)
  const isAuthorised = useSelector(getIsAuthorised)
  const userRole = useSelector(getUserRole)

  const renderPageOrRedirect = props => {
    const hasDedicatedRole = includes(userRole, allowedRoles)

    switch (true) {
      case !isAuthorised:
        return (
          <Redirect
            to={{
              pathname: PATHS.login,
              state: { from: props.location }
            }}
          />
        )
      case isAuthorised && isAuthFetched && !hasDedicatedRole:
        return (
          <Redirect
            to={{
              pathname: PATHS.dashboard,
              state: { from: props.location }
            }}
          />
        )
      default:
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
    }
  }

  return <Route {...rest} render={renderPageOrRedirect} />
}

PrivateRoute.defaultProps = {
  allowedRoles: [USER_ROLES.admin, USER_ROLES.user]
}

export default PrivateRoute
