import { Toast } from 'features/toast/ducks/records'
import {
  showToastRoutine,
  hideToastRoutine
} from 'features/toast/ducks/actions'

export default (state = new Toast(), action) => {
  switch (action.type) {
    case showToastRoutine.SUCCESS:
      return new Toast(action.payload)
    case hideToastRoutine.SUCCESS:
      return state.set('visible', false)
    default:
      return state
  }
}
