import { createMuiTheme } from '@material-ui/core/styles'
import { transparentize } from 'polished'

export default theme => createMuiTheme({
  palette: {
    common: {
      black: theme.palette.common.black,
      white: theme.palette.common.white
    },
    background: {
      paper: theme.palette.common.white,
      default: theme.palette.common.white
    },
    primary: {
      main: theme.palette.primary.main,
      light: theme.palette.primary.light,
      dark: theme.palette.primary.dark,
      contrastText: theme.palette.primary.contrastText
    },
    secondary: {
      main: theme.palette.secondary.main,
      light: theme.palette.secondary.light,
      dark: theme.palette.secondary.dark,
      contrastText: theme.palette.secondary.contrastText
    },
    error: {
      light: theme.palette.error.light,
      main: theme.palette.error.main,
      dark: theme.palette.error.dark,
      contrastText: theme.palette.error.contrastText
    },
    warning: {
      light: theme.palette.warning.light,
      main: theme.palette.warning.main,
      dark: theme.palette.warning.dark,
      contrastText: theme.palette.warning.contrastText
    },
    info: {
      light: theme.palette.info.light,
      main: theme.palette.info.main,
      dark: theme.palette.info.dark,
      contrastText: theme.palette.info.contrastText
    },
    success: {
      light: theme.palette.success.light,
      main: theme.palette.success.main,
      dark: theme.palette.success.dark,
      contrastText: theme.palette.success.contrastText
    },
    text: {
      primary: theme.palette.text.main,
      secondary: theme.palette.text.main,
      disabled: theme.palette.text.main,
      hint: theme.palette.text.main
    },
    grey: {
      50: '#fafafa',
      100: theme.palette.common.gray100,
      200: theme.palette.common.gray200,
      300: theme.palette.common.gray300,
      400: theme.palette.common.gray400,
      500: theme.palette.common.gray500,
      600: theme.palette.common.gray600,
      700: theme.palette.common.gray700,
      800: theme.palette.common.gray800,
      900: theme.palette.common.gray900,
      A100: theme.palette.common.gray100,
      A200: theme.palette.common.gray200,
      A400: theme.palette.common.gray400,
      A700: theme.palette.common.gray500
    },
    action: {
      active: transparentize('0.46', theme.palette.text.main),
      hover: transparentize('0.96', theme.palette.text.main),
      hoverOpacity: 0.04,
      selected: transparentize('0.92', theme.palette.text.main),
      selectedOpacity: 0.08,
      disabled: transparentize('0.74', theme.palette.text.main),
      disabledBackground: transparentize('0.88', theme.palette.text.main),
      disabledOpacity: 0.38,
      focus: transparentize('0.88', theme.palette.text.main),
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  shape: {
    borderRadius: '4px'
  },
  shadowBase: 'rgba(37, 38, 94, 0.1)',
  shadows: [
    'none',
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main,
    theme.shadows.main
  ],
  transitions: {
    easing: {
      easeInOut: theme.transitions.easing.easeInOut,
      easeOut: theme.transitions.easing.easeOut,
      easeIn: theme.transitions.easing.easeIn,
      sharp: theme.transitions.easing.sharp
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: theme.zIndex.appBar,
    drawer: theme.zIndex.drawer,
    modal: theme.zIndex.modal,
    snackbar: theme.zIndex.snackbar,
    tooltip: theme.zIndex.tooltip,
    menu: 1350
  },
  typography: {
    fontFamily: ['Jost', 'Open Sans', 'sans-serif']
  }
})
