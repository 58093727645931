import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { validateAddNotesField, validateAddNotesValues } from 'features/products/ducks/schema'

import { addNotesToProductRoutine } from 'features/products/ducks/actions'
import { getIsProductLoading, getProductNotes } from 'features/products/ducks/selectors'

import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'
import { isEmpty } from 'ramda'

const emptyValues = {
  id: '',
  notes: ''
}

export const AddNotesForm = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsProductLoading)
  const notes = useSelector(state => getProductNotes(state, id))

  const addNote = useCallback(
    payload => dispatch(addNotesToProductRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateAddNotesValues(values, _validateSchema)
  }, [values, id])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    setValues({
      id,
      notes
    })
  }, [notes])

  const handleSubmit = e => {
    e.preventDefault()
    addNote({
      values,
      callback: props.onSubmit
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div className='add-notes__input'>
        <TextField
          value={values.notes}
          required={isEmpty(notes) && isEmpty(values.notes)}
          name='notes'
          label={t('form.notes.label')}
          onChange={handleOnChange}
          validate={validateAddNotesField(values)}
          multiline
          rows={10}
        />
      </div>
      <div className='add-notes__cta'>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid || (isEmpty(notes) && isEmpty(values.notes))}
          onClick={handleSubmit}
        >
          {t('productPage.notes.saveCTA')}
        </Button>
      </div>
    </StyledForm>
  )
}

AddNotesForm.defaultProps = {
  onSubmit: () => {}
}

export default withRouter(AddNotesForm)

const StyledForm = styled.form`
  .add-notes__cta {
    text-align: center;
  }
`
