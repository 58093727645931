import React from 'react'
import {
  KeyboardDatePicker
} from '@material-ui/pickers'
import { formatDate, FORMATS } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import moment from 'moment'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const DatePicker = (props) => {
  const { minDate, maxDate, disablePast, disableFuture, required, name, label, disabled, value, onChange, errorText, onBlur, onFocus, error } = props
  const formattedValue = formatDate(value, FORMATS.slash)
  const classes = useDatePickterStyles()

  return (
    <FieldContainer>
      <KeyboardDatePicker
        className={classes.textField}
        label={label}
        name={name}
        required={required}
        autoOk
        disabled={disabled}
        variant='inline'
        inputVariant='outlined'
        format={FORMATS.slash}
        value={isNotNilOrEmpty(value) ? moment(formattedValue, FORMATS.slash) : null}
        onChange={onChange}
        error={error}
        helperText={errorText}
        onBlur={onBlur}
        onFocus={onFocus}
        mask='__/__/____'
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
      />
    </FieldContainer>
  )
}

DatePicker.defaultProps = {
  label: '',
  value: '',
  required: false,
  disabled: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {}
}

export default DatePicker

const FieldContainer = styled.div`
  margin: 10px 0;
  width: 100%;
`

const useDatePickterStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  }
}))
