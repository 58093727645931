import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  validatePriceUpFormField,
  validatePriceUpFormValues,
  validatePriceUpCurrentValues
} from 'features/flags/ducks/schema'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getPriceUpAmountByUnit,
  unitOptions,
  UNIT_FORMATS,
  PRODUCT_TASKS_NAMES,
  getPriceUpUnitByValues,
  getPriceUpAmountByValues,
  convertFromPenniesIfPrice
} from 'utils/flags'
import { convertFromPenniesValue, convertToPenniesValue } from 'utils/price'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

import { createProductTaskRoutine, toggleProductTasksRoutine } from 'features/flags/ducks/actions'
import { getProductFlagDefinitionByTaskName } from 'features/flags/ducks/selectors'
import { getProductPriceUpFlag } from 'features/products/ducks/selectors'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

import TextField from 'components/formElements/TextField'
import SingleSelectField from 'components/formElements/SingleSelectField'
import SwitchField from 'components/formElements/SwitchField'
import IconButton from 'components/atoms/IconButton'
import Success from 'components/icons/Success'
import PriceUp from 'components/icons/PriceUp'
import Tooltip from 'components/atoms/Tooltip'
import { isSectionExpanded, SECTION_IDS } from 'utils/productPage'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'

const emptyValues = {
  unit: UNIT_FORMATS.currency,
  qty: 0,
  amount: 0,
  maxPrice: 0,
  notSellingThreshold: 0
}

export const PriceUpForm = (props) => {
  const { match: { params: { id } } } = props
  const urlSearchQuery = useUrlSearchQuery()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const flagDefinition = useSelector(state => getProductFlagDefinitionByTaskName(state, id, PRODUCT_TASKS_NAMES.priceUp))
  const isActive = useSelector(state => getProductPriceUpFlag(state, id))

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [switchValue, setSwitchValue] = useState(false)
  const [currentValuesValid, _validateCurrentValues] = useState(false)

  const togglePriceUpFlag = useCallback(
    payload => dispatch(toggleProductTasksRoutine(payload)),
    [dispatch]
  )

  const setPriceUpDefinition = useCallback(
    payload => dispatch(createProductTaskRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const getCurrentValues = () => {
    const amount = getPriceUpAmountByValues(flagDefinition.priceIncreasePercentage, flagDefinition.priceIncreaseValue)
    const maxPrice = flagDefinition.maxPriceValue
    const unit = getPriceUpUnitByValues(flagDefinition.priceIncreasePercentage)

    return ({
      unit,
      qty: flagDefinition.priceIncreaseThreshold,
      amount: convertFromPenniesIfPrice(unit, amount),
      maxPrice: convertFromPenniesValue(maxPrice),
      notSellingThreshold: flagDefinition.notSellingThreshold
    })
  }

  useEffect(() => {
    validatePriceUpFormValues(values, _validateSchema)
    validatePriceUpCurrentValues(getCurrentValues(), _validateCurrentValues)
  }, [values, currentValuesValid, id])

  useEffect(() => {
    const currentValues = getCurrentValues()
    setValues(currentValues)
  }, [flagDefinition, id])

  useEffect(() => {
    setSwitchValue(isActive)
  }, [isActive, id])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const priceIncreaseValue = Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.currency)(values.unit))

    setPriceUpDefinition({
      values: {
        id,
        taskName: PRODUCT_TASKS_NAMES.priceUp,
        definition: {
          priceIncreaseThreshold: Number(values.qty),
          priceIncreasePercentage: Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.percentage)(values.unit)),
          priceIncreaseValue: convertToPenniesValue(priceIncreaseValue),
          maxPriceValue: convertToPenniesValue(Number(values.maxPrice)),
          notSellingThreshold: values.notSellingThreshold
        }
      },
      callback: handleCallback
    })
  }

  const handleSwitchChange = (name, value) => {
    setSwitchValue(value)
    togglePriceUpFlag({
      values: { id, isEnabled: value, taskName: PRODUCT_TASKS_NAMES.priceUp },
      callback: handleCallback
    })
  }

  const SwitchComponent = (
    <SwitchField
      value={switchValue}
      label={(
        <LabelContainer>
          <div className='label-icon'><PriceUp /></div>&nbsp;
          {t('productPage.flags.priceUp.label')}
        </LabelContainer>
      )}
      onChange={handleSwitchChange}
      name='priceUp'
      disabled={!currentValuesValid || !valid}
    />
  )

  const handleUnitChange = (name, value) => {
    setValues({
      ...values,
      amount: '',
      [name]: value
    })
  }

  return (
    <StyledForm>
      <SwitchContainer>
        {
          currentValuesValid && valid ? SwitchComponent : (
            <Tooltip title={t('productPage.flags.priceUp.tooltip')}>
              <div>
                {SwitchComponent}
              </div>
            </Tooltip>
          )
        }
      </SwitchContainer>
      <InputsContainer>
        <FieldContainer>
          <SingleSelectField
            options={unitOptions()}
            required
            name='unit'
            label={t('form.unit.label')}
            validate={validatePriceUpFormField(values)}
            onChange={handleUnitChange}
            value={values.unit}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            required
            type='number'
            name='amount'
            label={`${t('form.priceIncreaseAmount.label')} ${values.unit}`}
            onChange={handleOnChange}
            validate={validatePriceUpFormField(values)}
            value={values.amount}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            required
            type='number'
            name='qty'
            label={t('form.quantityThreshold.label')}
            onChange={handleOnChange}
            validate={validatePriceUpFormField(values)}
            value={values.qty}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            required
            type='number'
            name='maxPrice'
            label={t('form.maxPrice.label')}
            onChange={handleOnChange}
            validate={validatePriceUpFormField(values)}
            value={values.maxPrice}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            required
            type='number'
            name='notSellingThreshold'
            label={t('form.notSellingThreshold.label')}
            onChange={handleOnChange}
            validate={validatePriceUpFormField(values)}
            value={values.notSellingThreshold}
          />
        </FieldContainer>
      </InputsContainer>
      <SubmitContainer>
        <IconButton
          color='primary'
          type='submit'
          id='price-up-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          <Success />
        </IconButton>
      </SubmitContainer>
    </StyledForm>
  )
}

export default withRouter(PriceUpForm)

const StyledForm = styled.form`
  display: flex;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
  }
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  .label-icon {
    font-size: 20px;
  }
`

const FieldContainer = styled.div`
  display: inline-block;
  margin-left: 10px;
  min-width: 200px;
`

const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 77px;
`

const InputsContainer = styled.div`
  display: inline-flex;
  min-height: 77px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  height: 77px;
  margin-left: 10px;
`
