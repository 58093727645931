import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { IMPORT_ORDERS_MIN_DATE } from 'utils/integrations'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SEVERITY } from 'utils/toast'
import * as iaiService from 'services/IAIService'
import {
  validateImportOrdersSchemaField,
  validateImportOrdersSchemaValues
} from 'features/integrations/ducks/schema'

import { showToastRoutine } from 'features/toast/ducks/actions'

import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import DatePickerField from 'components/formElements/DatePickerField'
import { formatDate, FORMATS } from 'utils/date'

const emptyValues = {
  dateFrom: '',
  dateTo: ''
}

export const ImportOrdersFromIaiForm = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    validateImportOrdersSchemaValues(values, _validateSchema)
  }, [values])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      await iaiService.importOrdersFromIAI({
        dateFrom: formatDate(values.dateFrom, FORMATS.dashedReverse),
        dateTo: formatDate(values.dateTo, FORMATS.dashedReverse)
      })
      showToast({
        key: 'toast.importOrdersSuccess',
        severity: SEVERITY.success
      })
      setLoading(false)
      handleOnChange({
        dateFrom: '',
        dateTo: ''
      })
    } catch (err) {
      console.error(err)
      showToast({ key: 'toast.somethingWentWrong', severity: SEVERITY.error })
      setLoading(false)
    }
  }

  return (
    <Form>
      <FormFieldsContainer>
        <div className='import-orders__date-picker'>
          <DatePickerField
            value={values.dateFrom}
            disableFuture
            required
            name='dateFrom'
            label={t('form.dateFrom.label')}
            onChange={handleOnChange}
            validate={validateImportOrdersSchemaField(values)}
            dependentValue={values.dateTo}
            minDate={moment(IMPORT_ORDERS_MIN_DATE)}
          />
        </div>
        <div className='import-orders__date-picker'>
          <DatePickerField
            value={values.dateTo}
            disableFuture
            required
            name='dateTo'
            label={t('form.dateTo.label')}
            onChange={handleOnChange}
            validate={validateImportOrdersSchemaField(values)}
            dependentValue={values.dateFrom}
            minDate={moment(IMPORT_ORDERS_MIN_DATE)}
          />
        </div>
      </FormFieldsContainer>
      <ButtonContainer>
        <Button type='submit' disabled={!valid || loading} onClick={handleSubmit}>
          {t('settingsPage.importOrders.importOrdersCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default ImportOrdersFromIaiForm

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .import-orders__date-picker {
    display: inline-block;
    max-width: 500px;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
