import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as searchQueryService from 'services/SearchQueryService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

// ROUTINES

export const fetchSearchQueriesRoutine = createRoutine('FETCH_SEARCH_QUERIES')
export const createSearchQueryRoutine = createRoutine('SAVE_SEARCH_QUERY')
export const deleteSearchQueryRoutine = createRoutine('DELETE_SEARCH_QUERY')

// ACTIONS

function * fetchSearchQueries ({ payload }) {
  yield put(fetchSearchQueriesRoutine.request())
  try {
    const result = yield call(searchQueryService.fetchSearchQueries, payload)
    yield put(fetchSearchQueriesRoutine.success(result.data))
  } catch (e) {
    yield put(fetchSearchQueriesRoutine.failure(e))
    console.error(e)
  }
}

// Workaround - passing a callback to handle modal close after success
// issue link: https://github.com/redux-saga/redux-saga/issues/907
function * createSearchQuery ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createSearchQueryRoutine.request())
  try {
    yield call(searchQueryService.createSearchQuery, values)
    yield put(fetchSearchQueriesRoutine())
    yield put(createSearchQueryRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.saveSearchQuerySuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(createSearchQueryRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function * deleteSearchQuery ({ payload }) {
  yield put(deleteSearchQueryRoutine.request())
  try {
    yield call(searchQueryService.deleteSearchQuery, payload)
    yield put(fetchSearchQueriesRoutine())
    yield put(deleteSearchQueryRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.deleteSearchQuerySuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(deleteSearchQueryRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

// WATCHERS

export function * createSearchQueryWatcher () {
  yield takeLatest(createSearchQueryRoutine.TRIGGER, createSearchQuery)
}

export function * fetchSearchQueriesWatcher () {
  yield takeLatest(fetchSearchQueriesRoutine.TRIGGER, fetchSearchQueries)
}

export function * deleteSearchQueryWatcher () {
  yield takeLatest(deleteSearchQueryRoutine.TRIGGER, deleteSearchQuery)
}

// SAGAS

export const searchQueriesSagas = [
  fork(createSearchQueryWatcher),
  fork(fetchSearchQueriesWatcher),
  fork(deleteSearchQueryWatcher)
]
