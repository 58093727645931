import APIService from 'services/APIService'

const api = APIService()

export const fetchAllegroAccounts = query => api.get(`/allegro?${query}`)
export const editAllegroAccount = ({ id, name }) => api.patch(`/allegro/${id}`, { name })
export const refreshAllegroToken = ({ id }) => api.patch(`/allegro/${id}/refresh`)
export const deleteAllegroAccount = ({ id }) => api.delete(`/allegro/${id}`)
export const checkAllegroConnection = () => api.get('/allegro/connection-check')
export const addPromotionToProduct = ({ productId, name }) => api.post('/allegro-promotions', { productId, name })
export const removePromotionFromProduct = ({ productId, name }) => api.delete(`/allegro-promotions/${productId}/${name}`)
export const updateAuctionPrice = ({ offerId, price }) => api.patch(`/allegro/${offerId}/change-price`, { price })
export const syncSingleAuction = ({ offerId }) => api.post(`/allegro/${offerId}/auction-sync`)
