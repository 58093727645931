import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Create search query form validator

export const createSearchQuerySchema = object().shape({
  name: string()
    .required(() => ({ key: 'form.name.errors.required' })),
  content: string().required()
})

export const validateSearchQueryField = values => validateField(createSearchQuerySchema, values)
export const validateSearchQueryValues = validateValues(createSearchQuerySchema)
