import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  validateAssignProductsToWeightSchemaField,
  validateAssignProductsToWeightSchemaValues
} from 'features/weightSchemas/ducks/schema'

import { assignProductsToWeightSchemaRoutine, fetchWeightSchemasRoutine } from 'features/weightSchemas/ducks/actions'
import { getWeightSchemasAsOptions } from 'features/weightSchemas/ducks/selectors'
import { getSelectedItems } from 'ducks/global/selectors'

import Button from 'components/atoms/Button'
import { handleResetSelectionRoutine } from 'ducks/global/actions'
import Select from 'components/formElements/SingleSelectField'

const emptyValues = {
  id: '',
  productIds: []
}

export const AssignWeightSchemaToProductsForm = (props) => {
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const selectedItems = useSelector(getSelectedItems)
  const options = useSelector(getWeightSchemasAsOptions)
  const dispatch = useDispatch()

  const assignProductsToWeightSchema = useCallback(
    payload => dispatch(assignProductsToWeightSchemaRoutine(payload)),
    [dispatch]
  )

  const fetchWeightSchemas = useCallback(
    () => dispatch(fetchWeightSchemasRoutine()),
    [dispatch]
  )

  const handleResetSelection = useCallback(
    () => dispatch(handleResetSelectionRoutine()),
    [dispatch]
  )

  useEffect(() => {
    validateAssignProductsToWeightSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    fetchWeightSchemas()
  }, [])

  useEffect(() => {
    handleOnChange('productIds', selectedItems)
  }, [selectedItems])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    props.onSubmit()
    handleResetSelection()
  }

  const handleSubmit = e => {
    e.preventDefault()
    assignProductsToWeightSchema({
      values,
      callback: handleCallback
    })
  }

  return (
    <AssignWeightSchemaToProductsFormContainer>
      <div className='select-container'>
        <Select
          required
          name='id'
          options={options}
          label={t('form.weightSchema.label')}
          values={values}
          validate={validateAssignProductsToWeightSchemaField(values)}
          onChange={handleOnChange}
        />
      </div>
      <div className='button-container'>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          {t('monitoringPage.weightSchema.assignCTA')}
        </Button>
      </div>
    </AssignWeightSchemaToProductsFormContainer>
  )
}

export default AssignWeightSchemaToProductsForm

const AssignWeightSchemaToProductsFormContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
    align-items: flex-end;
  }
  
  .select-container {
    min-width: 300px;
    max-width: 300px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      min-width: 100%;
      max-width: 100%;
    }
  }
  
  .button-container {
    margin-left: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-left: 0;
      min-width: 100%;
    }
  }
`
