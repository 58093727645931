import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const SettingsData = Record({
  soldShort: 7,
  soldLong: 30,
  maxPriceValue: 9999999,
  priceIncreasePercentage: 1,
  priceIncreaseThreshold: 3,
  priceIncreaseValue: 0,
  notSellingThreshold: 3,
  stockDiffPercentage: 10,
  stockDiffAmount: 5,
  deliveredProductsSyncInterval: 60,
  priceDecreasePercentage: 1,
  priceDecreaseThreshold: 3,
  minMarginValue: 12,
  priceDecreaseValue: 0,
  priceDownStockDiffPercentage: 0
})

export const Settings = Record({
  state: API_STATES.PRISTINE,
  settings: new SettingsData()
})
