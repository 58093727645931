import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { getSelectedTheme } from 'ducks/global/selectors'
import { hasAllegroIntegrationError, getAllegroErrorMessage } from 'features/integrations/ducks/selectors'

import AllegroAccountEditNameForm from 'features/allegro/components/AllegroAccountEditNameForm'
import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'
import AllegroAccountDeleteButton from 'features/allegro/components/AllegroAccountDeleteButton'
import IntegrationAccount from 'features/integrations/components/IntegrationAccount'
import { refreshAllegroTokenRoutine } from 'features/allegro/ducks/actions'

export const AllegroAccount = (props) => {
  const { data } = props
  const [formVisible, setFormVisibility] = useState(false)
  const selectedThemeMode = useSelector(getSelectedTheme)
  const hasError = useSelector(state => hasAllegroIntegrationError(state, data.id))
  const errorMessage = useSelector(state => getAllegroErrorMessage(state, data.id))
  const dispatch = useDispatch()

  const refreshToken = useCallback(
    payload => dispatch(refreshAllegroTokenRoutine(payload)),
    [dispatch]
  )

  const handleRefresh = () => refreshToken({ id: data.id })

  const showForm = () => setFormVisibility(true)
  const hideForm = () => setFormVisibility(false)

  return (
    <AllegroAccountContainer themeMode={selectedThemeMode} formVisible={formVisible} accountActive={!hasError}>
      <IntegrationAccount
        name={
          <>
            {formVisible ? '' : data.name}
            <div className='allegro-account__edit-name'>
              {
                formVisible ? (
                  <AllegroAccountEditNameForm data={data} onSubmit={hideForm} onCancel={hideForm} />
                ) : (
                  <>
                    <IconButton size='small' onClick={showForm}>
                      <Edit />
                    </IconButton>
                    <AllegroAccountDeleteButton data={data} />
                  </>
                )
              }
            </div>
          </>
        }
        handleRefresh={handleRefresh}
        isActive={!hasError}
        details={errorMessage}
      />
    </AllegroAccountContainer>
  )
}

export default AllegroAccount

const AllegroAccountContainer = styled.div`
  &+& {
    border-top: 1px solid ${props => props.theme.palette.common.gray300};
  }
  
  .allegro-account__name {
    display: flex;
    align-items: center;
  }
  
  .allegro-account__edit-name {
    margin-left: ${props => props.formVisible ? '0' : '10px'};
    visibility: ${props => props.formVisible ? 'visible' : 'hidden'};
  }
  
  &:hover .allegro-account__edit-name {
    visibility: visible;
  }
`
