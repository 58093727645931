import React, { useCallback, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from 'features/toast/ducks/actions'
import queryString from 'query-string'
import { propEq, propOr, includes, pipe } from 'ramda'
import { ALLEGRO_ERRORS } from 'utils/errors'
import { SEVERITY } from 'utils/toast'
import PATHS from 'utils/paths'

export const AllegroIntegrationStatusNotification = (props) => {
  const { history: { push, location: { search } } } = props
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    slackIntegrationStatusNotification()
  }, [])

  const slackIntegrationStatusNotification = () => {
    const query = queryString.parse(search)
    const integrationSucceeded = propEq('integration_added', 'true')(query)
    const integrationFailed = pipe(propOr('', 'error'), errorCode =>
      includes(errorCode, ALLEGRO_ERRORS)
    )(query)

    switch (true) {
      case integrationSucceeded:
        showToast({
          key: 'toast.allegroIntegrationSuccess',
          severity: SEVERITY.success
        })
        return push(PATHS.allegro)
      case integrationFailed:
        showToast({
          key: 'toast.allegroIntegrationFailed',
          severity: SEVERITY.error
        })
        return push(PATHS.allegro)
      default:
        return null
    }
  }

  return (
    <div />
  )
}

export default withRouter(AllegroIntegrationStatusNotification)
