import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedItems } from 'ducks/global/selectors'
import { generateCurrentQuery } from 'utils/query'

import IconButton from 'components/atoms/IconButton'
import RemoveFromCart from 'components/icons/RemoveFromCart'
import { removeProductsFromCartRoutine } from 'features/products/ducks/actions'
import Tooltip from 'components/atoms/Tooltip'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'

export const RemoveProductsFromCart = (props) => {
  const { t } = useTranslation()
  const selectedItems = useSelector(getSelectedItems)
  const dispatch = useDispatch()

  const addProductsToCart = useCallback(
    payload => dispatch(removeProductsFromCartRoutine(payload)),
    [dispatch]
  )

  const fetchMonitoring = useCallback(
    () => dispatch(fetchMonitoringRoutine(generateCurrentQuery())),
    [dispatch]
  )

  const handleAddToCart = () => addProductsToCart({
    values: { productIds: selectedItems },
    callback: fetchMonitoring
  })

  return (
    <Tooltip title={t('ordersPage.removeFromCartCTA')}>
      <IconButton
        type='button'
        id='button-remove-from-cart'
        onClick={handleAddToCart}
        color='primary'
      >
        <RemoveFromCart />
      </IconButton>
    </Tooltip>
  )
}

export default RemoveProductsFromCart
