import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import { withRouter } from 'react-router-dom'

import { getWillLastHistoryArray, getWillLastHistoryMeta, getIsWillLastHistoryLoading } from 'features/willLastHistory/ducks/selectors'
import { fetchWillLastHistoryRoutine } from 'features/willLastHistory/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'

export const TodaysOrdersTable = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(getWillLastHistoryArray)
  const isLoading = useSelector(getIsWillLastHistoryLoading)
  const pagination = useSelector(getWillLastHistoryMeta)
  const fetchOrders = useCallback(
    query => dispatch(fetchWillLastHistoryRoutine({ query, id })),
    [dispatch]
  )

  const headers = [
    {
      id: 'day',
      sortable: true,
      cell: t('productPage.willLastHistory.table.headers.day')
    },
    {
      id: 'will_last_7',
      sortable: false,
      cell: t('productPage.willLastHistory.table.headers.will_last_7')
    },
    {
      id: 'will_last_30',
      sortable: false,
      cell: t('productPage.willLastHistory.table.headers.will_last_30')
    }
  ]

  const renderItems = data.map((data, index) => (
    <TableRow
      hover
      key={propOr(`will-last-history-row-${index}`, 'id', data)}
      id={`will-last-history-row-${index}`}
    >
      <TableCell width='10%'>
        {propOr('-', 'day')(data)}
      </TableCell>
      <TableCell width='10%'>
        {propOr('-', 'will_last_7')(data)}
      </TableCell>
      <TableCell>
        {propOr('-', 'will_last_30')(data)}
      </TableCell>
    </TableRow>
  ))

  return (
    <div id='will-last-history-table'>
      <EntitiesList
        disableSticky
        emptyStateText={t('productPage.willLastHistory.table.emptyState')}
        headers={headers}
        pagination={pagination}
        tableRows={renderItems}
        fetchRecords={fetchOrders}
        isLoading={isLoading}
        defaultSortedColumnId='day'
      />
    </div>
  )
}

export default withRouter(TodaysOrdersTable)
