import APIService from 'services/APIService'

const api = APIService()

export const fetchTags = query => api.get(`/tags?${query}`)
export const assignProductsToTag = ({ tagId, productIds }) => api.patch(`/tags/${tagId}/assign`, { productIds })
export const createTag = payload => api.post('/tags', payload)
export const editTag = ({ id, name }) => api.patch(`/tags/${id}`, { name })
export const deleteTag = id => api.delete(`/tags/${id}`)
export const removeTagFromProduct = ({ id, productId }) => api.patch(`/tags/${id}/remove-from-product`, { productId })
