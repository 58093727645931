import { createSelector } from 'reselect'
import { propOr, pipe, equals, pathOr } from 'ramda'
import { API_STATES } from 'utils/redux'

export const selectProductAuctions = state => state.productAuctions

export const getIsProductAuctionsLoading = createSelector(
  selectProductAuctions,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getIsSyncAuctionLoading = createSelector(
  selectProductAuctions,
  pipe(
    propOr([], 'syncAuctionState'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductAuctions = createSelector(
  selectProductAuctions,
  (state, id) => id,
  (productAuctions, id) => pathOr([], ['auctions', id])(productAuctions)
)
