import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const SearchQuery = Record({
  id: '',
  name: '',
  content: '',
  userId: null
})

export const SearchQueries = Record({
  state: API_STATES.PRISTINE,
  searchQueries: []
})
