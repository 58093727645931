import React from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { PRODUCT_SALE_CHANNEL_STATUS_TRANSLATIONS, PRODUCT_SALE_CHANNEL_STATUSES } from 'utils/saleChannels'

import { getProductSaleChannelsByChannelName } from 'features/saleChannels/ducks/selectors'

import SaleChannels from 'components/icons/SaleChannels'
import AddSaleChannelToProductModal
  from 'features/saleChannels/components/AddSaleChannelToProductModal'
import EditProductSaleChannelModal from 'features/saleChannels/components/EditProductSaleChannelModal'
import DeleteProductSaleChannelButton from 'features/saleChannels/DeleteProductSaleChannelButton'

export const ProductSaleChannel = (props) => {
  const { match: { params: { id } } } = props
  const { data } = props
  const channelElements = useSelector(state => getProductSaleChannelsByChannelName(state, id, data.name))
  const translatedStatus = status => PRODUCT_SALE_CHANNEL_STATUS_TRANSLATIONS()[status]

  const renderElements = channelElements.map(element => (
    <div className='sale-channel__element' key={element.id}>
      <div>
        {element.status === PRODUCT_SALE_CHANNEL_STATUSES.published
          ? <a href={element.link} rel='noopener noreferrer' target='_blank'>{element.link}</a>
          : translatedStatus(element.status)}
      </div>
      {
        element.status === PRODUCT_SALE_CHANNEL_STATUSES.published && (
          <div>
            <EditProductSaleChannelModal data={element} saleChannelId={data.id} />
            <DeleteProductSaleChannelButton data={element} />
          </div>
        )
      }
    </div>
  ))

  return (
    <ProductSaleChannelContainer>
      <div className='sale-channel__info'>
        <div className='sale-channel__title'>
          <div className='sale-channel__icon'>
            <SaleChannels />
          </div>
          <div className='sale-channel__name'>
            <span>
              {propOr('', 'name', data)}
            </span>
            <AddSaleChannelToProductModal saleChannelId={data.id} />
          </div>
        </div>
        <div>
          {renderElements}
        </div>
      </div>
    </ProductSaleChannelContainer>
  )
}

export default withRouter(ProductSaleChannel)

const ProductSaleChannelContainer = styled.div`
  display: inline-flex;
  width: 100%;
  background-color: ${props => props.theme.palette.common.gray100};
  padding: 20px;
  border-radius: ${props => props.theme.shape.borderRadius};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 10px;
  }
  
  .sale-channel__icon {
    font-size: 20px;
    margin-right: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 17px;
    margin-right: 10px;
    }
  }
  
  .sale-channel__info {
    width: 100%;
    flex-grow: 1;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      padding: 0;
    }
  }
  
  .sale-channel__title {
    display: flex;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 25px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  
  .sale-channel__name {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sale-channel__element {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
