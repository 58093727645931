import { createSelector } from 'reselect'
import { propOr, pipe, equals, pathOr } from 'ramda'
import { API_STATES } from 'utils/redux'

export const shippingOrders = state => state.shippingOrders

export const getIsShippingOrdersLoading = createSelector(
  shippingOrders,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getShippingOrdersArray = createSelector(
  shippingOrders,
  pipe(propOr([], 'shippingOrders'))
)

export const getShippingOrdersMeta = createSelector(
  shippingOrders,
  propOr({}, 'shippingOrdersMeta')
)

export const getShippingOrdersItems = createSelector(
  shippingOrders,
  (state, id) => id,
  (orders, id) => pathOr([], ['shippingOrderItems', id])(orders)
)
