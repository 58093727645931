import React from 'react'
import Icon from 'components/Icon'

export const MinMargin = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 391.7 437.3'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='M146.5,95.7C145.2,72.2,143.8,44,144,23h-0.8c-5.8,19.8-12.8,40.8-21.2,64l-29.8,81.8H75.8L48.5,88.5 C40.5,64.7,33.8,43,29,23h-0.5c-0.5,21-1.8,49.2-3.2,74.5l-4.5,72.2H0L11.8,1.2h27.8l28.8,81.5c7,20.8,12.8,39.2,17,56.8H86 c4.2-17,10.2-35.5,17.8-56.8l30-81.5h27.8L172,169.7h-21.2L146.5,95.7z' />
        <path d='M227.2,1.2v168.5h-21.8V1.2H227.2z' />
        <path d='M265.2,169.7V1.2H289l54,85.2c12.5,19.8,22.2,37.5,30.2,54.8l0.5-0.2c-2-22.5-2.5-43-2.5-69.2V1.2h20.5v168.5h-22 l-53.5-85.5c-11.8-18.8-23-38-31.5-56.2l-0.8,0.2c1.2,21.3,1.8,41.5,1.8,69.5v72H265.2z' />
      </g>
      <g>
        <path d='M172.9,293.2c0,41.1-21.6,61.5-46.8,61.5c-24.3,0-45.3-19.5-45.6-58.8c0-39,21.3-60.9,47.1-60.9 C154.9,235,172.9,256,172.9,293.2z M101.5,295c-0.6,24.9,8.7,43.8,25.2,43.8c17.4,0,25.2-18.6,25.2-44.4c0-23.7-6.9-43.5-25.2-43.5 C110.2,250.9,101.5,270.4,101.5,295z M124.3,436.6L238.9,235h16.8L141.1,436.6H124.3z M299.8,373.9c0,41.1-21.6,61.5-46.5,61.5 c-24.3,0-45.3-19.5-45.6-58.5c0-39.3,21.3-61.2,47.1-61.2C282.1,315.7,299.8,336.7,299.8,373.9z M228.4,375.7 c-0.3,24.9,9.3,43.8,25.5,43.8c17.4,0,25.2-18.6,25.2-44.4c0-23.7-6.9-43.5-25.2-43.5C237.4,331.6,228.4,351.1,228.4,375.7z' />
      </g>
    </Icon>
  )
}

export default MinMargin
