import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { transparentize } from 'polished'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import { getProductNotes } from 'features/products/ducks/selectors'
import SadFace from 'components/icons/SadFace'
import AddNotesModal from 'features/products/components/AddNotesModal'
import EditNotesModal from 'features/products/components/EditNotesModal'

export const NotesSection = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const notes = useSelector(state => getProductNotes(state, id))

  const emptyState = (
    <EmptyState id='product-notes-empty-state'>
      <SadFace />&nbsp;
      <span>{t('productPage.notes.emptyState')}</span>
      <AddNotesModal />
    </EmptyState>
  )

  return (
    <NotesContainer>
      {isNilOrEmpty(notes) && emptyState}
      {isNotNilOrEmpty(notes) && (
        <div id='product-notes' className='notes-container__notes'>
          <div>{notes}</div>
          <EditNotesModal />
        </div>
      )}
    </NotesContainer>
  )
}

export default withRouter(NotesSection)

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.common.white};
  font-size: 14px;
  width: 100%;
  padding: 10px;
  
  button {
    margin-left: 10px;
  }
`

const NotesContainer = styled.div`
  margin: 0 0 10px;
  border-radius: ${props => props.theme.shape.borderRadius};
  background-color: ${props => transparentize('0.8', props.theme.palette.secondary.main)};
  
  .notes-container__notes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
`
