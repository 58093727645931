import React, { useState, useEffect, useCallback } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { omit, pipe } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  validateLoginField,
  validateLoginValues
} from 'features/auth/ducks/schema'
import PATHS from 'utils/paths'

import { loginUserRoutine } from 'features/auth/ducks/actions'
import { getFailedLoginAttempts, getIsAuthLoading } from 'features/auth/ducks/selectors'

import ReCaptcha from 'components/formElements/ReCaptcha'
import Form from 'components/atoms/Form'
import TextField from 'components/formElements/TextField'
import Button from 'components/atoms/Button'

const emptyValues = {
  login: '',
  password: '',
  failedLoginAttempts: 0,
  captcha: ''
}

export const LoginForm = props => {
  const { history: { push } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loginUser = useCallback(
    payload => dispatch(loginUserRoutine({ values: payload, callback: () => push(PATHS.dashboard) })),
    [dispatch]
  )
  const failedLoginAttempts = useSelector(getFailedLoginAttempts)
  const isLoading = useSelector(getIsAuthLoading)

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    validateLoginValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    handleOnChange('failedLoginAttempts', failedLoginAttempts)
  }, [failedLoginAttempts])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    return pipe(
      omit(['failedLoginAttempts', 'captcha']),
      loginUser
    )(values)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        required
        name='login'
        label={t('form.login.label')}
        onChange={handleOnChange}
        validate={validateLoginField(values)}
      />
      <TextField
        required
        name='password'
        type='password'
        label={t('form.password.label')}
        onChange={handleOnChange}
        validate={validateLoginField(values)}
      />
      <StyledLink to={PATHS.resetPasswordInit}>
        {t('loginPage.resetPasswordCTA')}
      </StyledLink>
      <CaptchaContainer>
        <ReCaptcha
          name='captcha'
          onChange={handleOnChange}
          failedAttempts={failedLoginAttempts}
        />
      </CaptchaContainer>
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('loginPage.loginCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default withRouter(LoginForm)

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const CaptchaContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;

  div {
    display: inline-block;
  }
`

const StyledLink = styled(Link)`
  margin: 10px 0;
`
