export default {
  palette: {
    common: {
      black: '#D8D9DB',
      white: '#353941',
      gray100: '#2D3642',
      gray200: '#38414d',
      gray300: '#545d68',
      gray400: '#616a73',
      gray500: '#D8D9DB',
      gray600: '#E7E7E9',
      gray700: '#F2F2F4',
      gray800: '#FAFAFB',
      gray900: '#FCFCFD'
    },
    primary: {
      light: '#5DBCE2',
      main: '#2076B2',
      dark: '#094f75',
      contrastText: '#fff',
      gradient: 'linear-gradient(to bottom, #2d97d0, #2584b8, #1c72a1, #13608b, #094f75)'
    },
    secondary: {
      light: '#FDCE61',
      main: '#FCB72E',
      dark: '#D89521',
      contrastText: '#fff'
    },
    error: {
      light: '#FF8075',
      main: '#FF4747',
      dark: '#DB3342',
      contrastText: '#fff'
    },
    warning: {
      light: '#FFB867',
      main: '#FF9635',
      dark: '#DB7426',
      contrastText: '#192141'
    },
    info: {
      light: '#69CEFF',
      main: '#38B2FF',
      dark: '#288BDB',
      contrastText: '#fff'
    },
    success: {
      light: '#99D24A',
      main: '#6CB519',
      dark: '#549B12',
      contrastText: '#192141'
    },
    text: {
      main: '#D8D9DB'
    },
    constants: {
      black: '#000',
      white: '#fff'
    },
    type: 'dark'
  },
  shadows: {
    main: '4px 0 20px 0 rgba(0, 4, 12, 0.3)',
    paper: '0 4px 24px 0 rgba(0, 4, 12, 0.3)'
  }
}
