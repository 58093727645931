import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Popover from 'components/atoms/Popover'
import IconButton from 'components/atoms/IconButton'
import Save from 'components/icons/Save'
import Tooltip from 'components/atoms/Tooltip'

import CreateSearchQueryForm from 'features/searchQueries/CreateSearchQueryForm'

export const CreateSearchQueryPopover = props => {
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const { t } = useTranslation()

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const CreateSearchQueryContent = (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <PopoverContainer>
        <div className='create-search-query__title'>{t('filters.createSearchQuery.title')}</div>
        <CreateSearchQueryForm onSubmit={handleClose} />
      </PopoverContainer>
    </Popover>
  )

  return (
    <>
      <Tooltip title={t('filters.createSearchQuery.title')}>
        <IconButton onClick={handleOpen}>
          <Save />
        </IconButton>
      </Tooltip>
      {CreateSearchQueryContent}
    </>
  )
}

export default CreateSearchQueryPopover

export const PopoverContainer = styled.div`
  padding: 20px;
  min-width: 300px;
  
  .create-search-query__title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
`
