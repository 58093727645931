import styled from 'styled-components'

export default styled.div`
  padding: 33px;
  background-color: ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shadows.main};
  border-radius: 5px;
  
  &:focus {
    outline: none;
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 16px;
  }
`
