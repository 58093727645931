import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  validateUpdateEstimatedShippingTimeSchemaValues,
  validateUpdateEstimatedShippingTimeSchemaField
} from 'features/products/ducks/schema'
import { SECTION_IDS, isSectionExpanded } from 'utils/productPage'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

import {
  getProductRoutine,
  updateSingleEstimatedShippingTimeRoutine
} from 'features/products/ducks/actions'
import { getIsProductLoading, getProductEstimatedShippingTime } from 'features/products/ducks/selectors'

import TextField from 'components/formElements/TextField'
import IconButton from 'components/atoms/IconButton'
import Success from 'components/icons/Success'
import Loader from 'components/atoms/Loader'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

const emptyValues = {
  est: 0,
  productIds: []
}

export const UpdateSingleEstimatedShippingTime = (props) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { match: { params: { id } }, onSubmit } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const est = useSelector(state => getProductEstimatedShippingTime(state, id))
  const isLoading = useSelector(getIsProductLoading)

  const updateEstimatedShippingTime = useCallback(
    payload => dispatch(updateSingleEstimatedShippingTimeRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const getProduct = useCallback(
    payload => dispatch(getProductRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateUpdateEstimatedShippingTimeSchemaValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ est, productIds: [id] })
  }, [id, est])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleCallback = () => {
    onSubmit()
    getProduct({ id })
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    updateEstimatedShippingTime({ values, callback: handleCallback })
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextField
        required
        type='number'
        value={values.est}
        name='est'
        label={t('form.estimatedShippingTime.label')}
        onChange={handleOnChange}
        validate={validateUpdateEstimatedShippingTimeSchemaField(values)}
      />
      <IconButton
        type='submit'
        id='button-submit'
        disabled={isLoading || !valid || est === values.est}
        onClick={handleSubmit}
      >
        {isLoading ? <Loader size={20} /> : <Success />}
      </IconButton>
    </StyledForm>
  )
}

export default withRouter(UpdateSingleEstimatedShippingTime)

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`
