import React, { useEffect, useState } from 'react'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { isNil, pick, map, prop, pipe, keys, filter, includes, allPass, propEq } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'

import PlainMultiSelect from 'components/atoms/FilterInputs/PlainMultiSelect'

export const BooleanMultiSelectFilter = (props) => {
  const { label, options, exclude } = props
  const urlSearchQuery = useUrlSearchQuery()
  const { location: { search } } = useHistory()

  const selectedValues = pipe(
    map(prop('value')),
    values => pick(values, urlSearchQuery.getSearchQuery()),
    keys
  )(options)

  const convertBooleanToString = flag => {
    return flag.toString()
  }

  const selectedOptions = filter(
    allPass([
      option => includes(option.value, selectedValues),
      option => propEq(option.value, convertBooleanToString(!exclude), urlSearchQuery.getSearchQuery())
    ])
  )(options)

  const [value, setValue] = useState(selectedOptions)

  useEffect(() => {
    setValue(selectedOptions)
  }, [search, options])

  const handleChange = e => {
    const parsedNilValue = isNil(e) ? [] : e
    setValue(parsedNilValue)
    const mappedValues = parsedNilValue.map(value => ({
      [value.value]: !exclude
    }))

    options.forEach(option => urlSearchQuery.removeSearchQuery(option.value))
    if (isNotNilOrEmpty(parsedNilValue)) {
      mappedValues.forEach(value => urlSearchQuery.setSearchQuery(value))
    }
  }

  return (
    <>
      <PlainMultiSelect
        value={value}
        options={options}
        label={label}
        onChange={handleChange}
      />
    </>
  )
}

BooleanMultiSelectFilter.defaultProps = {
  options: []
}

export default BooleanMultiSelectFilter
