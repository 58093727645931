import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { applySpec, prop, pipe, propOr } from 'ramda'
import {
  validateAssignProductsToTagValues
} from 'features/tags/ducks/schema'
import { handleSearchTagOptionsWithExcluded } from 'utils/filters'

import { showToastRoutine } from 'features/toast/ducks/actions'
import { assignProductToTagRoutine } from 'features/tags/ducks/actions'
import { createTag } from 'services/TagsService'
import { getProductTags } from 'features/products/ducks/selectors'

import AsyncCreatableSelectField from 'components/formElements/AsyncCreatableSelectField'
import Button from 'components/atoms/Button'
import { SEVERITY } from 'utils/toast'

const emptyValues = {
  tagId: '',
  productIds: []
}

export const AssignTagToProductsForm = (props) => {
  const { onSubmit, match: { params: { id } } } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const currentTags = useSelector(state => getProductTags(state, id))
  const currentTagsIds = currentTags.map(tag => tag.id)

  const assignProductToTag = useCallback(
    payload => dispatch(assignProductToTagRoutine(payload)),
    [dispatch]
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateAssignProductsToTagValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({
      productIds: [id]
    })
  }, [id])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    assignProductToTag({
      values,
      callback: onSubmit
    })
  }

  const handleCreateOption = async inputValue => {
    try {
      const response = await createTag({ name: inputValue })
      showToast({
        key: 'toast.createTagSuccess',
        severity: SEVERITY.success
      })
      return pipe(
        propOr({}, 'data'),
        applySpec({
          value: prop('id'),
          label: prop('name')
        })
      )(response)
    } catch (e) {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }
  }

  return (
    <AssignTagToProductsFormContainer>
      <div className='select-container'>
        <AsyncCreatableSelectField
          required
          name='tagId'
          handleSearchOptions={handleSearchTagOptionsWithExcluded(currentTagsIds)}
          handleCreateNewOption={handleCreateOption}
          label={t('productPage.assignTag.label')}
          values={values}
          onChange={handleOnChange}
        />
      </div>
      <div className='button-container'>
        <Button
          type='button'
          id='button-cancel'
          onClick={onSubmit}
          variant='outlined'
        >
          {t('productPage.assignTag.cancel')}
        </Button>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid}
          onClick={handleSubmit}
        >
          {t('productPage.assignTag.assignCTA')}
        </Button>
      </div>
    </AssignTagToProductsFormContainer>
  )
}

export default withRouter(AssignTagToProductsForm)

const AssignTagToProductsFormContainer = styled.div`
  .select-container {
    min-width: 300px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      min-width: 100%;
    }
  }
  
  .button-container {
    text-align: right;
  
    button + button {
      margin-left: 10px;
    }
  }
`
