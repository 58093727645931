import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { SORT_ORDER } from 'utils/table'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import qs from 'qs'

import { getProductCommentsArray, getProductCommentsMeta } from 'features/comments/ducks/selectors'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'

import Comment from 'features/comments/components/Comment'
import SadFace from 'components/icons/SadFace'

export const CommentsList = (props) => {
  const { match: { params: { id } } } = props
  const [scrollHeightBeforeFetch, setScrollHeightBeforeFetch] = useState(0)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ListContainerRef = useRef(null)
  const comments = useSelector(state => getProductCommentsArray(state, id))
  const pagination = useSelector(state => getProductCommentsMeta(state, id))
  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const handleFetchProductComments = take => {
    const container = ListContainerRef.current
    setScrollHeightBeforeFetch(container.scrollHeight)
    fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: take
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })
  }

  const handleFetchMore = () => {
    handleFetchProductComments(Number(pagination.take) + Number(COMMENTS_PAGE_COUNT))
  }

  useEffect(() => {
    handleFetchProductComments(Number(COMMENTS_PAGE_COUNT))
  }, [id])

  const scrollToBottom = () => {
    const container = ListContainerRef.current
    const offset = container.scrollHeight - scrollHeightBeforeFetch
    if (container && Number(pagination.take) <= COMMENTS_PAGE_COUNT) {
      setTimeout(() => { container.scrollTop = container.scrollHeight }, 0)
    } else {
      setTimeout(() => { container.scrollTop = offset }, 0)
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [comments])

  const renderItems = comments.map(record => (
    <Comment key={record.id} data={record} />
  ))

  const emptyState = (
    <EmptyState>
      <SadFace />&nbsp;
      {t('productPage.comments.emptyState')}
    </EmptyState>
  )

  return (
    <StyledCommentsContainer ref={ListContainerRef}>
      {pagination.page < pagination.pagesTotal ? (
        <div className='comments__show-previous' onClick={handleFetchMore}>
          {t('productPage.comments.showPreviousCTA')}
        </div>
      ) : ''}
      {comments.length > 0 ? renderItems : emptyState}
    </StyledCommentsContainer>
  )
}

const StyledCommentsContainer = styled.div`
  padding: 14px;
  max-height: 300px;
  overflow-y: auto;
  
  .comments__show-previous {
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
  }
`

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
`

export default withRouter(CommentsList)
