import React from 'react'
import styled from 'styled-components'
import { PRODUCT_TASKS_API_FIELDS } from 'utils/flags'
import FlagCellIcon from 'components/FlagCellIcon'
import { filter, map, pipe, propOr } from 'ramda'

// This component is dedicated for displaying enabled flag icons based on
// PRODUCT_TASKS_API_FIELDS from src/utils/flags.js

export const FlagsCell = (props) => {
  const { data, byName } = props

  const enabledFlags = pipe(
    filter(
      field => propOr(false, byName ? field.name : field.key, data)
    ),
    map(field => <FlagCellIcon key={field.name} name={field.translation} icon={field.icon} />)
  )(PRODUCT_TASKS_API_FIELDS)

  return (
    <FlagsContainer>
      {enabledFlags}
    </FlagsContainer>
  )
}

export default FlagsCell

export const FlagsContainer = styled.div`
  display: flex;
`
