import {
  not,
  isNil,
  isEmpty,
  addIndex,
  map,
  includes,
  sort,
  length,
  pipe,
  lt,
  or, equals,
  propOr
} from 'ramda'

export const isNotNil = value => not(isNil(value))
export const isNotEmpty = value => not(isEmpty(value))
export const isNotNilOrEmpty = value => isNotNil(value) && isNotEmpty(value)
export const isNilOrEmpty = value => or(isNil(value), isEmpty(value))
export const notEquals = (value1) => (value2) => not(equals(value1, value2))
export const mapIndexed = addIndex(map)
export const isInArray = array => item => includes(item, array)
const diff = (a, b) => a.localeCompare(b)
export const sortStringsAsc = arr => sort(diff, arr)
export const lengthIsGreaterThan = number =>
  pipe(
    length,
    lt(number)
  )
export const hasNonEmptyProp = prop =>
  pipe(
    propOr(null, prop),
    isNotNilOrEmpty
  )
