import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateChartDatesSchemaField, validateChartDatesSchemaValues } from 'features/products/ducks/schema'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { getIsChartsLoading } from 'features/products/ducks/selectors'
import { getProductSalePlan } from 'features/salePlans/ducks/selectors'

import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import DatePickerField from 'components/formElements/DatePickerField'
import { formatISODate } from 'utils/date'
import usePrevious from 'hooks/usePrevious'
import { equals, not, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const emptyValues = {
  dateFrom: '',
  dateTo: ''
}

export const CumulativeSaleChartDatesForm = (props) => {
  const { fetchChartRoutine, match: { params: { id } }, enableFuture, setDateRanges } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsChartsLoading)
  const currentSalePlan = useSelector(state => getProductSalePlan(state, id))
  const prevPlan = usePrevious(currentSalePlan)
  const salePlan = useSelector(state => getProductSalePlan(state, id))

  const startDate = propOr('', 'starts_at', salePlan)
  const endDate = propOr('', 'expires_at', salePlan)

  const callRoutineWithDates = useCallback(
    () => dispatch(fetchChartRoutine({
      dateFrom: formatISODate(startDate),
      dateTo: formatISODate(endDate),
      productId: id
    })),
    [dispatch]
  )

  const handleInit = () => {
    callRoutineWithDates()
    setValues({
      dateFrom: formatISODate(startDate),
      dateTo: formatISODate(endDate)
    })
    setDateRanges({
      dateFrom: formatISODate(startDate),
      dateTo: formatISODate(endDate)
    })
  }

  useEffect(() => {
    handleInit()
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(prevPlan) && not(equals(prevPlan, currentSalePlan))) {
      callRoutineWithDates()
    }
  }, [currentSalePlan])

  const handleOnChange = (name, value) => {
    setValues({ ...values, [name]: value })
    setDateRanges({ ...values, [name]: value })
  }

  useEffect(() => {
    validateChartDatesSchemaValues(values, _validateSchema)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    callRoutineWithDates()
  }

  return (
    <Form>
      <FormFieldsContainer>
        <div className='chart-dates__date-picker'>
          <DatePickerField
            value={values.dateFrom}
            maxDate={moment(salePlan.expires_at)}
            minDate={moment(salePlan.starts_at)}
            required
            name='dateFrom'
            label={t('form.dateFrom.label')}
            onChange={handleOnChange}
            validate={validateChartDatesSchemaField(values)}
            dependentValue={values.dateTo}
          />
        </div>
        <div className='chart-dates__date-picker'>
          <DatePickerField
            value={values.dateTo}
            disableFuture={!enableFuture}
            maxDate={moment(salePlan.expires_at)}
            minDate={moment(salePlan.starts_at)}
            required
            name='dateTo'
            label={t('form.dateTo.label')}
            onChange={handleOnChange}
            validate={validateChartDatesSchemaField(values)}
            dependentValue={values.dateFrom}
          />
        </div>
        <div>
          <Button
            type='submit'
            id='button-submit-chart-dates'
            disabled={!valid || isLoading}
            onClick={handleSubmit}
          >
            {t('ordersPage.createShippingOrder.save')}
          </Button>
        </div>
      </FormFieldsContainer>
    </Form>
  )
}

export default withRouter(CumulativeSaleChartDatesForm)

const FormFieldsContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
     flex-direction: column;
     align-items: flex-start;
  }
  
  .chart-dates__date-picker {
    max-width: 350px;
    margin-right: 10px;
  }
  
  .chart-dates__range-picker {
    margin-right: 10px;
  }
`
