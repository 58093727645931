import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { FORMATS, formatISODate, formatDate } from 'utils/date'
import { or, isNil, isEmpty } from 'ramda'
import moment from 'moment'
import { isNotNilOrEmpty } from 'utils/ramda'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {
  KeyboardDatePicker
} from '@material-ui/pickers'

export const CombinedSelectWithInput = (props) => {
  const { label, options, name, onSelectChange, selectValue, onDateChange, dateValue } = props
  const classes = useStyles()

  const renderOptions = options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))

  const handleOnChange = e => onDateChange(formatISODate(e))
  const formattedValue = formatDate(dateValue, FORMATS.slash)

  return (
    <StyledContainer>
      <StyledLabel>
        {label}
      </StyledLabel>
      <div className='picker-container'>
        <Select
          id={`select-${name}`}
          className={classes.textField}
          onChange={onSelectChange}
          value={selectValue}
        >
          {renderOptions}
        </Select>
        <KeyboardDatePicker
          autoOk
          disableFuture
          disabled={or(isNil(selectValue), isEmpty(selectValue))}
          variant='inline'
          format={FORMATS.slash}
          value={isNotNilOrEmpty(dateValue) ? moment(formattedValue, FORMATS.slash) : null}
          onChange={handleOnChange}
          mask='__/__/____'
        />
      </div>
    </StyledContainer>
  )
}

export default CombinedSelectWithInput

const StyledContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  
  .picker-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const StyledLabel = styled.div`
  display: inline-block;
  flex: none;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
`

const useStyles = makeStyles(theme => ({
  control: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  }
}))
