import React from 'react'
import PlainSingleSelect from 'components/atoms/FilterInputs/PlainSingleSelect'
import { getOptionByValue } from 'utils/form'
import styled from 'styled-components'
import { deliveriesRangeOptions } from 'utils/deliveries'
import { useTranslation } from 'react-i18next'

export const DeliveriesDateRangeFilter = (props) => {
  const { value, onChange } = props
  const selectedOption = value => getOptionByValue(value)(deliveriesRangeOptions())
  const { t } = useTranslation()

  const handleChange = option => onChange(option.value)

  return (
    <StyledContainer>
      <div>
        <PlainSingleSelect
          label={t('productPage.deliveries.filters.dateRange.title')}
          variant='outlined'
          options={deliveriesRangeOptions()}
          value={selectedOption(value)}
          onChange={handleChange}
        />
      </div>
    </StyledContainer>
  )
}

export default DeliveriesDateRangeFilter

const StyledContainer = styled.div`
  margin: 20px 0 40px auto;
  text-align: right;
  
  & > div {
    display: inline-block;
    min-width: 300px;
    background-color: ${props => props.theme.palette.common.gray100};
    padding: 0 20px;
    border-radius: ${props => props.theme.shape.borderRadius};
    
    ${props => props.theme.breakpointsMedia.mobile} {
     width: 100%;
     min-width: auto;
    }
  
    & > div {
      display: inline-flex;
      min-width: 300px;
      
      ${props => props.theme.breakpointsMedia.mobile} {
        width: 100%;
        min-width: auto;
      }
    }
  }
`
