import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const Auctions = Record({
  offerId: '',
  createdAt: '',
  updatedAt: '',
  publishedAt: null,
  title: '',
  price: '',
  integration: {
    sellerId: '',
    createdAt: '',
    updatedAt: '',
    isActive: true,
    login: '',
    name: ''
  },
  promotions: {
    promotionPackage: false,
    halfPercent: false,
    emphasized: false,
    ads: false,
    bargain: false
  }
})

export const ProductAuctions = Record({
  state: API_STATES.PRISTINE,
  syncAuctionState: API_STATES.PRISTINE,
  auctions: {}
})
