import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  validateAutoDecisionPriceUpFormField,
  validateAutoDecisionPriceUpFormValues,
  validateAutoDecisionPriceUpFormCurrentValues
} from 'features/flags/ducks/schema'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getPriceUpAmountByUnit,
  unitOptions,
  UNIT_FORMATS,
  getPriceUpUnitByValues,
  getPriceUpAmountByValues,
  convertFromPenniesIfPrice
} from 'utils/flags'
import { convertToPenniesValue } from 'utils/price'

import {
  getPriceUpQty,
  getPriceUpPercentage,
  getPriceUpValue,
  getPriceUpNotSellingThreshold,
  getIsSettingsLoading
} from 'features/settings/ducks/selectors'

import TextField from 'components/formElements/TextField'
import SingleSelectField from 'components/formElements/SingleSelectField'
import Button from 'components/atoms/Button'
import { saveSettingsRoutine } from 'features/settings/ducks/actions'

const emptyValues = {
  unit: UNIT_FORMATS.currency,
  qty: 0,
  amount: 0,
  notSellingThreshold: 0
}

export const AutoDecisionForm = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const priceIncreasePercentage = useSelector(getPriceUpPercentage)
  const priceIncreaseValue = useSelector(getPriceUpValue)
  const priceIncreaseThreshold = useSelector(getPriceUpQty)
  const notSellingThreshold = useSelector(getPriceUpNotSellingThreshold)
  const isLoading = useSelector(getIsSettingsLoading)

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [currentValuesValid, _validateCurrentValues] = useState(false)

  const saveSettings = useCallback(
    payload => dispatch(saveSettingsRoutine(payload)),
    [dispatch]
  )

  const getCurrentValues = () => {
    const amount = getPriceUpAmountByValues(priceIncreasePercentage, priceIncreaseValue)
    const unit = getPriceUpUnitByValues(priceIncreasePercentage)

    return ({
      unit,
      qty: priceIncreaseThreshold,
      amount: convertFromPenniesIfPrice(unit, amount),
      notSellingThreshold
    })
  }

  useEffect(() => {
    validateAutoDecisionPriceUpFormValues(values, _validateSchema)
    validateAutoDecisionPriceUpFormCurrentValues(getCurrentValues(), _validateCurrentValues)
  }, [values, currentValuesValid])

  useEffect(() => {
    const currentValues = getCurrentValues()
    setValues(currentValues)
  }, [priceIncreasePercentage, priceIncreaseValue, priceIncreaseThreshold, notSellingThreshold])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    const priceIncreaseValue = Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.currency)(values.unit))

    saveSettings({
      priceIncreaseThreshold: Number(values.qty),
      priceIncreasePercentage: Number(getPriceUpAmountByUnit(values.amount, UNIT_FORMATS.percentage)(values.unit)),
      priceIncreaseValue: convertToPenniesValue(priceIncreaseValue),
      notSellingThreshold: values.notSellingThreshold
    })
  }

  const handleUnitChange = (name, value) => {
    setValues({
      ...values,
      amount: '',
      [name]: value
    })
  }

  return (
    <form>
      <div>
        <SingleSelectField
          options={unitOptions()}
          required
          name='unit'
          label={t('form.unit.label')}
          validate={validateAutoDecisionPriceUpFormField(values)}
          onChange={handleUnitChange}
          value={values.unit}
        />
        <TextField
          required
          type='number'
          name='amount'
          label={`${t('form.priceIncreaseAmount.label')} ${values.unit}`}
          onChange={handleOnChange}
          validate={validateAutoDecisionPriceUpFormField(values)}
          value={values.amount}
        />
        <TextField
          required
          type='number'
          name='qty'
          label={t('form.quantityThreshold.label')}
          onChange={handleOnChange}
          validate={validateAutoDecisionPriceUpFormField(values)}
          value={values.qty}
        />
        <TextField
          required
          type='number'
          name='notSellingThreshold'
          label={t('form.notSellingThreshold.label')}
          onChange={handleOnChange}
          validate={validateAutoDecisionPriceUpFormField(values)}
          value={values.notSellingThreshold}
        />
      </div>
      <ButtonContainer>
        <Button
          type='submit'
          id='button-submit'
          disabled={!valid || isLoading}
          onClick={handleSubmit}
        >
          {t('settingsPage.autoDecisionSettings.saveSettingsCTA')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

export default withRouter(AutoDecisionForm)

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
