import React from 'react'

import Button from 'components/atoms/Button'
import AddAllegro from 'components/icons/AddAllegro'

const hrefLink = `https://allegro.pl/auth/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ALLEGRO_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ALLEGRO_AUTH_REDIRECT_URI}`

export const AddAllegroAccount = (props) => {
  return (
    <a href={hrefLink} rel='noopener noreferrer'>
      <Button
        startIcon={<AddAllegro />}
      >
        Dodaj konto allegro
      </Button>
    </a>
  )
}

export default AddAllegroAccount
