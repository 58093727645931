import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SEVERITY } from 'utils/toast'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'

import { getAllIntegrationsConnectionStatusRoutine } from 'features/integrations/ducks/actions'
import { getHasAnyConnectionError } from 'features/integrations/ducks/selectors'

import Snackbar from 'components/atoms/Snackbar'
import Alert from 'components/atoms/Alert'

export const AllIntegrationsConnectionStatusNotification = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const getAllIntegrationsConnectionStatus = useCallback(
    () => dispatch(getAllIntegrationsConnectionStatusRoutine()),
    [dispatch]
  )

  useEffect(() => {
    getAllIntegrationsConnectionStatus()
    history.listen(getAllIntegrationsConnectionStatus)
  }, [])

  const hasAnyConnectionError = useSelector(getHasAnyConnectionError)
  const handleCtaClick = () => history.push(PATHS.integrations)

  return hasAnyConnectionError ? (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open
    >
      <Alert
        severity={SEVERITY.error}
        variant='filled'
      >
        <StyledConnectionStatusNotificationMessage>
          <div className='connection-status-notification__message'>
            {t('integrationsPage.connectionStatus.message')}
          </div>
          <div onClick={handleCtaClick} className='connection-status-notification__cta'>
            {t('integrationsPage.connectionStatus.cta')}
          </div>
        </StyledConnectionStatusNotificationMessage>
      </Alert>
    </Snackbar>
  ) : ''
}

export default AllIntegrationsConnectionStatusNotification

const StyledConnectionStatusNotificationMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .connection-status-notification__message {}
  
  .connection-status-notification__cta {
    margin-left: 20px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
`
