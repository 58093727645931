import { WeightSchema, WeightSchemas } from 'features/weightSchemas/ducks/records'
import { fetchWeightSchemasRoutine, createWeightSchemaRoutine, deleteWeightSchemaRoutine } from 'features/weightSchemas/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new WeightSchemas(), action) => {
  switch (action.type) {
    case fetchWeightSchemasRoutine.REQUEST:
    case createWeightSchemaRoutine.REQUEST:
    case deleteWeightSchemaRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchWeightSchemasRoutine.FAILURE:
    case createWeightSchemaRoutine.FAILURE:
    case deleteWeightSchemaRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchWeightSchemasRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('weightSchemas', transferCollectionToImmutable(action.payload.data, WeightSchema))
    default:
      return state
  }
}
