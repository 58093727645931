import { propOr, pathOr, pipe, equals } from 'ramda'
import { createSelector } from 'reselect'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { isNotNilOrEmpty } from 'utils/ramda'
import { API_STATES } from 'utils/redux'

export const selectAuth = state => state.auth

export const isAuthLoaded = createSelector(
  selectAuth,
  pipe(
    pathOr('', ['user', 'id']),
    isNotNilOrEmpty
  )
)

export const getIsAuthorised = () =>
  isNotNilOrEmpty(LocalStorageService.get(LOCAL_STORAGE_KEYS.token))

export const getIsAuthLoading = createSelector(
  selectAuth,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getAuthUser = createSelector(
  selectAuth,
  propOr({}, 'user')
)

export const getUserRole = createSelector(
  selectAuth,
  pathOr('', ['user', 'role'])
)

export const getFailedLoginAttempts = createSelector(
  selectAuth,
  propOr(0, 'failedLoginAttempts')
)

export const getUserLogin = createSelector(
  selectAuth,
  pathOr('', ['user', 'login'])
)

export const getUserId = createSelector(
  selectAuth,
  pathOr('', ['user', 'id'])
)

export const getUsersHiddenColumns = createSelector(
  selectAuth,
  pathOr([], ['user', 'settings', 'hiddenColumns'])
)
