import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'

import { getSaleChannelsArray, getSaleChannelsMeta, getIsSaleChannelsLoading } from 'features/saleChannels/ducks/selectors'
import { fetchSaleChannelsRoutine } from 'features/saleChannels/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import CreateSaleChannelModal from 'features/saleChannels/components/CreateSaleChannelModal'
import EditSaleChannelModal from 'features/saleChannels/components/EditSaleChannelModal'
import DeleteSaleChannelButton from 'features/saleChannels/DeleteSaleChannelButton'

export const SaleChannelsTable = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(getSaleChannelsArray)
  const isLoading = useSelector(getIsSaleChannelsLoading)
  const pagination = useSelector(getSaleChannelsMeta)

  const fetchSaleChannels = useCallback(
    query => dispatch(fetchSaleChannelsRoutine(query)),
    [dispatch]
  )

  const headers = [
    {
      id: 'name',
      sortable: false,
      cell: t('saleChannelsPage.table.headers.name')
    },
    {
      id: 'actions',
      sortable: false,
      cell: '',
      align: 'right'
    }
  ]

  const renderItems = data.map((data, index) => (
    <TableRow key={propOr(`sale-channel-${index}`, 'id', data)}>
      <TableCell>
        {propOr('', 'name', data)}
      </TableCell>
      <TableCell>
        <ActionsCell>
          <EditSaleChannelModal data={data} />
          <DeleteSaleChannelButton data={data} />
        </ActionsCell>
      </TableCell>
    </TableRow>
  ))

  return (
    <EntitiesList
      emptyStateText={t('saleChannelsPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      isLoading={isLoading}
      fetchRecords={fetchSaleChannels}
      defaultSortedColumnId='name'
      additionalActionButtons={(
        <div>
          <CreateSaleChannelModal />
        </div>
      )}
    />
  )
}

export default SaleChannelsTable

const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
