import React, { useEffect, useState } from 'react'

import SwitchField from 'components/formElements/SwitchField'
import styled from 'styled-components'

export const AllegroPromotionSwitch = (props) => {
  const { label, name, icon: Icon, productId, isSelected, handleChange } = props

  const [switchValue, setSwitchValue] = useState(false)

  useEffect(() => {
    setSwitchValue(isSelected)
  }, [isSelected])

  const handleSwitchChange = (name, value) => {
    setSwitchValue(value)
    handleChange({ value, name, productId })
  }

  return (
    <SwitchField
      value={switchValue}
      label={(
        <LabelContainer>
          <div className='label-icon'><Icon /></div>&nbsp;
          {label}
        </LabelContainer>
      )}
      name={name}
      onChange={handleSwitchChange}
    />
  )
}

export default AllegroPromotionSwitch

const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  .label-icon {
    font-size: 20px;
  }
`
