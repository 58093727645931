import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddTagForm from 'features/tags/AddTagForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Pallet from 'components/icons/Pallet'

export const AddTagModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button startIcon={<Pallet />} onClick={handleOpen}>{t('tagsPage.addTag.addTagTitle')}</Button>
      <Modal
        title={t('tagsPage.addTag.addTagTitle')}
        open={open}
        handleClose={handleClose}
      >
        <AddTagForm onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default AddTagModal
