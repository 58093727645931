import { string, object, array } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Assign Products to weight schema form validator

export const assignProductsToWeightSchemaSchema = object().shape({
  id: string()
    .required(() => ({ key: 'form.weightSchema.errors.required' })),
  productIds: array().required()
})

export const validateAssignProductsToWeightSchemaField = values => validateField(assignProductsToWeightSchemaSchema, values)
export const validateAssignProductsToWeightSchemaValues = validateValues(assignProductsToWeightSchemaSchema)

// Create/Edit weight schema form validator

export const createWeightSchema = object().shape({
  name: string()
    .required(() => ({ key: 'form.weightSchemaName.errors.required' }))
    .min(3, () => ({
      key: 'form.weightSchemaName.errors.lengthMin',
      options: { number: 3 }
    }))
    .max(50, () => ({
      key: 'form.weightSchemaName.errors.lengthMax',
      options: { number: 50 }
    }))
})

export const validateCreateWeightSchemaField = values => validateField(createWeightSchema, values)
export const validateCreateWeightSchemaValues = validateValues(createWeightSchema)
