import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersHiddenColumns } from 'features/auth/ducks/selectors'
import { updateUserSettingsRoutine } from 'features/auth/ducks/actions'
import { reactToItemSelection } from 'utils/array'
import { MONITORING_COLUMNS, replaceDotsWithDashes } from 'utils/settings'

import Popover from 'components/atoms/Popover'
import FormControlLabel from 'components/atoms/FormControlLabel'
import Checkbox from 'components/atoms/Checkbox'
import styled from 'styled-components'
import { includes, not } from 'ramda'
import { useTranslation } from 'react-i18next'
import Settings from 'components/icons/Settings'
import IconButton from 'components/atoms/IconButton'
import { getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'
import { API_FIELD_NAME } from 'utils/apiFilterFields'

export const MonitoringColumnsVisibilitySettings = (props) => {
  const { t } = useTranslation()
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const hiddenColumns = useSelector(getUsersHiddenColumns)
  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)
  const dispatch = useDispatch()

  const updateUserHiddenColumns = useCallback(
    payload => dispatch(updateUserSettingsRoutine(payload)),
    [dispatch]
  )

  const toggleColumnVisibility = columnId => () => {
    const newState = reactToItemSelection(columnId)(hiddenColumns)
    updateUserHiddenColumns({ hiddenColumns: newState })
  }

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const renderColumnSelections = MONITORING_COLUMNS.map((col) => {
    const soldShortParams = [replaceDotsWithDashes(API_FIELD_NAME.soldShort), replaceDotsWithDashes(API_FIELD_NAME.willLastShort)]
    const soldLongParams = [replaceDotsWithDashes(API_FIELD_NAME.soldLong), replaceDotsWithDashes(API_FIELD_NAME.willLastLong)]

    const translationParams = () => {
      switch (true) {
        case includes(col.id, soldShortParams):
          return { count: soldShortValue }
        case includes(col.id, soldLongParams):
          return { count: soldLongValue }
        default:
          return {}
      }
    }

    return (
      <div key={`column-visibility-setting-${col.id}`}>
        <FormControlLabel
          control={
            <Checkbox
              checked={not(includes(col.id, hiddenColumns))}
              onChange={toggleColumnVisibility(col.id)}
              name={col.id}
            />
          }
          label={t(col.translationKey, translationParams())}
        />
      </div>
    )
  })

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <Settings />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <PopoverLabel>
          {t('monitoringPage.table.hideColumns')}:
        </PopoverLabel>
        <PopoverContainer>
          {renderColumnSelections}
        </PopoverContainer>
      </Popover>
    </div>
  )
}

export default MonitoringColumnsVisibilitySettings

export const PopoverContainer = styled.div`
  padding: 0 20px 20px;
  min-width: 100px;
  max-height: calc(100vh - 500px);
  overflow: hidden;
  overflow-y: auto;
`

export const PopoverLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding: 10px 20px;
`
