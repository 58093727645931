import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

export const SingleSelect = props => {
  const {
    variant,
    options,
    name,
    required,
    disabled,
    label,
    value,
    onChange,
    onFocus,
    onBlur,
    error,
    errorText
  } = props

  const classes = useSelectStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [label])

  const renderOptions = options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))

  return (
    <SelectContainer>
      <FormControl className={classes.control} variant={variant} required={required} error={error} disabled={disabled}>
        <InputLabel ref={inputLabel} id={name}>
          {label}
        </InputLabel>
        <Select
          id={`select-${name}`}
          className={classes.textField}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          labelId={name}
          value={value}
          labelWidth={labelWidth}
        >
          {renderOptions}
        </Select>
        <FormHelperText>
          {errorText}
        </FormHelperText>
      </FormControl>
    </SelectContainer>
  )
}

SingleSelect.defaultProps = {
  variant: 'outlined'
}

export default SingleSelect

const SelectContainer = styled.div`
  margin: 10px 0;
  width: 100%;
`

const useSelectStyles = makeStyles(theme => ({
  control: {
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%'
  }
}))
