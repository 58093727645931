import React from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'

export const ProductThumbnail = props => {
  const { product } = props
  return <ThumbnailContainer imageSrc={propOr('', 'photoUrl')(product)} />
}

export default ProductThumbnail

export const ThumbnailContainer = styled.div`
  background-image: url(${props => props.imageSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background-color: ${props => props.theme.palette.constants.white};
  color: ${props => props.theme.palette.secondary.main};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: ${props => props.theme.shadows.main};
`
