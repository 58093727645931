import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ALERT_TYPES, geDifferenceAlertUnit,
  getAlertUnit,
  isAlertSeen,
  prepareFetchAlertsCurrentQuery
} from 'utils/alerts'
import { formatDate, FORMATS } from 'utils/date'
import { Link, useHistory } from 'react-router-dom'
import useSelectionSaga from 'hooks/useSelectionSaga'
import { equals, pathOr, propOr, not } from 'ramda'

import { fetchAlertsRoutine } from 'features/alerts/ducks/actions'
import { getIsAlertsLoading, getAlertsArray, getAlertsMeta } from 'features/alerts/ducks/selectors'
import { getHasSomeItemsSelectedGlobally } from 'ducks/global/selectors'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import Price from 'components/icons/Price'
import PackageChecked from 'components/icons/PackageChecked'
import FlagsCell from 'features/monitoring/components/FlagsCell'
import NewAlertRowNotification from 'features/alerts/components/NewAlertRowNotification'
import ArchiveAlertButton from 'features/alerts/components/ArchiveAlertButton'
import AlertsOrdersFilters from 'features/alerts/components/AlertsOrdersFilters'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { getUserLogin, getUserRole } from 'features/auth/ducks/selectors'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import CopyToClipboard from 'components/CopyToClipboard'
import Checkbox from 'components/atoms/Checkbox'
import SelectedAlertsState from 'features/alerts/components/SelectedAlertsState'
import Transit from 'components/icons/Transit'
import New from 'components/icons/New'
import { USER_ROLES } from 'utils/user'

export const AlertsOrders = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const data = useSelector(getAlertsArray)
  const pagination = useSelector(getAlertsMeta)
  const isLoading = useSelector(getIsAlertsLoading)
  const currentAlertTypes = [ALERT_TYPES.priceChange, ALERT_TYPES.stockChange, ALERT_TYPES.priceStockChange]
  const urlSearchQuery = useUrlSearchQuery()
  const userLogin = useSelector(getUserLogin)
  const userRole = useSelector(getUserRole)
  const hasSomeItemsSelectedGlobally = useSelector(getHasSomeItemsSelectedGlobally)
  const isNotFilteredByUserLogin = urlSearchQuery.hasSearchQuery(API_FIELD_NAME.userLogin) === false
  const visibleItemsIds = data.map(data => data.id)
  const {
    hasSomeItemSelected,
    hasAllVisibleItemsSelected,
    isItemSelected,
    handleSelectItem,
    handleSelectCollection
  } = useSelectionSaga(visibleItemsIds)

  const isAdmin = equals(userRole, USER_ROLES.admin)

  const adminColumns = isAdmin ? [
    {
      id: API_FIELD_NAME.isAlertArchived,
      sortable: false,
      cell: t('alertsPage.table.headers.status')
    },
    {
      id: 'archivedAt',
      sortable: false,
      cell: t('alertsPage.table.headers.archivedAt')
    },
    {
      id: 'archivedBy',
      sortable: false,
      cell: t('alertsPage.table.headers.archivedBy')
    }
  ] : []

  const adminFilters = isAdmin ? { [API_FIELD_NAME.isAlertArchived]: false } : {}

  useEffect(() => {
    if (userLogin && isNotFilteredByUserLogin) {
      urlSearchQuery.setSearchQuery({
        [API_FIELD_NAME.alertsUserLogin]: userLogin,
        ...adminFilters
      })
    }
  }, [userLogin])

  const fetchOrderAlerts = useCallback(
    query => dispatch(
      fetchAlertsRoutine(
        prepareFetchAlertsCurrentQuery(query, currentAlertTypes)
      )),
    [dispatch]
  )

  const headers = [
    {
      id: 'new',
      sortable: false,
      cell: ''
    },
    {
      id: 'type',
      sortable: false,
      cell: t('alertsPage.table.headers.type')
    },
    {
      id: 'createdAt',
      sortable: false,
      cell: t('alertsPage.table.headers.createdAt')
    },
    {
      id: 'ean',
      sortable: false,
      cell: t('alertsPage.table.headers.ean')
    },
    {
      id: 'name',
      sortable: false,
      cell: t('alertsPage.table.headers.name')
    },
    {
      id: 'stockBefore',
      sortable: false,
      cell: t('alertsPage.table.headers.stockBefore')
    },
    {
      id: 'stockAfter',
      sortable: false,
      cell: t('alertsPage.table.headers.stockAfter')
    },
    {
      id: 'stockDifference',
      sortable: false,
      cell: t('alertsPage.table.headers.difference')
    },
    {
      id: 'priceBefore',
      sortable: false,
      cell: t('alertsPage.table.headers.priceBefore')
    },
    {
      id: 'priceAfter',
      sortable: false,
      cell: t('alertsPage.table.headers.priceAfter')
    },
    {
      id: 'priceDifference',
      sortable: false,
      cell: t('alertsPage.table.headers.difference')
    },
    {
      id: 'flags',
      sortable: false,
      cell: t('alertsPage.table.headers.flags')
    },
    ...adminColumns,
    {
      id: 'selectAll',
      sortable: false,
      align: 'right',
      cell: (
        <Checkbox
          indeterminate={hasSomeItemSelected}
          checked={hasAllVisibleItemsSelected}
          onChange={handleSelectCollection}
        />
      )
    }
  ]

  const getTypeIcon = data => {
    switch (data.type) {
      case ALERT_TYPES.priceChange:
        return <Price />
      case ALERT_TYPES.stockChange:
        return <PackageChecked />
      case ALERT_TYPES.priceStockChange:
        return <Transit />
      case ALERT_TYPES.newProduct:
        return <New />
      default:
        return ''
    }
  }

  const getStockBefore = data => {
    switch (data.type) {
      case ALERT_TYPES.stockChange:
        return (
          <span>
            {pathOr('-', ['content', 'before'], data)}&nbsp;
            {getAlertUnit(data)}
          </span>
        )
      case ALERT_TYPES.priceStockChange:
        return (
          <span>
            {pathOr('-', ['content', 'stockBefore'], data)}&nbsp;
            szt.
          </span>
        )
      default:
        return '-'
    }
  }

  const getStockAfter = data => {
    switch (data.type) {
      case ALERT_TYPES.stockChange:
        return (
          <span>
            {pathOr('-', ['content', 'after'], data)}&nbsp;
            {getAlertUnit(data)}
          </span>
        )
      case ALERT_TYPES.priceStockChange:
        return (
          <span>
            {pathOr('-', ['content', 'stockAfter'], data)}&nbsp;
            szt.
          </span>
        )
      case ALERT_TYPES.newProduct:
        return (
          <span>
            {pathOr('-', ['content', 'quantity'], data)}&nbsp;
            szt.
          </span>
        )
      default:
        return '-'
    }
  }

  const getStockDifference = data => {
    switch (data.type) {
      case ALERT_TYPES.stockChange:
        return (
          <span>
            {pathOr('-', ['content', 'difference'], data)}&nbsp;
            {geDifferenceAlertUnit(data)}
          </span>
        )
      case ALERT_TYPES.priceStockChange:
        return (
          <span>
            {pathOr('-', ['content', 'stockDifference'], data)}&nbsp;
            szt.
          </span>
        )
      default:
        return '-'
    }
  }

  const getPriceBefore = data => {
    switch (data.type) {
      case ALERT_TYPES.priceChange:
        return (
          <span>
            {pathOr('-', ['content', 'before'], data)}&nbsp;
            {getAlertUnit(data)}
          </span>
        )
      case ALERT_TYPES.priceStockChange:
        return (
          <span>
            {pathOr('-', ['content', 'priceBefore'], data)}&nbsp;
            zł
          </span>
        )
      default:
        return '-'
    }
  }

  const getPriceAfter = data => {
    switch (data.type) {
      case ALERT_TYPES.priceChange:
        return (
          <span>
            {pathOr('-', ['content', 'after'], data)}&nbsp;
            {getAlertUnit(data)}
          </span>
        )
      case ALERT_TYPES.priceStockChange:
        return (
          <span>
            {pathOr('-', ['content', 'priceAfter'], data)}&nbsp;
            zł.
          </span>
        )
      case ALERT_TYPES.newProduct:
        return (
          <span>
            {pathOr('-', ['content', 'priceNet'], data)}&nbsp;
            zł.
          </span>
        )
      default:
        return '-'
    }
  }

  const getPriceDifference = data => {
    switch (data.type) {
      case ALERT_TYPES.priceChange:
        return (
          <span>
            {pathOr('-', ['content', 'difference'], data)}&nbsp;
            {geDifferenceAlertUnit(data)}
          </span>
        )
      case ALERT_TYPES.priceStockChange:
        return (
          <span>
            {pathOr('-', ['content', 'priceDifference'], data)}&nbsp;
            %
          </span>
        )
      default:
        return '-'
    }
  }

  const getStatus = isArchived => isArchived ? t('alertsPage.statuses.archived') : t('alertsPage.statuses.active')

  const renderItems = data.map(data => {
    const isNew = !isAlertSeen(data)

    return (
      <TableRow checked={isItemSelected(data.id)} hover key={`alert-row-${data.id}`}>
        <TableCell width='1%'>
          <IconContainer>
            {isNew && <NewAlertRowNotification id={data.id} />}
          </IconContainer>
        </TableCell>
        <TableCell width='2%'>
          <IconContainer>
            {getTypeIcon(data)}
          </IconContainer>
        </TableCell>
        <TableCell width='150px'>
          <CellContainer isNew={isNew}>
            {formatDate(data.created_at, FORMATS.slashTime)}
          </CellContainer>
        </TableCell>
        <TableCell width='5%'>
          <CellContainer isNew={isNew}>
            <CopyToClipboard textToCopy={propOr('', 'ean', data)}>
              {data.type === ALERT_TYPES.newProduct ? pathOr('-', ['content', 'ean'], data) : propOr('', 'ean', data)}
            </CopyToClipboard>
          </CellContainer>
        </TableCell>
        <TableCell width='30%'>
          <CellContainer isNew={isNew}>
            {
              data.type === ALERT_TYPES.newProduct ? pathOr('-', ['content', 'name'], data) : (
                <Link
                  to={{
                    pathname: `/product/${data.product_id}`,
                    state: {
                      from: `${history.location.pathname}${history.location.search}`
                    }
                  }}
                >
                  {propOr('-', 'name', data)}
                </Link>
              )
            }
          </CellContainer>
        </TableCell>
        <TableCell width='8%'>
          <CellContainer isNew={isNew}>
            {getStockBefore(data)}
          </CellContainer>
        </TableCell>
        <TableCell width='8%'>
          <CellContainer isNew={isNew}>
            {getStockAfter(data)}
          </CellContainer>
        </TableCell>
        <TableCell width='8%'>
          <CellContainer isNew={isNew}>
            {getStockDifference(data)}
          </CellContainer>
        </TableCell>
        <TableCell width='8%'>
          <CellContainer isNew={isNew}>
            {getPriceBefore(data)}
          </CellContainer>
        </TableCell>
        <TableCell width='8%'>
          <CellContainer isNew={isNew}>
            {getPriceAfter(data)}
          </CellContainer>
        </TableCell>
        <TableCell width='8%'>
          <CellContainer isNew={isNew}>
            {getPriceDifference(data)}
          </CellContainer>
        </TableCell>
        <TableCell>
          <CellContainer isNew={isNew}>
            <FlagsCell byName data={data} />
          </CellContainer>
        </TableCell>
        {
          isAdmin && (
            <>
              <TableCell>
                {getStatus(propOr(false, 'is_archived', data))}
              </TableCell>
              <TableCell>
                {data.archived_at ? formatDate(data.archived_at, FORMATS.slashTime) : '-'}
              </TableCell>
              <TableCell>
                {propOr('-', 'archived_by_user', data)}
              </TableCell>
            </>
          )
        }
        <TableCell width='2%' align='right'>
          {
            not(propOr(false, 'is_archived', data)) && (
              <Checkbox
                checked={isItemSelected(data.id)}
                onChange={handleSelectItem(data.id, data)}
              />
            )
          }
        </TableCell>
      </TableRow>
    )
  })

  return (
    <EntitiesList
      emptyStateText={t('alertsPage.table.emptyState')}
      headers={headers}
      pagination={pagination}
      tableRows={renderItems}
      fetchRecords={fetchOrderAlerts}
      isLoading={isLoading}
      filters={<AlertsOrdersFilters />}
      hasSomeItemSelected={hasSomeItemsSelectedGlobally}
      selectionActions={
        <>
          <SelectedAlertsState />
          <SelectedItemsActionsContainer>
            <ArchiveAlertButton types={currentAlertTypes} />
          </SelectedItemsActionsContainer>
        </>
      }
    />
  )
}

export default AlertsOrders

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 10px 0;
`

const CellContainer = styled.div`
  font-weight: ${props => props.isNew ? '500' : '400'};
`

const SelectedItemsActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
