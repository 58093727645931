import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { editAllegroAccountRoutine } from 'features/allegro/ducks/actions'
import { validateEditAllegroAccountField, validateEditAllegroAccountValues } from 'features/allegro/ducks/schema'

import TextField from 'components/formElements/TextField'
import IconButton from 'components/atoms/IconButton'
import Success from 'components/icons/Success'
import Close from 'components/icons/Close'

const emptyValues = {
  name: '',
  id: ''
}

export const AllegroAccountEditNameForm = (props) => {
  const { onSubmit, onCancel, data } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const editAccountName = useCallback(
    payload => dispatch(editAllegroAccountRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateEditAllegroAccountValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ ...values, id: data.id, name: data.name })
  }, [])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    editAccountName({
      values,
      callback: onSubmit
    })
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextInputContainer>
        <TextField
          value={values.name}
          required
          name='name'
          label={t('form.name.label')}
          onChange={handleOnChange}
          validate={validateEditAllegroAccountField(values)}
        />
      </TextInputContainer>
      <IconButtonContainer>
        <IconButton size='small' onClick={onCancel}>
          <Close />
        </IconButton>
      </IconButtonContainer>
      <IconButtonContainer>
        <IconButton size='small' disabled={!valid} onClick={handleSubmit}>
          <StyledSuccess valid={valid} />
        </IconButton>
      </IconButtonContainer>
    </StyledForm>
  )
}

export default AllegroAccountEditNameForm

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`

const TextInputContainer = styled.div`
  max-width: 200px;
`

const IconButtonContainer = styled.div`
  margin-left: 10px;
  flex: none;
`

const StyledSuccess = styled(Success)`
  color: ${props => props.valid ? props.theme.palette.primary.main : props.theme.palette.common.gray400};
`
