import { Record } from 'immutable'
import { API_STATES } from 'utils/redux'

export const ProductFlag = Record({
  name: '',
  definition: {}
})

export const ProductFlags = Record({
  state: API_STATES.PRISTINE,
  flags: {}
})
