import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  deleteSaleChannelRoutine
} from 'features/saleChannels/ducks/actions'

import DeleteWithConfirm from 'components/DeleteWithConfirm'

export const DeleteSaleChannelButton = (props) => {
  const { data } = props
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleDelete = useCallback(
    payload => dispatch(deleteSaleChannelRoutine(payload)),
    [dispatch]
  )

  const handleSubmit = id => handleDelete(id)

  return (
    <DeleteWithConfirm
      text={t('saleChannelsPage.deleteSaleChannel.text')}
      submitText={t('saleChannelsPage.deleteSaleChannel.submitCTA')}
      cancelText={t('saleChannelsPage.deleteSaleChannel.cancelCTA')}
      id={data.id}
      onSubmit={handleSubmit}
    />
  )
}

export default DeleteSaleChannelButton
