import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  validateUpdateRetailPriceValues,
  validateUpdateRetailPriceField
} from 'features/products/ducks/schema'
import { calculateMargin } from 'utils/price'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { CURRENCY_SYMBOL } from 'utils/general'
import qs from 'qs'
import { COMMENTS_PAGE_COUNT } from 'utils/comments'
import { SORT_ORDER } from 'utils/table'
import { SECTION_IDS, isSectionExpanded } from 'utils/productPage'

import {
  updateRetailPriceRoutine,
  getProductRoutine,
  fetchProductBundlesRoutine
} from 'features/products/ducks/actions'
import { fetchProductCommentsRoutine } from 'features/comments/ducks/actions'
import { getProductRetailPrice, getIsProductLoading, getProductPurchasePrice } from 'features/products/ducks/selectors'

import TextField from 'components/formElements/TextField'
import Popover from 'components/atoms/Popover'
import Button from 'components/atoms/Button'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'

const emptyValues = {
  retailPrice: 0,
  id: ''
}

export const UpdateRetailPriceForm = (props) => {
  const urlSearchQuery = useUrlSearchQuery()
  const { onSubmit } = props
  const id = pathOr('', ['match', 'params', 'id'])(props)
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const retailPrice = useSelector(state => getProductRetailPrice(state, id))
  const purchasePrice = useSelector(state => getProductPurchasePrice(state, id))
  const isLoading = useSelector(getIsProductLoading)
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? id : undefined

  const updateRetailPrice = useCallback(
    payload => dispatch(updateRetailPriceRoutine(payload)),
    [dispatch]
  )

  const getProduct = useCallback(
    payload => dispatch(getProductRoutine(payload)),
    [dispatch]
  )

  const fetchProductComments = useCallback(
    payload => dispatch(fetchProductCommentsRoutine(payload)),
    [dispatch]
  )

  const fetchProductBundles = useCallback(
    payload => dispatch(fetchProductBundlesRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    validateUpdateRetailPriceValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    setValues({ retailPrice, id })
  }, [id, retailPrice])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    showConfirmation ? handleOpen(e) : handleSubmitForm()
  }

  const handleCallback = () => {
    handleClose()
    onSubmit()
    getProduct({ id })
    isSectionExpanded(SECTION_IDS.comments, urlSearchQuery.getSearchQuery()) && fetchProductComments({
      id,
      query: qs.stringify({
        limit: {
          page: 1,
          take: COMMENTS_PAGE_COUNT
        },
        order: {
          by: 'created_at',
          dir: SORT_ORDER.desc
        }
      })
    })

    isSectionExpanded(SECTION_IDS.bundles, urlSearchQuery.getSearchQuery()) && fetchProductBundles({ id })
  }

  const handleSubmitForm = () => updateRetailPrice({ values, callback: handleCallback })

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const deviation = ((values.retailPrice * 100) / retailPrice) - 100
  const showConfirmation = Math.abs(deviation) >= 20

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('productPage.retailPrice.confirmation.content', { currentPrice: retailPrice, nextPrice: values.retailPrice })}
        <ConfirmationButtonsContainer>
          <Button disabled={isLoading || !valid || retailPrice === values.retailPrice} onClick={handleSubmitForm} variant='outlined'>
            {t('productPage.retailPrice.confirmation.submitCTA')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            {t('productPage.retailPrice.confirmation.cancelCTA')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FlexContainer>
        <TextField
          required
          type='number'
          value={values.retailPrice}
          name='retailPrice'
          label={t('form.retailPrice.label')}
          onChange={handleOnChange}
          validate={validateUpdateRetailPriceField(values)}
        />
        <CalculatorContainer>
          <CalculatedValueContainer>
            <div className='calculated-value__label'>
              {t('monitoringPage.table.headers.purchasePrice')}
            </div>
            {purchasePrice}{CURRENCY_SYMBOL}
          </CalculatedValueContainer>
          <CalculatedValueContainer>
            <div className='calculated-value__label'>
              {t('monitoringPage.table.headers.margin')}
            </div>
            {isNotNilOrEmpty(values.retailPrice) && calculateMargin(values.retailPrice, purchasePrice)}%
          </CalculatedValueContainer>
        </CalculatorContainer>
      </FlexContainer>
      <SubmitButtonsContainer>
        <Button
          type='button'
          id='button-cancel'
          disabled={isLoading || !valid}
          onClick={onSubmit}
          variant='outlined'
        >
          {t('productPage.retailPrice.cancel')}
        </Button>
        <Button
          type='submit'
          id='button-submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('productPage.retailPrice.submitCTA')}
        </Button>
      </SubmitButtonsContainer>
      {ConfirmationPopup}
    </StyledForm>
  )
}

export default withRouter(UpdateRetailPriceForm)

const StyledForm = styled.form`
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ConfirmationContainer = styled.div`
  padding: 20px;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`

const CalculatedValueContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 14px;
  font-size: 12px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-left: 0;
    margin-right: 20px;
  }
  
  .calculated-value__label {
    font-weight: 700;
    margin-bottom: 2px;
  }
  
  & + & {
    border-left: 1px solid ${props => props.theme.palette.common.gray300};
  }
`

const CalculatorContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  width: 100%;
  border: 1px solid ${props => props.theme.palette.common.gray300};
  border-radius: ${props => props.theme.shape.borderRadius};
`

const SubmitButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button + button {
    margin-left: 10px;
  }
`
