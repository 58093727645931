import React from 'react'
import Icon from 'components/Icon'

export const PriceUp = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          d='m316.5625 361.152344c0 16.152344-6.390625 31.492187-17.992188 43.199218-6.664062 6.726563-14.53125 11.714844-23.066406 14.765626v30.796874h-40.054687v-30.84375c-23.621094-8.570312-41.058594-31.734374-41.058594-58.148437h40.058594c0 11.523437 9.78125 21.628906 20.929687 21.628906 11.253906 0 21.128906-9.996093 21.128906-21.394531 0-8.699219-9.558593-14.628906-29.847656-25.203125l-.535156-.28125c-17.476562-9.109375-46.726562-24.355469-46.726562-61.691406 0-22.335938 14.71875-42.035157 35.050781-50.277344v-33.152344h40.054687v32.394531c21.023438 7.636719 36.050782 27.492188 36.050782 50.722657h-40.054688c0-7.601563-6.925781-14.019531-15.121094-14.019531-8.332031 0-15.925781 6.832031-15.925781 14.332031 0 11.160156 6.289063 16.320312 25.183594 26.171875l.539062.277344c11.128907 5.800781 22.636719 11.800781 32.035157 20.078124 12.839843 11.3125 19.351562 24.988282 19.351562 40.644532zm-60.921875-361.152344-255.640625 255.640625h115.28125v106.148437h40.054688v-146.203124h-58.632813l158.9375-158.9375 158.9375 158.9375h-58.960937v146.203124h40.054687v-106.148437h115.609375zm-140.359375 512h40.054688v-40.054688h-40.054688zm240.335938 0h40.054687v-40.054688h-40.054687zm-240.335938-70.097656h40.054688v-50.070313h-40.054688zm240.335938 0h40.054687v-50.070313h-40.054687zm0 0'
        />
      </g>
    </Icon>
  )
}

export default PriceUp
