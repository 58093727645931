import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getSelectedTheme } from 'ducks/global/selectors'

import Checkmark from 'components/icons/Checkmark'
import Warning from 'components/icons/Warning'
import IntegrationRefreshButton from 'features/integrations/components/IntegrationRefreshButton'
import Loader from 'components/atoms/Loader'

export const IntegrationAccount = (props) => {
  const { handleRefresh, name, isActive, details, loading } = props
  const selectedThemeMode = useSelector(getSelectedTheme)

  const icon = isActive ? <StyledCheckmark /> : <StyledWarning />

  const actionsStatic = (
    <>
      <div className='integration-account__icon'>
        {icon}
      </div>
      {
        !isActive && (
          <div className='integration-account__refresh-button'>
            <IntegrationRefreshButton handleRefresh={handleRefresh} />
          </div>
        )
      }
    </>
  )

  const actionsLoading = (
    <div className='integration-account__loader'>
      <Loader size={20} />
    </div>
  )

  return (
    <IntegrationAccountContainer themeMode={selectedThemeMode} accountActive={isActive}>
      <div className='integration-account__name'>
        {name}
      </div>
      {
        details && (
          <div className='integration-account__details'>
            {details}
          </div>
        )
      }
      <div className='integration-account__description'>
        {
          loading ? actionsLoading : actionsStatic
        }
      </div>
    </IntegrationAccountContainer>
  )
}

export default IntegrationAccount

const IntegrationAccountContainer = styled.div`
  padding: ${props => props.formVisible ? '0px 20px' : '16px 20px'};
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
     flex-direction: column;
     align-items: flex-start;
  }
  
  &+& {
    border-top: 1px solid ${props => props.theme.palette.common.gray300};
  }
  
  &:hover {
    background-color: ${props => props.theme.palette.common.gray100};
  }
  
  .integration-account__name {
    display: flex;
    align-items: center;
  }
  
  .integration-account__details {
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.palette.common.gray200};
    padding: 0 10px;
    border-radius: ${props => props.theme.shape.borderRadius};
    font-size: 12px;
    word-break: break-all;
    white-space: pre-wrap;
    margin: 0 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      overflow: auto;
      max-height: 100px;
      margin: 20px 0;
    }
  }
  
  .integration-account__description {
    display: flex;
    align-items: center;
  }
  
  .integration-account__default {
    color: ${props => props.theme.palette.primary.main};
    margin-right: 10px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .integration-account__set-default {
    display: none;
    margin-right: 10px;
  }
  
  &:hover .integration-account__set-default {
    display: block;
  }
  
  .integration-account__refresh-button {
    display: none;
  }
  
  .integration-account__loader,
  .integration-account__icon {
    display: flex;
    align-items: center;
  }
  
  &:hover .integration-account__icon {
    display: ${props => props.accountActive ? 'flex' : 'none'};
  }
  
  &:hover .integration-account__refresh-button {
    display: ${props => props.accountActive ? 'none' : 'block'};
  }
`

const StyledCheckmark = styled(Checkmark)`
  color: ${props => props.theme.palette.success.main};
`

const StyledWarning = styled(Warning)`
  color: ${props => props.theme.palette.warning.main};
`
