import APIService from 'services/APIService'

const api = APIService()

export const fetchProductById = ({ id }) => api.get(`/products/${id}`)
export const fetchProductTagsById = ({ id }) => api.get(`/products/${id}/tags`)
export const updateRetailPrice = ({ id, retailPrice }) => api.post(`/products/${id}/update-retail-price`, { retailPrice })
export const addProductsToCart = ({ productIds }) => api.patch('/products/shipping-cart/add', { productIds })
export const removeProductsFromCart = ({ productIds }) => api.patch('/products/shipping-cart/remove', { productIds })
export const updateEstimatedShippingTime = ({ productIds, est }) => api.patch('/products/update-est', { productIds, est })
export const updateShippingQuantity = ({ productIds, qty }) => api.patch('/products/update-actual-shipping-quantity', { productIds, qty })
export const deactivateProducts = ({ ids }) => api.patch('/products/deactivate', { ids })
export const snoozeProducts = ({ productIds, snoozedUntil, comment }) => api.patch('/products/snooze', { productIds, snoozedUntil, comment })
export const snoozeAutoDecision = ({ productIds, snoozedUntil, comment }) => api.patch('/products/snooze-auto-decision', { productIds, snoozedUntil, comment })
export const syncProduct = ({ id }) => api.get(`/products/${id}/sync`)
export const fetchProductBundles = ({ id }) => api.get(`/products/${id}/bundles`)
export const importBundles = () => api.post('/iai/bundles/sync')
export const importAllegro = () => api.post('/allegro/sync')
export const fetchSaleChart = ({ productId, dateFrom, dateTo, cumulative }) => api.post(`/products/${productId}/sale-chart`, { dateFrom, dateTo, cumulative })
export const fetchAuctions = (id) => api.get(`/products/${id}/auctions`)
export const updateSoldNew = () => api.post('/products/update-sold-new')
export const addProductNotes = ({ id, notes }) => api.patch(`/products/${id}/update-notes`, { notes })
export const fetchWillLastHistory = ({ id, query }) => api.get(`/products/${id}/will-last-history?${query}`)
