import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { generateIaiProductLink } from 'utils/general'
import ProductWeightSchema from 'features/products/components/ProductWeightSchema'

import { getProductDetails } from 'features/products/ducks/selectors'

export const ProductIdentification = (props) => {
  const { match: { params: { id } } } = props
  const data = useSelector(state => getProductDetails(state, id))

  return (
    <ProductIdentificationContainer>
      <div className='product-identification__producer'>
        {data.lineName}, {data.producerName} ({data.producer_code}) <ProductWeightSchema />
      </div>
      <div className='product-identification__id'>
        <a
          className='product-identification__name'
          href={generateIaiProductLink(data.iaiId)}
          rel='noopener noreferrer'
          target='_blank'
        >
          {data.name}
        </a>
      </div>
    </ProductIdentificationContainer>
  )
}

export default withRouter(ProductIdentification)

const ProductIdentificationContainer = styled.div`
  .product-identification__producer {
    font-size: 10px;
    color: ${props => props.theme.palette.common.gray500};
    margin-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  
  .product-identification__id {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 17px;
      line-height: 23px;
    }
  }
  
  .product-identification__ean {
    font-weight: 700;
  }
  
  .product-identification__name {}
`
