import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { fetchProductBundlesRoutine } from 'features/products/ducks/actions'
import { getProductBundles } from 'features/products/ducks/selectors'

import Grid from 'components/atoms/Grid'
import ProductBundle from 'features/products/components/ProductBundle'
import SadFace from 'components/icons/SadFace'

export const BundlesSection = (props) => {
  const { match: { params: { id } } } = props
  const dispatch = useDispatch()
  const bundles = useSelector(state => getProductBundles(state, id))
  const { t } = useTranslation()

  const fetchProductBundles = useCallback(
    payload => dispatch(fetchProductBundlesRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    fetchProductBundles({ id })
  }, [id])

  const renderBundles = bundles.map(data =>
    <Grid key={`bundle-${data.id}`} item xs={12} sm={6}>
      <ProductBundle data={data} />
    </Grid>
  )

  const emptyState = (
    <Grid item xs={12}>
      <EmptyState>
        <SadFace />&nbsp;
        {t('productPage.bundles.emptyState')}
      </EmptyState>
    </Grid>
  )

  return (
    <Grid container spacing={2}>
      {bundles.length === 0 ? emptyState : renderBundles}
    </Grid>
  )
}

export default withRouter(BundlesSection)

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: ${props => props.theme.palette.common.gray300};
  font-size: 14px;
  width: 100%;
`
