import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'

import { getAuthUser } from 'features/auth/ducks/selectors'

import UserAvatar from 'components/UserAvatar'

export const TopNavAuthorisedUser = (props) => {
  const user = useSelector(getAuthUser)

  return (
    <TopNavAuthorisedUserContainer>
      <UserAvatar user={user} />
      <div className='auth-user__email'>
        {propOr('', 'email')(user)}
      </div>
    </TopNavAuthorisedUserContainer>
  )
}

export default TopNavAuthorisedUser

const TopNavAuthorisedUserContainer = styled.div`
  display: flex;
  align-items: center;
  
  .auth-user__email {
    margin-left: 10px;
  }
`
