import { always, identity, ifElse, isNil, pipe } from 'ramda'

export const roundNumber = number => Math.round(Number(number))

export const numberWithSpaces = number =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const twoDecimal = number => number.toFixed(2)

export const formatNumber =
  pipe(
    ifElse(
      isNil,
      always(twoDecimal(0)),
      identity
    ),
    numberWithSpaces
  )

export const roundAndFormatNumber =
  pipe(
    ifElse(
      isNil,
      always(twoDecimal(0)),
      identity
    ),
    roundNumber,
    numberWithSpaces
  )
