import { pathOr } from 'ramda'
import { SEVERITY } from 'utils/toast'

export const errors = {
  accountInactive: 'user.inactive',
  tokenExpired: 'user.token.expired',
  emailExists: 'user.email-exists',
  loginExists: 'user.login-exists',
  entityNotFound: 'entity.not-found',
  lineNameTaken: 'line.name-exists',
  entityExists: 'entity.already-exists'
}

export default errors

export const getErrorName = error =>
  pathOr('', ['response', 'data', 'name'], error)

export const ALLEGRO_ERRORS = [
  'authorization_pending',
  'slow_down',
  'access_denied',
  'invalid_grant',
  'fatal_error',
  'unauthorised'
]

export const getEntityExistsError = error => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.entityExists:
      errorTranslationKey = 'toast.entityExists'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}
