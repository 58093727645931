import {
  propOr,
  pipe,
  equals,
  map,
  applySpec,
  prop
} from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES, transformById } from 'utils/redux'

export const selectWeightSchemas = state => state.weightSchemas

export const getIsWeightSchemaLoading = createSelector(
  selectWeightSchemas,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getWeightSchemasArray = createSelector(
  selectWeightSchemas,
  propOr([], 'weightSchemas')
)

export const getWeightSchemasAsOptions = createSelector(
  selectWeightSchemas,
  pipe(
    propOr([], 'weightSchemas'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('id')
      })
    )
  )
)

export const getWeightSchemasAsFilterOptions = createSelector(
  selectWeightSchemas,
  pipe(
    propOr([], 'weightSchemas'),
    map(
      applySpec({
        label: prop('name'),
        value: prop('name')
      })
    )
  )
)

export const getWeightSchemasById = createSelector(
  selectWeightSchemas,
  (state, id) => id,
  (schemas, id) => pipe(
    propOr([], 'weightSchemas'),
    transformById,
    propOr({}, id)
  )(schemas)
)
