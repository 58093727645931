import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, delay } from '@redux-saga/core/effects'
import * as weightSchemaService from 'services/WeightSchemaService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { propOr } from 'ramda'
import { getProductRoutine } from 'features/products/ducks/actions'

// ROUTINES

export const fetchWeightSchemasRoutine = createRoutine('FETCH_WEIGHT_SCHEMAS')
export const createWeightSchemaRoutine = createRoutine('CREATE_WEIGHT_SCHEMA')
export const editWeightSchemaRoutine = createRoutine('EDIT_WEIGHT_SCHEMA')
export const deleteWeightSchemaRoutine = createRoutine('DELETE_WEIGHT_SCHEMA')
export const assignProductsToWeightSchemaRoutine = createRoutine('ASSIGN_PRODUCTS_TO_WEIGHT_SCHEMA')
export const removeWeightSchemaFromProductRoutine = createRoutine('REMOVE_WEIGHT_SCHEMA_FROM_PRODUCT')
export const setWeightSchemaAsDefaultRoutine = createRoutine('SET_WEIGHT_SCHEMA_AS_DEFAULT')
export const unsetDefaultWeightSchemaRoutine = createRoutine('UNSET_DEFAULT_WEIGHT_SCHEMA')

// ACTIONS

function * fetchWeightSchemas ({ payload }) {
  yield put(fetchWeightSchemasRoutine.request())
  try {
    const result = yield call(weightSchemaService.fetchWeightSchemas, payload)
    yield put(fetchWeightSchemasRoutine.success(propOr([], 'data')(result)))
  } catch (e) {
    yield put(fetchWeightSchemasRoutine.failure(e))
    console.error(e)
  }
}

function * createWeightSchema ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createWeightSchemaRoutine.request())
  try {
    yield call(weightSchemaService.createWeightSchema, values)
    delay(500)
    yield put(fetchWeightSchemasRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.createWeightSchemaSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(createWeightSchemaRoutine.success())
    callback()
  } catch (e) {
    yield put(createWeightSchemaRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * editWeightSchema ({ payload: { id = '', values = {}, callback = () => {} } }) {
  yield put(editWeightSchemaRoutine.request())
  try {
    yield call(weightSchemaService.editWeightSchema, { id, values })
    delay(500)
    yield put(fetchWeightSchemasRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.editWeightSchemaSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(editWeightSchemaRoutine.success())
    callback()
  } catch (e) {
    yield put(editWeightSchemaRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * deleteWeightSchema ({ payload }) {
  yield put(deleteWeightSchemaRoutine.request())
  try {
    yield call(weightSchemaService.deleteWeightSchema, payload)
    delay(500)
    yield put(fetchWeightSchemasRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.createWeightSchemaSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(deleteWeightSchemaRoutine.success())
  } catch (e) {
    yield put(deleteWeightSchemaRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * assignProductsToWeightSchema ({ payload: { values = {}, callback = () => {} } }) {
  yield put(assignProductsToWeightSchemaRoutine.request())
  try {
    yield call(weightSchemaService.assignProductsToWeightSchema, values)
    delay(500)
    yield put(
      showToastRoutine({
        key: 'toast.assignProductsToWeightSchemaSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(assignProductsToWeightSchemaRoutine.success())
    callback()
  } catch (e) {
    yield put(assignProductsToWeightSchemaRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * removeWeightSchemaFromProduct ({ payload: { values = {}, callback = () => {} } }) {
  yield put(removeWeightSchemaFromProductRoutine.request())
  try {
    yield call(weightSchemaService.removeWeightSchemaFromProduct, values)
    delay(500)
    yield put(
      showToastRoutine({
        key: 'toast.removeWeightSchemaFromProductSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(getProductRoutine({ id: values.productId }))
    yield put(removeWeightSchemaFromProductRoutine.success())
    callback()
  } catch (e) {
    yield put(removeWeightSchemaFromProductRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * setWeightSchemaAsDefault ({ payload: { id = '', callback = () => {} } }) {
  yield put(setWeightSchemaAsDefaultRoutine.request())
  try {
    yield call(weightSchemaService.setWeightSchemaAsDefault, { id })
    delay(500)
    yield put(fetchWeightSchemasRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.setWeightSchemaAsDefaultSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(setWeightSchemaAsDefaultRoutine.success())
    callback()
  } catch (e) {
    yield put(setWeightSchemaAsDefaultRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * unsetDefaultWeightSchema () {
  yield put(unsetDefaultWeightSchemaRoutine.request())
  try {
    yield call(weightSchemaService.unsetDefaultWeightSchema)
    delay(500)
    yield put(fetchWeightSchemasRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.unsetDefaultWeightSchemaSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(unsetDefaultWeightSchemaRoutine.success())
  } catch (e) {
    yield put(unsetDefaultWeightSchemaRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * fetchWeightSchemasWatcher () {
  yield takeLatest(fetchWeightSchemasRoutine.TRIGGER, fetchWeightSchemas)
}

export function * createWeightSchemaWatcher () {
  yield takeLatest(createWeightSchemaRoutine.TRIGGER, createWeightSchema)
}

export function * deleteWeightSchemaWatcher () {
  yield takeLatest(deleteWeightSchemaRoutine.TRIGGER, deleteWeightSchema)
}

export function * editWeightSchemaWatcher () {
  yield takeLatest(editWeightSchemaRoutine.TRIGGER, editWeightSchema)
}

export function * assignProductsToWeightSchemaWatcher () {
  yield takeLatest(assignProductsToWeightSchemaRoutine.TRIGGER, assignProductsToWeightSchema)
}

export function * removeWeightSchemaFromProductWatcher () {
  yield takeLatest(removeWeightSchemaFromProductRoutine.TRIGGER, removeWeightSchemaFromProduct)
}

export function * setWeightSchemaAsDefaultWatcher () {
  yield takeLatest(setWeightSchemaAsDefaultRoutine.TRIGGER, setWeightSchemaAsDefault)
}

export function * unsetDefaultWeightSchemaWatcher () {
  yield takeLatest(unsetDefaultWeightSchemaRoutine.TRIGGER, unsetDefaultWeightSchema)
}

// SAGAS

export const weighSchemasSagas = [
  fork(fetchWeightSchemasWatcher),
  fork(createWeightSchemaWatcher),
  fork(editWeightSchemaWatcher),
  fork(assignProductsToWeightSchemaWatcher),
  fork(removeWeightSchemaFromProductWatcher),
  fork(deleteWeightSchemaWatcher),
  fork(setWeightSchemaAsDefaultWatcher),
  fork(unsetDefaultWeightSchemaWatcher)
]
