import React from 'react'
import styled from 'styled-components'

import AllegroNameLogo from 'components/icons/AllegroNameLogo'
import Tooltip from 'components/atoms/Tooltip'

export const AllegroPromo = (props) => {
  return (
    <Tooltip title={props.promoType}>
      <StyledPromoContainer>
        <div className='allegro-promo__logo'>
          {props.logo || <AllegroNameLogo />}
        </div>
      </StyledPromoContainer>
    </Tooltip>
  )
}

export default AllegroPromo

const StyledPromoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${props => props.theme.palette.text.main};
  margin-right: 3px;
  
  .allegro-promo__logo {
    height: auto;
    display: flex;
    align-items: center;
  
    svg {
      font-size: 20px;
    }
  }
  
  .allegro-promo__type {
    font-size: 10px;
    text-align: center;
  }
`
