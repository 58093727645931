import { propOr, pipe, equals, pathOr, propEq, lt, find } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'
import { Product } from 'features/products/ducks/records'
import { convertPriceWithLabel } from 'utils/price'
import { SHIPPING_CART_STATUS } from 'utils/shippingOrders'

export const selectProducts = state => state.products

export const getIsProductLoading = createSelector(
  selectProducts,
  pipe(
    propOr('', 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductRetailPrice = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pipe(
    pathOr(0, ['products', id, 'retailPrice']),
    convertPriceWithLabel
  )(products)
)

export const getIsProductInCart = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pipe(
    pathOr(new Product(), ['products', id]),
    propEq('shippingStatus', SHIPPING_CART_STATUS.inCart)
  )(products)
)

export const getProductEstimatedShippingTime = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(30, ['products', id, 'estimatedShippingTime'])(products)
)

export const getIsProductInStock = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pipe(
    pathOr(0, ['products', id, 'stock']),
    lt(0)
  )(products)
)

export const getIsProductActive = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pipe(
    pathOr(0, ['products', id, 'isActive'])
  )(products)
)

export const getProductDetails = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(new Product(), ['products', id])(products)
)

export const getProductPurchasePrice = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pipe(
    pathOr(0, ['products', id, 'purchasePrice']),
    convertPriceWithLabel
  )(products)
)

export const getProductTags = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr([], ['products', id, 'tags'])(products)
)

export const getProductPriceUpFlag = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(false, ['products', id, 'price_up_task_enabled'])(products)
)

export const getProductPriceDownFlag = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(false, ['products', id, 'price_down_task_enabled'])(products)
)

export const getProductPromoDiffFlag = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(false, ['products', id, 'promo_diff_task_enabled'])(products)
)

export const getProductAutoDecisionFlag = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(false, ['products', id, 'auto_decision_task_enabled'])(products)
)

export const getProductBundles = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) =>
    pathOr([], ['bundles', id])(products)
)

export const getIsProductSyncLoading = createSelector(
  selectProducts,
  pipe(
    propOr('', 'syncState'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductHasAds = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) =>
    pathOr(false, ['products', id, 'hasAds'])(products)
)

export const getProductBundleHasAds = createSelector(
  selectProducts,
  (state, bundleId) => bundleId,
  (state, bundleId, productId) => productId,
  (products, bundleId, productId) =>
    pipe(
      pathOr([], ['bundles', productId]),
      find(propEq('productId', bundleId)),
      propOr(false, 'hasAds')
    )(products)
)

export const getIsChartsLoading = createSelector(
  selectProducts,
  pipe(
    propOr('', 'chartsState'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductSimpleSaleChart = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) =>
    pathOr({}, ['simpleSaleChart', id])(products)
)

export const getProductCumulativeSaleChart = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) =>
    pathOr({}, ['cumulativeSaleChart', id])(products)
)

export const getProductShippingDetails = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr([], ['products', id, 'shippingDetails'])(products)
)

export const getWeightSchemaId = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr('', ['products', id, 'weight_schema_id'])(products)
)

export const getProductNotes = createSelector(
  selectProducts,
  (state, id) => id,
  (products, id) => pathOr(null, ['products', id, 'notes'])(products)
)
