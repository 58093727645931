import React, { useEffect, useState } from 'react'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr, head, drop, pipe, split, last, join } from 'ramda'
import { numericFilterOptions, isRangeFilter } from 'utils/filters'
import { convertToPenniesIfNumber, convertFromPenniesIfNumber } from 'utils/price'
import { useHistory } from 'react-router-dom'

import CombinedSelectWithInput from 'components/atoms/FilterInputs/CombinedSelectWithInput'

export const NumericFilter = (props) => {
  const { name, label, shouldBeChangedToPennies } = props
  const urlSearchQuery = useUrlSearchQuery()
  const { location: { search } } = useHistory()

  const getUrlIndicator = pipe(
    propOr('', name),
    head
  )

  const getUrlBaseValue = pipe(
    propOr('', name),
    drop(1),
    split(','),
    head
  )

  const getUrlSecondValue = pipe(
    propOr('', name),
    drop(1),
    split(','),
    last
  )

  const selectedValue = query => shouldBeChangedToPennies ? convertFromPenniesIfNumber(getUrlBaseValue(query)) : getUrlBaseValue(query)
  const selectedSecondValue = query => shouldBeChangedToPennies ? convertFromPenniesIfNumber(getUrlSecondValue(query)) : getUrlSecondValue(query)

  const joinValuesIfNeeded = (base, second) => isRangeFilter(indicator) ? join((','), [base, second]) : base
  const checkSecondValueIfNeeded = () => isRangeFilter(indicator) ? isNotNilOrEmpty(secondValue) : true
  const checkDependentValueIfNeeded = (base, second) => isRangeFilter(indicator) ? (isNotNilOrEmpty(base) || isNotNilOrEmpty(second)) : isNotNilOrEmpty(base)

  const [indicator, setIndicator] = useState(getUrlIndicator(urlSearchQuery.getSearchQuery()))
  const [baseValue, setBaseValue] = useState(selectedValue(urlSearchQuery.getSearchQuery()))
  const [secondValue, setSecondValue] = useState(selectedSecondValue(urlSearchQuery.getSearchQuery()))

  useEffect(() => {
    setBaseValue(selectedValue(urlSearchQuery.getSearchQuery()))
    setSecondValue(selectedSecondValue(urlSearchQuery.getSearchQuery()))
    setIndicator(getUrlIndicator(urlSearchQuery.getSearchQuery()))
  }, [search])

  const handleIndicatorChange = e => {
    setIndicator(e.target.value)
    const queryBaseValue = shouldBeChangedToPennies ? convertToPenniesIfNumber(baseValue) : baseValue
    const querySecondValue = shouldBeChangedToPennies ? convertToPenniesIfNumber(secondValue) : secondValue

    if (isNotNilOrEmpty(baseValue) && isNotNilOrEmpty(e.target.value) && checkSecondValueIfNeeded(secondValue)) {
      urlSearchQuery.setSearchQuery({
        [name]: e.target.value + joinValuesIfNeeded(queryBaseValue, querySecondValue)
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
      setBaseValue('')
      setSecondValue('')
    }
  }

  const handleBaseValueChange = e => {
    setBaseValue(e.target.value)
    const queryBaseValue = shouldBeChangedToPennies ? convertToPenniesIfNumber(e.target.value) : e.target.value
    const querySecondValue = shouldBeChangedToPennies ? convertToPenniesIfNumber(secondValue) : secondValue

    if (isRangeFilter(indicator) && secondValue < e.target.value) {
      return null
    } else if (isNotNilOrEmpty(indicator) && checkDependentValueIfNeeded(e.target.value, secondValue)) {
      return urlSearchQuery.setSearchQuery({
        [name]: indicator + joinValuesIfNeeded(queryBaseValue, querySecondValue)
      })
    } else {
      return urlSearchQuery.removeSearchQuery(name)
    }
  }

  const handleSecondValueChange = e => {
    setSecondValue(e.target.value)
    const queryBaseValue = shouldBeChangedToPennies ? convertToPenniesIfNumber(baseValue) : baseValue
    const querySecondValue = shouldBeChangedToPennies ? convertToPenniesIfNumber(e.target.value) : e.target.value

    if (isRangeFilter(indicator) && baseValue > e.target.value) {
      return null
    } else if (isNotNilOrEmpty(indicator) && checkDependentValueIfNeeded(baseValue, e.target.value)) {
      return urlSearchQuery.setSearchQuery({
        [name]: indicator + joinValuesIfNeeded(queryBaseValue, querySecondValue)
      })
    } else {
      return urlSearchQuery.removeSearchQuery(name)
    }
  }

  return (
    <>
      <CombinedSelectWithInput
        label={label}
        selectValue={indicator}
        options={numericFilterOptions()}
        onSelectChange={handleIndicatorChange}
        inputType='number'
        isRange={isRangeFilter(indicator)}
        inputBaseValue={baseValue}
        inputSecondValue={secondValue}
        onInputChange={handleBaseValueChange}
        onSecondInputChange={handleSecondValueChange}
      />
    </>
  )
}

export default NumericFilter
