import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ResetPasswordInit from 'features/auth/ResetPasswordInit'
import VerticalAlignContainer from 'components/VerticalAlignContainer'
import Paper from 'components/atoms/Paper'
import FormTitle from 'components/atoms/FormTitle'
import FormDescription from 'components/atoms/FormDescription'

export const InitResetPasswordPage = (props) => {
  const { t } = useTranslation()

  return (
    <VerticalAlignContainer>
      <StyledPaper>
        <FormTitle>{t('resetPasswordPage.init.title')}</FormTitle>
        <FormDescription>{t('resetPasswordPage.init.text')}</FormDescription>
        <ResetPasswordInit />
      </StyledPaper>
    </VerticalAlignContainer>
  )
}

export default InitResetPasswordPage

const StyledPaper = styled(Paper)`
  min-width: 600px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    min-width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
  }
`
