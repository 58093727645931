import { Record } from 'immutable'
import { append, mergeAll, pipe, reduce } from 'ramda'
import { PRODUCT_TASKS_API_FIELDS } from 'utils/flags'
import { API_STATES } from 'utils/redux'

const flagValues = () =>
  pipe(
    reduce(
      (acc, value) => append({ [value.name]: false }, acc),
      []
    ),
    mergeAll
  )(PRODUCT_TASKS_API_FIELDS)

export const Product = Record({
  productId: '',
  iaiId: 0,
  createdAt: '',
  updatedAt: null,
  retailPrice: 0,
  photoUrl: '',
  producerId: '',
  producerName: '',
  userLogin: null,
  lineName: null,
  purchasePrice: 0,
  latestCommentContent: '',
  latestCommentUserId: '',
  salesDynamics: null,
  isBundled: false,
  soldToday: null,
  willLastShort: null,
  willLastLong: null,
  frozenMoney: 0,
  sold1: 0,
  soldShort: 0,
  soldLong: 0,
  margin: '',
  allegroFee: '',
  isEmphasized: false,
  hasPromotionPackage: false,
  hasHalfPercent: false,
  isBargain: false,
  hasAds: false,
  latestCommentCreatedAt: '',
  shippingStatus: null,
  suggestedShippingQty: null,
  estimatedShippingTime: 30,
  actualShippingQty: null,
  activatedAt: null,
  iaiCreatedAt: '',
  isActive: false,
  virtualStock: 0,
  id: '',
  ean: null,
  name: '',
  stock: 0,
  tags: [],
  snoozed_until: null,
  auto_decision_snoozed_until: null,
  latestCommentAuthor: '',
  shippingDetails: [],
  producer_code: '',
  weight_schema_id: '',
  notes: '',
  ...flagValues()
})

export const Bundle = Record({
  productId: '',
  iaiId: 0,
  createdAt: '',
  updatedAt: null,
  retailPrice: 0,
  photoUrl: '',
  producerId: '',
  producerName: '',
  purchasePrice: 0,
  salesDynamics: null,
  isBundled: null,
  isBundle: true,
  soldToday: null,
  willLastShort: null,
  willLastLong: null,
  frozenMoney: null,
  sold1: null,
  soldShort: null,
  soldLong: null,
  margin: '',
  allegroFee: '',
  isEmphasized: null,
  hasPromotionPackage: null,
  hasHalfPercent: null,
  isBargain: null,
  hasAds: null,
  latestCommentCreatedAt: null,
  shippingStatus: null,
  suggestedShippingQty: null,
  estimatedShippingTime: 0,
  actualShippingQty: null,
  activatedAt: null,
  iaiCreatedAt: '',
  isActive: true,
  virtualStock: 0,
  priceUpTaskEnabled: false,
  snoozedUntil: null,
  id: '',
  ean: null,
  name: '',
  stock: 0,
  bundleItems: []
})

export const Products = Record({
  state: API_STATES.PRISTINE,
  chartsState: API_STATES.PRISTINE,
  syncState: API_STATES.PRISTINE,
  products: {},
  bundles: {},
  simpleSaleChart: {},
  cumulativeSaleChart: {}
})
