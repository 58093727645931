import { values } from 'ramda'

export const API_STATES = {
  PRISTINE: 'pristine',
  IN_PROGRESS: 'in-progress',
  DONE: 'done'
}

export const transformById = (collection, idKey = 'id') =>
  collection.reduce((hash, entity) => {
    hash[entity[idKey]] = entity

    return hash
  }, {})

export const transformToArray = values

export const transferCollectionToImmutable = (
  collection,
  ImmutableRecord
) => collection.map(entity => new ImmutableRecord(entity))

export const transferCollectionToImmutableById = (
  collection,
  ImmutableRecord
) => transformById(transferCollectionToImmutable(collection, ImmutableRecord))

export const transferEntityToImmutableById = (entity, ImmutableRecord, key = 'id') => {
  const convertedRecord = new ImmutableRecord(entity)
  return {
    [convertedRecord[key]]: convertedRecord
  }
}
