import { DaysOff } from 'features/daysOff/ducks/records'
import { fetchDaysOffRoutine } from 'features/daysOff/ducks/actions'
import { API_STATES } from 'utils/redux'

export default (state = new DaysOff(), action) => {
  switch (action.type) {
    case fetchDaysOffRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchDaysOffRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchDaysOffRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('daysOff', action.payload)
    default:
      return state
  }
}
