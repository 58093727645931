import React, { useState, useEffect } from 'react'
import useUrlSearchQuery from 'hooks/useUrlSearchQuery'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { getOptionByValue } from 'utils/form'

import PlainSingleSelect from 'components/atoms/FilterInputs/PlainSingleSelect'

export const BooleanSelectFilter = (props) => {
  const { name, label, options } = props
  const { location: { search } } = useHistory()
  const urlSearchQuery = useUrlSearchQuery()

  const urlQuery = propOr('', name)(urlSearchQuery.getSearchQuery())
  const selectedOption = value => getOptionByValue(value)(options)

  useEffect(() => {
    setValue(urlQuery)
  }, [search])

  const [value, setValue] = useState(urlQuery)

  const handleIndicatorChange = option => {
    const selectedValue = propOr(null, 'value', option)

    setValue(selectedValue)
    if (isNotNilOrEmpty(selectedValue)) {
      urlSearchQuery.setSearchQuery({
        [name]: option.value
      })
    } else {
      urlSearchQuery.removeSearchQuery(name)
    }
  }

  return (
    <PlainSingleSelect
      label={label}
      variant='standard'
      options={options}
      value={selectedOption(value)}
      onChange={handleIndicatorChange}
    />
  )
}

export default BooleanSelectFilter
