import { ProductAuctions, Auctions } from 'features/productAuctions/ducks/records'
import { fetchProductAuctionsRoutine, syncSingleAuctionRoutine } from 'features/productAuctions/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new ProductAuctions(), action) => {
  switch (action.type) {
    case syncSingleAuctionRoutine.REQUEST:
      return state.set('syncAuctionState', API_STATES.IN_PROGRESS)
    case fetchProductAuctionsRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case fetchProductAuctionsRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case syncSingleAuctionRoutine.FAILURE:
    case syncSingleAuctionRoutine.SUCCESS:
      return state.set('syncAuctionState', API_STATES.DONE)
    case fetchProductAuctionsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('auctions',
          {
            ...state.auctions,
            [action.payload.id]: transferCollectionToImmutable(action.payload.data, Auctions)
          }
        )
    default:
      return state
  }
}
