import { string, object, number, array } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'
import { dateIsBeforeComparedDate } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'

// Update retail price form validator

export const updateRetailPriceSchema = object().shape({
  id: string()
    .required(),
  retailPrice: number()
    .required(() => ({ key: 'form.retailPrice.errors.required' }))
    .min(0, () => ({
      key: 'form.retailPrice.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateUpdateRetailPriceField = values => validateField(updateRetailPriceSchema, values)
export const validateUpdateRetailPriceValues = validateValues(updateRetailPriceSchema)

// Update estimated shipping time form validator

export const updateEstimatedShippingTimeSchema = object().shape({
  productIds: array()
    .required(),
  est: number()
    .required(() => ({ key: 'form.days.errors.required' }))
    .min(0, () => ({
      key: 'form.days.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateUpdateEstimatedShippingTimeSchemaField = values => validateField(updateEstimatedShippingTimeSchema, values)
export const validateUpdateEstimatedShippingTimeSchemaValues = validateValues(updateEstimatedShippingTimeSchema)

// Update shipping quantity form validator

export const updateShippingQuantitySchema = object().shape({
  productIds: array()
    .required(),
  qty: number()
    .required(() => ({ key: 'form.days.errors.required' }))
    .min(0, () => ({
      key: 'form.days.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateUpdateShippingQuantitySchemaField = values => validateField(updateShippingQuantitySchema, values)
export const validateUpdateShippingQuantitySchemaValues = validateValues(updateShippingQuantitySchema)

// Snooze products form validator

export const snoozeProductsSchema = object().shape({
  productIds: array()
    .required(),
  snoozedUntil: string()
    .required(() => ({ key: 'form.snoozeDate.errors.required' })),
  comment: string()
    .required(() => ({ key: 'form.comment.errors.required' }))
})

export const validateSnoozeProductsSchemaField = values => validateField(snoozeProductsSchema, values)
export const validateSnoozeProductsSchemaValues = validateValues(snoozeProductsSchema)

// Chart dates form validator

export const chartDatesForm = object().shape({
  dateFrom: string()
    .required(() => ({ key: 'form.dateFrom.errors.required' }))
    .when('dateTo', (dateTo, schema) => {
      return schema.test(
        'validate-date-to',
        () => ({ key: 'form.dateFrom.errors.afterDateTo' }),
        dateFrom => {
          return isNotNilOrEmpty(dateTo) ? dateIsBeforeComparedDate(dateFrom, dateTo) : true
        }
      )
    }),
  dateTo: string()
    .required(() => ({ key: 'form.dateTo.errors.required' }))
    .when('dateFrom', (dateFrom, schema) => {
      return schema.test(
        'validate-date-from',
        () => ({ key: 'form.dateTo.errors.beforeDateFrom' }),
        dateTo => {
          return isNotNilOrEmpty(dateFrom) ? dateIsBeforeComparedDate(dateFrom, dateTo) : true
        }
      )
    })
}, ['dateFrom', 'dateTo'])

export const validateChartDatesSchemaField = values => validateField(chartDatesForm, values)
export const validateChartDatesSchemaValues = validateValues(chartDatesForm)

// Add notes form validator

export const AddNotes = object().shape({
  id: string()
    .required(),
  notes: string()
})

export const validateAddNotesField = values => validateField(AddNotes, values)
export const validateAddNotesValues = validateValues(AddNotes)
