import { Auth } from 'features/auth/ducks/records'
import { User } from 'features/users/ducks/records'
import {
  loginUserRoutine,
  fetchAuthUserRoutine
} from 'features/auth/ducks/actions'
import { API_STATES } from 'utils/redux'

export default (state = new Auth(), action) => {
  switch (action.type) {
    case loginUserRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case loginUserRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
        .set('failedLoginAttempts', state.failedLoginAttempts + 1)
    case loginUserRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('failedLoginAttempts', 0)
    case fetchAuthUserRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('user', new User(action.payload))
    default:
      return state
  }
}
