import React from 'react'

import TodaysOrdersTable from 'features/orders/TodaysOrdersTable'

export const TodayOrdersSection = () => {
  return (
    <TodaysOrdersTable />
  )
}

export default TodayOrdersSection
