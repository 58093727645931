import React, { useCallback, useEffect, useState } from 'react'
import { fetchWeightSchemasRoutine, removeWeightSchemaFromProductRoutine } from 'features/weightSchemas/ducks/actions'
import { useSelector, useDispatch } from 'react-redux'
import { getWeightSchemasById } from 'features/weightSchemas/ducks/selectors'
import { propOr } from 'ramda'
import { withRouter } from 'react-router-dom'
import { getWeightSchemaId } from 'features/products/ducks/selectors'
import Chip from 'components/atoms/Chip'
import { isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import Tooltip from 'components/atoms/Tooltip'
import { useTranslation } from 'react-i18next'
import Popover from 'components/atoms/Popover'
import {
  ConfirmationButtonsContainer,
  ConfirmationContainer
} from 'features/products/components/ProductActivityFlag'
import Button from 'components/atoms/Button'

export const ProductWeightSchema = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)

  const dispatch = useDispatch()
  const schemaId = useSelector(state => getWeightSchemaId(state, id))
  const weightSchema = useSelector(state => getWeightSchemasById(state, schemaId))

  const componentId = open ? schemaId : undefined

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const removeWeightSchema = useCallback(
    payload => dispatch(removeWeightSchemaFromProductRoutine(payload)),
    [dispatch]
  )

  const handleRemoveWeightSchema = () => removeWeightSchema(
    {
      values: { productId: id, id: schemaId },
      callback: handleClose
    }
  )

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {t('productPage.weightSchema.remove.confirmation.content')}
        <ConfirmationButtonsContainer>
          <Button onClick={handleRemoveWeightSchema} variant='outlined'>
            {t('productPage.weightSchema.remove.confirmation.submitCTA')}
          </Button>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
          >
            {t('productPage.weightSchema.remove.confirmation.cancelCTA')}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  const fetchWeightSchemas = useCallback(
    () => dispatch(fetchWeightSchemasRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchWeightSchemas()
  }, [])

  return isNotNilOrEmpty(schemaId) && (
    <ChipContainer>
      <Tooltip title={t('form.weightSchema.label')}>
        <Chip size='small' onDelete={handleOpen} label={propOr('', 'name', weightSchema)} />
      </Tooltip>
      {ConfirmationPopup}
    </ChipContainer>
  )
}

export default withRouter(ProductWeightSchema)

const ChipContainer = styled.div`
  display: inline-block;
  margin-left: 5px;
  letter-spacing: none;
  text-transform: none;
`
