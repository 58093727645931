import React from 'react'
import Icon from 'components/Icon'

export const GiftBasket = props => {
  return (
    <Icon
      width='1em'
      height='1em'
      viewBox='0 0 512.064 512.064'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path d='m512.032 270.064h-29.512c2.094-36.058-4.437-72.144-19.185-105.307-15.948-35.861-40.855-66.882-72.304-90.158v-44.535h-90v4.495c-37.159-7.571-75.261-5.714-111.673 5.514l-31.688-31.28c-7.838-7.838-19.299-10.712-29.907-7.5s-18.55 11.96-20.717 22.795l-7.377 36.448c-9.32 1.32-18.303 5.555-25.457 12.708-6.975 6.974-11.372 15.836-12.728 25.432l-36.979 7.396c-10.869 2.174-19.616 10.115-22.828 20.724s-.338 22.069 7.5 29.906l30.87 30.871c-8.519 26.561-12.075 54.641-10.479 82.491h-29.536v91h29.994c.037 55.798 24.049 109.886 66.04 147.211l4.263 3.789h311.406l4.263-3.789c41.991-37.325 66.003-91.413 66.04-147.211h29.994zm-181-98.787v-51.213h30v51.213l30 30v68.787h-60c0-26.595-11.599-50.529-30-67.02v-1.767zm-30 98.787h-120c0-33.084 26.916-60 60-60s60 26.916 60 60zm-150 0h-30c0-18.288 16.427-32.68 34.996-29.57-3.233 9.266-4.996 19.215-4.996 29.57zm331 30v31h-452v-31zm-29.559-30h-31.441v-81.213l-30-30v-45.266c42.829 40.15 65.481 97.203 61.441 156.479zm-91.441-210v30h-30v-30zm-224.581-30.024.007-.03 45.208 44.63-36.956 7.391c-3.732-6.241-9.066-11.702-15.623-15.613zm-41.025 64.418c5.849-5.848 15.363-5.849 21.213 0 5.863 5.863 5.863 15.35 0 21.213-5.85 5.849-15.365 5.848-21.213 0-5.863-5.863-5.863-15.351 0-21.213zm-28.072 33.639c3.795 6.347 9.179 11.776 15.647 15.629l-7.399 36.974-45.204-45.212zm-2.196 80.73c17.158 6.38 36.221-4.01 39.866-22.238l7.401-37c9.309-1.326 18.282-5.559 25.427-12.704 6.975-6.974 11.372-15.836 12.728-25.432l36.979-7.396c10.869-2.174 19.616-10.115 22.828-20.724 1.802-5.954 1.687-12.175-.157-17.886 29.86-7.189 60.752-7.268 90.804-.18v93.584l-27.346 27.346c-10.128-3.957-21.141-6.133-32.654-6.133-28.217 0-53.438 13.057-69.952 33.441-6.39-2.265-13.151-3.441-20.048-3.441-33.084 0-60 26.916-60 60h-31.421c-1.361-20.553.528-41.276 5.545-61.237zm335.027 273.237h-288.306c-33.042-31.451-51.835-75.756-51.862-121.2h392.03c-.026 45.444-18.819 89.749-51.862 121.2z' />
        <path d='m121.032 391.064h90v30h-90z' />
        <path d='m271.032 421.064h90v30h-90z' />
      </g>
    </Icon>
  )
}

export default GiftBasket
