import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as iaiService from 'services/IAIService'
import * as allegroService from 'services/AllegroService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

// ROUTINES

export const getIaiIntegrationStatusRoutine = createRoutine('GET_IAI_INTEGRATION_STATUS')
export const getAllegroIntegrationStatusRoutine = createRoutine('GET_ALLEGRO_INTEGRATION_STATUS')
export const checkIaiConnectionRoutine = createRoutine('CHECK_IAI_CONNECTION')
export const getAllIntegrationsConnectionStatusRoutine = createRoutine('GET_ALL_INTEGRATIONS_CONNECTION_STATUS')

// ACTIONS

function * getIaiIntegrationStatus () {
  yield put(getIaiIntegrationStatusRoutine.request())
  try {
    const result = yield call(iaiService.getIaiConnectionStatus)
    yield put(getIaiIntegrationStatusRoutine.success(result.data))
  } catch (e) {
    yield put(getIaiIntegrationStatusRoutine.failure(e))
    console.error(e)
  }
}

function * getAllegroIntegrationStatus () {
  yield put(getIaiIntegrationStatusRoutine.request())
  try {
    const result = yield call(allegroService.checkAllegroConnection)
    yield put(getAllegroIntegrationStatusRoutine.success(result.data))
  } catch (e) {
    yield put(getAllegroIntegrationStatusRoutine.failure(e))
    console.error(e)
  }
}

function * checkIaiConnection () {
  yield put(checkIaiConnectionRoutine.request())
  try {
    const result = yield call(iaiService.checkIaiConnection)
    yield put(checkIaiConnectionRoutine.success(result.data))
    result.data.errorsExist
      ? yield put(
        showToastRoutine({
          key: 'toast.somethingWentWrong',
          severity: SEVERITY.error
        })
      )
      : yield put(
        showToastRoutine({
          key: 'toast.checkIaiConnectionSuccess',
          severity: SEVERITY.success
        })
      )
  } catch (e) {
    yield put(checkIaiConnectionRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function * getAllIntegrationsConnectionStatus () {
  yield put(getAllIntegrationsConnectionStatusRoutine.request())
  try {
    yield put(getIaiIntegrationStatusRoutine())
    yield put(getAllegroIntegrationStatusRoutine())
    yield put(getAllIntegrationsConnectionStatusRoutine.success())
  } catch (e) {
    yield put(getAllIntegrationsConnectionStatusRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function * getIaiIntegrationStatusWatcher () {
  yield takeLatest(getIaiIntegrationStatusRoutine.TRIGGER, getIaiIntegrationStatus)
}

export function * getAllegroIntegrationStatusWatcher () {
  yield takeLatest(getAllegroIntegrationStatusRoutine.TRIGGER, getAllegroIntegrationStatus)
}

export function * checkIaiConnectionWatcher () {
  yield takeLatest(checkIaiConnectionRoutine.TRIGGER, checkIaiConnection)
}

export function * getAllIntegrationsConnectionStatusWatcher () {
  yield takeLatest(getAllIntegrationsConnectionStatusRoutine.TRIGGER, getAllIntegrationsConnectionStatus)
}

// SAGAS

export const integrationsSagas = [
  fork(getIaiIntegrationStatusWatcher),
  fork(checkIaiConnectionWatcher),
  fork(getAllIntegrationsConnectionStatusWatcher),
  fork(getAllegroIntegrationStatusWatcher)
]
