import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getHeadErrorOrEmptyObject } from 'utils/form'
import Input from 'components/atoms/Input'

export const TextField = props => {
  const {
    raw,
    name,
    type,
    onChange,
    validate,
    label,
    value: initialValue,
    required,
    disabled,
    reset,
    multiline,
    rows,
    rowsMax,
    autocomplete
  } = props
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: {}
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (touched && !reset) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObject(v) })
      })
    }
  }, [value, touched, reset])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (reset) {
      _setValue(initialValue || '')
    }
  }, [reset])

  const handleFocus = () => _setTouched(true)
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  return (
    <Input
      raw={raw}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      disabled={disabled}
      required={required}
      name={name}
      label={label}
      value={value}
      errorText={valid || disabled ? '' : t(error.key, error.options)}
      type={type}
      error={!valid && !disabled}
      onFocus={handleFocus}
      onChange={handleChange}
      autoComplete={autocomplete}
    />
  )
}

TextField.defaultProps = {
  onChange: () => {},
  validate: () => {}
}

export default TextField
