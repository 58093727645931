import { Deliveries, DeliveriesMeta, Delivery } from 'features/deliveries/ducks/records'
import { fetchProductDeliveriesRoutine } from 'features/deliveries/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new Deliveries(), action) => {
  switch (action.type) {
    case fetchProductDeliveriesRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchProductDeliveriesRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchProductDeliveriesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('deliveriesMeta', new DeliveriesMeta(action.payload.result.meta))
        .set('deliveries', {
          ...state.deliveries,
          [action.payload.productId]: transferCollectionToImmutable(action.payload.result.data, Delivery)
        })
    default:
      return state
  }
}
