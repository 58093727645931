import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getSearchQueriesArray } from 'features/searchQueries/ducks/selectors'
import { fetchSearchQueriesRoutine } from 'features/searchQueries/ducks/actions'

import Menu from 'components/atoms/Menu'
import IconButton from 'components/atoms/IconButton'
import BookmarksIcon from 'components/icons/Bookmarks'
import SearchQueryItem from 'features/searchQueries/components/SearchQueryItem'

const MENU_HEIGHT = 48

export const SelectSearchQueriesMenu = (props) => {
  const dispatch = useDispatch()
  const searchQueries = useSelector(getSearchQueriesArray)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpen = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const fetchSearchQueries = useCallback(
    () => dispatch(fetchSearchQueriesRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchSearchQueries()
  }, [])

  const renderMenuItems = searchQueries.map(query => (
    <SearchQueryItem key={query.id} data={query} closeMenu={handleClose} />
  ))

  return searchQueries.length > 0 && (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleOpen}
      >
        <BookmarksIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: MENU_HEIGHT * 4.5,
            width: '40ch'
          }
        }}
      >
        {renderMenuItems}
      </Menu>
    </div>
  )
}

export default SelectSearchQueriesMenu
