import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { lt, pathOr, pipe } from 'ramda'
import { useTranslation } from 'react-i18next'
import { getSelectedTheme } from 'ducks/global/selectors'
import { THEME_MODES } from 'utils/theme'

import Transit from 'components/icons/Transit'
import Tooltip from 'components/atoms/Tooltip'
import { getProductRoutine } from 'features/products/ducks/actions'
import { getProductShippingDetails } from 'features/products/ducks/selectors'
import { formatDate, FORMATS } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import Loader from 'components/atoms/Loader'

export const TransitStatus = (props) => {
  const { t } = useTranslation()
  const selectedThemeMode = useSelector(getSelectedTheme)
  const dispatch = useDispatch()

  const productId = pathOr('', ['data', 'id'], props)

  const getProduct = useCallback(
    payload => dispatch(getProductRoutine(payload)),
    [dispatch]
  )

  const shippingDetails = useSelector(state => getProductShippingDetails(state, productId))

  const handleFetchProductDetails = () => getProduct({ id: productId })

  const renderShippingDetails = shippingDetails.map(
    (shippingDetail, id) =>
      <div key={`product-shipping-detail-${id}`}>{t('productPage.stock.details', { quantity: shippingDetail.quantity, date: formatDate(shippingDetail.toBeShippedAt, FORMATS.slash) })}</div>
  )

  const isInOrder = pipe(
    pathOr(0, ['data', 'virtualStock']),
    lt(0)
  )(props)

  const tooltipTitle = (
    <div>
      <div>{t('productPage.stock.inDelivery')}:</div>
      {isNotNilOrEmpty(shippingDetails) ? renderShippingDetails : <Loader size={10} color='secondary' />}
    </div>
  )

  return isInOrder && (
    <Tooltip onOpen={handleFetchProductDetails} title={tooltipTitle}>
      <IconContainer themeMode={selectedThemeMode}><Transit fontSize='small' /></IconContainer>
    </Tooltip>
  )
}

export default TransitStatus

const IconContainer = styled.div`
  display: inline-block;
  font-size: 10px;
  color: ${props => props.themeMode === THEME_MODES.light ? props.theme.palette.common.gray500 : props.theme.palette.common.gray600};
`
