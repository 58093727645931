import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditShippingOrderForm from 'features/shippingOrders/EditShippingOrderForm'
import Modal from 'components/atoms/Modal'
import IconButton from 'components/atoms/IconButton'
import Edit from 'components/icons/Edit'

export const EditShippingOrderModal = (props) => {
  const { data, isShipped } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton id='edit-shipping-order' size='small' onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal
        title={t('ordersPage.editShippingOrder.editCTA')}
        open={open}
        handleClose={handleClose}
      >
        <EditShippingOrderForm data={data} isShipped={isShipped} onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default EditShippingOrderModal
