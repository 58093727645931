import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateChartDatesSchemaField, validateChartDatesSchemaValues } from 'features/products/ducks/schema'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { getIsChartsLoading } from 'features/products/ducks/selectors'
import { getProductSalePlan } from 'features/salePlans/ducks/selectors'

import Button from 'components/atoms/Button'
import Form from 'components/atoms/Form'
import DatePickerField from 'components/formElements/DatePickerField'
import SelectChartDateRange from 'features/products/components/SelectChartDateRange'
import { formatISODate, nDaysAgo, today } from 'utils/date'
import usePrevious from 'hooks/usePrevious'
import { equals, not } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const emptyValues = {
  dateFrom: '',
  dateTo: ''
}

export const ChartDatesForm = (props) => {
  const { fetchChartRoutine, match: { params: { id } }, enableFuture } = props
  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsChartsLoading)
  const currentSalePlan = useSelector(state => getProductSalePlan(state, id))
  const prevPlan = usePrevious(currentSalePlan)
  const salePlan = useSelector(state => getProductSalePlan(state, id))

  const callRoutineWithDates = useCallback(
    payload => dispatch(fetchChartRoutine(payload)),
    [dispatch]
  )

  const handleInit = () => {
    callRoutineWithDates({
      dateFrom: formatISODate(nDaysAgo(30)),
      dateTo: formatISODate(today()),
      productId: id
    })
    setValues({
      dateFrom: formatISODate(nDaysAgo(30)),
      dateTo: formatISODate(today())
    })
  }

  useEffect(() => {
    handleInit()
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(prevPlan) && not(equals(prevPlan, currentSalePlan))) {
      callRoutineWithDates({ ...values, productId: id })
    }
  }, [currentSalePlan])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    validateChartDatesSchemaValues(values, _validateSchema)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    callRoutineWithDates({ ...values, productId: id, showToast: true })
  }

  const handleSelectRange = ranges => setValues(ranges)

  return (
    <Form>
      <FormFieldsContainer>
        <div className='chart-dates__date-picker'>
          <DatePickerField
            value={values.dateFrom}
            disableFuture
            required
            name='dateFrom'
            label={t('form.dateFrom.label')}
            onChange={handleOnChange}
            validate={validateChartDatesSchemaField(values)}
            dependentValue={values.dateTo}
          />
        </div>
        <div className='chart-dates__date-picker'>
          <DatePickerField
            value={values.dateTo}
            disableFuture={!enableFuture}
            maxDate={moment(salePlan.expires_at)}
            required
            name='dateTo'
            label={t('form.dateTo.label')}
            onChange={handleOnChange}
            validate={validateChartDatesSchemaField(values)}
            dependentValue={values.dateFrom}
          />
        </div>
        <div className='chart-dates__range-picker'>
          <SelectChartDateRange values={values} onSelectRange={handleSelectRange} />
        </div>
        <div>
          <Button
            type='submit'
            id='button-submit-chart-dates'
            disabled={!valid || isLoading}
            onClick={handleSubmit}
          >
            {t('ordersPage.createShippingOrder.save')}
          </Button>
        </div>
      </FormFieldsContainer>
    </Form>
  )
}

export default withRouter(ChartDatesForm)

const FormFieldsContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.theme.breakpointsMedia.mobile} {
     flex-direction: column;
     align-items: flex-start;
  }
  
  .chart-dates__date-picker {
    max-width: 300px;
    margin-right: 10px;
  }
  
  .chart-dates__range-picker {
    margin-right: 10px;
  }
`
