import { propOr, pipe, equals, pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'
import { isNotNilOrEmpty } from 'utils/ramda'
import { isTodayOrPast } from 'utils/date'

export const selectSalePlans = state => state.salePlans

export const getIsSalePlansLoading = createSelector(
  selectSalePlans,
  pipe(
    propOr('', 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductSalePlan = createSelector(
  selectSalePlans,
  (state, id) => id,
  (salePlans, id) => pathOr({}, ['salePlans', id])(salePlans)
)

export const getHasCurrentSalePlanValid = createSelector(
  getProductSalePlan,
  (salePlan) => isNotNilOrEmpty(salePlan) && isTodayOrPast(salePlan.expires_at) === false
)
