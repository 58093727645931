import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork, delay } from '@redux-saga/core/effects'
import * as productFlagsService from 'services/ProductFlagsService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { getProductRoutine } from 'features/products/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { propOr } from 'ramda'

// ROUTINES

export const fetchProductTasksRoutine = createRoutine('FETCH_PRODUCT_TASKS')
export const createProductTaskRoutine = createRoutine('CREATE_PRODUCT_TASK')
export const toggleProductTasksRoutine = createRoutine('TOGGLE_PRODUCT_TASK')

// ACTIONS

function * createProductTask ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createProductTaskRoutine.request())
  try {
    yield call(productFlagsService.createProductTask, values)
    yield put(fetchProductTasksRoutine({ id: values.id }))
    yield put(createProductTaskRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.createTaskDefinitionSuccess',
        severity: SEVERITY.success
      })
    )
    yield delay(500)
    callback()
  } catch (e) {
    yield put(createProductTaskRoutine.failure(e))
    console.error(e)
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function * fetchProductTasks ({ payload }) {
  yield put(fetchProductTasksRoutine.request())
  try {
    const result = yield call(productFlagsService.fetchProductTasks, payload)
    yield put(fetchProductTasksRoutine.success({ productId: payload.id, result: propOr({}, 'data', result) }))
  } catch (e) {
    yield put(fetchProductTasksRoutine.failure(e))
    console.error(e)
  }
}

function * toggleProductTask ({ payload: { values = {}, callback = () => {} } }) {
  yield put(toggleProductTasksRoutine.request())
  try {
    yield call(productFlagsService.toggleProductTasks, values)
    yield delay(500)
    yield put(getProductRoutine({ id: values.id }))
    yield put(toggleProductTasksRoutine.success())
    if (values.isEnabled) {
      yield put(
        showToastRoutine({
          key: 'toast.enableFlagSuccess',
          severity: SEVERITY.success
        })
      )
    } else {
      yield put(
        showToastRoutine({
          key: 'toast.disableFlagSuccess',
          severity: SEVERITY.success
        })
      )
    }
    callback()
  } catch (e) {
    yield put(toggleProductTasksRoutine.failure(e))
    console.error(e)
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function * createProductTaskWatcher () {
  yield takeLatest(createProductTaskRoutine.TRIGGER, createProductTask)
}

export function * fetchProductTasksWatcher () {
  yield takeLatest(fetchProductTasksRoutine.TRIGGER, fetchProductTasks)
}

export function * toggleProductTasksWatcher () {
  yield takeLatest(toggleProductTasksRoutine.TRIGGER, toggleProductTask)
}

// SAGAS

export const flagsSagas = [
  fork(createProductTaskWatcher),
  fork(fetchProductTasksWatcher),
  fork(toggleProductTasksWatcher)
]
