import i18n from 'providers/i18n'
import { pathOr } from 'ramda'

export const USER_ROLES = {
  admin: 'admin',
  user: 'user'
}

export const userRoleOptions = () => [
  {
    label: i18n.t(`userRoles.${USER_ROLES.admin}`),
    value: USER_ROLES.admin
  },
  {
    label: i18n.t(`userRoles.${USER_ROLES.user}`),
    value: USER_ROLES.user
  }
]

export const getUserLoginById = (users, id) => pathOr('-', [id, 'login'])(users)
