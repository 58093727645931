import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { replace } from 'ramda'

export const replaceDotsWithDashes = str => replace('.', '-', str)

export const MONITORING_COLUMNS = [
  {
    id: replaceDotsWithDashes('thumbnail'),
    translationKey: 'monitoringPage.table.headers.thumbnail'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.ean),
    translationKey: 'monitoringPage.table.headers.id'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.productName),
    translationKey: 'monitoringPage.table.headers.name'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.stock),
    translationKey: 'monitoringPage.table.headers.stock'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.shippingStatus),
    translationKey: 'monitoringPage.table.headers.shippingStatus'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.retailPrice),
    translationKey: 'monitoringPage.table.headers.retailPrice'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.margin),
    translationKey: 'monitoringPage.table.headers.margin'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.frozenMoney),
    translationKey: 'monitoringPage.table.headers.frozenMoney'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.salePlanDiff),
    translationKey: 'monitoringPage.table.headers.salePlanDiff'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.soldNew),
    translationKey: 'monitoringPage.table.headers.soldNew'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.soldToday),
    translationKey: 'monitoringPage.table.headers.soldToday'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.sold1),
    translationKey: 'monitoringPage.table.headers.sold1'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.soldShort),
    translationKey: 'monitoringPage.table.headers.soldX'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.soldLong),
    translationKey: 'monitoringPage.table.headers.soldX'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.willLastShort),
    translationKey: 'monitoringPage.table.headers.willLastX'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.willLastLong),
    translationKey: 'monitoringPage.table.headers.willLastX'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.salesDynamics),
    translationKey: 'monitoringPage.table.headers.salesDynamics'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.lastChange),
    translationKey: 'monitoringPage.table.headers.lastChange'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.isBundled),
    translationKey: 'monitoringPage.table.headers.isBundled'
  },
  {
    id: replaceDotsWithDashes('flags'),
    translationKey: 'monitoringPage.table.headers.flags'
  },
  {
    id: replaceDotsWithDashes('promo'),
    translationKey: 'monitoringPage.table.headers.promo'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.allegroFee),
    translationKey: 'monitoringPage.table.headers.allegroFee'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.linesName),
    translationKey: 'monitoringPage.table.headers.line'
  },
  {
    id: replaceDotsWithDashes(API_FIELD_NAME.estimatedShippingTime),
    translationKey: 'monitoringPage.table.headers.estimatedShippingTime'
  }
]
