import { Global } from 'ducks/global/records'
import { reactToItemSelection, reactToArraySelection } from 'utils/array'
import {
  changeThemeRoutine,
  handleSelectItemRoutine,
  handleSelectCollectionRoutine,
  handleResetSelectionRoutine
} from 'ducks/global/actions'
import { updateMultipleShippingQuantityRoutine } from 'features/products/ducks/actions'
import { map, pipe, propOr } from 'ramda'

export default (state = new Global(), action) => {
  switch (action.type) {
    case changeThemeRoutine.SUCCESS:
      return state
        .set('theme', action.payload)
    case handleSelectItemRoutine.SUCCESS:
      return state
        .set('selectedItems', reactToItemSelection(action.payload.item)(state.selectedItems))
        .set('selectedItemsData', reactToItemSelection(action.payload.data)(state.selectedItemsData))
    case handleSelectCollectionRoutine.SUCCESS:
      return state
        .set('selectedItems', reactToArraySelection(action.payload.collection)(state.selectedItems))
        .set('selectedItemsData', reactToArraySelection(action.payload.collectionData)(state.selectedItemsData))
    case handleResetSelectionRoutine.SUCCESS:
      return state
        .set('selectedItems', [])
        .set('selectedItemsData', [])
    case updateMultipleShippingQuantityRoutine.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedSelectedItemsData = pipe(
        propOr([], 'selectedItemsData'),
        map(
          selectedItem => selectedItem.id === action.payload.product_id
            ? selectedItem.set('actualShippingQty', action.payload.actual_shipping_qty)
            : selectedItem)
      )(state)
      return state
        .set('selectedItemsData', updatedSelectedItemsData)
    default:
      return state
  }
}
