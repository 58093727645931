import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import CreateSalePlanForm from 'features/salePlans/CreateSalePlanForm'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

export const CreateSalePlanModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ModalTriggerContainer>
      <Button onClick={handleOpen}>{t('productPage.salePlan.createPlan.title')}</Button>
      <Modal
        title={t('productPage.salePlan.createPlan.title')}
        open={open}
        handleClose={handleClose}
      >
        <CreateSalePlanForm onSubmit={handleClose} />
      </Modal>
    </ModalTriggerContainer>
  )
}

export default CreateSalePlanModal

export const ModalTriggerContainer = styled.div`
  flex: none;
  margin: 0 10px 0 40px;
`
