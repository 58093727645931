import { propOr, pipe, equals, pathOr, reverse } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'
import { ProductCommentMeta } from 'features/comments/ducks/records'

export const selectProductComments = state => state.comments

export const getIsProductCommentsLoading = createSelector(
  selectProductComments,
  pipe(
    propOr('', 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getProductCommentsMeta = createSelector(
  selectProductComments,
  (state, id) => id,
  (comments, id) => pathOr(new ProductCommentMeta(), ['products', id, 'meta'])(comments)
)

export const getProductCommentsArray = createSelector(
  selectProductComments,
  (state, id) => id,
  (comments, id) => pipe(
    pathOr([], ['products', id, 'comments']),
    reverse
  )(comments)
)
