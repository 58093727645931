import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { syncSingleAuctionRoutine } from 'features/productAuctions/ducks/actions'
import { getIsSyncAuctionLoading } from 'features/productAuctions/ducks/selectors'

import IconButton from 'components/atoms/IconButton'
import Tooltip from 'components/atoms/Tooltip'
import Sync from 'components/icons/Sync'

export const SyncSingleAuction = (props) => {
  const { offerId, match: { params: { id } } } = props
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSyncAuctionLoading)
  const { t } = useTranslation()

  const syncAuction = useCallback(
    () => dispatch(syncSingleAuctionRoutine({ productId: id, offerId: offerId })),
    [dispatch]
  )

  return (
    <Tooltip title={t('productPage.saleChannels.auctions.sync.tooltip')}>
      <IconButton
        type='button'
        id='button-sync'
        color='primary'
        onClick={syncAuction}
        disabled={isLoading}
      >
        <Sync className='icon-container' />
      </IconButton>
    </Tooltip>
  )
}

export default withRouter(SyncSingleAuction)
