import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SwipeableDrawer as MaterialDrawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generateSelectedTheme, THEME_MODES } from 'utils/theme'

import { getSelectedTheme } from 'ducks/global/selectors'

const Drawer = (props) => {
  const {
    isOpen,
    children,
    onClose,
    onOpen,
    anchor
  } = props

  const selectedThemeMode = useSelector(getSelectedTheme)

  const useDrawerStyles = makeStyles((muiTheme) => ({
    root: {
      backgroundColor: selectedThemeMode === THEME_MODES.light
        ? generateSelectedTheme().palette.primary.main
        : generateSelectedTheme().palette.common.white
    },
    backdrop: {
      backgroundColor: generateSelectedTheme().palette.common.gray100
    }
  }))

  const classes = useDrawerStyles()

  return (
    <MaterialDrawer
      classes={{
        paper: classes.root
      }}
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      anchor={anchor}
      ModalProps={{ onBackdropClick: onClose }}
      BackdropProps={{
        timeout: 500,
        style: {
          opacity: '.6'
        },
        className: classes.backdrop
      }}
    >
      <StyledContent>{children}</StyledContent>
    </MaterialDrawer>
  )
}

Drawer.defaultProps = {
  anchor: 'left',
  onOpen: () => {},
  onClose: () => {}
}

export default Drawer

const StyledContent = styled.div`
  min-width: 300px;
  height: 100%;
  box-sizing: border-box;
  padding: 0 17px;
`
