import errors, { getErrorName } from 'utils/errors'
import { SEVERITY } from 'utils/toast'

export const getEmailError = error => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.entityNotFound:
      errorTranslationKey = 'toast.emailNotFound'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}
