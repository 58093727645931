import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import { nullableFilterOptions } from 'utils/filters'

import SingleSelectFilter from 'components/tableFilters/SingleSelectFilter'
import Grid from 'components/atoms/Grid'

export const ProducersFilters = (props) => {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <SingleSelectFilter options={nullableFilterOptions()} label={t('producersPage.filters.hasLine')} name={API_FIELD_NAME.linesName} />
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default ProducersFilters

const StyledContainer = styled.div`
  max-width: 700px; 
  
  ${props => props.theme.breakpointsMedia.mobile} {
    max-width: 100%;
  }
`
