import { propOr, pipe, equals } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'

export const selectOrders = state => state.orders

export const getIsOrdersLoading = createSelector(
  selectOrders,
  pipe(
    propOr([], 'state'),
    equals(API_STATES.IN_PROGRESS)
  )
)

export const getOrdersMeta = createSelector(
  selectOrders,
  propOr({}, 'ordersMeta')
)

export const getOrdersArray = createSelector(
  selectOrders,
  propOr([], 'orders')
)
