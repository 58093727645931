import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { formatPrice, roundAndFormatPrice } from 'utils/price'
import {
  formatNumber,
  roundAndFormatNumber,
  twoDecimal
} from 'utils/number'

import { getProductDetails } from 'features/products/ducks/selectors'
import { getSoldLongValue, getSoldShortValue } from 'features/settings/ducks/selectors'

import Table from 'components/atoms/Table/Table'
import TableBody from 'components/atoms/Table/TableBody'
import TableHeader from 'components/atoms/Table/TableHeader'
import TableHeaderCell from 'components/atoms/Table/TableHeaderCell'
import TableRow from 'components/atoms/Table/TableRow'
import { API_FIELD_NAME } from 'utils/apiFilterFields'
import TableCell from 'components/atoms/Table/TableCell'
import DynamicsIndicator from 'components/DynamicsIndicator'
import { isNotNilOrEmpty } from 'utils/ramda'
import { formatDate, FORMATS } from 'utils/date'
import BooleanIcons from 'components/BooleanIcons'
import AllegroPromo from 'features/allegro/components/AllegroPromo'
import AllegroAds from 'components/icons/AllegroAds'
import Star from 'components/icons/Star'
import AllegroPackagePromo from 'components/icons/AllegroPackagePromo'
import AllegroBargain from 'components/icons/AllegroBargain'
import AllegroHalfPercent from 'components/icons/AllegroHalfPercent'
import Tooltip from 'components/atoms/Tooltip'
import CopyToClipboard from 'components/CopyToClipboard'

export const ProductMonitoringRow = (props) => {
  const { match: { params: { id } } } = props
  const data = useSelector(state => getProductDetails(state, id))
  const soldLongValue = useSelector(getSoldLongValue)
  const soldShortValue = useSelector(getSoldShortValue)
  const { t } = useTranslation()

  const headers = [
    {
      id: API_FIELD_NAME.ean,
      sortable: true,
      cell: t('monitoringPage.table.headers.id')
    },
    {
      id: API_FIELD_NAME.stock,
      sortable: false,
      cell: t('monitoringPage.table.headers.stock'),
      tooltip: t('monitoringPage.table.tooltip.stock'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.retailPrice,
      sortable: false,
      cell: t('monitoringPage.table.headers.retailPrice'),
      tooltip: t('monitoringPage.table.tooltip.retailPrice'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.purchasePrice,
      sortable: false,
      cell: t('monitoringPage.table.headers.purchasePrice'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.margin,
      sortable: false,
      cell: t('monitoringPage.table.headers.margin'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.frozenMoney,
      sortable: false,
      cell: t('monitoringPage.table.headers.frozenMoney'),
      tooltip: t('monitoringPage.table.tooltip.frozenMoney'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldToday,
      sortable: false,
      cell: t('monitoringPage.table.headers.soldToday'),
      tooltip: t('monitoringPage.table.tooltip.soldToday'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.sold1,
      sortable: false,
      cell: t('monitoringPage.table.headers.sold1'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldShort,
      sortable: false,
      cell: t('monitoringPage.table.headers.soldX', { count: soldShortValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.soldLong,
      sortable: false,
      cell: t('monitoringPage.table.headers.soldX', { count: soldLongValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.willLastShort,
      sortable: false,
      cell: t('monitoringPage.table.headers.willLastX', { count: soldShortValue }),
      tooltip: t('monitoringPage.table.tooltip.willLastX', { count: soldShortValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.willLastLong,
      sortable: false,
      cell: t('monitoringPage.table.headers.willLastX', { count: soldLongValue }),
      tooltip: t('monitoringPage.table.tooltip.willLastX', { count: soldLongValue }),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.salesDynamics,
      sortable: false,
      cell: t('monitoringPage.table.headers.salesDynamics'),
      tooltip: t('monitoringPage.table.tooltip.salesDynamics'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.iaiCreatedAt,
      sortable: false,
      cell: t('monitoringPage.table.headers.iaiCreatedAt'),
      tooltip: t('monitoringPage.table.tooltip.iaiCreatedAt')
    },
    {
      id: API_FIELD_NAME.activatedAt,
      sortable: false,
      cell: t('monitoringPage.table.headers.activatedAt'),
      tooltip: t('monitoringPage.table.tooltip.activatedAt')
    },
    {
      id: API_FIELD_NAME.lastChange,
      sortable: false,
      cell: t('monitoringPage.table.headers.lastChange'),
      tooltip: t('monitoringPage.table.tooltip.lastChange')
    },
    {
      id: API_FIELD_NAME.isBundled,
      sortable: false,
      cell: t('monitoringPage.table.headers.isBundled')
    },
    {
      id: 'promo',
      sortable: false,
      cell: t('monitoringPage.table.headers.promo')
    },
    {
      id: API_FIELD_NAME.allegroFee,
      sortable: false,
      cell: t('monitoringPage.table.headers.allegroFee'),
      tooltip: t('monitoringPage.table.tooltip.allegroFee'),
      align: 'right'
    },
    {
      id: API_FIELD_NAME.linesName,
      sortable: false,
      cell: t('monitoringPage.table.headers.line')
    },
    {
      id: API_FIELD_NAME.estimatedShippingTime,
      sortable: false,
      cell: t('monitoringPage.table.headers.estimatedShippingTime'),
      tooltip: t('monitoringPage.table.tooltip.estimatedShippingTime'),
      align: 'right'
    }
  ]

  const renderHeaders = headers.map(header => (
    <TableHeaderCell
      disableSticky
      key={header.id}
      sortable={header.sortable}
      columnId={header.id}
      align={header.align || 'left'}
      tooltip={header.tooltip || null}
    >
      {header.cell}
    </TableHeaderCell>
  ))

  const renderRow = (
    <TableRow hover key={propOr(`product-details-row-${id}`, 'id', data)}>
      <TableCell>
        <Tooltip title={propOr('', 'ean', data)}>
          <div>
            <CopyToClipboard textToCopy={propOr('', 'ean', data)}>
              {propOr('-', 'ean', data)}
            </CopyToClipboard>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'stock', data))}
        {data.virtualStock > 0 && ` (${formatNumber(propOr(twoDecimal(0), 'virtualStock', data))})`}
      </TableCell>
      <TableCell align='right'>
        {formatPrice(propOr(twoDecimal(0), 'retailPrice', data))}
      </TableCell>
      <TableCell align='right'>
        {formatPrice(propOr(twoDecimal(0), 'purchasePrice', data))}
      </TableCell>
      <TableCell align='right'>
        {propOr('0.00', 'margin', data)}%
      </TableCell>
      <TableCell align='right'>
        {roundAndFormatPrice(propOr(twoDecimal(0), 'frozenMoney', data))}
      </TableCell>
      <TableCell align='right'>
        <span id='product-monitoring-row-sold-today'>
          {formatNumber(propOr(twoDecimal(0), 'soldToday', data))}
        </span>
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'sold1', data))}
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'soldShort', data))}
      </TableCell>
      <TableCell align='right'>
        {formatNumber(propOr(twoDecimal(0), 'soldLong', data))}
      </TableCell>
      <TableCell align='right'>
        {roundAndFormatNumber(propOr(twoDecimal(0), 'willLastShort', data))}
      </TableCell>
      <TableCell align='right'>
        {roundAndFormatNumber(propOr(twoDecimal(0), 'willLastLong', data))}
      </TableCell>
      <TableCell align='right'>
        <DynamicCellContainer>
          <DynamicsIndicator value={propOr(0, 'salesDynamics')(data)} />
          <span>
            {formatNumber(propOr(twoDecimal(0), 'salesDynamics', data))}%
          </span>
        </DynamicCellContainer>
      </TableCell>
      <TableCell>
        {isNotNilOrEmpty(data.iaiCreatedAt) ? formatDate(data.iaiCreatedAt, FORMATS.slash) : '-'}
      </TableCell>
      <TableCell>
        {isNotNilOrEmpty(data.activatedAt) ? formatDate(data.activatedAt, FORMATS.slash) : '-'}
      </TableCell>
      <TableCell>
        {
          isNotNilOrEmpty(propOr(null, 'latestCommentCreatedAt', data)) ? (
            <Tooltip
              title={
                <TooltipContent>
                  <strong>{data.latestCommentAuthor}:</strong>
                  <br />{data.latestCommentContent}
                </TooltipContent>
              }
              interactive
            >
              <LastCommentCreatedAtContainer>
                {formatDate(data.latestCommentCreatedAt, FORMATS.slash)}
              </LastCommentCreatedAtContainer>
            </Tooltip>
          ) : '-'
        }
      </TableCell>
      <TableCell>
        <BooleanIcons checked={propOr(false, 'isBundled', data)} />
      </TableCell>
      <TableCell>
        <PromoCellContainer>
          {propOr(false, 'hasAds', data) && <AllegroPromo logo={<AllegroAds />} promoType={t('monitoringPage.table.allegroPromoTypes.hasAds')} />}
          {propOr(false, 'isEmphasized', data) && <AllegroPromo logo={<Star />} promoType={t('monitoringPage.table.allegroPromoTypes.isEmphasized')} />}
          {propOr(false, 'hasPromotionPackage', data) && <AllegroPromo logo={<AllegroPackagePromo />} promoType={t('monitoringPage.table.allegroPromoTypes.hasPromotionPackage')} />}
          {propOr(false, 'isBargain', data) && <AllegroPromo logo={<AllegroBargain />} promoType={t('monitoringPage.table.allegroPromoTypes.isBargain')} />}
          {propOr(false, 'hasHalfPercent', data) && <AllegroPromo logo={<AllegroHalfPercent />} promoType={t('monitoringPage.table.allegroPromoTypes.hasHalfPercent')} />}
        </PromoCellContainer>
      </TableCell>
      <TableCell align='right'>
        {formatPrice(propOr(twoDecimal(0), 'allegroFee', data))}
      </TableCell>
      <TableCell>
        {propOr('-', 'lineName', data)}
      </TableCell>
      <TableCell align='right'>
        {roundAndFormatNumber(propOr(twoDecimal(0), 'estimatedShippingTime', data))}
      </TableCell>
    </TableRow>
  )

  return (
    <ProductMonitoringRowContainer>
      <Table padding='none'>
        <TableHeader>
          <TableRow>
            {renderHeaders}
          </TableRow>
        </TableHeader>
        <TableBody>
          {renderRow}
        </TableBody>
      </Table>
    </ProductMonitoringRowContainer>
  )
}

export default withRouter(ProductMonitoringRow)

const ProductMonitoringRowContainer = styled.div`
 
  width: 100%;
  overflow-x: auto;
  margin-top: 10px;
  background-color: ${props => props.theme.palette.common.gray100};
  border-radius: ${props => props.theme.shape.borderRadius};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-top: 20px;
  }
  
  .MuiTableCell-root,
  .MuiTableRow-head,
  .MuiTableRow-root {
    background-color: transparent !important;
    font-size: 11px !important;
    line-height: 12px !important;
    border: none !important;
  }
`

const PromoCellContainer = styled.div`
  display: flex;
`

const LastCommentCreatedAtContainer = styled.div`
  cursor: pointer;
`

const TooltipContent = styled.div`
  font-size: 11px;
  font-weight: 300;
`

const DynamicCellContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
