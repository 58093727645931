import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import CurrentSalePlan from 'features/salePlans/CurrentSalePlan'
import SaleChart from 'features/products/components/SaleChart'
import CumulativeSaleChart from 'features/products/components/CumulativeSaleChart'
import Divider from 'components/atoms/Divider'
import { useDispatch } from 'react-redux'
import { fetchDaysOffRoutine } from 'features/daysOff/ducks/actions'
import Grid from 'components/atoms/Grid'

export const SaleSection = (props) => {
  const dispatch = useDispatch()

  const fetchDaysOff = useCallback(
    () => dispatch(fetchDaysOffRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchDaysOff()
  }, [])

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SaleChart />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CumulativeSaleChart />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Divider />
          <CurrentSalePlan />
        </Grid>
      </Grid>
    </div>
  )
}

export default SaleSection

export const ChartContainer = styled.div`
  display: inline-block;
  margin-right: 40px;
`
