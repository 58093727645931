import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { pipe, propOr } from 'ramda'
import { withRouter } from 'react-router-dom'

import { getOrdersArray, getOrdersMeta, getIsOrdersLoading } from 'features/orders/ducks/selectors'
import { fetchTodayOrdersRoutine } from 'features/orders/ducks/actions'

import TableCell from 'components/atoms/Table/TableCell'
import TableRow from 'components/atoms/Table/TableRow'
import EntitiesList from 'components/EntitiesList'
import { formatDate, FORMATS } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import { numberWithSpaces, twoDecimal } from 'utils/number'
import { convertPriceWithLabel } from 'utils/price'
import BooleanIcons from 'components/BooleanIcons'
import { SORT_ORDER } from 'utils/table'

export const TodaysOrdersTable = (props) => {
  const { match: { params: { id } } } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(getOrdersArray)
  const isLoading = useSelector(getIsOrdersLoading)
  const pagination = useSelector(getOrdersMeta)
  const fetchOrders = useCallback(
    query => dispatch(fetchTodayOrdersRoutine({ query, id })),
    [dispatch]
  )

  const getNumberPropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      numberWithSpaces
    )

  const getPricePropAndFormat = key =>
    pipe(
      propOr(twoDecimal(0), key),
      convertPriceWithLabel,
      numberWithSpaces
    )

  const headers = [
    {
      id: 'created_at',
      sortable: true,
      cell: t('productPage.orders.table.headers.createdAt')
    },
    {
      id: 'shipped_at',
      sortable: true,
      cell: t('productPage.orders.table.headers.shippedAt')
    },
    {
      id: 'quantity',
      sortable: true,
      cell: t('productPage.orders.table.headers.quantity'),
      align: 'right'
    },
    {
      id: 'price',
      sortable: true,
      cell: t('productPage.orders.table.headers.price'),
      align: 'right'
    },
    {
      id: 'sold_in_bundle',
      sortable: true,
      cell: t('productPage.orders.table.headers.bundle'),
      align: 'right'
    }
  ]

  const renderItems = data.map((data, index) => (
    <TableRow
      hover
      key={propOr(`orders-row-${index}`, 'id', data)}
      id={`sold-today-row-${index}`}
    >
      <TableCell>
        {formatDate(data.created_at, FORMATS.slash)}
      </TableCell>
      <TableCell>
        {isNotNilOrEmpty(data.shipped_at) ? formatDate(data.shipped_at, FORMATS.slash) : '-'}
      </TableCell>
      <TableCell align='right'>
        <span id={`sold-today-row-${index}-quantity`} className='sold-today-quantity'>
          {getNumberPropAndFormat('quantity')(data)}
        </span>
      </TableCell>
      <TableCell align='right'>
        {getPricePropAndFormat('price')(data)}
      </TableCell>
      <TableCell align='right'>
        <BooleanIcons checked={propOr(false, 'sold_in_bundle', data)} />
      </TableCell>
    </TableRow>
  ))

  return (
    <div id='sold-today-list-table'>
      <EntitiesList
        disableSticky
        emptyStateText={t('productPage.orders.table.emptyState')}
        headers={headers}
        pagination={pagination}
        tableRows={renderItems}
        fetchRecords={fetchOrders}
        isLoading={isLoading}
        defaultSortedColumnId='created_at'
        defaultSortDirection={SORT_ORDER.desc}
      />
    </div>
  )
}

export default withRouter(TodaysOrdersTable)
