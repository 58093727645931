import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Add User form validator

export const addUserSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' })),
  login: string()
    .required(() => ({ key: 'form.login.errors.required' }))
    .min(3, () => ({
      key: 'form.login.errors.lengthMin',
      options: { number: 3 }
    }))
    .max(40, () => ({
      key: 'form.login.errors.lengthMax',
      options: { number: 40 }
    })),
  role: string().required(() => ({ key: 'form.role.errors.required' }))
})

export const validateAddUserField = values => validateField(addUserSchema, values)
export const validateAddUserValues = validateValues(addUserSchema)

// Update User form validator

export const updateUserSchema = object().shape({
  id: string().required(),
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' })),
  login: string()
    .required(() => ({ key: 'form.login.errors.required' }))
    .min(3, () => ({
      key: 'form.login.errors.lengthMin',
      options: { number: 3 }
    }))
    .max(40, () => ({
      key: 'form.login.errors.lengthMax',
      options: { number: 40 }
    })),
  role: string().required(() => ({ key: 'form.role.errors.required' }))
})

export const validateUpdateUserField = values => validateField(updateUserSchema, values)
export const validateUpdateUserValues = validateValues(updateUserSchema)
