import React from 'react'
import styled from 'styled-components'

import Checkmark from 'components/icons/Checkmark'
import Close from 'components/icons/Close'

export const BooleanIcons = (props) => {
  const { checked } = props

  return checked ? <StyledCheckmark /> : <StyledClose />
}

export default BooleanIcons

const StyledCheckmark = styled(Checkmark)`
  color: ${props => props.theme.palette.success.main};
`

const StyledClose = styled(Close)`
  color: ${props => props.theme.palette.common.gray300};
`
