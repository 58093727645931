import {
  not,
  includes,
  append,
  ifElse,
  filter,
  pipe,
  equals,
  without,
  uniq,
  flatten,
  lt,
  length, identity
} from 'ramda'

import { isInArray } from 'utils/ramda'

export const arrayContainsPartArray = array => pipe(
  filter(isInArray(array)),
  length,
  lt(0)
)

export const arrayContainsFullArray = baseArray => arrayToCompare => pipe(
  filter(isInArray(arrayToCompare)),
  length,
  filteredLength => equals(filteredLength, length(arrayToCompare))
)(baseArray)

export const reactToItemSelection = item => ifElse(
  includes(item),
  filter(
    pipe(
      equals(item),
      not
    )
  ),
  append(item)
)

export const reactToArraySelection = collection => ifElse(
  arrayContainsPartArray(collection),
  without(collection),
  pipe(
    append(collection),
    flatten,
    uniq
  )
)

export const addToArrayIfNotIncluded = item =>
  ifElse(
    includes(item),
    identity,
    append(item)
  )
