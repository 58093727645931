
import { Orders, Order, OrdersMeta } from 'features/orders/ducks/records'
import { fetchTodayOrdersRoutine } from 'features/orders/ducks/actions'
import { API_STATES, transferCollectionToImmutable } from 'utils/redux'

export default (state = new Orders(), action) => {
  switch (action.type) {
    case fetchTodayOrdersRoutine.REQUEST:
      return state
        .set('state', API_STATES.IN_PROGRESS)
    case fetchTodayOrdersRoutine.FAILURE:
      return state
        .set('state', API_STATES.DONE)
    case fetchTodayOrdersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('orders', transferCollectionToImmutable(action.payload.data, Order))
        .set('ordersMeta', new OrdersMeta(action.payload.meta))
    default:
      return state
  }
}
