import { Monitoring, MonitoringMeta, MonitoringItem } from 'features/monitoring/ducks/records'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'
import { transferCollectionToImmutable, API_STATES } from 'utils/redux'

export default (state = new Monitoring(), action) => {
  switch (action.type) {
    case fetchMonitoringRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case fetchMonitoringRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case fetchMonitoringRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('collection', transferCollectionToImmutable(action.payload.data, MonitoringItem))
        .set('monitoringMeta', new MonitoringMeta(action.payload.meta))
    default:
      return state
  }
}
