import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork } from '@redux-saga/core/effects'

// ROUTINES

export const showToastRoutine = createRoutine('SHOW_TOAST')
export const hideToastRoutine = createRoutine('HIDE_TOAST')

// ACTIONS

function * showToast ({ payload }) {
  yield put(showToastRoutine.request())
  try {
    yield put(showToastRoutine.success({ ...payload, visible: true }))
  } catch (e) {
    yield put(showToastRoutine.failure(e))
    console.error(e)
  }
}

function * hideToast () {
  yield put(hideToastRoutine.request())
  try {
    yield put(hideToastRoutine.success())
  } catch (e) {
    yield put(hideToastRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function * showToastWatcher () {
  yield takeLatest(showToastRoutine.TRIGGER, showToast)
}

export function * hideToastWatcher () {
  yield takeLatest(hideToastRoutine.TRIGGER, hideToast)
}

// SAGAS

export const toastSagas = [fork(showToastWatcher), fork(hideToastWatcher)]
