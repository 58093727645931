import { mergeRight } from 'ramda'
import { Account, Allegro, AccountsMeta } from 'features/allegro/ducks/records'
import { fetchAllegroAccountsRoutine, editAllegroAccountRoutine } from 'features/allegro/ducks/actions'
import { transferCollectionToImmutableById, transferEntityToImmutableById } from 'utils/redux'

export default (state = new Allegro(), action) => {
  switch (action.type) {
    case editAllegroAccountRoutine.SUCCESS:
      return state
        .set('accounts', mergeRight(state.accounts, transferEntityToImmutableById(action.payload, Account)))
    case fetchAllegroAccountsRoutine.SUCCESS:
      return state
        .set('accounts', transferCollectionToImmutableById(action.payload.data, Account))
        .set('accountsMeta', new AccountsMeta(action.payload.meta))
    default:
      return state
  }
}
