import { string, object, number } from 'yup'
import {
  validateValues,
  validateField
} from 'utils/form'

// Create sale plan form validator

export const createSalePlanSchema = object().shape({
  productId: string().required(),
  expiresAt: string()
    .required(() => ({ key: 'form.expiresAt.errors.required' })),
  stockAfter: number()
    .required(() => ({ key: 'form.stockAfter.errors.required' }))
    .min(0, () => ({
      key: 'form.stockAfter.errors.numberMin',
      options: { number: 0 }
    }))
})

export const validateCreateSalePlanSchemaField = values => validateField(createSalePlanSchema, values)
export const validateCreateSalePlanSchemaValues = validateValues(createSalePlanSchema)
