import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as shippingOrdersService from 'services/ShippingOrdersService'
import { showToastRoutine } from 'features/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { generateCurrentQuery } from 'utils/query'
import { fetchMonitoringRoutine } from 'features/monitoring/ducks/actions'
import { pathOr } from 'ramda'

// ROUTINES

export const fetchShippingOrdersRoutine = createRoutine('FETCH_SHIPPING_ORDERS')
export const createShippingOrderRoutine = createRoutine('CREATE_SHIPPING_ORDER')
export const editShippingOrderRoutine = createRoutine('EDIT_SHIPPING_ORDER')
export const editShippingOrderDateRoutine = createRoutine('EDIT_SHIPPING_ORDER_DATE')
export const getShippingOrderItemsRoutine = createRoutine('GET_SHIPPING_ORDER_ITEMS')

// ACTIONS

function * createShippingOrder ({ payload: { values = {}, callback = () => {} } }) {
  yield put(createShippingOrderRoutine.request())
  try {
    yield call(shippingOrdersService.createShippingOrders, values)
    yield put(
      showToastRoutine({
        key: 'toast.createShippingOrderSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchMonitoringRoutine(generateCurrentQuery()))
    yield put(createShippingOrderRoutine.success())
    callback()
  } catch (e) {
    yield put(createShippingOrderRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

function * fetchShippingOrders ({ payload }) {
  yield put(fetchShippingOrdersRoutine.request())
  try {
    const result = yield call(shippingOrdersService.fetchShippingOrders, payload)
    yield put(fetchShippingOrdersRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchShippingOrdersRoutine.failure(e))
  }
}

function * editShippingOrder ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editShippingOrderRoutine.request())
  try {
    yield call(shippingOrdersService.editShippingOrder, values)
    yield put(getShippingOrderItemsRoutine({ id: values.id }))
    yield put(
      showToastRoutine({
        key: 'toast.editShippingOrderSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchShippingOrdersRoutine(generateCurrentQuery()))
    yield put(editShippingOrderRoutine.success())
    callback()
  } catch (e) {
    yield put(editShippingOrderRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

function * editShippingOrderDate ({ payload: { values = {}, callback = () => {} } }) {
  yield put(editShippingOrderDateRoutine.request())
  try {
    yield call(shippingOrdersService.editShippingOrderDate, values)
    yield put(
      showToastRoutine({
        key: 'toast.editShippingOrderSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchShippingOrdersRoutine(generateCurrentQuery()))
    yield put(editShippingOrderDateRoutine.success())
    callback()
  } catch (e) {
    yield put(editShippingOrderDateRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

function * getShippingOrderItems ({ payload: { id } }) {
  yield put(getShippingOrderItemsRoutine.request())
  try {
    const result = yield call(shippingOrdersService.getShippingOrderItems, { id })
    yield put(getShippingOrderItemsRoutine.success({ id, data: pathOr([], ['data', 'data'], result) }))
  } catch (e) {
    yield put(getShippingOrderItemsRoutine.failure(e))
    yield put(showToastRoutine({
      key: 'toast.somethingWentWrong',
      severity: SEVERITY.error
    }))
  }
}

// WATCHERS

export function * createShippingOrderWatcher () {
  yield takeLatest(createShippingOrderRoutine.TRIGGER, createShippingOrder)
}

export function * fetchShippingOrdersWatcher () {
  yield takeLatest(fetchShippingOrdersRoutine.TRIGGER, fetchShippingOrders)
}

export function * editShippingOrderWatcher () {
  yield takeLatest(editShippingOrderRoutine.TRIGGER, editShippingOrder)
}

export function * editShippingOrderDateWatcher () {
  yield takeLatest(editShippingOrderDateRoutine.TRIGGER, editShippingOrderDate)
}

export function * getShippingOrderItemsWatcher () {
  yield takeLatest(getShippingOrderItemsRoutine.TRIGGER, getShippingOrderItems)
}

export const shippingOrdersSagas = [
  fork(createShippingOrderWatcher),
  fork(fetchShippingOrdersWatcher),
  fork(editShippingOrderWatcher),
  fork(editShippingOrderDateWatcher),
  fork(getShippingOrderItemsWatcher)
]
